import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardActions } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import useRequest from '../../../hooks/useRequest';
import { useMst } from '../../../models';
import BoardService from '../../../services/BoardService';
import NoticeService from '../../../services/NoticeService';
import '../../Board/css/_custom.scss';
import { useTranslation } from 'react-i18next';
import { commonsMenu, noticeListMenu } from '../../../menu';
import Badge from '../../../components/bootstrap/Badge';
import showNotification from '../../../components/extras/showNotification';

const NoticeBoard = () => {
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef();
	const { company } = useMst();
	const { responseData, loading, request } = useRequest([]);
	const noticeColumnList = useMemo(() => [{ name: '제목' }, { name: '작성일' }], []);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	useEffectOnce(() => {
		request(
			BoardService.list,
			['notice', company.get.id],
			(res) => {
				if (res?.data) {
					const response = res.data;
					let sorted = [];
					response.sort((a, b) => {
						if (a.createdAt < b.createdAt) {
							return 1;
						}
						if (a.createdAt > b.createdAt) {
							return -1;
						}
						return 0;
					});
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === true));
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === false && data.notice === true));
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === false && data.notice === false));
					return { data: sorted };
				}
			},
			(error) => {
				showNotification(t('공지 사항'), t('공지 사항을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
			}
		);
	});

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const isAll = responseData[rowIndex - 1]?.category?.includes('ALL');

			switch (columnIndex) {
				case 0:
					data =
						responseData[rowIndex - 1]?.notice === true || isAll ? (
							<div>
								<span className={`small me-2 ${isAll ? '' : 'noti-badge'}`}>
									{isAll ? <Badge>Pinat</Badge> : <Icon icon='PushPin' />}
								</span>
								<span>{responseData[rowIndex - 1]?.title || '-'}</span>
								<span>{responseData[rowIndex - 1]?.files?.length > 0 ? <Icon icon='AttachFile' /> : ''}</span>
							</div>
						) : responseData[rowIndex - 1]?.notice === false ? (
							<div>
								<span>{responseData[rowIndex - 1]?.title || '-'}</span>
								<span>{responseData[rowIndex - 1]?.files?.length > 0 ? <Icon icon='AttachFile' /> : ''}</span>
							</div>
						) : (
							''
						);
					break;
				case 1:
					data = timeForToday(responseData[rowIndex - 1]?.createdAt);
					break;
			}

			return data;
		},
		[responseData]
	);

	function isWithinAWeek(dateString) {
		const inputDate = new Date(dateString);
		const currentDate = new Date();
		const oneWeek = 7 * 24 * 60 * 60 * 1000;
		return currentDate.getTime() - inputDate.getTime() <= oneWeek;
	}

	const timeForToday = (value) => {
		const today = new Date();
		const timeValue = new Date(value);

		const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
		if (betweenTime < 1) return '방금전';
		if (betweenTime < 60) {
			return `${betweenTime}분전`;
		}

		const betweenTimeHour = Math.floor(betweenTime / 60);
		if (betweenTimeHour < 24) {
			return `${betweenTimeHour}시간전`;
		}

		return moment(timeValue).format('YYYY-MM-DD');
	};

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(noticeColumnList[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer ${
							columnIndex === 0 ? 'justify-content-start' : 'justify-content-center'
						} ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
						onClick={() => {
							navigate(`${noticeListMenu.notice.path}/${responseData[rowIndex - 1].idx}`);
						}}>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, noticeColumnList, responseData, navigate, renderText, t]
	);

	return (
		<Card stretch style={{backgroundColor:'transparent', boxShadow:'none'}}>
			<CardHeader className='flex-row' style={{backgroundColor:'transparent', boxShadow:'none'}}>
				<CardLabel>
					<CardTitle className={'cursor-pointer'} onClick={() => navigate(noticeListMenu.notice.path)}>
						{t('공지 사항')}
					</CardTitle>
				</CardLabel>
				<CardActions>
					<div className='d-flex align-items-center cursor-pointer'
						onClick={()=> {
							navigate(noticeListMenu.notice.path);
						}}
					>
						{t('더보기')} <Icon icon='KeyboardArrowRight' size='lg' />
					</div>
				</CardActions>
			</CardHeader>
			<CardBody
				className='overflow-auto dashboard-height p-0'
				style={{ minHeight: '285px' }}
				ref={ref}
				onMouseOver={(e) => {
					if (ref?.current?.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					if(ref?.current) {
						ref.current.scroll = true;
						document.body.style.overflow = 'auto';
						document.body.style.removeProperty('padding-right');
					}
				}}>
				{loading && responseData.length <= 0 ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) : !loading && responseData.length <= 0 ? (
					<div className='pb-5 lead h-100 d-flex flex-column justify-content-center align-itmes-center text-center'>
						<span>{t('공지사항이 없습니다.')}</span>
					</div>
				) : (
					<div className='w-100'>
						{
							responseData.map((v,i) => {
								return (
									<Card
										key={i}
										className='custom-box-shadow rounded-2'
										style={i === responseData.length - 1 ? {cursor:'pointer', marginBottom:'6px'} : {cursor:'pointer'}}
										onClick={() => {
											navigate(`${noticeListMenu.notice.path}/${responseData[i].idx}`);
										}}
									>
										<CardBody className='d-flex justify-content-between align-itmes-center px-4 py-5'>
											<div>
												{
													v.category?.includes('ALL') && <Badge className='me-3'>공지</Badge>
												}
												<span>{v.title}</span>
												<span className='ms-1'>{responseData[i]?.files?.length > 0 ? <Icon icon='AttachFile' /> : ''}</span>
												{
													isWithinAWeek(v.createdAt) && <Badge rounded={1} color='danger' className={'p-1 text-white'}>N</Badge>
												}
											</div>
											<div>
												<span>{timeForToday(v.createdAt)}</span>
											</div>
										</CardBody>
									</Card>
								)
							})
						}
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default memo(NoticeBoard);
