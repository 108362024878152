let host = window.location.host.indexOf('localhost') > -1 ? 'local' : window.location.host.replace('lite.pinat.co.kr', '')
if (!host) host = 'prod'
// host = 'prod' 

if (window.location.host.indexOf('metapay-dev.co.kr') > -1) {
  host = 'lsdev'
}
if (window.location.host.indexOf('metapay.co.kr') > -1) {
  host = 'lsprod'
}
if (window.location.host.indexOf('ls.pinat.co.kr') > -1) {
  host = 'lsdev'
}

let appVersion = 'APP_VERSION'
let buildVersion = 'BUILD_VERSION'

const domainGroups = {
  pinat: ['localhost:3000', 'devwork.pinat.co.kr', 'qawork.pinat.co.kr', 'work.pinat.co.kr'],
  ls: ['ls-time.metapay-dev.co.kr', 'ls-time.metapay.co.kr'],
  gaon: ['gaon-time.metapay-dev.co.kr', 'gaon-time.metapay.co.kr'],
  evk: ['evk-time.metapay-dev.co.kr', 'evk-time.metapay.co.kr']
}

let title = '핀앳';
let domainGroup;
if (domainGroups.ls.includes(window.location.host)) {
  domainGroup = 'ls';
  title = '엘에스전선 주식회사';
} else if (domainGroups.gaon.includes(window.location.host)) {
  domainGroup = 'gaon';
  title = '가온전선 (주)';
} else if (domainGroups.evk.includes(window.location.host)) {
  domainGroup = 'evk';
  title = '엘에스이브이코리아 주식회사';
} else {
  domainGroup = 'pinat';
}

const FIREBASE_APP = {
  pinat: {
    apiKey: "AIzaSyCUXtTbRCmgvchMjIAVnoMpFB6NaMgK3SI",
    authDomain: "pinat-eb6f2.firebaseapp.com",
    projectId: "pinat-eb6f2",
    storageBucket: "pinat-eb6f2.appspot.com",
    messagingSenderId: "2596862216",
    appId: "1:2596862216:web:39378c9b0529e3fba9d4bd",
    measurementId: "G-2XR62C8FND"
  },
  ls: {
    apiKey: "AIzaSyATWO2lLlMRNDzz376c6l1PfhVb4xKA4cg",
    authDomain: "metatime-bad42.firebaseapp.com",
    projectId: "metatime-bad42",
    storageBucket: "metatime-bad42.appspot.com",
    messagingSenderId: "649124742809",
    appId: "1:649124742809:web:3981c6e0e1b34f6cde5a08",
    measurementId: "G-K4JY0JTGS8"
  },
  gaon: {
    apiKey: "AIzaSyATWO2lLlMRNDzz376c6l1PfhVb4xKA4cg",
    authDomain: "metatime-bad42.firebaseapp.com",
    projectId: "metatime-bad42",
    storageBucket: "metatime-bad42.appspot.com",
    messagingSenderId: "649124742809",
    appId: "1:649124742809:web:3981c6e0e1b34f6cde5a08",
    measurementId: "G-K4JY0JTGS8"
  },
  evk: {
    apiKey: "AIzaSyATWO2lLlMRNDzz376c6l1PfhVb4xKA4cg",
    authDomain: "metatime-bad42.firebaseapp.com",
    projectId: "metatime-bad42",
    storageBucket: "metatime-bad42.appspot.com",
    messagingSenderId: "649124742809",
    appId: "1:649124742809:web:3981c6e0e1b34f6cde5a08",
    measurementId: "G-K4JY0JTGS8"
  }
}

const exceptCompList = ['5000']

const config = {
  local: {
    CLIENT_ID: 'kr.co.pinat',
    API_URL: "http://localhost:11062/api/v1",
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
  dev: {
    API_URL: "https://devapi.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
  qa: {
    API_URL: "https://qaapi.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
  prod: {
    API_URL: "https://lite.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
  lsdev: {
    API_URL: "https://"+window.location.host+"/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    AZURE_CLIENT_ID: '44abf638-f2f8-4e17-8bb3-6112b7835287',
    AZURE_TENANT: 'e3ac560c-55aa-49d9-b1e6-9b5acb802bfa',
    AZURE_REDIRECT_URI: '/auth/azure/callback',
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
  lsprod: {
    API_URL: "https://"+window.location.host+"/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "2e6a6b6de3e1a73cff8123cf558ea2fd",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    AZURE_CLIENT_ID: 'ecce71ba-5a26-40f6-af39-7baef17f57d9',
    AZURE_TENANT: 'e3ac560c-55aa-49d9-b1e6-9b5acb802bfa',
    AZURE_REDIRECT_URI: '/auth/azure/callback',
    appVersion,
    buildVersion,
    title,
    domainGroup,
    FIREBASE_CONFIG: FIREBASE_APP[domainGroup],
    exceptCompList,
    host
  },
}

export default config[host]
