import React, { forwardRef, useContext } from 'react';
import {t} from "i18next";
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../../components/bootstrap/Modal";
import Button from "../../../../../../components/bootstrap/Button";
import Card, { CardBody } from '../../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../../../components/bootstrap/forms/Label';
import Select from '../../../../../../components/bootstrap/forms/Select';
import Icon from '../../../../../../components/icon/Icon';
import Input from '../../../../../../components/bootstrap/forms/Input';
import ThemeContext from '../../../../../../contexts/themeContext';

const WorkCalenderFlexibleWorkApplyModal = forwardRef((props, ref) => {
  const { mobileDesign } = useContext(ThemeContext);
  const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black'
		}
	};

  return (
    <>
      <Modal
        isCentered={true}
        size='lg'
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        titleId="example-title"
        isScrollable
        isStaticBackdrop
        fullScreen={'sm'}
      >
        <ModalHeader className={!mobileDesign && 'user-modal-header'}>
          <ModalTitle className='fw-bold'>
            {t('탄력근무 신청')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody className='pt-0'>
          <Card className='custom-box-shadow rounded-2' style={{marginBottom : '24px'}}>
            <CardBody>
              <p className='mb-0'>{t('이번주 근무시간')}</p>
              <p className='text-black fw-bold mb-0 mt-2'>
                <span>10</span>{t('시간')}
              </p>
            </CardBody>
          </Card>
          <div>
            {/* 기간단위 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('기간단위')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <div
								  className='d-flex justify-content-around'
								  style={{
								  	padding: '4px',
								  	borderRadius: 8,
								  	backgroundColor: '#F1F1F1',
								  }}
                >
                  <Button
										className='w-100'
										style={{
											padding: '7px 12px 7px 12px',
											borderRadius: 8,
											backgroundColor: 'white'
										}}
                  >{t('2주')}</Button>
                </div>
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('기간단위 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 신청기간 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('신청기간')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Input
                  className='w-100'
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    fontSize: 11,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                  value={t('2024.00.00 (월) - 2024.00.00 (월)')}
                />
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('신청기간 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 신청정보 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('신청정보')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Input
                  className='w-100'
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    fontSize: 11,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                  value={t('근무시간 합계')}
                />
                <Input
                  className='w-100 mt-3'
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    fontSize: 11,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                  value={t('탄력근무ㅣ주 평균 40시간 근무')}
                />
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('신청정보 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 결제라인 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('결재라인')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Select
									placeholder={t('결재라인을 선택해주세요')}
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
								/>
								<div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('결재라인 선택은 필수입니다')}</span>
								</div>
              </div>
            </FormGroup>
            {/* 참조 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('참조')}</Label>
              </div>
              <div>
								<Button
									type={'button'}
									style={{
										borderRadius: 8,
									}}
									isOutline
									color={'info'}
                >
									<div className='d-flex align-items-center'>
										<Icon icon='Add' size='lg' color='info' />
										<span className='custom-write-button-margin'>{t('추가하기')}</span>
									</div>
								</Button>
							</div>
            </FormGroup>
            {/* 사유 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('사유')}</Label>
              </div>
              <div className='col-9'>
								<textarea id='etc' className='form-control' rows='3' style={newStyle()} placeholder={t('사유를 입력해주세요')} />
							</div>
            </FormGroup>
          </div>
        </ModalBody> 
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="info"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('닫기')}</span>
          </Button>
          <Button
            color="info"
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100'
          >
            <span>{t('신청')}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default WorkCalenderFlexibleWorkApplyModal;
