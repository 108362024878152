import React, { useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Badge from '../../../components/bootstrap/Badge';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../models';
import CompanyService from '../../../services/CompanyService';
import DepartmentService from '../../../services/DepartmentService';
import UserService from '../../../services/UserService';
import GroupService from '../../../services/GroupService';
import FileExplorerTheme from '@nosferatu500/theme-file-explorer';
import SortableTree, { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import AuthService from '../../../services/AuthService';
import showNotification from '../../../components/extras/showNotification';
import { setItem, getItem, removeItem } from '../../../lib/localstorage';

// 전역 컴포넌트
// 부서별 인원 선택 모달창 (참고)
//  props {
// 	require(필수 값) - true
	
// 	title(string) : '모달 타이틀',
// 	selectedUser(array) : '넘겨받은 인원',
// 	setSelectedUser(array) : '넘길 인원',
// 	isOpenFindUser(boolean) : '모달창 open' 
// 	setIsOpenFindUser((boolean)) : '모달창 openFunction'
// 	
// 
// ---------------------------------------------------------------
//  
// 
// 	require(필수 값) - false

// 	setIsOpenSendMail((boolean)) : '대시보드 메일보내기 전용 모달창 openFunction'
//  }
const MultiPersonSelectionModal = (props) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { company } = useMst();
	const [departmentTree, setDepartmentTree] = useState([]);
	const [selectedUser, setSelectedUser] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [searchUsers, setSearchUsers] = useState([]); // 검색 유저

	const getDepartUser = async (departId) => {
		//console.log(departId);
		setSearchUsers([]);
		await UserService.listBy('department', departId, company.get.id).then((res) => {
			// console.log('res.data >> ',res.data)
			if (res.data) {
				setSearchUsers(res.data);
			}
		});
	};
	const loadDepartment = async () => {
		//await loadAllUsers();
		let response = await DepartmentService.getDepartementTree(company.get.id);
		
		if (response.data) {
			setDepartmentTree(toggleExpandedForAll({ treeData: response.data, expanded: true }));
		}
	};
	useEffect(() => {
		setSelectedUser(props.selectedUser);
	}, [props.selectedUser]);

	useEffect(() => {
		if(props.isOpenFindUser){
			loadDepartment();
			setSearchUsers([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isOpenFindUser]);

	useEffect(() => {
		let tmpUsers = [];
		const selectedUserIds = selectedUser.map(x=>x.userId)
		const searchUsersIds = searchUsers.map(x=>x.userId)
		if(selectAll){
			searchUsers.forEach((user) => {
				if (!selectedUserIds.includes(user.userId)) {
					tmpUsers.push(user);
				}
			});
			setSelectedUser([...selectedUser,...tmpUsers]);
		}else{
			setSelectedUser(selectedUser.filter(x=>!searchUsersIds.includes(x.userId)))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll]);
	return (
		<Modal
			setIsOpen={props.setIsOpenFindUser}
			isOpen={props.isOpenFindUser}
			isStaticBackdrop={false}
			isScrollable={true}
			isCentered={true}
			size={'lg'}
			//fullScreen={true}
		>
			<ModalHeader /* setIsOpen={props.setIsOpenFindUser} */>
				<ModalTitle><p className='fs-3 fw-bold'>{props?.title}</p></ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row w-100 h-100'>
					<div className='col-lg-5'>
						<Card className='w-100 h-100 ' shadow='none' borderSize={0} isScrollable>
							<CardHeader>
								<CardTitle>{t('조직도')}</CardTitle>
							</CardHeader>
							<CardBody className='w-100'>
								<SortableTree
									style={{ minHeight: 500 }}
									treeData={departmentTree}
									onChange={setDepartmentTree}
									maxDepth={10}
									getNodeKey={({ node }) => node.id}
									canDrag={false}
									theme={FileExplorerTheme}
									generateNodeProps={(nodes) => {
										//console.log('nodes>>', nodes);
										let nodeProps = {
											onClick: async (e) => {
												e.preventDefault();
												await getDepartUser(nodes.node.id);
											},
											title: (
												<ButtonGroup>
													<Button size='sm' color='light'>
														<span className='fw-light fs-6'>
															&nbsp;
															{nodes.node.title}
														</span>
													</Button>
													<Button color='light' size='sm'>
														<Badge key={`tree-${nodes.node.id}`} color='dark'>
															{nodes.node.userCount}
														</Badge>
													</Button>
												</ButtonGroup>
											),
										};
										return nodeProps;
									}}
								/>
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-7'>
						<div className='row d-flex flex-row h-100'>
							<div className='col-lg-7'>
								<Card className='w-100 h-100' shadow='none' borderSize={0} isScrollable={true}>
									<CardHeader>
										<CardTitle className='d-flex justify-content-between w-100 align-items-start'>
											{t('구성원')} {`(${searchUsers.length})`}
											<Button
												color='success'
												size='sm'
												onClick={() => {setSelectAll(!selectAll)}}>
												{selectAll? t('되돌리기'):t('전체적용')}
											</Button>
										</CardTitle>
									</CardHeader>
									<CardBody
										className='d-flex flex-wrap align-items-start justify-content-start align-content-start p-1 w-100'
										isScrollable>
										<div className='d-flex flex-wrap align-content-center px-0 w-100'>
											{searchUsers.map((searchUser) => {
												//console.log(searchUser)
												return (
													<div key={`user-${searchUser.userId}`} className='py-1 w-100'>
														<div className='w-100 position-relative'>
															<Button
																color='light'
																size='sm'
																isDisable={
																	selectedUser?.findIndex((v) => v.userId === searchUser.userId) < 0
																		? false
																		: true
																}
																className='text-nowrap d-flex flex-column w-100'
																onClick={() => {
																	setSelectedUser([...selectedUser, searchUser]);
																}}>
																<span className='fs-6'>
																	{searchUser.user.name}
																	{searchUser.rank && (
																		<span className='fw-light '>{` ${searchUser.rank.name}`}</span>
																	)}
																</span>
																{searchUser.user && (
																	<span className='fw-light'>{` ${searchUser.user.email}`}</span>
																)}
																{/* {searchUser.department && (
																					<span className='fw-light'>{` ${searchUser.department.name}`}</span>
																				)} */}
															</Button>
															<Button
																icon={
																	selectedUser?.findIndex((v) => v.userId === searchUser.userId) < 0
																		? 'check'
																		: ''
																}
																size='sm'
																className='position-absolute top-0 end-0'
																onClick={() => {
																	setSelectedUser([...selectedUser, searchUser]);
																}}
															/>
														</div>
													</div>
												);
											})}
											{searchUsers.length === 0 && (
												<div className='fw-light'>
													<div>{t('부서내 검색인원이 없습니다.')}</div>
													<div>{t('다른 부서를 선택해 주세요.')}</div>
												</div>
											)}
										</div>
									</CardBody>
								</Card>
							</div>

							<div className='col-lg-5'>
								<Card shadow='none' borderSize={0} isScrollable className='h-100'>
									<CardHeader>
										<CardTitle>
											{t('적용 직원')} ({selectedUser ? selectedUser.length : 0})
										</CardTitle>
									</CardHeader>
									<CardBody
										className='d-flex flex-wrap align-items-start justify-content-start align-content-start w-100 p-1 pt-2'
										isScrollable>
										{selectedUser?.map((useUser, useUserIndex) => {
											//console.log(">>>", useUser)
											//const useUser = allUsers.find(v=>v.user.id === u)
											return (
												<div key={`apply-user-${useUser.id}`} className='py-1 w-100'>
													<div className='w-100 position-relative'>
														<Button
															color='light'
															size='sm'
															className='text-nowrap d-flex flex-column w-100'
															onClick={() => {
																let tmp = [...selectedUser];
																tmp.splice(useUserIndex, 1);
																setSelectedUser(tmp);
															}}>
															<span className='fs-6'>
																{useUser.user.name}
																{useUser.rank && (
																	<span className='fw-light '>{` ${useUser?.rank?.name}`}</span>
																)}
															</span>
															{/* {useUser.user && (
																			<span className='fw-light'>{` ${useUser.user.email}`}</span>
																		)} */}
															{<span className='fw-light'>{` ${useUser?.user?.email}`}</span>}
														</Button>
														<Button
															icon='close'
															size='sm'
															className='position-absolute top-0 end-0'
															onClick={() => {
																let tmp = [...selectedUser];
																tmp.splice(useUserIndex, 1);
																setSelectedUser(tmp);
															}}
														/>
														{/* <Button
															type='button'
															color='danger'
															size='sm'
															className='text-nowrap'
															onClick={() => {
																let tmp = [...selectGroup.users];
																tmp.splice(useUserIndex, 1);
																setSelectGroup({
																	...selectGroup,
																	users: tmp,
																});
															}}>
															미적용
														</Button> */}
													</div>
												</div>
											);
										})}

										{selectedUser && selectedUser.length === 0 && (
											<div className='fw-light'>
												<div>{t('적용 인원이 없습니다.')}</div>
											</div>
										)}
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button
						type='button'
						color='info'
						onClick={() => {
							props.setSelectedUser(selectedUser);
							if (props.setIsOpenSendMail)
								props.setIsOpenSendMail(true);
							// if(props.executeFunction)
							// 	props.executeFunction()
							props.setIsOpenFindUser(false);
						}}>
						{t('적용')}
					</Button>
					<Button
						type='button'
						color='light'
						onClick={() => {
							props.setIsOpenFindUser();
							setSelectedUser(props.selectedUser);
							if (props.setIsOpenSendMail)
								props.setIsOpenSendMail(true);
						}}>
						{t('닫기')}
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);
};
export default React.memo(MultiPersonSelectionModal);
