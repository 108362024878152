import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Card, { CardActions, CardBody, CardHeader, CardTitle } from '../../../../components/bootstrap/Card';
import useRequest from '../../../../hooks/useRequest';
import { useMst } from '../../../../models';
import AttendService from '../../../../services/AttendService';
import showNotification from '../../../../components/extras/showNotification';
import Badge from '../../../../components/bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import { usersMenu } from '../../../../menu';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Popovers from '../../../../components/bootstrap/Popovers';
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import ThemeContext from '../../../../contexts/themeContext';
import WinkingDocument from '../../../../assets/img/WinkingDocument.png';

const UserWeeklyAttendHistoryCard = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'menu']);
    const { user, company } = useMst();
    const { mobileDesign } = useContext(ThemeContext);
    const ref = useRef();
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            key: 'selection',
        },
    ]);
    const [tempSelectedDateRange, setTempSelectedDateRange] = useState([
        {
            startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            key: 'selection',
        },
    ]);
    const { responseData: logData, loading: logLoading, request: logRequest } = useRequest([]);

    const requestSort = useCallback((a, b) => {
        const existYaxisInA = 'yaxis' in a;
        const existYaxisInB = 'yaxis' in b;
        //정렬할 때 yaix가 있으면 해당 셀은 고정되어야 함.
        if (existYaxisInA && existYaxisInB) {
            return a.yaxis < b.yaxis ? -1 : a.yaxis > b.yaxis ? 1 : 0;
        } else if ((existYaxisInA && !existYaxisInB) || (!existYaxisInA && existYaxisInB)) {
            return 0;
        } else {
            return a.duration < b.duration
                ? 1
                : a.duration < b.duration
                    ? -1
                    : moment(a.start) < moment(b.start)
                        ? -1
                        : moment(a.start) > moment(b.start)
                            ? 1
                            : moment(a.end) < moment(b.end)
                                ? 1
                                : moment(a.end) > moment(b.end)
                                    ? -1
                                    : 0;
        }
    }, []);

    const requestAllData = useCallback(async () => {
        await logRequest(
            AttendService.getLogAttend,
            [0, selectedDateRange[0].startDate, selectedDateRange[0].endDate, company.get.id, 'day', user.me.id],
            (res) => {
                if (res?.data) {
                    let result;
                    const initDateList = [0, 1, 2, 3, 4, 5, 6].map((index) => ({
                        date: moment(selectedDateRange[0].startDate).add(index, 'days'),
                        specialDay: [],
                        data: [],
                    }));

                    const newList = res?.data?.[0]?.reduce((all, cur) => {
                        if (!cur?.start) return all;
                        const dateIndexList = [];

                        all.forEach((dateObj, index) => {
                            if (
                                moment(dateObj.date).format('YYYY-MM-DD') >= moment(cur.start).format('YYYY-MM-DD') &&
                                moment(dateObj.date).format('YYYY-MM-DD') <= moment(cur.end).format('YYYY-MM-DD')
                            ) {
                                dateIndexList.push(index);
                            }
                        });

                        if (dateIndexList.length > 0) {
                            dateIndexList.forEach((dateIndex) => {
                                cur['duration'] = moment(moment(cur.end).format('YYYY-MM-DD')).diff(
                                    moment(moment(cur.start).format('YYYY-MM-DD')),
                                    'days'
                                );
                                cur['id'] = uuid();
                                if ('memo' in cur) {
                                    all[dateIndex].specialDay.push(cur);
                                } else {
                                    all[dateIndex].data.push(cur);
                                }
                            });
                        }
                        return all;
                    }, initDateList);

                    let prevObjForCheckIsSameObj = null;
                    result = newList.map((newListDateObj, newListIndex) => {
                        let copyNewListObj = { ...newListDateObj };

                        let insertedYaxis = null;
                        insertedYaxis = copyNewListObj.data.map((d, i) => {
                            if (newListIndex === 0) {
                                copyNewListObj.data.sort(requestSort);
                                return { ...d, yaxis: i };
                            } else {
                                const isEx = prevObjForCheckIsSameObj?.data.find((o) => o.id === d.id) || false;
                                if (isEx) {
                                    return { ...d, yaxis: isEx.yaxis };
                                }
                                return d;
                            }
                        });
                        copyNewListObj = {
                            ...copyNewListObj,
                            data: insertedYaxis,
                        };

                        const yaxisIndexList = copyNewListObj.data.reduce((all, cur, i) => {
                            if ('yaxis' in cur) {
                                all.push(i);
                            }
                            return all;
                        }, []);
                        const swap = (arr, targetIndex, comparisonTargetIndex) => {
                            [arr[targetIndex], arr[comparisonTargetIndex]] = [arr[comparisonTargetIndex], arr[targetIndex]];
                        };
                        if (copyNewListObj.data.length > 1) {
                            for (let i = 0; i < copyNewListObj.data.length; i++) {
                                if (yaxisIndexList.includes(i)) continue;
                                let target = copyNewListObj.data[i];
                                for (let j = i; j < copyNewListObj.data.length; j++) {
                                    let comparisonTarget = copyNewListObj.data[j + 1];
                                    if (comparisonTarget === undefined || 'yaxis' in comparisonTarget) continue;
                                    if (target.duration < comparisonTarget.duration) swap(copyNewListObj.data, i, j + 1);
                                    else if (target.duration > comparisonTarget.duration) continue;
                                    else {
                                        if (moment(target.start) < moment(comparisonTarget.start)) continue;
                                        else if (moment(target.start) > moment(comparisonTarget.start)) swap(copyNewListObj.data, i, j + 1);
                                        else {
                                            if (moment(target.end) < moment(comparisonTarget.end)) continue;
                                            else if (moment(target.end) > moment(comparisonTarget.end)) swap(copyNewListObj.data, i, j + 1);
                                            else ;
                                        }
                                    }
                                }
                            }
                        }
                        //정렬되어있는 것들에게 순서대로 yaxis삽입하는 것이므로 미리 정렬이 되어 있어야 함
                        copyNewListObj.data.map((d, i) => {
                            if (!('yaxis' in d)) {
                                const yaxisList = copyNewListObj.data
                                    .filter((o) => 'yaxis' in o)
                                    .sort((a, b) => (a.yaxis < b.yaxis ? -1 : a.yaxis > b.yaxis ? 1 : 0));
                                copyNewListObj.data[i]['yaxis'] = yaxisList.reduce((all, cur) => {
                                    if (all === cur.yaxis) {
                                        return all + 1;
                                    }
                                    return all;
                                }, 0);
                            }
                        });
                        copyNewListObj.data.sort(requestSort);

                        let insertedDummy = [];
                        copyNewListObj.data.map((d, i) => {
                            if (i === 0) {
                                if (i < d.yaxis) {
                                    for (let j = 0; j < d.yaxis; j++) {
                                        insertedDummy.push({ isDummy: true, yaxis: j });
                                    }
                                }
                                insertedDummy.push(d);
                            }
                            if (i !== 0) {
                                const diffYaxis = Math.abs(d.yaxis - copyNewListObj.data[i - 1].yaxis);
                                if (diffYaxis > 1) {
                                    for (let j = 0; j < diffYaxis; j++) {
                                        insertedDummy.push({ isDummy: true, yaxis: j + i });
                                    }
                                }
                                insertedDummy.push(d);
                            }
                        });
                        copyNewListObj = {
                            ...copyNewListObj,
                            data: insertedDummy,
                        };

                        prevObjForCheckIsSameObj = copyNewListObj;
                        return copyNewListObj;
                    });

                    return { data: result };
                }
            },
            (error) => {
                showNotification(t('주간 근무 현황'), t('주간근무 현황을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
            }
        );
    }, [user, company, selectedDateRange, requestSort, logRequest, t]);

    const calendarBadgeClickEvent = (data) => {
        // console.log('data', data)
        navigate(usersMenu.WorkingSchedule.path, { state: { id : data?.attend ? data?.attend?.id : data?.request?.id, type: data?.attend ? 'WORK' : 'REST' } });
    };

    useEffect(() => {
        requestAllData();
    }, [requestAllData]);

    return (
        <Card className='custom-box-shadow rounded-2' stretch>
            <CardHeader className='rounded-2'>
                <CardTitle className={'cursor-pointer'} onClick={() => navigate(usersMenu.WorkingSchedule.path)}>
                    {t('주간 근무 현황')}
                </CardTitle>
                <CardActions>
                    <Button
                        icon='ArrowBackIos'
                        color='light'
                        className={`border border-light ${mobileDesign && 'me-0'}`}
                        isOutline
                        isDisable={logLoading}
                        onClick={() =>
                            setSelectedDateRange((prev) => {
                                let prevDate = moment(prev[0].startDate).subtract(1, 'weeks');
                                return [
                                    {
                                        ...prev,
                                        startDate: moment(prevDate).startOf('isoWeek').format('YYYY-MM-DD'),
                                        endDate: moment(prevDate).endOf('isoWeek').format('YYYY-MM-DD'),
                                    },
                                ];
                            })
                        }></Button>
                    <Popovers
                        id='selectDate'
                        placement={'bottom'}
                        trigger='click'
                        desc={
                            <Card className='shadow-none w-100 mb-0'>
                                <CardBody className='px-0 pb-0 row'>
                                    <DateRange
                                        direction='horizontal'
                                        locale={locales['ko']}
                                        dateDisplayFormat='yyyy-MM-dd'
                                        dragSelectionEnabled={false}
                                        showDateDisplay={false}
                                        focusedRange={[0, 0]}
                                        ranges={[
                                            {
                                                key: 'selection',
                                                startDate: moment(selectedDateRange[0].startDate).toDate(),
                                                endDate: moment(selectedDateRange[0].endDate).toDate(),
                                            },
                                        ]}
                                        /* preview={{
                                            key: 'selection',
                                            startDate: moment(tempSelectedDateRange[0].startDate).toDate(),
                                            endDate: moment(tempSelectedDateRange[0].endDate).toDate(),
                                        }}
                                        onPreviewChange={(date) => {
                                            if (!date) return;
                                            setTempSelectedDateRange([
                                                {
                                                    key: 'selection',
                                                    startDate: moment(date).startOf('isoWeek').format('YYYY-MM-DD'),
                                                    endDate: moment(date).endOf('isoWeek').format('YYYY-MM-DD'),
                                                },
                                            ]);
                                        }} */
                                        onChange={(item) => {
                                            if (logLoading) return;
                                            setSelectedDateRange((prev) => {
                                                if (
                                                    moment(prev[0].startDate) > moment(item.selection.endDate) ||
                                                    moment(prev[0].endDate) < moment(item.selection.endDate)
                                                ) {
                                                    return [
                                                        {
                                                            key: 'selection',
                                                            startDate: moment(item.selection.endDate).startOf('isoWeek').format('YYYY-MM-DD'),
                                                            endDate: moment(item.selection.endDate).endOf('isoWeek').format('YYYY-MM-DD'),
                                                        },
                                                    ];
                                                }
                                                return prev;
                                            });
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        }>
                        <Button className={mobileDesign && 'px-0 me-0'} isDisable={logLoading}>
                            {`${moment(selectedDateRange[0].startDate).format('YYYY-MM-DD(ddd)')} ~ ${moment(
                                selectedDateRange[0].endDate
                            ).format('YYYY-MM-DD(ddd)')}`}
                        </Button>
                    </Popovers>
                    <Button
                        color='light'
                        isOutline
                        className='border border-light'
                        icon='ArrowForwardIos'
                        isDisable={logLoading}
                        onClick={() =>
                            setSelectedDateRange((prev) => {
                                let prevDate = moment(prev[0].startDate).add(1, 'weeks');
                                return [
                                    {
                                        ...prev,
                                        startDate: moment(prevDate).startOf('isoWeek').format('YYYY-MM-DD'),
                                        endDate: moment(prevDate).endOf('isoWeek').format('YYYY-MM-DD'),
                                    },
                                ];
                            })
                        }
                    ></Button>
                </CardActions>
            </CardHeader>
            <CardBody
                className={`${mobileDesign ? 'pt-0 overflow-hidden' : 'dashboard-short-height overflow-auto'}`}
                ref={ref}
                onMouseOver={(e) => {
                    if (ref?.current?.scroll === true) {
                        ref.current.scroll = false;
                        document.body.style.overflow = 'hidden';
                        document.body.style.paddingRight = '10px';
                    }
                }}
                onMouseLeave={(e) => {
                    if (ref?.current) {
                        ref.current.scroll = true;
                        document.body.style.overflow = 'auto';
                        document.body.style.removeProperty('padding-right');
                    }
                }}>
                {logLoading ? (
                    <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                        <p>{t('데이터를 불러오고 있습니다.')}</p>
                    </div>
                ) : !logLoading && logData?.length <= 0 ? (
                    <div className='h-100 d-flex flex-column justify-content-center align-items-center lead'>
                        <img src={WinkingDocument} />
                        <p className='mt-3 fs-6'>{t('근무 일정이 없습니다.')}</p>
                    </div>
                ) : !logLoading && logData?.length > 0 ? (
                    <div className={`h-100 m-0 ${mobileDesign ? 'row col-12' : 'd-flex flex-row flex-nowrap justify-content-between'}`}>
                        {logData.map((log, index) => (
                            <Card key={`logData-${index}`} className={`m-0 p-0 ${mobileDesign ? 'col-4 border-top border-light' : 'd-flex flex-fill flex-row flex-1 w-100'} `} shadow='none'>
                                <CardBody className='p-0'>
                                    <div className={`px-1 py-2 ${index >= 5 ? 'text-danger' : 'text-dark'} d-flex justify-content-center position-relative ${mobileDesign ? 'pt-3' : 'flex-wrap border-2 border-light border-bottom'}`}>
                                        {
                                            mobileDesign ?
                                            // 모바일버전
                                            <div>{moment(log?.date).format('MM-DD dddd')}</div> :
                                            // pc버전
                                            <>
                                                <div className={'w-100 text-center'}>{moment(log?.date).format('dd')}</div>
                                                <div className="bold fs-5 p-3 fw-bold w-100 text-center">
                                                    <sapn>{moment(log?.date).format('DD')}</sapn>
                                                    {/* 오늘날짜면 파란색 동그라미 추가해주세요 class (bg-info rounded-circle p-2 text-white) */}
                                                    {/* <sapn className='bg-info rounded-circle p-2 text-white'>{moment(log?.date).format('DD')}</sapn> */}
                                                </div>
                                                <div className='w-100 position-absolute d-flex justify-content-center' style={{bottom: '5px'}}>
                                                    {log?.specialDay?.map((d, i) => (
                                                        <Badge key={`memo-${i}`} color='danger' className={i > 0 ? 'ms-1' : 0}>
                                                            {d.memo || ''}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className={mobileDesign ? 'pb-3 px-1' : 'py-2 px-2'}>
                                        {/* 휴가(실근무) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-solid-2 bg-bd-success-50 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('연차')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>종일</span>
                                        </Badge>
                                        {/* 휴가(계획) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-custom-success bd-dotted-2 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('연차')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 근무(실근무) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-solid-2 bg-bd-info-50 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('시차')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 근무(계획) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-custom-info bd-dotted-2 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('시차')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 특근or연장(실근무) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-solid-2 bg-bd-secondary-50 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('연장')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 특근or연장(계획) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-custom-secondary bd-dotted-2 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('연장')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 출장(실근무) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-solid-2 bg-bd-warning-50 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('출장')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 출장(계획) */}
                                        <Badge
                                            className={`mt-2 ${mobileDesign ? 'px-2' : 'px-3'} py-2 d-block text-wrap text-start cursor-pointer text-dark bd-custom-warning bd-dotted-2 rounded-8px`}
                                            color={null}
                                        >
                                            <span className={!mobileDesign && 'pe-1 me-1 border-end border-dark'}>{t('출장')}</span>
                                            <span className={`fw-normal ${mobileDesign && 'mt-1 d-block'}`}>09:30 - 06:30</span>
                                        </Badge>
                                        {/* 모바일에서 라벨 3개 이상일때 보여지는 아이콘 */}
                                        {
                                            mobileDesign && 
                                            <div className='text-center mt-2'>
                                                <Icon icon='MoreHoriz' size='lg' />
                                            </div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                ) : (
                    <></>
                )}
            </CardBody>
        </Card>
    );
});
export default React.memo(UserWeeklyAttendHistoryCard);
