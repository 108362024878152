import React, { useState, forwardRef, useContext } from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../../components/bootstrap/Modal";
import Button from "../../../../../../components/bootstrap/Button";
import Card, { CardBody } from '../../../../../../components/bootstrap/Card';
import {t} from "i18next";
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../../../components/bootstrap/forms/Label';
import Select from '../../../../../../components/bootstrap/forms/Select';
import Icon from '../../../../../../components/icon/Icon';
import Input from '../../../../../../components/bootstrap/forms/Input';
import RestTypeSelectModal from "../../../../components/modal/RestTypeSelectModal";
import ThemeContext from '../../../../../../contexts/themeContext';

const UserReqVacation = forwardRef((props, ref) => {
  const [isOpenRestTypeSelectModal, setIsOpenRestTypeSelectModal] = useState(false);
  const { mobileDesign } = useContext(ThemeContext);

  const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black'
		}
	};

  return (
    <>
      <Modal
        isCentered={true}
        size='lg'
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        titleId="example-title"
        isScrollable
        isStaticBackdrop
        fullScreen={'sm'}
      >
        <ModalHeader className={!mobileDesign && 'user-modal-header'}>
          <ModalTitle className='fw-bold'>
            {t('휴가신청')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody style={{overflowX: 'hidden'}}>
          <Card className='custom-box-shadow rounded-2 position-relative' style={{marginBottom : '24px'}}>
            <CardBody>
              <p className='mb-0'>{t('총 잔여휴가')}</p>
              <p className='text-black-50 fw-bold mb-0 mt-2'>
                <span className='text-info'><span>10</span>{t('일')} <sapn>6</sapn>{t('시간')}</span><span className='mx-2'>/</span><span>18</span>{t('일')}
              </p>
              <div>
                {/* 버튼 disabled시 d-none 넣어주시면 됩니다. */}
                <Button
                  className='position-absolute top-50 start-0 translate-middle custom-box-shadow bg-white d-none'
                  rounded='circle'
                  style={{width: '30px', height: '30px'}}
                  icon='ChevronLeft'
                />
                <Button
                  className='position-absolute top-50 start-100 translate-middle custom-box-shadow bg-white'
                  style={{width: '30px', height: '30px'}}
                  rounded='circle'
                  icon='ChevronRight'
                />
              </div>
            </CardBody>
          </Card>
          <div>
            {/* 휴가유형 */}
            <FormGroup className='d-flex'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('휴가유형')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
								<Button
                  className='w-100 d-flex justify-content-between align-items-center'
									style={{
										borderRadius: 8,
										borderWidth: 1,
										borderColor: '#D9D9D9',
										padding: '10px',
										color: '#808080'
									}}
                  onClick={() => {
                    setIsOpenRestTypeSelectModal(true)
                  }}
                >
                  <span>{t('사용할 휴가를 선택해주세요')}</span>
                  <Icon icon='KeyboardArrowDown' size='2x' />
								</Button>
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('휴가유형 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 날짜선택 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('날짜선택')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Input
                  className='w-100'
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    fontSize: 11,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                  value={t('2024.00.00(월) - 2024.00.00(월)')}
                />
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('날짜 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 시간설정(종일 선택) */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('시간설정')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <div
								  className='d-flex justify-content-around'
								  style={{
								  	padding: '4px',
								  	borderRadius: 8,
								  	backgroundColor: '#F1F1F1',
								  }}
                >
                  <Button
										className='w-100'
										style={{
											padding: '7px 12px 7px 12px',
											borderRadius: 8,
											backgroundColor: 'white'
										}}
                  >{t('종일')}</Button>
                  <Button
										className='w-100 text-muted'
										style={{
											padding: '7px 12px 7px 12px',
											borderRadius: 8,
											backgroundColor: 'F1F1F1'
										}}
                  >{t('직접입력')}</Button>
                </div>
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('시간 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 시간설정(직접입력 선택) */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('시간설정')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <div
								  className='d-flex justify-content-around'
								  style={{
									  padding: '4px',
									  borderRadius: 8,
									  backgroundColor: '#F1F1F1',
                  }}
                >
                  <Button
										className='w-100 text-muted'
										style={{
											padding: '7px 12px 7px 12px',
											borderRadius: 8,
											backgroundColor: '#F1F1F1'
										}}
                  >{t('종일')}</Button>
                  <Button
										className='w-100'
										style={{
											padding: '7px 12px 7px 12px',
											borderRadius: 8,
											backgroundColor: 'white'
										}}
                  >{t('직접입력')}</Button>
                </div>
                <div className='d-flex justify-content-between mt-3'>
                  <Button
                    style={{
                      width: 'calc(50% - 5px)',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      padding: '10px',
                      color: '#808080'
                    }}
                  >
                    {t('오전 00:00')}
                  </Button>
                  <Button
                    style={{
                      width: 'calc(50% - 5px)',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      padding: '10px',
                      color: '#808080'
                    }}
                  >
                    {t('오후 00:00')}
                  </Button> 
                </div>
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('시간 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 결제라인 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('결재라인')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Select
									placeholder={t('결재라인을 선택해주세요')}
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
								/>
								<div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('결재라인 선택은 필수입니다')}</span>
								</div>
              </div>
            </FormGroup>
            {/* 참조 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('참조')}</Label>
              </div>
              <div>
								<Button
									type={'button'}
									style={{
										borderRadius: 8,
									}}
									isOutline
									color={'info'}
                >
									<div className='d-flex align-items-center'>
										<Icon icon='Add' size='lg' color='info' />
										<span className='custom-write-button-margin'>{t('추가하기')}</span>
									</div>
								</Button>
							</div>
            </FormGroup>
            {/* 사유 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('사유')}</Label>
              </div>
              <div className='col-9'>
								<textarea id='etc' className='form-control' rows='3' style={newStyle()} placeholder={t('사유를 입력해주세요')} />
							</div>
            </FormGroup>
          </div>
        </ModalBody> 
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="info"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('닫기')}</span>
          </Button>
          <Button
            color="info"
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100'
          >
            <span>{t('신청')}</span>
          </Button>
        </ModalFooter>
      </Modal>
      <RestTypeSelectModal 
        isOpen={isOpenRestTypeSelectModal}
        setIsOpen={setIsOpenRestTypeSelectModal}
      />
    </>
  );
});

export default UserReqVacation;
