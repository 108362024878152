import { APIClient } from "../lib/apiHelper";

const { get, post, put } = new APIClient();

export default {
  /**
   * 이메일 인증
   * @method GET
   * @param {STRING} email
   */
  emailAuth: email => get(`/auth/email-auth?email=${email}`),
  /**
   * 이메일 인증 코드 확인
   * @method GET
   * @param {STRING} email
   * @param {STRING} code
   */
  emailAuthConfirm: (email, code) =>
    get(`/auth/email-auth-confirm?email=${email}&code=${code}`),
  /**
   * 이메일 존재 여부 확인
   * @method GET
   * @param {STRING} email
   */
  isExistEmail: email => get(`/auth/is-exist-email?email=${email}`),
    /**
   * 이메일 존재 여부 확인 return object
   * @method GET
   * @param {STRING} email
   */
  isExistEmail2: email => get(`/auth/is-exist-email2?email=${email}`),
  /**
   * 회원 가입
   * @method POST
   * @param {OBJECT} user
   * =========================
   * @param {STRING} email
   * @param {STRING} password
   * @param {STRING} name
   */
  register: user => post("/auth/register", user),
  /**
   * 로그인
   * @method POST
   * @param {STRING} email
   * @param {STRING} password
   */
  login: user => post("/auth/login", user),
  logout: user => put('/auth/logout'),
  /**
   * 내정보 가져 오기
   * @method GET
   * no-params
   */
  me: () => get("/auth/me"),
  /**
   * 비밀번호 찾기 이메일 인증
   * @method GET
   * @param {STRING} email
   */
  forgetPassword: email => get(`/auth/forget-password?email=${email}`),
  /**
   * 비밀번호 찾기 이메일 인증
   * @method GET
   * @param {STRING} email
   * @param {STRING} code
   */
  forgetPasswordConfirm: (email, code) =>
    get(`/auth/forget-password-confirm?email=${email}&code=${code}`),
  /**
   * 비밀번호 찾기 이메일 인증
   * @method GET
   * @param {STRING} email
   * @param {STRING} password
   */
  changePassword: async (email, password) => {
    const dummy = password.split("").reverse().join("")
    const passwordData = { email, password, dummy }

    return put("/auth/change-password", passwordData)
  },
  /**
   * 직원 초대
   * @method POST
   * @param {OBJECT} user
   */
  inviteUser: user => post("/invite", user),
  /**
   * 직원 초대
   * @method POST
   * @param {STRING} code
   */
  getInviteCode: code => get("/invite?code=" + code),
  /**
   * 회사 참여 코드
   */
  joinCodeCompany: code => get("/auth/joinCodeCompany?code=" + code),
  /**
   * 근태 보고 메일링
   */
  attendReportMail: (companyId, email, date) => get("/auth/attendReportMail?companyId="+companyId+"&email="+email+"&date="+date),
  /**
   * 근태 보고 메일링2
   */
   attendReportMail2: (datas) => post("/auth/attendReportMail2",datas),
/**
 * 
 */
 attendReportExcel: (companyId, sdate, edate, type) => get("/auth/attendReportExcel?companyId="+companyId+"&sdate="+sdate+"&edate="+edate+"&type="+type ),
 /**
  * 세션 체크
  */
 check: () => get("/auth/check"),
  /**
   * azure oauth login
   */
  azureLogin: params => post('/auth/azureLogin', params)
}
