import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import Button from '../../../../components/bootstrap/Button';
import { HeaderRight } from '../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Icon from '../../../../components/icon/Icon';
import {  noticeListMenu } from '../../../../menu';
import ThemeContext from '../../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../../lang';
import showNotification from '../../../../components/extras/showNotification';
import useDarkMode from '../../../../hooks/useDarkMode';
import Popovers from '../../../../components/bootstrap/Popovers';
import Badge from '../../../../components/bootstrap/Badge';
import { useChannelIOApi } from 'react-channel-plugin';
import { useMst } from '../../../../models';
import NotificationList from '../../../common/Headers/components/NotificationList';
import {Link} from 'react-router-dom';
import { observer } from 'mobx-react';
import NotificationSetting from '../../../common/Headers/components/NotificationSetting';
import moment from 'moment';
import 'moment/locale/ko';
import AlarmService from '../../../../services/AlarmService';
// import moment from 'moment/min/moment-with-locales';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [ settingFlag, setSettingFlag ] = useState(false);
	const { fullScreenStatus, setFullScreenStatus, lang, setLang } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { showMessenger, updateUser } = useChannelIOApi();
	
	const { i18n } = useTranslation();
	
	const { user, company, notifiction } = useMst();
	const { t } = useTranslation(['translation', 'menu']);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng).then(()=>{
			moment.locale(lng);		
			// moment.updateLocale(lng);
			// moment.defineLocale(lng);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`${getLangWithKey(lng)?.text}`}</span>
				</span>,
				t('You updated the language of the site.'),
			);
		}).then(()=>{
			setLang(lng);
		})
	};

	const allReadHandler = useCallback(async()=> {
		// console.log('userId, companyId')
		await AlarmService.alarmReadAll({userId : user.id, companyId : company.get.id}).then(response => {
			if(response.data){
				// console.log('response >>',response)
				showNotification('알람','알람을 모두 읽었습니다.','info');
				notifiction.getRefresh(company.get.id);
				setOffcanvasStatus(false);
			}else{
				showNotification('알람','모두읽기 실패.','danger')
			}
		})
	},[user, company, notifiction])

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
		moment.locale(i18n.language);		
		// setLang(i18n.language);
	});

	const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				<div className='col-auto'>
					<Link to={noticeListMenu.notice.path}>
        	  <Button
        	    {...styledBtn}
        	    icon='Campaign'
        	  />
        	</Link>
				</div>

				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('다크 / 화이트 모드')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('전체화면')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={getLangWithKey(i18n.language)?.icon}
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button isDisable={lang === LANG[i].lng} icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Notifications */}
				<div className='col-auto position-relative'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
					{notifiction?.get.badge > 0 && (
						<Badge color={'danger'} className='position-absolute top-0 end-0'>
							{notifiction?.get.badge > 99 ? '99+' : notifiction?.get.badge}
						</Badge>
					)}
				</div>
				{afterChildren}
			</div>

			<OffCanvas id='notificationCanvas' titleId='offcanvasExampleLabel' placement='end' isOpen={offcanvasStatus} setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle className='w-100 d-flex justify-content-between' id='offcanvasExampleLabel'>
						<div>
							{t('알림')} ({notifiction?.get.badge})			
						</div>
						<div>
							<Button color='info' size='sm' onClick={() => allReadHandler()}>
								{'모두 읽음'}
							</Button>										
							<Button
								color='light'
								icon={!settingFlag ? 'GearFill' : 'Bell'}
								isOutline={true}
								className='border-0'
								onClick={() => {
									setSettingFlag(!settingFlag);
								}}
							/>
						</div>
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>{settingFlag ? <NotificationSetting /> : <NotificationList />}</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default observer(CommonHeaderRight);
