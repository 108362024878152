import moment from 'moment';
import React, { useCallback, useRef, useContext, useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { useSSR, useTranslation } from 'react-i18next'; //번역관련
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Input from '../../../../components/bootstrap/forms/Input';
import InputGroup from '../../../../components/bootstrap/forms/InputGroup';
import Popovers from '../../../../components/bootstrap/Popovers';
import showNotification from '../../../../components/extras/showNotification';
import * as locales from 'react-date-range/dist/locale';
import Button from '../../../../components/bootstrap/Button';
import ThemeContext from '../../../../contexts/themeContext';
import { useMst } from '../../../../models';
import { useEffectOnce } from 'react-use';
import GroupService from '../../../../services/GroupService';

const GetRestInfo = ({ userData, setSelectedUser, isEditFlag }) => {
	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const popRef = useRef({});
	const { user, company } = useMst();
	const [userGroup, setUserGroup] = useState({});

	const changedRecordTime = useCallback((state) => {
		const starttime = userData.attend?.dates?.history?.findLast((i)=> i.state === state)?.realTime;
		const endtime = userData.attend?.dates?.history?.findLast((i)=> i.state === state)?.realTime;
		if(state == 'S'){
			return moment(starttime).format('HH:mm');
		} else {
			return moment(endtime).format('HH:mm');
		}
	},[userData])

	const getMyGroup = async () => {
		const res = await GroupService.getGroupInfoById(user.me.id, company.get.id);
		if(res?.data)
		setUserGroup(res?.data || {});

	}

	useEffect(() => {
		getMyGroup()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, userData]);

	const convertMinsToHrsMins = useCallback(
		(mins) => {
			let h = Math.floor(mins / 3600);
			let m = Math.floor((mins % 3600) / 60);
			let s = Math.floor(mins % 60);
			//let h = Math.floor(mins / (60 * 60));
			//let m = mins % (60);
			// h = h < 10 && h > 0 ? '0' + h : h;
			// m = m < 10 ? '0' + m : m;
			return t(`{{hour}}시간 ${m > 0 ? m + '분' : ''}`, { hour: h, minute: m });
		},
		[t]
	);

	const { mobileDesign } = useContext(ThemeContext);
	
	if (userData?.type === 'RECORD') {
		if (userData?.attend) {
			if (userData?.startTime || userData?.endTime) {
				return (
					<>
						{userData?.userId && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 대상 : ')}</span></td>
								<td><div>{userData?.user.name}</div></td>
							</tr>
						)}
						{userData?.startDate && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경일')}</span></td>
								<td><div>{moment(userData?.startDate).format('YYYY-MM-DD')}</div></td>
							</tr>
						)}
						{userData?.startTime && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 출근시간')}</span></td>
								<td>
									<div>
										{changedRecordTime('S')}
										{' > '}
										{userData?.startTime}
									</div>
								</td>
							</tr>
						)}
						{userData?.endTime && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 퇴근시간')}</span></td>
								<td>
									<div>
										{changedRecordTime('E')}
										{' > '}
										{userData?.endTime}
									</div>
								</td>
							</tr>
						)}
						{userData?.etc && 	
						<tr>
								<td><span className='fw-bold mx-3'>{t('사유')}</span></td>
								<td>{userData?.etc}</td>
							</tr>}
							{userData?.memo && (
								<tr>
									<td><span className='fw-bold mx-3'>{t('메모')}</span></td>
									<td>{userData?.memo}</td>
								</tr>
							)}
					</>
				);
			}
		} else {
			//attend가 없을시
			if (userData?.startTime || userData?.endTime) {
				return (
					<>
						{userData?.userId && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 대상 : ')}</span></td>
								<td><div>{userData?.user.name}</div></td>
							</tr>
						)}
						{userData?.startDate && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경일')}</span></td>
								<td><div>{moment(userData?.startDate).format('YYYY-MM-DD')}</div></td>
							</tr>
						)}
						{userData?.startTime && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 출근시간')}</span></td>
								<td>
									<div>
										{t('미근무')}
										{' > '}
										{userData?.startTime}
									</div>
								</td>
							</tr>
						)}
						{userData?.endTime && (
							<tr>
								<td><span className='fw-bold mx-3'>{t('변경 퇴근시간')}</span></td>
								<td>
									<div>
										{t('미근무')}
										{' > '}
										{userData?.endTime}
									</div>
								</td>
							</tr>
						)}
							{userData?.etc && 	<tr>
								<td><span className='fw-bold mx-3'>{t('사유')}</span></td>
								<td>{userData?.etc}</td>
							</tr>}
							{userData?.memo && (
								<tr>
									<td><span className='fw-bold mx-3'>{t('메모')}</span></td>
									<td>{userData?.memo}</td>
								</tr>
							)}
					</>
				);
			}
		}
	}
	
		// 기존 소스
		// if (userData.startTime && userData.endTime) {
		// 	return (
		// 		<>
		// 			<tr>
		// 				<td>{t('변경 출근시간')}</td>
		// 				<td>
		// 					{isEditFlag ? (
		// 						<InputGroup>
		// 							<Input
		// 								type='date'
		// 								name='startDate'
		// 								//readOnly={formik.values.endDate ? true : false}
		// 								//min={moment(userData.startDate).format('YYYY-MM-DD')}
		// 								max={moment().format('YYYY-MM-DD')}
		// 								value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, startDate: moment(e.target.valueAsDate), endDate: moment(e.target.valueAsDate) });
		// 								}}
		// 							/>
		// 							<Input
		// 								type={'time'}
		// 								value={userData.startTime}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, startTime: e.target.value });
		// 								}}
		// 							/>
		// 						</InputGroup>
		// 					) : (
		// 						<div>{userData?.attend ? changedRecordTime('S') : t('미근무')}{' > '}{userData?.startTime}</div>
		// 					)}
		// 				</td>
		// 			</tr>
		// 			<tr>
		// 				<td>{t('변경 퇴근시간')}</td>
		// 				<td>
		// 					{isEditFlag ? (
		// 						<InputGroup>
		// 							<Input
		// 								type='date'
		// 								name='endDate'
		// 								min={moment(userData.startDate).format('YYYY-MM-DD')}
		// 								max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
		// 								value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
		// 								}}
		// 								onBlur={(e) => {
		// 									setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
		// 								}}
		// 							/>
		// 							<Input
		// 								type={'time'}
		// 								value={userData.endTime}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, endTime: e.target.value });
		// 								}}
		// 							/>
		// 						</InputGroup>
		// 					) : (
		// 						<div>{userData?.attend ? changedRecordTime('E') : t('미근무')}{' > '}{userData?.endTime}</div>
		// 					)}
		// 				</td>
		// 			</tr>
		// 			<tr>
		// 				<td>{t('사유')}</td>
		// 				<td>{userData?.etc}</td>
		// 			</tr>
		// 		</>
		// 	);
		// }
		// if (userData.startTime) {
		// 	return (
		// 		<tr>
		// 			<td>{t('변경 출근시간')}</td>
		// 			<td>
		// 				{isEditFlag ? (
		// 					<InputGroup>
		// 						<Input
		// 							type='date'
		// 							name='startDate'
		// 							//readOnly={formik.values.endDate ? true : false}
		// 							min={moment(userData.startDate).format('YYYY-MM-DD')}
		// 							max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
		// 							value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
		// 						/>
		// 						<Input
		// 							type={'time'}
		// 							value={userData.startTime}
		// 							onChange={(e) => {
		// 								setSelectedUser({ ...userData, startTime: e.target.value });
		// 							}}
		// 						/>
		// 					</InputGroup>
		// 				) : (
		// 					<div>{userData.startTime}</div>
		// 				)}
		// 			</td>
		// 		</tr>
		// 	);
		// }
		// if (userData.endTime) {
		// 	return (
		// 		<>
		// 			<tr>
		// 				<td>{t('변경 퇴근시간')}</td>
		// 				<td>
		// 					{isEditFlag ? (
		// 						<InputGroup>
		// 							<Input
		// 								type='date'
		// 								name='endDate'
		// 								min={moment(userData.endDate).format('YYYY-MM-DD')}
		// 								max={moment(userData.endDate).add(1, 'day').format('YYYY-MM-DD')}
		// 								value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
		// 								}}
		// 								onBlur={(e) => {
		// 									setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
		// 								}}
		// 							/>
		// 							<Input
		// 								type={'time'}
		// 								value={userData.endTime}
		// 								onChange={(e) => {
		// 									setSelectedUser({ ...userData, endTime: e.target.value });
		// 								}}
		// 							/>
		// 						</InputGroup>
		// 					) : (
		// 						<div>{userData.endTime}</div>
		// 					)}
		// 				</td>
		// 			</tr>
		// 			<tr>
		// 				<td>{t('사유')}</td>
		// 				<td>{userData?.etc}</td>
		// 			</tr>{' '}
		// 		</>
		// 	);
		// }
	// } 
	else if (userData?.type === 'REST' || userData?.type === 'USER_REST' || userData?.type === 'REST_TRANSFER') {
		//외출, 연차, 경조사, 반차, 오전반차, 오후반차, 포상, 반반차, 휴가, 병가, 가족돌봄휴가
		if (userData?.restday?.name === '외출') {
			return (
				<>
					{isEditFlag ? (
						<>
							<tr>
							<td><span className='fw-bold mx-3'>{t('외출시간')}</span></td>
								<td>
									{isEditFlag ? (
										<InputGroup>
											<Input
												type='date'
												name='startDate'
												//readOnly={formik.values.endDate ? true : false}
												min={moment(userData.startDate).format('YYYY-MM-DD')}
												max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
												value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
												readOnly
											/>
											<Input
												type={'time'}
												value={userData.startTime}
												onChange={(e) => {
													if (moment(userData.endTime, 'HH:mm') < moment(e.target.value, 'HH:mm')) showNotification('외출 신청', '외출시간이 복귀시간보다 늦을 수 없습니다', 'danger');
													else setSelectedUser({ ...userData, startTime: e.target.value });
												}}
											/>
										</InputGroup>
									) : (
										<div>{userData.startTime}</div>
									)}
								</td>
							</tr>
							<tr>
							<td><span className='fw-bold mx-3'>{t('복귀시간')}</span></td>
								<td>
									{isEditFlag ? (
										<InputGroup>
											<Input
												type='date'
												name='endDate'
												value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
												onChange={(e) => {
													setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
												}}
												onBlur={(e) => {
													setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
												}}
											/>
											<Input
												type={'time'}
												value={userData.endTime}
												onChange={(e) => {
													if (moment(userData.startTime, 'HH:mm') > moment(e.target.value, 'HH:mm')) showNotification('외출 신청', '복귀시간은 외출시간보다 빠를 수 없습니다', 'danger');
													else setSelectedUser({ ...userData, endTime: e.target.value });
												}}
											/>
										</InputGroup>
									) : (
										<div>{userData.endTime}</div>
									)}
								</td>
							</tr>
						</>
					) : (
						<>
							<tr>
							<td><span className='fw-bold mx-3'>{t('기간')}</span></td>
								<td>
									{`${moment(userData.startDate).format('YYYY-MM-DD')} ~ ${moment(userData.endDate).format('YYYY-MM-DD')} `}
									{`(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
								</td>
							</tr>
							<tr>
							<td><span className='fw-bold mx-3'>{t('외출 시간')}</span></td>
								<td>{`${userData.startTime} ~ ${userData.endTime}`}</td>
							</tr>
							{userData?.etc && 	<tr>
								<td><span className='fw-bold mx-3'>{t('사유')}</span></td>
								<td>{userData?.etc}</td>
							</tr>}
							{userData?.memo && (
								<tr>
									<td><span className='fw-bold mx-3'>{t('메모')}</span></td>
									<td>{userData?.memo}</td>
								</tr>
							)}
						</>
					)}
				</>
			);
		} else if (
			userData?.restday?.name === '연차' ||
			userData?.restday?.name === '경조사' ||
			userData?.restday?.name === '포상' ||
			userData?.restday?.name === '병가' ||
			userData?.restday?.name === '휴가' ||
			userData?.restday?.name === '가족돌봄휴가' ||
			userData?.restday?.name === '연차 이월'
		) {
			return (
				<>
					{isEditFlag ? (
						<>
							{/* <tr>
							<td>{t('시작일자')}</td>
							<td>
								<Input
									type='date'
									name='startDate'
									//readOnly={formik.values.endDate ? true : false}
									value={moment(userData.startDate).format('YYYY-MM-DD')}
								
								/>
							</td>
						</tr>
						<tr>

							<td>{t('종료일자')}</td>
							<td>
								<Input
									type='date'
									name='endDate'
									//readOnly={formik.values.endDate ? true : false}
									value={moment(userData.startDate).format('YYYY-MM-DD')}
								
								/>
							</td>
						</tr> */}
							<tr>
								<td><span className='fw-bold'>{t('기간')}</span></td>
								<td>
									<Button
										type='button'
										color='info'
										isLight
										icon='CalendarCheck'
										onClick={() => {
											popRef.current.onOpen();
										}}>
										{/* {t('기간 선택')} */}
									</Button>
									<Popovers //달력 필터
										trigger={'click'}
										className='mw-100'
										placement={'right'}
										ref={popRef}
										desc={
											<div className='col-xl-12'>
												<Card className='shadow-none w-100'>
													<CardBody className='px-0 row'>
														<DateRange
															locale={locales['ko']}
															dateDisplayFormat='yyyy-MM-dd'
															moveRangeOnFirstSelection={false}
															ranges={[
																{
																	startDate: new Date(moment(userData?.startDate).format('YYYY-MM-DD')),
																	endDate: new Date(moment(userData?.duration[userData?.duration?.length - 1]).format('YYYY-MM-DD')),
																	key: 'selection',
																},
															]}
															onChange={(item) => {
																setSelectedUser({ ...userData, endDate: moment(item.selection.endDate), startDate: moment(item.selection.startDate) });
															}}
														/>
													</CardBody>
												</Card>
											</div>
										}>
										<span className='text-dark fw-light' style={{ cursor: 'pointer' }}>

											{`${moment(userData.startDate).format('YYYY-MM-DD')} ~ ${moment(userData?.duration[userData?.duration?.length - 1]).format('YYYY-MM-DD')} `}
										</span>
									</Popovers>
								</td>
							</tr>
						</>
					) : (
						<>
						{userData?.restday?.name === '연차 이월' ?
						<tr>
						<td><span className='fw-bold'>{t('신청일 수')}</span></td>
							<td>
								{userData?.deduction || 0}{t('일')}
							</td>
						</tr>	
						:
						<>
							<tr>
							<td><span className='fw-bold'>{t('기간')}</span></td>
								<td>
									{`${moment(userData.startDate).format('YYYY-MM-DD')} ~ ${moment(userData.endDate).format('YYYY-MM-DD')} `}
									{`(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
								</td>
							</tr>
							{userData.startTime &&
							<tr>
								<td><span className='fw-bold'>{t('시간')}</span></td>
								<td>{`${userData.startTime} ~ ${userData.endTime} (${userData.deduction * 8}시간)`}</td>
							</tr>
							}
								{userData?.etc && 	<tr>
									<td><span className='fw-bold'>{t('사유')}</span></td>
									<td>{userData?.etc}</td>
								</tr>}
								{userData?.memo && (
									<tr>
										<td><span className='fw-bold'>{t('메모')}</span></td>
										<td>{userData?.memo}</td>
									</tr>
								)}
								</>
					}
						</>
					)}
				</>
			);
		} else if (
			userData?.restday?.name === '반차' ||
			userData?.restday?.name === '반반차' ||
			userData?.restday?.name === '오전반차' ||
			userData?.restday?.name === '오후반차' ||
			// userData?.restday?.name === '1시간 연차' ||
			// userData?.restday?.name === '6시간 연차' ||
			// userData?.restday?.name === '8시간 연차'
			userData?.restday?.paidInfo?.paidUnit === 'h' //연차일시
		) {
			return (
				<>
					{isEditFlag ? (
						<>
							<tr>
							<td><span className='fw-bold mx-3'>{t('시작시간')}</span></td>
								<td>
									{isEditFlag ? (
										<InputGroup>
											<Input
												type='date'
												name='startDate'
												//readOnly={formik.values.endDate ? true : false}
												min={moment(userData.startDate).format('YYYY-MM-DD')}
												max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
												value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
												readOnly
											/>
											<Input
												type={'time'}
												value={moment(userData.startTime).format('YYYY-MM-DD')}
												onChange={(e) => {
													if (moment(userData.endTime, 'HH:mm') < moment(e.target.value, 'HH:mm')) showNotification('신청', '시작시간이 종료시간보다 늦을 수 없습니다', 'danger');
													else setSelectedUser({ ...userData, startTime: e.target.value, endTime: moment(e.target.value, 'HH:mm').add(4, 'hours').format('HH:mm') });
												}}
											/>
										</InputGroup>
									) : (
										<div>{userData.startTime}</div>
									)}
								</td>
							</tr>
							<tr>
							<td><span className='fw-bold mx-3'>{t('종료시간')}</span></td>
								<td>
									{isEditFlag ? (
										<InputGroup>
											<Input
												type='date'
												name='endDate'
												value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
												onChange={(e) => {
													setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
												}}
												onBlur={(e) => {
													setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
												}}
											/>
											<Input
												type={'time'}
												value={userData.endTime}
												onChange={(e) => {
													if (moment(userData.startTime, 'HH:mm') > moment(e.target.value, 'HH:mm')) showNotification('신청', '종료시간은 시작시간보다 빠를 수 없습니다', 'danger');
													else setSelectedUser({ ...userData, endTime: e.target.value });
												}}
											/>
										</InputGroup>
									) : (
										<div>{userData.endTime}</div>
									)}
								</td>
							</tr>
						</>
					) : (
						<>
							<tr>
							<td><span className='fw-bold'>{t('기간')}</span></td>
								<td>
									{`${moment(userData.startDate).format('YYYY-MM-DD')} ~ ${moment(userData.endDate).format('YYYY-MM-DD')} `}
									{`(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
								</td>
							</tr>
							{userData.startTime &&
							<tr>
								<td><span className='fw-bold'>{t('시간')}</span></td>
								<td>{`${userData.startTime} ~ ${userData.endTime} (${userData.deduction * 8}시간)`}</td>
							</tr>
							}
								{userData?.etc && 	<tr>
									<td><span className='fw-bold'>{t('사유')}</span></td>
									<td>{userData?.etc}</td>
								</tr>}
								{userData?.memo && (
									<tr>
										<td><span className='fw-bold'>{t('메모')}</span></td>
										<td>{userData?.memo}</td>
									</tr>
							)}
						</>
					)}
				</>
			);
		} else {
			return (
				<>
				{isEditFlag ? (
					<>
						{userData.startTime &&
						<tr>
							<td><span className='fw-bold mx-3'>{t('시작시간')}</span></td>
							<td>
								{isEditFlag ? (
									<InputGroup>
										<Input
											type='date'
											name='startDate'
											//readOnly={formik.values.endDate ? true : false}
											min={moment(userData.startDate).format('YYYY-MM-DD')}
											max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
											value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
											readOnly
										/>
										<Input
											type={'time'}
											value={userData.startTime}
											onChange={(e) => {
												if (moment(userData.endTime, 'HH:mm') < moment(e.target.value, 'HH:mm')) showNotification('휴가 시간', '시작시간이 종료시간보다 늦을 수 없습니다', 'danger');
												else setSelectedUser({ ...userData, startTime: e.target.value });
											}}
										/>
									</InputGroup>
								) : (
									<div>{userData.startTime}</div>
								)}
							</td>
						</tr>
						}
						<tr>
						<td><span className='fw-bold mx-3'>{t('종료시간')}</span></td>
							<td>
								{isEditFlag ? (
									<InputGroup>
										<Input
											type='date'
											name='endDate'
											value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
											onChange={(e) => {
												setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
											}}
											onBlur={(e) => {
												setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate), startDate: moment(e.target.valueAsDate) });
											}}
										/>
										<Input
											type={'time'}
											value={userData.endTime}
											onChange={(e) => {
												if (moment(userData.startTime, 'HH:mm') > moment(e.target.value, 'HH:mm')) showNotification('종료시간', '종료시간은 시작시간보다 빠를 수 없습니다', 'danger');
												else setSelectedUser({ ...userData, endTime: e.target.value });
											}}
										/>
									</InputGroup>
								) : (
									<div>{userData.endTime}</div>
								)}
							</td>
						</tr>
					</>
				) : (
					<>
						<tr>
						<td><span className='fw-bold'>{t('기간')}</span></td>
							<td>
								{`${moment(userData.startDate).format('YYYY-MM-DD')} ~ ${moment(userData.endDate).format('YYYY-MM-DD')} `}
								{`(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
							</td>
						</tr>
						{userData.startTime &&
						<tr>
							<td><span className='fw-bold'>{t('시간')}</span></td>
							<td>{`${userData.startTime} ~ ${userData.endTime} (${userData.deduction * 8}시간)`}</td>
						</tr>
						}
							{userData?.etc && 	<tr>
								<td><span className='fw-bold'>{t('사유')}</span></td>
								<td>{userData?.etc}</td>
							</tr>}
							{userData?.memo && (
								<tr>
									<td><span className='fw-bold'>{t('메모')}</span></td>
									<td>{userData?.memo}</td>
								</tr>
							)}
					</>
				)}
			</>
			);
		}
	} else if (userData?.type === 'WORK') {
		//재택, 외근, 출장, 특근, 연장
		let midNight = (moment(userData.endTime, 'HH:mm').isSame(moment('00:00', 'HH:mm')));

		// 탄력 신청 2주 일정
		let attendFlexibleList = [moment(userData.startDate).format('MM.DD (dd)')];
		for (let i = 1; i < 14; i++) {
			let nextDate = moment(userData.startDate).add(i, 'days');
    		attendFlexibleList.push(nextDate.format('MM.DD (dd)'));
		}

		const attendFlexibleTime = (i) => {
			const targetDate = attendFlexibleList[i]
		  
			const workingData = userData?.data?.list?.find(v => moment(v?.start).format('MM.DD (dd)') === targetDate)
			
			if (workingData) {
				const startTime = moment(workingData?.start);
				const endTime = moment(workingData?.end);
		  
				const formattedStartTime = startTime.toLocaleString();
				const formattedEndTime = endTime.toLocaleString();
				const diffTime = moment.duration(endTime.diff(startTime)).asHours();
				let diffSeconds =  moment.duration(endTime.diff(startTime)).asSeconds();
				let workTime = 0
				if(workingData?.row && workingData?.row?.workingTime){
					workTime = workingData?.row?.workingTime - workingData?.row?.restTime
				}else if(!workingData?.row?.workingTime){

					const formattedForStartTime = moment(startTime);
					const formattedForEndTime = moment(endTime);

						
					let restRealTimeMinutes = diffSeconds;
					let restTimeMinutes = 0;
					if(!userGroup?.restAuto && Object.keys(userGroup).length !== 0) {
						if(userGroup?.restTime[moment(formattedForStartTime).format('E')-1][0][0] === "") {            
							restTimeMinutes =
								Math.floor(
								(parseInt(restRealTimeMinutes)) /
									(270 * 60)
								) *
								(30 * 60);
							} else {
							// 시간 범위 체크
							const s2 = moment(formattedForStartTime).format('HH:mm');
							const e2 = moment(formattedForEndTime).format('HH:mm');
							userGroup?.restTime[moment(formattedStartTime).format('E')-1].map( times => {
								if(times[0] !== '') {
								 
								if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[1], 'HH:mm') <= moment(e2)) {
									console.log('this ALL', moment(times[0], 'HH:mm').format('HH:mm'), moment(times[1], 'HH:mm').format('HH:mm'))
									restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
									// console.log("= ", moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second'))
								} else if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[0], 'HH:mm') <= moment(e2)) {
									console.log('this S', moment(times[0], 'HH:mm').format('HH:mm'));
									restTimeMinutes += moment(e2).diff(moment(times[0], 'HH:mm'), 'second');
									// console.log("= ", moment(e).diff(moment(times[0], 'HH:mm'), 'second'))
								} else if(moment(times[1], 'HH:mm') <= moment(e2) && moment(times[1], 'HH:mm') >= moment(s2)) {
									console.log('this E', moment(times[1], 'HH:mm').format('HH:mm'))
									restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(s2), 'second');
									// console.log("= ", moment(times[1], 'HH:mm').diff(moment(s), 'second'))
								} else {
									console.log('...')
									const start = moment(s2, 'HH:mm');
									const end = moment(e2, 'HH:mm');
									const restStart = moment(times[0], 'HH:mm');
									const restEnd = moment(times[1], 'HH:mm');
									if (
										(restStart >= start && restStart <= end) || (restEnd >= start && restEnd <= end)
									) {
										restTimeMinutes += restEnd.diff(restStart, 'second');
									}else{
										restTimeMinutes = 0
									}
								}
				
								}
							})
						}
						restTimeMinutes = restTimeMinutes > 0 ? restTimeMinutes : 0;
					}else{
						restTimeMinutes =Math.floor((parseInt(restRealTimeMinutes)) /(270 * 60)) *(30 * 60);
					}
	
					workTime = restRealTimeMinutes - restTimeMinutes

				}

				
				return <div className={!mobileDesign && 'p-3'}>
							<sapn>{`${moment(formattedStartTime).format('HH:mm')} - ${moment(formattedEndTime).format('HH:mm')}`}</sapn>
							<div>{`${convertMinsToHrsMins(workTime)}`}</div>
					   </div>
			} else {
				return <div className={!mobileDesign && 'p-3'}>
							<sapn>없음</sapn>
					   </div>
			}
		}

		return (
			<>
			
				{!isEditFlag && (
					
					<> 
					{userData?.data && userData?.data?.list?.length > 0 && userData?.data?.list[0]?.title === '탄력근무' && 
						<tr>
							<td className='align-top' style={{minWidth: '50px'}}><span className='fw-bold align-top'>{t('일정')}</span></td>
							<td>
								<div className='d-flex text-center flex-wrap'>
									{userData?.data && userData?.data?.list?.length > 0 && userData?.data?.list[0]?.title =='탄력근무' &&
										attendFlexibleList?.map((v, i) =>{
											return (
												<div className='border border-dark' style={mobileDesign ? {width : 'calc(100% / 4)'} : {width : 'calc(100% / 7)'}} key={i}>
													<div className='bg-l25-dark'>{v}</div>
													{ attendFlexibleTime(i) }
												</div>
											)
										})
									}
								</div>
							</td>
						</tr>
						}
						<tr>
						<td><span className='fw-bold'>{t('기간')}</span></td>
							<td>
								{/* {`${moment(userData.startDate).format('YYYY-MM-DD')} ${userData?.restday?.name === '연장' ? userData?.startTime : ''} ~ ${moment(midNight ? moment(userData.endDate).add(1, 'day') : userData.endDate).format('YYYY-MM-DD')} ${ userData?.restday?.name === '연장' ? userData?.endTime : ''} `} */}
								{`${moment(userData.startDate).format('YYYY-MM-DD')} ${userData?.restday?.name === '연장' ? userData?.startTime : ''} ~ ${moment(userData.endDate).format('YYYY-MM-DD')} ${ userData?.restday?.name === '연장' ? userData?.endTime : ''} `}

								{userData?.restday?.name !== '연장' && `(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
								<br />
							</td>
						</tr>
						<tr>
						<td><span className='fw-bold'>{t('근무지')}</span></td>
							<td>{t(userData?.area?.name)}</td>
						</tr>
					{userData?.restday?.name !== '연장' &&
						<tr>
						<td><span className='fw-bold'>{t('대문자시간')}</span></td>
							{/* LS <td>{userData.startTime && userData.endTime ? ` ${userData.startTime}-${userData.endTime}` : t('종일 근무')}</td> */}
							<td>{(userData?.data?.list?.length > 0 && userData?.data?.list[0]?.title === '탄력근무')
										? `평균 ${userData?.data?.row?.avg}`
										: (userData?.startTime && userData?.endTime)
											? ` ${userData.startTime}-${userData.endTime}`
											: t('종일 근무')}</td>
						</tr>
					}
						{Object.keys(userData?.data).includes('ot') &&	
							<tr>
								<td><span className='fw-bold'>{t('휴일대체')}</span></td>
								<td>{userData?.data?.alternativeDate ? `${t('신청')} | ${moment(userData?.startDate).format('YYYY.MM.DD (dd)')} <=> ${moment(userData?.data?.alternativeDate).format('YYYY.MM.DD (dd)')}` : `${t('신청안함')} | OT ${t('차감')} (${(userData?.data?.ot / 3600)}시간)`}</td>
							</tr>
						}
						{userData?.etc && 	<tr>
								<td><span className='fw-bold'>{t('사유')}</span></td>
								<td>{userData?.etc}</td>
							</tr>}
							{userData?.memo && (
								<tr>
									<td><span className='fw-bold'>{t('메모')}</span></td>
									<td>{userData?.memo}</td>
								</tr>
							)}
					</>
				)}
				{isEditFlag && (
					<>
						<tr>
						<td><span className='fw-bold'>{t('출근')}</span></td>
							<td>
								<InputGroup>
									<Input
										type='date'
										name='startDate'
										//readOnly={formik.values.endDate ? true : false}
										value={`${moment(userData.startDate).format('YYYY-MM-DD')}`}
										onChange={(e) => {
											setSelectedUser({ ...userData, startDate: moment(e.target.valueAsDate), endDate: moment(e.target.valueAsDate) });
										}}
										onBlur={(e) => {
											setSelectedUser({ ...userData, startDate: moment(e.target.valueAsDate) });
										}}
									/>
									<Input
										type={'time'}
										value={userData.startTime}
										onChange={(e) => {
											setSelectedUser({ ...userData, startTime: e.target.value });
										}}
									/>
								</InputGroup>
							</td>
						</tr>
						<tr>
						<td><span className='fw-bold'>{t('퇴근')}</span></td>
							<td>
								<InputGroup>
									<Input
										type='date'
										name='endDate'
										min={moment(userData.startDate).format('YYYY-MM-DD')}
										max={moment(userData.startDate).add(1, 'day').format('YYYY-MM-DD')}
										value={`${moment(userData.endDate).format('YYYY-MM-DD')}`}
										onChange={(e) => {
											setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
										}}
										onBlur={(e) => {
											setSelectedUser({ ...userData, endDate: moment(e.target.valueAsDate) });
										}}
									/>
									<Input
										type={'time'}
										value={userData.endTime}
										onChange={(e) => {
											setSelectedUser({ ...userData, endTime: e.target.value });
										}}
									/>
								</InputGroup>
							</td>
						</tr>
					</>
				)}
			</>
		);
	} else if (userData?.type?.includes('CANCEL')){
		return (
			<>
				<tr>
				<td><span className='fw-bold'>{t('신청 기간')}</span></td>
					<td>
						{`${moment(userData.startDate).format('YYYY-MM-DD')} ${userData?.duration?.length > 1 ? '~' + moment(userData.endDate).format('YYYY-MM-DD') : ''}`}
						
						{`(${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}${t('일')})`}
						&nbsp;
						<span>{'> ' + t('취소')}</span>
					</td>
				</tr>
				<tr>
				<td><span className='fw-bold'>{t('차감 시간')}</span></td>
					{
					userData?.type === 'REST_TRANSFER_CANCEL' ?
					<td>
						{`${userData?.deduction}일 > 0일`}
					</td> 
					:
					((userData?.deduction === 1 || userData?.deduction === 0) ? 
					<td>
						{`${moment(moment(userData.endDate).format('YYYY-MM-DD')).diff(moment(userData.startDate), 'd') + 1}일 > 0일`}
					</td>
					:
					<td>
						<span>{convertMinsToHrsMins(moment(userData?.endTime, 'HH:mm').diff(moment(userData?.startTime, 'HH:mm'), 'minutes') * 60)}</span>
						{` > 0${t('시간')}`}
					</td>)  
				}
				</tr>
				{userData?.etc && 	<tr>
					<td><span className='fw-bold'>{t('사유')}</span></td>
					<td>{userData?.etc}</td>
				</tr>}
				{userData?.memo && (
					<tr>
						<td><span className='fw-bold'>{t('메모')}</span></td>
						<td>{userData?.memo}</td>
					</tr>
				)}
			</>
		);
	} else {
		return <></>;
	}
};

export default React.memo(GetRestInfo);
