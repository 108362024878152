import { APIClient } from '../lib/apiHelper';

const get = new APIClient().get;
const post = new APIClient().post;
const put = new APIClient().put;
const remove = new APIClient().remove;

export default {
	search: (search) => get('/user?' + (search ? new URLSearchParams(search).toString() : '')),
	modify: (user) => put('/user', user),
	remove: (user) => remove('/user'),
	listBy: (type, id, companyId) => get('/user/list?type=' + type + '&id=' + id + '&companyId=' + companyId),

	list : (userCompany) => post('/user/list', userCompany),
	/**
	 * 개인 결재라인 가져오기
	 * @method GET
	 * @param
	 */
	getApproval: (companyId, search='') => get(`/approval/${companyId}?${(search ? new URLSearchParams(search).toString() : 'type=tree')}`),
	getApprovalOrigin: (companyId) => get('/approval?companyId='+companyId),
	/**
	 * 결재라인 추가
	 * @method POST
	 * @param {OBJECT} approval
	 */
	addApproval: (approval) => post('/approval', approval),
	/**
	 * 결재라인 수정
	 * @method PUT
	 * @param {OBJECT} approval
	 */
	putApproval: (approval) => put('/approval', approval),
	/**
	 * 결재라인 삭제
	 * @method REMOVE
	 * @param {number} approvalId
	 */
	removeApproval: (approvalId) => remove('/approval?approvalId=' + approvalId),
/**
	 * 휴가 유형 가져오기
	 * @method POST
	 * @param {OBJECT} restday
	 */ // typeArray, userId, companyId, (year)
	getUsersRestList: (restday) => post('/user/restday', restday),
	/**
	 * 결재라인 엑셀 등록
	 * @method POST
	 * @param {OBJECT} approval
	 */
	addApprovalExcel: (approval) => post('/approval-excel', approval)
};
