import React from 'react';
import { Link } from "react-router-dom"
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import pubMarkupList from './pubMarkupList'

const PubIndex = () => {
  return (
    <PageWrapper>
      <Page container='fluid'>
        <h3>Pub Markup List</h3>
        <ul>
          {
            pubMarkupList.map((item, idx) => {
              return (
              <li key={idx}>
                <Link to={item.path} target={item.target}>{item.title}</Link>
              </li>
              )
            })
          }
        </ul>
      </Page>
    </PageWrapper>
  )
}

export default PubIndex;
