import { APIClient } from '../lib/apiHelper'

const {get, post, put, remove} = new APIClient()

export default {
	/**
	 *  조직도 부서 등록
	 * @method POST
	 * @param {Object} department
	 * ===========================
	 * @param {Integer} companyId
	 * @param {String} name
	 * @param {Integer} parentId
	 * @param {Array} users
	 */
	addDepartement: (department) => post('/department/add', department),
	/**
	 *  조직도 부서 목록
	 * @method GET
	 * @param {Integer} companyId
	 */
	getDepartement: (companyId) => get(`/department/list/${companyId}`),
	/**
	 *  순수 부서 목록
	 * @method GET
	 * @param {Integer} companyId
	 */
	getDepartementList: (companyId) => get(`/department/purelist/${companyId}`),
	/**
	 *  조직도 부서 수정
	 * @method GET
	 * @param {Integer} companyId
	 */
	updateDepartment: (department) => put('/department/update', department),

	/**
	 *  조직도 부서 수정(트리)
	 * @method GET
	 * @param {Integer} companyId
	 */
	updateDepartmentTree: (department) => post('/department/updateTree', department),
	/**
	 *  조직도 부서 삭제
	 * @method delete
	 * @param {Integer} id
	 */
	removeDepartement: (companyId, id) => remove('/department/remove?id=' + id + '&companyId=' + companyId),
	/**
	 *  조직도 부서 목록
	 * @method GET
	 * @param {Integer} companyId
	 */
	getDepartementTree: (companyId, tree) => get(`/department/tree/${companyId}/${tree}`),
	/**
	 * 조직도 부서 저장(트리) NEW
	 *
	 */
	updateTreeDepart: (department) => post('/department/updateTreeDepart', department),
  /**
   * 유저포함
   * @param {*} companyId 
   * @returns 
   */
  getDepartmentTreeUser: (companyId) => get(`/department/treeUser/${companyId}`),
	/**
	 * 부서인원 업데이트
	 *
	 */
	 updateUsersDepart: (ids) => put('/user/department/update', ids),

  
};