import React, { useEffect, useState, useContext } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageRow from '../../../layout/Page/PageRow';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Logo from '../../../components/Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import { demoPages, extraMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Checks from '../../../components/bootstrap/forms/Checks';
import AuthService from '../../../services/AuthService';
import showNotification from '../../../components/extras/showNotification';
import { useMst } from '../../../models';
import Modal, { ModalBody, ModalFooter } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import LANG, { getLangWithKey } from '../../../lang';
import Label from '../../../components/bootstrap/forms/Label';
import moment from 'moment';
import ThemeContext from '../../../contexts/themeContext';

const SignUp = () => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useMst();
	const { lang, setLang } = useContext(ThemeContext);
	const { t, i18n } = useTranslation(['translation', 'menu']);
	const navigate = useNavigate();

	const [companyName, setCompanyName] = useState('');
	const [checkInviteCode, setCheckInviteCode] = useState(false);
	const [disableSendCodeBtn, setDisableSendCodeBtn] = useState(false);
	const [openCodeInput, setOpenCodeInput] = useState(false);
	const [codeIsConfirmed, setCodeIsCondirmed] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [ModalLink, setModalLink] = useState('');
	const [disableRegisterBtn, setDisableRegisterBtn] = useState(false);

	//타이머
	const [sec, setSec] = useState(0);
	const [min, setMin] = useState(10);
	const [timerOn, setTimerOn] = useState(false);

	const [visiblePW, setVisiblePW] = useState(false);
	const [visiblePW2, setVisiblePW2] = useState(false);

	const loginData = useFormik({
		initialValues: {
			email: '',
			code: '',
			name: '',
			password: '',
			password2: '',
			checkA: false,
			checkB: false,
			checkC: false,
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = '필수입니다';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = '이메일 주소 형식이 올바르지 않습니다';
			}
			if (!checkInviteCode) {
				if (!values.code) {
					errors.code = '필수입니다';
				}
			}
			if (!values.name) {
				errors.name = '필수입니다';
			}
			if (!values.password) {
				errors.password = '필수입니다';
			} else if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,40}$/i.test(values.password)) {
				errors.password = '대/소문자, 특수문자 포함(6자 이상) 입력하세요';
			}
			if (!values.password2) {
				errors.password2 = '필수입니다';
			} else if (values.password !== values.password2) {
				errors.password2 = '비밀번호를 다시 확인해주세요';
			}
			if (!values.checkA) {
				errors.checkA = '동의해주세요';
			}
			if (!values.checkB) {
				errors.checkB = '동의해주세요';
			}
			if (!values.checkC) {
				errors.checkC = '동의해주세요';
			}

			return errors;
		},
		onSubmit: async (values) => {
			if (!checkInviteCode) {
				showNotification(t('회원가입'), t('이메일 인증을 해주세요'), 'danger');
				return false;
			}
			register(values);
			setDisableRegisterBtn(true);
		},
	});

	//* 함수는 사용 순서대로 나열 *//

	const changeLanguage = (lng) => {
		i18n
			.changeLanguage(lng)
			.then(() => {
				moment.locale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('You updated the language of the site.')
				);
			})
			.then(() => {
				setLang(lng);
			});
	};

	// 레이블 옆에 * (필수 사항)
	const labelWithErrors = (label, errors) => {
		return (
			<Label>
				<div className='d-flex'>
					<>{t(label)}</>
					<div className='text-danger px-1 fw-normal'>{errors && errors === '필수입니다' ? '* ' : !errors ? '' : ''}</div>
				</div>
			</Label>
		);
	};

	// 해당 필드 아래쪽에 나오는 에러메세지
	const errorMessage = (pyStyle, errors = '') => {
		return <div className={`text-danger px-2 py-${pyStyle}`}>{errors && errors !== '필수입니다' && errors !== '동의해주세요' ? '* ' + t(errors) : errors === '동의해주세요' ? ' *' : ''}</div>;
	};

	const sendAuthCode = async () => {
		setTimerOn(true);
		if (loginData.values.email && !loginData.errors.email) {
			const checkExistEmail = await AuthService.isExistEmail(loginData.values.email);
			if (checkExistEmail.data) {
				setTimerOn(false);
				if(checkExistEmail?.message){
					showNotification(t('이메일 확인'), t(checkExistEmail?.message), 'danger');
				} else {
					showNotification(t('이메일 확인'), t('이미 존재하는 이메일입니다.'), 'danger');
				}
			} else {
				
				// 존재하지 않는 이메일 확인 후 인증번호 발송
				showNotification(t('이메일 확인'), t('이메일을 확인중입니다.'));
				AuthService.emailAuth(loginData.values.email)
					.then(() => {
						setDisableSendCodeBtn(true);
						setTimerOn(true);
						setOpenCodeInput(true);
						showNotification(t('인증번호 발송'), t(`인증번호를 {{email}} 으로 발송하였습니다. 이메일이 확인되지 않을 경우 스팸함 확인을 해주세요.`, { email: loginData.values.email }), 'info');
					})
					.catch((e) => {
						console.log(e);
						showNotification(t('인증번호 발송'), t('인증번호 발송에 실패하였습니다. 다시 시도해주세요.'), 'danger');
					});
			}
		} else {
			showNotification(t('이메일 확인'), t('이메일을 확인해주세요'), 'danger');
		}
	};

	const confirmAuthCode = async (email, code) => {
		const confirmCode = await AuthService.emailAuthConfirm(email, code);
		if (!confirmCode.data) {
			showNotification(t('인증번호'), t('인증번호가 틀립니다.'), 'danger');
		} else {
			showNotification(t('인증번호'), t('인증번호가 확인 되었습니다.'), 'info');
			setCodeIsCondirmed(true);
			setOpenCodeInput(false);
			setCheckInviteCode(true);
		}
	};

	const AgreedModal = (type) => {
		switch (type) {
			case 'A':
				setModalLink('https://pinat.co.kr/terms.html');
				break;
			case 'B':
				setModalLink('https://pinat.co.kr/terms01.html');
				break;
			case 'C':
				setModalLink('https://pinat.co.kr/terms02.html');
				break;
		}
		setOpenModal(true);
	};

	const reset = () => {
		loginData.resetForm();
		setCheckInviteCode(false);
		setCodeIsCondirmed(false);
		setDisableRegisterBtn(false);
		setDisableSendCodeBtn(false);
		setOpenCodeInput(false);
		setTimerOn(false);
		setSec(0);
		setMin(10);
	};

	const register = async (values) => {
		if (loginData.isValid && (checkInviteCode || codeIsConfirmed)) {
			const register = await AuthService.register({ email: values.email, password: values.password2, name: values.name });
			console.log(register);
			if (register?.id > 0 && register?.code === 201) {
				showNotification(t('회원 가입'), t(register?.message), 'info');
				AuthService.login(values)
					.then(async () => {
						await user.authMe();
						navigate('/' + extraMenu.changeCompany.path);
					})
					.catch((e) => {
						console.log(e);
						showNotification(t('회원 가입'), t('회원가입에 실패하였습니다. 다시 시도해주세요.'), 'danger');
					});
			} else {
				showNotification(t('회원 가입'), t('회원가입에 실패하였습니다.'), 'danger');
			}
		} else {
			showNotification(t('회원 가입'), t('입력 정보를 확인해주세요.'), 'danger');
		}
	};

	const registerCodeCheck = async (code) => {
		const response = await AuthService.getInviteCode(code);
		if (response.data) {
			// 이메일 중복 확인
			const existEmail = await AuthService.isExistEmail(response.data.data.email);
			if (existEmail.data) {
				// 가입된 이메일이 있다면,
				showNotification(t('회원가입'), t('가입된 정보가 존재합니다'), 'warning');
			} else {
				loginData.setFieldValue('email', response?.data?.data?.email);
				loginData.setFieldValue('name', response?.data?.data?.name);
				setCompanyName(response.data.company.name);
				setCheckInviteCode(true);
			}
		}
	};

	// 초대 코드 이메일로 들어온 경우 실행
	useEffect(() => {
		if (window.location.search.indexOf('?code=') > -1) {
			const code = window.location.search.replace('?code=', '');
			if (code && code.length > 0) {
				registerCodeCheck(code);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (timerOn) {
			const timer = setInterval(() => {
				if (parseInt(sec) === 0) {
					if (parseInt(min) > 0) {
						setSec(59);
						setMin(parseInt(min) - 1);
					} else if (parseInt(min) === 0) {
						clearInterval(timer);
						setTimerOn(false);
					}
				} else if (parseInt(sec) > 0) {
					if (parseInt(min) > 0) {
						setSec(parseInt(sec) - 1);
					} else if (parseInt(min) === 0) {
						setSec(parseInt(sec) - 1);
					}
				}
			}, 1000);
			return () => clearInterval(timer);
		} else {
			setMin(10);
			setSec(0);
		}
	}, [sec, min, timerOn]);

	return (
		<PageWrapper title={'SignUp'} className='p-0 flex-row'>
			<Page className='col-xl-6 p-0 d-flex align-items-center justify-content-center'>
				<div className='col-xl-6 col-lg-6'>
					<Dropdown className='mb-4'>
						<DropdownToggle hasIcon={false}>
							<Button
								aria-label='Change language'
								data-tour='lang-selector'
								color={darkModeStatus ? 'dark' : 'light'}
								hoverShadow='default'
								isLight={!darkModeStatus}
								size='lg'
								icon={getLangWithKey(i18n.language).icon}
                style={{boxShadow: '0px 4px 4px 3px rgba(115, 115, 115, 0.25)'}} 
							/>
						</DropdownToggle>
						<DropdownMenu>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										disabled={lang === LANG[i].lng}
										onClick={() => {
											changeLanguage(LANG[i].lng);
										}}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
					<div className='mb-5'>
						<Link
							to={'/' + demoPages.login.path}
							className={classNames('text-decoration-none  fw-bold display-2', {
								'text-dark': !darkModeStatus,
								'text-light': darkModeStatus,
							})}>
							<Logo width={150} />
						</Link>
					</div>
          <div className='mb-5'>
						<div className='fw-bold h1'>{t('회원 가입')},</div>
						<div className='h4 text-muted'>{companyName ? companyName : t('회원가입 후 이용해주세요')}!</div>
					</div>
					<div
						className={classNames('rounded-pill p-1 mb-5', {
							'bg-l10-dark': !darkModeStatus,
							'bg-lo10-dark': darkModeStatus,
						})}>
						<div className='row'>
							<div className='col'>
								<Link to={'/' + demoPages.login.path}>
									<Button color={darkModeStatus ? 'light' : ''} isLight className='rounded-pill w-100 text-muted fw-bold' onClick={() => {}}>
										{t('로그인')}
									</Button>
								</Link>
							</div>
							<div className='col'>
								<Button
									color={darkModeStatus ? 'light' : ''}
									className='rounded-pill w-100 fw-bold bg-white'
									onClick={() => {
										reset();
									}}>
									{t('회원가입')}
								</Button>
							</div>
						</div>
					</div>
          <form className='row' onSubmit={loginData.handleSubmit}>
						<div className='col-12'>
							<FormGroup>
								<InputGroup>
                  <div
								  	className='border border-light d-flex align-items-center rounded-start border-end-0 bg-l10-info text-muted'
								  	style={{height:'47.5px', width:'80px'}}
								  >
								  	<Icon
								  		icon='MailOutline'
								  		className='ms-3 me-2'
								  	/>
								  	<span>{t('이메일')}</span>
								  </div>
									<Input className='py-3 border-start-0 border-end-0 bg-l10-info' id='email' type='text' readOnly={!!disableSendCodeBtn || !!checkInviteCode} value={loginData.values.email} onChange={loginData.handleChange} />
									<Button
                    className='btn-disable-opacity border border-light rounded-end border-start-0 bg-l10-info text-info'
										disabled={!loginData.values.email || loginData.errors.email || timerOn || (!checkInviteCode ? false : true)}
										onClick={() => {
											sendAuthCode();
										}}>
										{t('인증번호 발송')}
									</Button>
								</InputGroup>
								{errorMessage(2, loginData.errors.email)}
							</FormGroup>
							{openCodeInput && (
								<FormGroup>
									<InputGroup>
                    <div
								    	className='border border-light d-flex align-items-center rounded-start border-end-0 bg-l10-info text-muted'
								    	style={{height:'47.5px', width:'80px'}}
								    >
								    	<Icon
								    		icon='MailOutline'
								    		className='ms-3 me-2'
								    	/>
								    	<span>{t('인증번호')}</span>
								    </div>
										<Input className='py-3 border-start-0 border-end-0 bg-l10-info' id='code' type='text' readOnly={codeIsConfirmed} placeholder={t('6자리 입력')} value={loginData.values.code} onChange={loginData.handleChange} />
										<Button
											className='btn-disable-opacity border border-light rounded-end border-start-0 bg-l10-info text-info'
											disabled={loginData.errors.code}
											onClick={() => {
												confirmAuthCode(loginData.values.email, loginData.values.code);
											}}>
											{t('인증')}
										</Button>
									</InputGroup>
									{/* 동적인것은 dom 업데이트 할때마다 업데이트 하니 여기 직접 입력*/}
									{<div className='px-2 pt-2 text-muted'>{`${t('인증번호 유효시간')} : ${min}${t('분')} ${sec < 10 ? `0` + sec : sec}${t('초')}`}</div>}
									{errorMessage(2, loginData.errors.code)}
								</FormGroup>
							)}
							<FormGroup>
                <InputGroup>
                  <div
							  		className='border border-light d-flex align-items-center rounded-start border-end-0 bg-l10-info text-muted'
							  		style={{height:'47.5px', width:'80px'}}
							  	>
							  		<Icon
							  			icon='PersonOutline'
							  			className='ms-3 me-2'
							  		/>
							  		<span>{t('성함')}</span>
							  	</div>
							  	<Input
							  		className='py-3 border-start-0 border-end-0 bg-l10-info'
							  		id='name'
							  		type='text'
							  		readOnly={checkInviteCode && window.location.search.indexOf('?code=') > -1 ? true : false}
							  		value={loginData.values.name}
							  		onChange={loginData.handleChange}
							  	/>
                </InputGroup>
							  {errorMessage(2, loginData.errors.name)}
							</FormGroup>
						  <FormGroup>
						  	<InputGroup>
                  <div
								  	className='border border-light d-flex align-items-center rounded-start border-end-0 bg-l10-info text-muted'
								  	style={{height:'47.5px', width:'80px'}}
								  >
								  	<Icon
								  		icon='Lock'
								  		className='ms-3 me-2'
								  	/>
								  	<span>{t('비밀번호')}</span>
								  </div>
						  		<Input className='py-3 border-start-0 border-end-0 bg-l10-info' id='password' type={visiblePW ? 'text' : 'password'} value={loginData.values.password} onChange={loginData.handleChange} />
						  		<Button className='border border-light rounded-end border-start-0 bg-l10-info text-muted' onClick={() => setVisiblePW(!visiblePW)}>
						  			<Icon icon={visiblePW ? 'Visibility' : 'VisibilityOff'} />
						  		</Button>
						  	</InputGroup>
						  	{errorMessage(2, loginData.errors.password)}
						  </FormGroup>
							<FormGroup className='mb-4'>
								<InputGroup>
                  <div
								  	className='border border-light d-flex align-items-center rounded-start border-end-0 bg-l10-info text-muted'
								  	style={{height:'47.5px', width:'110px'}}
								  >
								  	<Icon
								  		icon='Lock'
								  		className='ms-3 me-2'
								  	/>
								  	<span>{t('비밀번호 확인')}</span>
								  </div>
									<Input className='py-3 border-start-0 border-end-0 bg-l10-info' id='password2' type={visiblePW2 ? 'text' : 'password'} value={loginData.values.password2} onChange={loginData.handleChange} />
									<Button className='border border-light rounded-end border-start-0 bg-l10-info text-muted' onClick={() => setVisiblePW2(!visiblePW2)}>
										<Icon icon={visiblePW2 ? 'Visibility' : 'VisibilityOff'} />
									</Button>
								</InputGroup>
								{errorMessage(2, loginData.errors.password2)}
							</FormGroup>
							<div className='text-muted'>
								<div className='d-flex'>
									<Checks
										id='checkA'
										label={t('서비스 이용약관 동의') + t(' (필수)')}
										checked={loginData.values.checkA}
										onChange={(e) => {
											if (e.target.checked) {
												AgreedModal('A');
											}
											loginData.handleChange(e);
										}}></Checks>
									{errorMessage(0, loginData.errors.checkA)}
								</div>
								<div className='d-flex'>
									<Checks
										id='checkB'
										label={t('개인정보취급방침 동의') + t(' (필수)')}
										checked={loginData.values.checkB}
										onChange={(e) => {
											if (e.target.checked) {
												AgreedModal('B');
											}
											loginData.handleChange(e);
										}}></Checks>
									{errorMessage(0, loginData.errors.checkB)}
								</div>
								<div className='d-flex'>
									<Checks
										id='checkC'
										label={t('위치기반서비스 동의') + t(' (필수)')}
										checked={loginData.values.checkC}
										onChange={(e) => {
											if (e.target.checked) {
												AgreedModal('C');
											}
											loginData.handleChange(e);
										}}></Checks>
									{errorMessage(0, loginData.errors.checkC)}
								</div>
							</div>
							<div className='my-5'>
								<Button className='w-100 py-3' color='info' disabled={!loginData.isValid || (!checkInviteCode && !codeIsConfirmed) || disableRegisterBtn} type='submit' onClick={() => {}}>
									{t('회원가입')}
								</Button>
							</div>
						</div>
					</form>
					{/* <div className='text-center'>
						<a
							href={'//pinat.co.kr/terms01.html'}
							target={'_blank'}
							rel='noopener noreferrer'
							className={classNames('link-light text-decoration-none me-3', {
								'link-light': false,
								'link-light': true,
							})}>
							{t('개인정보취급방침')}
						</a>
						<a
							href='//pinat.co.kr/terms.html'
							target={'_blank'}
							rel='noopener noreferrer'
							className={classNames('link-light text-decoration-none', {
								'link-light': false,
								'link-light': true,
							})}>
							{t('서비스 이용약관')}
						</a>
					</div> */}
				</div>
				<Modal isOpen={openModal} setIsOpen={setOpenModal}>
					<ModalBody>
						<iframe width='100%' height={window.innerHeight - 200} frameBorder='0' src={ModalLink} />
					</ModalBody>
					<ModalFooter className='bg-info d-flex justify-content-center' isCentered>
						<Button
							className='text-white'
							size='lg'
							onClick={() => {
								setOpenModal(false);
							}}>
							{t('닫기')}
						</Button>
					</ModalFooter>
				</Modal>
			</Page>
      <PageRow className='login-bg col-md-6 p-0' />
		</PageWrapper>
	);
};
export default SignUp;
