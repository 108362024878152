import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import Modal, {ModalBody, ModalFooter} from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import Checks from "../../../components/bootstrap/forms/Checks";
import Icon from "../../../components/icon/Icon";
import imgCalendar from "../../../assets/img/deadline/img_calendar.png";
import {useNavigate} from "react-router-dom";
import deadlineService from "../../../services/DeadlineService";
import {useMst} from "../../../models";
import moment from "moment";

const DeadlineAlertModal = ({ isOpen, setIsOpen, deadline }) => {
  const { t } = useTranslation(['translation', 'menu'])
  const { user } = useMst()
  const navigate = useNavigate()
  const [dismissModal, setDismissModal] = useState(false)

  const handleDismissModal = () => {
    const userDeadlineId = deadline?.userDeadlines
        ?.find(v => v.userId === user?.id)
        ?.id

    deadlineService.dismissModal({ userDeadlineId })
  }

  return (
    <Modal
      isCentered
      isStaticBackdrop
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      size={'md'}
    >
      <div className='modal-wraped mx-3'>
        <ModalBody>
          <div className='text-center mt-5'>
            <img src={imgCalendar} alt="달력 이미지" />
            <h3 className='mt-5 fw-bold'>{t('곧 근무가 마감돼요!')}</h3>
            <p className='mt-3 text-muted'>
              {t('마감 기간에 해당하는 근무를 확인하고')}
              <br />{t('근무를 확정해주세요.')}
            </p>
            <p className='text-secondary mt-4 lh-lg'>
              <Icon
                icon='Error'
                size='2x'
                className='me-2'
              />
              <spna className='fw-bold me-1'>{moment(deadline?.completeDate)?.format('YYYY년 MM월 DD일')}</spna>{t('이후엔 수정이 불가능해요')}
            </p>
          </div>
          <ModalFooter className='justify-content-center'>
            <Button
              style={{
                borderRadius: 7,
                border: '1px solid #e6e7e7'
              }}
              className='me-3'
              onClick={() => {
                if (dismissModal)
                  handleDismissModal()
                setIsOpen(false)
              }}
            >
              <span className='px-3 text-muted'>{t('닫기')}</span>
            </Button>
            <Button
              style={{
                borderRadius: 7,
              }}
              color='secondary'
              onClick={() => {
                if (dismissModal)
                  handleDismissModal()
                navigate('/work/calender')
              }}
            >
              <span className='px-3'>{t('근무 확인하기')}</span>
            </Button>
          </ModalFooter>
          <div className='d-flex justify-content-center mt-3 mb-4'>
            <Checks
              type={'checkbox'}
              label={t('다시 보지 않기')}
              checked={dismissModal}
              onChange={() => setDismissModal(!dismissModal)}
            />
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default React.memo(DeadlineAlertModal)