import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import Badge from '../../../components/bootstrap/Badge';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import Avatar, { AvatarGroup } from '../../../components/Avatar';
import useRequest from '../../../hooks/useRequest';
import { useMst } from '../../../models';
import CompanyService from '../../../services/CompanyService';
import { useTranslation } from 'react-i18next';
import showNotification from '../../../components/extras/showNotification';
import { adminsMenu } from '../../../menu';
import Tooltips from "../../../components/bootstrap/Tooltips";

const OvertimeWorkers = observer(({ workTime = 40, workOverTime = 12 }) => {
	const { company } = useMst();
	const ref = useRef();
	const { t } = useTranslation(['translation', 'menu']);
	const { responseData, loading, request } = useRequest([]);
	const { lang } = useContext(ThemeContext);
	const navigate = useNavigate();
	const workTotalTime = workTime + workOverTime
	useEffect(() => {
		request(CompanyService.workTimeOver, [company.id, workTotalTime, moment().format('YYYY-MM-DD')], null, (error) => {
			showNotification(t('초과 근무자'), t('초과 근무자를 불러오는 도중에 문제가 발생했습니다.'), 'danger');
		});
	}, [company, workTotalTime, request, t]);

	//나중에는 workTime, workOverTime을 표준 근무제로 부터 가져오도록 해야 할듯.
	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			<CardHeader className='rounded-2 flex-row'>
				<CardLabel>
					<CardTitle onClick={() => {
						navigate(adminsMenu.workingManagement.subMenu.workingLog.path, {
							state: {
								overWork: true
							}
						});
					}} className='d-flex align-items-center cursor-pointer'>
						{/*<span className='me-2'>{t(`{{value}}시간 초과 근무자`, { value: worktimeToString })}</span>*/}
						<span className='me-2'>{t(`초과 근무자`)}</span>
						{
							responseData?.length > 0 &&
							<Badge className='text-wrap bg-l10-danger px-3'>
								<span className='fw-bold text-danger fs-6'>{responseData?.length || 0}</span>
							</Badge>
						}
					</CardTitle>
				</CardLabel>
				<CardActions>
					<div className='d-flex align-items-center cursor-pointer' onClick={() => {
						navigate(adminsMenu.workingManagement.subMenu.workingLog.path, {
							state: {
								overWork: true
							}
						});
					}}>
						{t('더보기')} <Icon icon='KeyboardArrowRight' size='lg' />
					</div>
				</CardActions>
			</CardHeader>
			<CardBody
				ref={ref}
				isScrollable
				className='dashboard-height overflow-scroll p-0'
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{loading ? (
					<DashboardLoadingSpinner />
				) : !loading && responseData.length <= 0 ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center text-muted'>
						<Icon size={'6x'} icon={'CheckCircle'} />
						{/*<p className='mt-5'>{t('{{value}}시간 초과근무자가 없습니다.', { value: worktimeToString })}</p>*/}
						<p className='mt-5'>{t('초과근무자가 없습니다.')}</p>
					</div>
				) : (
					<>
						<div
							className='border-bottom border-light d-flex justify-content-between align-items-center px-3 py-4'>
							<div className='text-muted'>{t('이름')}</div>
							<div className='text-muted'>
								{t('초과근무시간')}
								<Tooltips
									title={
										<div className='text-start p-3'>
											<span className='fs-6'>기본 근무시간 <b>40시간</b>,</span><br/>
											<span className='fs-6'>연장 근무시간 <b>12시간</b>을 초과한 시간이 표기합니다.</span><br/>
											<span className='ms-2 text-white-50'>(표준근로제 기준, 근로제 및 근무그룹에 따라 변동)</span><br/>
										</div>
									}
									placement='top'
									flip='right'
								>
									<Icon
										icon='InfoOutline'
										className='ms-1'
										size='lg'
									/>
								</Tooltips>
							</div>
						</div>

					<div className='w-100'>
				{
					responseData.map((v,i) => {
					return (
						<div
							key={i}
							className='border-bottom border-light d-flex justify-content-between align-items-center px-3 py-4'
						>
							<div>
								<Avatar
									textSizeRatio={10}
									maxInitials={2}
									size={36}
									userName={v.user?.name}
									src={null}
									className='me-3'
								/>
								<span className='text-dark fw-bold'>{v.user?.name}</span>
								<span className='mx-1 text-muted'>ㅣ</span>
								<span className='text-muted'>{v.rank?.name}</span>
							</div>
							{/*<div className='text-muted'>*/}
							{/*				<span className='text-danger fw-bold'>*/}
							{/*					{(workTime === 42) ?*/}
							{/*						Math.abs((v.weekWorkingTotal / 60 / 60) - workTime).toFixed(1)*/}
							{/*						:*/}
							{/*						Math.abs((v.weekWorkingTotal / 60 / 60) + (v.weekWorkingOverTotal / 60 / 60) - workTime).toFixed(1)*/}
							{/*					}*/}
							{/*					시간</span> 초과*/}
							{/*</div>*/}
							<div className='text-muted'>
								{/* 시간 값에 따른 텍스트컬러 변경을 위한 className 분기 작업 필요(text-black, text-danger, text-muted) */}
								{/* text-black - 기본근무시간 초과 text-danger - 연장근무시간 초과시 text-muted - 기본근무시간만 초과시 연장근무시간 00:00표시용 */}
								<span className='me-4'>기본
									<span className={`ms-2 fw-bold 
										${ (((v.weekWorkingOverTotal / 60 / 60) - workOverTime) > 0) 
											?
										      'text-danger' 
										      :
											  (((v.weekWorkingTotal / 60 / 60) - workTime) > 0)
												?
												  'text-black' 
												  : 
												  'text-muted'}`}>
										{ ((v.weekWorkingTotal / 60 / 60) - workTime > 0) ? Math.abs((v.weekWorkingTotal / 60 / 60) - workTime).toFixed(1) : (0.0).toFixed(1)}
									</span>
								</span>
								<span>연장
									<span className={`ms-2 fw-bold
										${ (((v.weekWorkingOverTotal / 60 / 60) - workOverTime) > 0)
										?
										'text-danger'
										:
										'text-muted'}`}>
										{ ((v.weekWorkingOverTotal / 60 / 60) - workOverTime > 0) ? Math.abs((v.weekWorkingOverTotal / 60 / 60) - workOverTime).toFixed(1) : (0.0).toFixed(1)}
									</span>
								</span>
							</div>
						</div>

					)
					})
				}
					</div>
					</>
				)}
			</CardBody>
		</Card>
	);
});

const DashboardLoadingSpinner = () => {
	return (
		<div
			className='h-100 d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle'>
			<Spinner className='me-1' tag='span' color='primary' isGrow/>
			<Spinner className='me-1' tag='span' color='secondary' isGrow/>
			<Spinner className='me-1' tag='span' color='dark' isGrow />
		</div>
	);
};

export default OvertimeWorkers;
