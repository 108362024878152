import { APIClient } from "../lib/apiHelper"

const {get, post, put, remove} = new APIClient()

export default {

  /**
   * 그룹 생성
   * @method POST
   * @param {OBJECT} group
   * =========================
   * @param {INTEGER} companyId
   * @param {STRING} name
   * @param {JSONB} users
   * @param {JSONB} workDay 
   * @param {STRING} startTime
   * @param {STRING} endTime
   */
   create: group => post('/group', group),

   /**
   * 그룹 수정
   * @method PUT
   * @param {OBJECT} group
   * =========================
   * @param {INTEGER} id (필수)
   * =========================
   * @param {STRING} name
   * @param {JSONB} users
   * @param {JSONB} workDay 
   * @param {STRING} startTime
   * @param {STRING} endTime
   */
   modify: group => put('/group', group),
   /**
   * 그룹 종료
   * @method workingGroupEnd
   * @param {OBJECT} data
   */
    workingGroupEnd: data => put('/group/workingGroupEnd', data),

  /**
   * 그룹목록
   * @param companyId
   * @returns {Promise<AxiosResponse<any>>}
   */
  list: (companyId, isUser=false) => get(`/group?companyId=${companyId}${isUser===true ? '&isUser=true' : ''}`),
  get: (id) => get(`/group/id?id=${id}`),
  /*
  *2022-05-02 개인 그룹 가져오기
  */
  getGroupInfoById: (userId, companyId, date=null) => get(`/group/id2?userId=${userId}&companyId=${companyId}${date ? '&date='+date : ''}`),

  /**
   * 그룹 정보 요청
   * @method GET
   * @param {OBJECT} search
   * =========================
   * @param {STRING} companyId
   * @param {STRING} name
   * @param {JSONB} users
   * @param {JSONB} work_day
   * @param {STRING} count
   * @param {STRING} offset
   * @param {STRING} order
   * @param {STRING} desc
   */
   search: (search) => get(`/group?${  search ? new URLSearchParams(search).toString() : ''}`),   
   searchSchdule: (search) => get(`/group/schdule?${  search ? new URLSearchParams(search).toString() : ''}`),
   searchFlexibleSchedule: (search) => get(`/group/flexibleSchedule?${  search ? new URLSearchParams(search).toString() : ''}`),
   delete: (id) => remove(`/group?id=${id}`),
   /**
    * 그룹 사용자 지정
    * @param {*} group 
    * @returns 
    */
   saveUser: (group) => post('/workgroup/saveuser', group),
   /**
    * 그룹 정보 요청 NEW 2022-03-29
    * @param {*} search 
    * @returns 
    */
   searchNew: (search) => get(`/groupNew?${  search ? new URLSearchParams(search).toString() : ''}`),
   /**
   * 권한 별 그룹목록
   * @param companyId
   * @returns {Promise<AxiosResponse<any>>}
   */
  listByPermissions: (companyId, isUser=false) => get(`/group/groupByPermissions?companyId=${companyId}${isUser===true ? '&isUser=true' : ''}`),
}
