import React, { useEffect, useState } from 'react';
// import {useReactToPrint} from 'react-to-print';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../../models';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../../../components/bootstrap/Modal';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import CompanyService from '../../../../services/CompanyService';
import showNotification from '../../../../components/extras/showNotification';
import deadlineService from "../../../../services/DeadlineService";
import WorkRecodeCloseModal from "../../Company/components/WorkRecodeCloseModal";
import AttendService from "../../../../services/AttendService";

const ApprovalContentsRequestModal = ({
	userData, 
	setUserData,
	openRequest,
	setOpenRequest,
	setIsModalOpen,
	setApprovalCheckValue,
	setAgreeCheckValue,
	approvalCheckValue,
	agreeCheckValue,
	approvalList,
	approvalList1,
	setApprovalList,
	proxy,
	setUserInformation,
	userInformation,
	setApprovalList1,
	openModal,
	refreshOrigin
}) => {

	const { user, company } = useMst();
	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const [requestData, setRequestData] = useState(userData);
	const [closedAttendModalOpen, setClosedAttendModalOpen] = useState(false)

	// const approvalY = approvalList1.find((i)=> i.state === 'REQUEST')?.y;
	// const approvalX = approvalList1.find((i)=> i.state === 'REQUEST')?.x;
	// const agreeY = agreeList.find((i)=> i.state === 'REQUEST')?.y;
	// const agreeX = agreeList.find((i)=> i.state === 'REQUEST')?.x;
	
	const [data, setData] = useState({
		id: requestData.id,
		companyId: company.get.id,
		userId: user.me.id,
		y: parseInt(openModal.selectUserY),
		x: parseInt(openModal.selectUserX),
		state: '',
		memo: '',
	});

	const [checkApprovalList, setCheckApprovalList] = useState([
		{ id: 1, label: proxy ? '대결' : '승인', value: 'APPROVAL' },
		// { id: 2, label: '대결', value: 'APPROVAL_PROXY' },
		{ id: 3, label: '전결', value: 'APPROVAL_ALL' },
		{ id: 4, label: '반려', value: 'REJECT' },
	]);
	const [checkAgreeList, setCheckAgreeList] = useState([
		{ id: 1, label: proxy ? '대결' : '합의', value: 'APPROVAL' },
		// { id: 1, label: '합의', value: 'APPROVAL' },
		{ id: 2, label: '반대', value: 'REJECT' },
	])

	// const [proxy, setProxy] = useState(false);

	// const whosNext = async () => {
	// 	console.log('requestData', requestData)
	// 	await CompanyService.who(company.get.id, requestData?.id).then((res)=>{
	// 		console.log("res>>>", res) // 해당 결재 인원 정보만 띄워줌
	// 		let isAbsentee = res.data.users[0].isAbsentee;
	// 		if(isAbsentee){
	// 			setProxy(true);
	// 		} else {
	// 			setProxy(false);
	// 		}
	// 	})
	// }
	
	useEffect(()=> {
		if(proxy) {
			setCheckApprovalList([
				{ id: 1, label: proxy ? '대결' : '승인', value: 'APPROVAL' },
				// { id: 2, label: '대결', value: 'APPROVAL_PROXY' },
				{ id: 3, label: '전결', value: 'APPROVAL_ALL' },
				{ id: 4, label: '반려', value: 'REJECT' },
			]);
		}
	},[proxy])

	const modalChecksItems = (checkType) => {
	
		return (
			<>
				<div className='d-flex check-box fw-bold'>
					{checkType == 'APPROVAL'
						? checkApprovalList?.map((approval) => {
								return (
									<div key={approval.value} className='align-items-center justify-content-center d-flex w-100 mx-4 mb-3'>
										<Checks
											id={approval.id}
											type='checkbox'
											label={t(approval.label)}
											checked={approval.value == approvalCheckValue}
											onChange={() => {
												setApprovalCheckValue(approval.value);
											}}
										/>
									</div>
								);
						  })
						: checkAgreeList?.map((agree) => {
								return (
									<div key={agree.value} className='align-items-center justify-content-center d-flex w-100 mx-4 mb-3'>
										<Checks
											id={agree.id}
											type='checkbox'
											label={t(agree.label)}
											checked={agree.value == agreeCheckValue}
											onChange={() => {
												setAgreeCheckValue(agree.value);
											}}
										/>
									</div>
								);
						  })}
				</div>
				<div className='memo-box fw-bold justify-content-center mt-4 mb-2'>
					{approvalCheckValue == 'REJECT' || agreeCheckValue == 'REJECT' ? (
						<>
							<div className='fs-5 mb-3 d-flex justify-content-center '>{t(`${checkType == 'APPROVAL' ? '반려' : '반대'}하시겠습니까?`)}</div>
							<Textarea style={{ backgroundColor: '#f2f2f2' }} placeholder={t(`${checkType == 'APPROVAL' ? '반려' : '반대'} 사유를 입력하세요`)} value={data.memo}
								onChange={(e) =>
									setData({
										...data,
										memo: e.target.value,
									})
								}/>
						</>
					) : approvalCheckValue == 'APPROVAL' || agreeCheckValue == 'APPROVAL' ? (
						<>
							<div className='fs-5 mb-3 d-flex justify-content-center '>{t(`${checkType == 'APPROVAL' ? '승인' : '합의'}하시겠습니까?`)}</div>
								{/* checkType == 'APPROVAL' ? proxy ? '대결' : '승인' : '합의'}하시겠습니까? */}
							<Textarea style={{ backgroundColor: '#f2f2f2' }} placeholder={t('의견을 입력하세요')} value={data.memo}
								onChange={(e) =>
									setData({
										...data,
										memo: e.target.value,
									})
								}/>
						</>
					) : approvalCheckValue == 'APPROVAL_ALL' && checkType == 'APPROVAL' ? (
						<>
							<div className='fs-5 mb-3 d-flex justify-content-center '>{t('최종 결재 처리하시겠습니까') + '?'}</div>
							<Textarea style={{ backgroundColor: '#f2f2f2' }} placeholder={t('의견을 입력하세요')} value={data.memo}
								onChange={(e) =>
									setData({
										...data,
										memo: e.target.value,
									})
								}/>
						</>
					) 
					// : (
						: approvalCheckValue == 'APPROVAL_PROXY' ? (
							<>
								<div className='fs-5 mb-3 d-flex justify-content-center '>{t('대신 승인하시겠습니까') + '?'}</div>
								<Textarea style={{ backgroundColor: '#f2f2f2' }} placeholder={t('의견을 입력하세요') + '.'} />
							</>
						)
						// '')
				: <></>}
				</div>
			</>
		);
	};

	const saveDataBtn = async () => {
		// 마감된 기간의 근무 기록 수정인지 체크
		// 마감기간 최종 마감 후에만, 근무기록 수정 결제를 하게 되어 있으므로, 마감기간 체크 하지 않도록 기존 로직 주석처리
		/* if (['APPROVAL', 'APPROVAL_ALL'].includes(approvalCheckValue) && requestData?.type === 'RECORD') {
			const { data: approvable } = await deadlineService.attendUpdatable({
				userId: requestData?.userId,
				companyId: requestData?.companyId,
				eventStartDate: requestData?.startDate,
				eventEndDate: requestData?.endDate
			})

			if (!approvable) { // 이미 마감된 기간의 근무를 수정하는 기안이다, block it !!
				setClosedAttendModalOpen(true)
				return false
			}
		}
		*/

		if((approvalCheckValue == 'REJECT' || agreeCheckValue == 'REJECT') && !data.memo){
			showNotification(t('결재'), t('반려 이유를 입력해주세요')	, 'danger');
		} else {
			// 주 52시간 근무 초과 여부 체크
			if (data.state?.includes('APPROVAL')
				&& requestData?.type === 'WORK'
				&& ['연장', '특근'].includes(requestData?.restday?.name)
			) {
				const res = await AttendService.check52(
					requestData.userId,
					`${requestData.startDate} ${requestData.startTime}`,
					`${requestData.endDate} ${requestData.endTime}`,
					requestData.restday.name
				)
				if (res?.data?.isOver) {
					showNotification(t('결재'), t('근무 시간 초과로 승인이 불가합니다.')	, 'danger');
					return false
				}
			}

			setOpenRequest(false);
			try {
				
				// if(requestData.state == 'REQUEST'){ // REQUEST일 경우에만 수정 가능
					let apprvalCopy = approvalList ? [...approvalList] : [...approvalList1];   //approvalList는 최상단 부모에서 가져온 리스트다
					await CompanyService.approval(data).then(res=> {
						// error
						if (res?.data?.message) {
							showNotification(t('실패'), res?.data?.message, 'danger');
							return
						}

						setUserData(res?.data);
						refreshOrigin();
						let findIndex = apprvalCopy?.findIndex(i => i?.id == res.data?.id);
						if (findIndex > -1) {
							apprvalCopy[findIndex] = {
								...apprvalCopy[findIndex], 
								state: res.data.state,
							}

						const typeXY = (y, x) => {
							return(
								apprvalCopy[findIndex].approvals[y].line[x] = {
									...apprvalCopy[findIndex].approvals[y].line[x],
									state: res.data.approvals[y].line[x].state,
									sussDate: res.data.approvals[y].line[x].sussDate,
									memo: res.data.approvals[y].line[x].memo,
									otherUser: res.data.approvals[y].line[x].otherUser
								})
							}
							// if(openModal?.modalTypeFlag === 'APPROVAL'){
							// 	typeXY(data.y, data.x)
							// } else {
								typeXY(data.y, data.x)
							// }
						}
						setApprovalList([...apprvalCopy]) 
						setApprovalList1([...apprvalCopy]) 
						setUserInformation({...userInformation,
							state: res?.data?.state
						})
						setData({
							...data,
							state: openModal?.modalTypeFlag  === 'APPROVAL' ? approvalCheckValue : agreeCheckValue
						})
						setIsModalOpen(false);
					})
			} catch (error) {
				// 예외 처리
				console.error(error);
			}
		}
	}

	useEffect(()=> {
		if(openModal?.modalTypeFlag  === 'AGREE'){
			setData({
				...data,
				state: agreeCheckValue
			})
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[openModal?.modalTypeFlag , agreeCheckValue])

	useEffect(()=> {
		if(openModal?.modalTypeFlag  === 'APPROVAL'){
			setData({
				...data,
				state: approvalCheckValue
			})}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[openModal?.modalTypeFlag , approvalCheckValue])

	return (
		<>
			<Modal isOpen={openRequest} setIsOpen={setOpenRequest} isCentered>
				<ModalHeader className='mx-3 my-3' setIsOpen={setOpenRequest}>
					<ModalTitle>{openModal?.modalTypeFlag  == 'APPROVAL' ? t('결재서') : t('합의서')}</ModalTitle>
				</ModalHeader>
				<ModalBody className='border py-5'>{openModal?.modalTypeFlag  == 'APPROVAL' ? modalChecksItems('APPROVAL') : modalChecksItems('AGREE')}</ModalBody>
				<ModalFooter className='d-flex justify-content-center my-3'>
					<div className=''>
						<Button
							color='success'
							onClick={() => {
								saveDataBtn();
							}}>
							{t('확인')}
						</Button>
						<Button color='light' onClick={() => setOpenRequest(false)}>
							{t('취소')}
						</Button>
					</div>
				</ModalFooter>
			</Modal>

			<WorkRecodeCloseModal
				isOpen={closedAttendModalOpen}
				setIsOpen={setClosedAttendModalOpen}
			/>
		</>
	);
};
export default ApprovalContentsRequestModal;
