import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import Card, { CardLabel, CardBody, CardTitle, CardHeader, CardActions } from '../../../../components/bootstrap/Card';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../../models';
import CompanyService from '../../../../services/CompanyService';
import moment from 'moment';
import useRequest from '../../../../hooks/useRequest';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import { useNavigate } from 'react-router-dom';
import { usersMenu, eapproval } from '../../../../menu';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Badge from '../../../../components/bootstrap/Badge';
import showNotification from '../../../../components/extras/showNotification';
import Chart from '../../../../components/extras/Chart';
import AttendService from '../../../../services/AttendService';
import ThemeContext from '../../../../contexts/themeContext';

const UserRestOrWorkListCard = ({ type = 'REST' }) => {
	const typeName = type === 'REST' ? '휴가' : '근무';
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef();
  const { mobileDesign } = useContext(ThemeContext);
	const navigate = useNavigate();
	const { company, user } = useMst();
	const { responseData: originData, loading, request } = useRequest([]);
	const [selectedDate, setSelectedDate] = useState({
		startDate: moment().startOf('year').format('YYYY-MM-DD'),
		endDate: moment().endOf('year').format('YYYY-MM-DD'),
	});
	const [workTime, setWorkTime] = useState({
		work: [{ time: 0 }],
		businessOffsite: [{ time: 0 }],
		over: [{ time: 0 }],
	});
	const [successRestDay, setSuccessRestDay] = useState({
		day: 0,
		hrs: 0,
	});
	const convertMinsToHrsMins = useCallback(
		(mins) => {
			let h = Math.floor(mins / 3600);
			let m = Math.floor((mins % 3600) / 60);
			let s = Math.floor(mins % 60);
			//let h = Math.floor(mins / (60 * 60));
			//let m = mins % (60);
			// h = h < 10 && h > 0 ? '0' + h : h;
			// m = m < 10 ? '0' + m : m;
			return t(`{{hour}}시간 ${m > 0 ? m + '분' : ''}`, { hour: h.toString(), minute: m.toString() });
		},
		[t]
	);
	const timeLabel = `${convertMinsToHrsMins((workTime?.work[0]?.time || 0) + (workTime?.over[0]?.time || 0) + (workTime?.businessOffsite[0]?.time || 0))}`;
	
	const restColumnList = useMemo(
		() => [{ name: `${typeName} 유형` }, { name: '시작일' }, { name: '종료일' }, { name: '결재 상태' }],
		[typeName]
	);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	const reFresh = useCallback(async () => {
		const currentDate = moment().format('YYYY-MM-DD')
		const startDate = currentDate < moment().format('YYYY-MM-16') ? moment().subtract(1, 'M').format('YYYY-MM-16') : moment().format('YYYY-MM-16')
		const endDate = currentDate < moment().format('YYYY-MM-16') ? moment().format('YYYY-MM-15') : moment().add(1, 'M').format('YYYY-MM-15')
		await AttendService.getLogAttend(0, startDate, endDate, company.get.id, 'day', user.me.id).then(async (res) => {
			if (res?.data?.length > 0) {

				let attendCategories = res.data[0]?.filter((i) => i.attend); 

				let businessOffsiteWork = [...attendCategories].filter((i) => i.attend.dates.history[0].attendCategory?.name === '출장' || i.attend.dates.history[0].attendCategory?.name === '외근');
				let normalWork = [...attendCategories].filter((i) => businessOffsiteWork.findIndex((v) => v.attend.id == i.attend.id) === -1);

				// 근무
				const totalNormalWorkSecondsDifference = normalWork.reduce((total, currentItem) => {
					return total + ((currentItem?.attend?.workingTime || 0 + currentItem?.attend?.nightTime || 0) - (currentItem?.attend?.restTime || 0));
				}, 0);

				// 외근, 출장
				const totalbusinessOffsiteWorkSecondsDifference = businessOffsiteWork.reduce((total, currentItem) => {
					return total + ((currentItem?.attend?.workingTime || 0 + currentItem?.attend?.nightTime || 0) - (currentItem?.attend?.restTime || 0));
				}, 0);

				// 연장
				const totalOverTimeSecondsDifference = attendCategories?.reduce((total, currentItem) => {
					return total + ((currentItem?.attend?.workingOverTime || 0) + (currentItem?.attend?.nightOverTime || 0) - (currentItem?.attend?.restOverTime || 0));
				}, 0);

				setWorkTime({
					work: [{ time: totalNormalWorkSecondsDifference }, ...normalWork],
					businessOffsite: [{ time: totalbusinessOffsiteWorkSecondsDifference }, ...businessOffsiteWork],
					over: [{ time: totalOverTimeSecondsDifference }, ...attendCategories],
				});
			}})

			const rest = await CompanyService.getVacationStatus({
				companyId: company.get.id,
				userId: user.me.id,
				sdate: moment().startOf('month').format('YYYY-MM-DD'),
				edate: moment().endOf('month').format('YYYY-MM-DD'),
			});

			if (rest?.data) {
				const totalDeduction = rest?.data?.reduce((total, item) => {
					const deduction = item?.deduction || 0;
					const durationLength = item?.duration?.length || 0;

					return total + (deduction > 0 ? deduction : durationLength);
				}, 0);

				let day = Math.floor(totalDeduction);
				let hrs = (totalDeduction - Math.floor(totalDeduction)) / 0.125;

				setSuccessRestDay({
					day: day,
					hrs: hrs,
				});
			}
		},[company.get.id, user.me.id])

	useEffect(()=> {
		reFresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	useEffect(() => {
		request(
			CompanyService.getRestOrWorkStatus,
			[
				{
					companyId: company.id,
					userId: user.id,
					type: type,
					startDate: selectedDate.startDate,
					endDate: selectedDate.endDate,
				},
			],
			null,
			(error) => {
				showNotification(t(`${typeName} 현황`), t(`${typeName} 현황을 불러오는 도중에 문제가 발생했습니다.`), 'danger');
			},
			{
				key: 'startDate',
				type: 'date',
				direction: 'descending',
			}
		);
	}, [user, company, selectedDate, typeName, type, request, t]);

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const dataOfRowIndex = originData[rowIndex - 1];

			switch (columnIndex) {
				case 0:
					data = dataOfRowIndex?.restday?.name
						? dataOfRowIndex?.restday?.name === '근무' && dataOfRowIndex?.type === 'RECORD'
							? '근무 기록'
							: dataOfRowIndex?.restday?.name
						: '';
					break;
				case 1:
					data = dataOfRowIndex?.startDate ? moment(dataOfRowIndex?.startDate).format('YYYY-MM-DD') : '-';
					break;
				case 2:
					data = dataOfRowIndex?.endDate ? moment(dataOfRowIndex?.endDate).format('YYYY-MM-DD') : '-';
					break;
				case 3:
					const reqState = dataOfRowIndex?.state;
					data =
						reqState === 'REQUEST' ? (
							<div className='text-primary'>{t('진행중')}</div>
						) : reqState === 'SUCCESS' ? (
							<div className='text-success'>{t('완료')}</div>
						) : reqState === 'REJECT' ? (
							<div className='text-danger'>{t('반려')}</div>
						) : (
							'-'
						);
					break;
			}

			return data;
		},
		[originData, t]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(restColumnList[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer justify-content-center ${
							rowIndex % 2 === 0 ? 'bg-l50-light' : ''
						}`}
						onClick={() => {
							// navigate(usersMenu.approvalDocument.path + '/' + originData[rowIndex - 1]?.id)
							navigate(usersMenu.WorkingSchedule.path, { state: { id: originData[rowIndex - 1]?.id } });
						}}
						>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, originData, restColumnList, navigate, renderText, t]
	);

	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			<CardHeader className={`rounded-2 ${mobileDesign && 'd-flex flex-row'}`}>
				<CardLabel>
					<CardTitle className='cursor-pointer' onClick={() => navigate('/' + usersMenu.WorkingSchedule.path)}>
						{t('근무 현황')}
					</CardTitle>
				</CardLabel>
				<CardActions className={`d-flex align-items-end ${mobileDesign && 'flex-column-reverse'}`}>
					{/* <span className='text-wrap text-dark small'>{`${moment(selectedDate.startDate).format('L(ddd)')}~${moment(
						selectedDate.endDate
					).format('L(ddd)')}`}</span> */}
					<span className={`text-wrap text-dark small ${mobileDesign && 'mt-2 me-0'}`}>24.02.15 - {moment().format('YYYY.MM.DD HH:mm 현재')}</span>
					<div>
						<Badge color='light' className='text-wrap'>
							<span className='text-dark'>{t('총 상신 수')} : </span>
							<span className='fw-bold text-info fs-6'>{originData?.length || 0}</span>
						</Badge>
					</div>
				</CardActions>
			</CardHeader>
			<CardBody
				className='dashboard-height overflow-auto'
				ref={ref}
				onMouseOver={(e) => {
					if (ref?.current?.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					if(ref?.current) {
						ref.current.scroll = true;
						document.body.style.overflow = 'auto';
						document.body.style.removeProperty('padding-right');
					}
				}}>
				{loading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) 
				// 데이터 없을때
				// : originData.length <= 0 ? (
				// 	<div className='pb-5 lead h-100 d-flex flex-column justify-content-center align-itmes-center text-center'>
				// 		<span>{t('근무 내역이 비어있습니다.')}</span>
				// 	</div>
				// ) 
				: (
					<div className='d-flex flex-column justify-content-around h-100'>
						{/* <Chart
							series={graphValues.series}
							options={graphValues.options}
							type={graphValues.options.chart.type}
							width={graphValues.options.chart.width}
							className='user-dashboard-chart'
						/> */}
						<Chart
							// height={210}
							options={{
								dataLabels: {
									enabled:false
								},
								chart: {
									type: 'donut',
									width: mobileDesign ? '250px' : '60%',
									toolbar: {
										download: false,
										show: false,
									},
								},
								labels: ["근무", "연장", "출장", "연차", "특근"],
								colors: ['#88DBED','#FE91AE','#FFD275','#79D3BA','#8189F7'],
								legend: {
									show: false,
								},
								plotOptions: {
									pie: {
										donut: {
											labels: {
												show: true,
                        value: {
                          fontSize: mobileDesign ? '12px' : '20px',
                          offsetY: mobileDesign ? 0 : 10,
                        },
												total: {
													show: true,
													showAlways: true,
													fontWeight: 600,
													label: timeLabel,
													formatter: () => {
														return '총 근무시간';
													}
												}
											}
										}
									}
								},
							}}
							series={[
								(workTime.work[0].time / 3600),(workTime.over[0].time / 3600),(workTime.businessOffsite[0].time / 3600)
							]}
							type='donut'
							width={mobileDesign? '250px':'60%'}
							className='user-dashboard-chart'
						/>
						<div className='mb-4 text-center'>
							<p className='mb-0 text-muted'>계획 <span>180시간 00분</span></p>
						</div>
						<div className='d-flex justify-content-around'>
							<div>
								<p className='d-flex align-items-center'>
									<span className='d-block rounded-circle me-2' style={{width: '8px', height: '8px', backgroundColor: '#88DBED'}}></span>
									{t('근무')}
								</p>
								<p className='mb-0'><span className='fs-5 text-dark fw-bold me-1'>{convertMinsToHrsMins(workTime?.work[0]?.time)}</span></p>
							</div>
							<div>
								<p className='d-flex align-items-center'>
									<span className='d-block rounded-circle me-2' style={{width: '8px', height: '8px', backgroundColor: '#8189F7'}}></span>
									{t('특근')}
								</p>
								<p className='mb-0'><span className='fs-5 text-dark fw-bold me-1'>8시간</span></p>
							</div>
							<div>
								<p className='d-flex align-items-center'>
									<span className='d-block rounded-circle me-2' style={{width: '8px', height: '8px', backgroundColor: '#FE91AE'}}></span>
									{t('연장')}
								</p>
								<p className='mb-0'><span className='fs-5 text-dark fw-bold me-1'>{convertMinsToHrsMins(workTime?.over[0]?.time)}</span></p>
							</div>
							<div>
								<p className='d-flex align-items-center'>
									<span className='d-block rounded-circle me-2' style={{width: '8px', height: '8px', backgroundColor: '#FFD275'}}></span>
									{t('출장')}
								</p>
								<p className='mb-0'><span className='fs-5 text-dark fw-bold me-1'>{convertMinsToHrsMins(workTime?.businessOffsite[0]?.time)}</span></p>
							</div>
							<div>
								<p className='d-flex align-items-center'>
									<span className='d-block rounded-circle me-2' style={{width: '8px', height: '8px', backgroundColor: '#79D3BA'}}></span>
									{t('연차')}
								</p>
								<p className='mb-0'><span className='fs-5 text-dark fw-bold me-1'>{(successRestDay.day || 0) + t('일')}&nbsp;
								{(successRestDay.hrs || 0) + t('시간')}</span>
								</p>
							</div>
						</div>
					</div>
				)}
			</CardBody>
		</Card>
	);
};
export default React.memo(UserRestOrWorkListCard);
