import { APIClient } from '../lib/apiHelper'
const {post, get, put} = new APIClient()

export default {
    
    alarm: alarm => get('/alarm?companyId=' + alarm),
    alarmModify: alarm => put('/alarm', alarm),
    alarmSetting: alarm => get('/alarm/setting?userId=' + alarm),
    alarmSettingModify: alarm => put('/alarm/setting', alarm),
    alarmRead: alarm => put('/alarm/read', alarm),
    alarmReadAll : userCompanyData => put('/alarm/read/all',userCompanyData),

}