import { saveAs } from 'file-saver';
import moment from 'moment';
import showNotification from '../components/extras/showNotification';
import CompanyService from '../services/CompanyService';

export function getTextColorByBackgroundColor(hexColor) {
	const c = hexColor.substring(1); // 색상 앞의 # 제거
	const rgb = parseInt(c, 16); // rrggbb를 10진수로 변환
	const r = (rgb >> 16) & 0xff; // red 추출
	const g = (rgb >> 8) & 0xff; // green 추출
	const b = (rgb >> 0) & 0xff; // blue 추출

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

	// 색상 선택
	return luma < 127.5 ? 'white' : 'dark'; // 글자색이
}

export function divisionArray(arr, n) {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	let tmp = [];
	for (var i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
}

export function remainingRests(a) {		
	let originalRest = parseFloat(a || 0);

	let result = 0;
	let day = 0;
	let hrs = 0;

	result = originalRest < 0 ? Math.abs(originalRest) : originalRest;
	day = Math.floor(result);

	if (result < 1) {
		hrs = result / 0.125;
	} else {
		hrs = (result % Math.floor(result)) / 0.125;
	}

	return { day: day, hrs: hrs };
};

export function convertToYYYYMMDD(dateString) {
	const formattedDate = moment(dateString, ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY_MM_DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY', 'YYYY.MM.DD']).format('YYYY-MM-DD');
	return formattedDate;
}

export const checkMoment = (input, format = 'YYYY-MM-DD') => {
	const formats = ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY_MM_DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY', 'YYYY.MM.DD']; // 이후 계속 추가
	for (let i = 0; i < formats.length; i++) {
		if (moment(input, formats[i]).isValid() === true) return moment(input, formats[i]).format(format);
	}
	return input;
};

export function s2ab(s) {
	var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	var view = new Uint8Array(buf); //create uint8array as viewer
	for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
	return buf;
}

export async function verificationFile(file, validTableData, userCompanyList, companyId, type) {
	if (file) {
		return new Promise(async (resolve) => {
			const xlsx = require('xlsx');
			const reader = new FileReader();
			let excelKeys = [];
			switch (type) {
				case 'rest':
					// 사용자 검증 / 사용 시작일자 / 사용 종료일자 / 휴가여부
					let resRestType = await CompanyService.getRestType(companyId);
					let restType = resRestType.data;

					reader.onload = (e) => {
						let userList = userCompanyList.map((x) => x.user);
						let result = true;
						let target = e.target.result;
						const wb = xlsx.read(target, { type: 'binary' });
						const sheetname = wb.SheetNames[0];
						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false });

						const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

						for (let i = 0; i < data.length; i++) {
							// console.log(data[i]['사용 시작일자'], (data[i]['사용 시작일자']).indexOf('-'))
							//사용 시작일자 유효성 체크
							if (data[i]['사용 시작일자'].indexOf('-') < 0) {
								data[i]['사용 시작일자'] = data[i]['사용 시작일자']
									.split('/')
									.map((x) => (x.length == 1 ? '0' + x : x))
									.join('/');
								// console.log('A', data[i]['사용 시작일자'])
								if (!moment(data[i]['사용 시작일자'], 'MM/DD/YY', true).isValid()) {
									data[i]['사용 시작일자'] = validTableData?.length > 0 ? data[i]['사용 시작일자'] : moment(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD');
									data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ? data[i]['사용 시작일자'] : '#' + data[i]['사용 시작일자'] + '#';
									result = false;
								} else {
									data[i]['사용 시작일자'] = moment(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD');
								}
							} else {
								// console.log('B')
								if (!moment(data[i]['사용 시작일자'], 'YYYY-MM-DD', true).isValid()) {
									data[i]['사용 시작일자'] = validTableData?.length > 0 ? data[i]['사용 시작일자'] : moment(data[i]['사용 시작일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
									data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ? data[i]['사용 시작일자'] : '#' + data[i]['사용 시작일자'] + '#';
									result = false;
								}
							}
							//사용 종료일자 유효성 체크
							if (data[i]['사용 종료일자'].indexOf('-') < 0) {
								data[i]['사용 종료일자'] = data[i]['사용 종료일자']
									.split('/')
									.map((x) => (x.length == 1 ? '0' + x : x))
									.join('/');
								// console.log('A', data[i]['사용 시작일자'])
								if (!moment(data[i]['사용 종료일자'], 'MM/DD/YY', true).isValid()) {
									data[i]['사용 종료일자'] = validTableData?.length > 0 ? data[i]['사용 종료일자'] : moment(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD');
									data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ? data[i]['사용 종료일자'] : '#' + data[i]['사용 종료일자'] + '#';
									result = false;
								} else {
									data[i]['사용 종료일자'] = moment(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD');
								}
							} else {
								// console.log('B')
								if (!moment(data[i]['사용 종료일자'], 'YYYY-MM-DD', true).isValid()) {
									data[i]['사용 종료일자'] = validTableData?.length > 0 ? data[i]['사용 종료일자'] : moment(data[i]['사용 종료일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
									data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ? data[i]['사용 종료일자'] : '#' + data[i]['사용 종료일자'] + '#';
									result = false;
								}
							}

							const checkValid = (str) => {
								str = str.replace(/ /g, ''); // 공백 제거
								return str.charAt(0) == '#' && str.charAt(str.length - 1) == '#' ? str.substring(1, str.length - 1) : str; // '#'있다면 제거
							};
							// 이메일 유효성체크 준비
							data[i]['이메일'] = checkValid(data[i]['이메일']);

							//이름과 이메일 매칭 체크
							let nameUser = userList.find((x) => x.name == data[i]['이름'])?.email;
							let emailUser = userList.find((x) => x.email == data[i]['이메일'])?.email;

							//이메일 형식 체크
							if (data[i]['이메일'].length < 6 || !regExpEmail.test(data[i]['이메일']) || !userList.find((x) => x.email == data[i]['이메일']) || nameUser !== emailUser) {
								data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ? data[i]['이메일'] : '#' + data[i]['이메일'] + '#';
								result = false;
							}

							//사용자 유효성 체크
							if (!userList.find((x) => x.name == data[i]['이름'].replace(/ /g, ''))) {
								data[i]['이름'] = data[i]['이름'].indexOf('#') > -1 ? data[i]['이름'] : '#' + data[i]['이름'] + '#';

								// 기존 소스
								// data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ?
								// data[i]['이메일'] :  '#' + data[i]['이메일'] + '#'
								result = false;
							}

							// 휴가 여부 체크
							// 임시로 연차와 반차만 입력 가능하도록.
							if (!['연차', '반차'].find((x) => x == data[i]['휴가유형'].replace(/ /g, ''))) {
								// 기존 소스 : if (!restType.find(x => x.name == data[i]['휴가유형'].replace(/ /g, ''))) {
								data[i]['휴가유형'] = data[i]['휴가유형'].indexOf('#') > -1 ? data[i]['휴가유형'] : '#' + data[i]['휴가유형'] + '#';
								result = false;
							}
						}
						resolve({ data: data, result: result });
					};
					reader.readAsBinaryString(file);
					break;

				case 'work':
					excelKeys = ['name', 'companyNumber', 'date', 'startTime', 'endTime', 'ccCode1', 'ccCode2', 'ccCode3'];
					// 근태 업로드
					reader.onload = (e) => {
						let result = true;
						let target = e.target.result;
						const wb = xlsx.read(target, { type: 'binary' });
						const sheetname = wb.SheetNames[0];
						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false, defval: ' ' }); // 데이터가 없는 셀의 기본값 공백(' ')으로 설정

						console.log('work data', data);

						for (let i = 0; i < data.length; i++) {
							if (Object.keys(data[i]).includes('공사현장')) {
								result = false;
								return resolve({ msg: 'notMatchingType' });
							}
							// 컬럼별 validation
							if (!moment(convertToYYYYMMDD(data[i]['일자']), 'YYYY-MM-DD', true).isValid()) {
								data[i]['일자'] = validTableData?.length > 0 ? data[i]['일자'] : moment(convertToYYYYMMDD(data[i]['일자']), 'YYYY-MM-DD').format('YYYY-MM-DD');
								data[i]['일자'] = data[i]['일자'].indexOf('#') > -1 ? data[i]['일자'] : '#' + data[i]['일자'] + '#';
								result = false;
							} else {
								data[i]['일자'] = convertToYYYYMMDD(data[i]['일자']);
							}
							// 근태CC 1,2,3 validation -> 세 컬럼 중 한 컬럼의 값만 존재해야 함
							let count = 0;
							for (let key in data[i]) {
								if (['근태CC 1', '근태CC 2', '근태CC 3'].includes(key) && !!data[i][key]?.trim()) {
									count++;
									if (count > 1) {
										data[i]['근태CC 1'] = data[i]['근태CC 1'].indexOf('#') > -1 ? data[i]['근태CC 1'] : '#' + data[i]['근태CC 1'] + '#';
										data[i]['근태CC 2'] = data[i]['근태CC 2'].indexOf('#') > -1 ? data[i]['근태CC 2'] : '#' + data[i]['근태CC 2'] + '#';
										data[i]['근태CC 3'] = data[i]['근태CC 3'].indexOf('#') > -1 ? data[i]['근태CC 3'] : '#' + data[i]['근태CC 3'] + '#';
										result = false;
										continue;
									}
								}
							}

							console.log('>>', data[i]['근무 시작시간'], moment(data[i]['근무 시작시간'], 'HH:mm', true).isValid());

							// 231228 근무 시작 시간이 HH:mm, H:mm 두 형식에 맞게 들어오지 않을때, #을 붙여서 업로드 방지하도록 유효성 체크 추가
							if (!moment(data[i]['근무 시작시간'], 'HH:mm', true).isValid() && !moment(data[i]['근무 시작시간'], 'H:mm', true).isValid()) {
								data[i]['근무 시작시간'] = validTableData?.length > 0 ? data[i]['근무 시작시간'] : moment(data[i]['근무 시작시간'], 'H:mm').format('HH:mm');
								// H:mm을 HH:mm으로 형식을 바꿨는데도 불구하고, Valid 오류가 발생하게 된다면, 그때 result를 false로 처리
								// 여기서 처리를 하더라도, backend 로직에서 시간 type이 맞지 않는다면 attend save시 exception이 발생함.
								if (!moment(data[i]['근무 시작시간'], 'HH:mm', true).isValid() && !moment(data[i]['근무 시작시간'], 'H:mm', true).isValid()) {
									data[i]['근무 시작시간'] = data[i]['근무 시작시간'].indexOf('#') > -1 ? data[i]['근무 시작시간'] : '#' + data[i]['근무 시작시간'] + '#';
									result = false;
								}
							}
							// 근무 시작 시간과 동일하게 변경
							if (!moment(data[i]['근무 종료시간'], 'HH:mm', true).isValid() && !moment(data[i]['근무 종료시간'], 'H:mm', true).isValid()) {
								data[i]['근무 종료시간'] = validTableData?.length > 0 ? data[i]['근무 종료시간'] : moment(data[i]['근무 종료시간'], 'H:mm').format('HH:mm');
								if (!moment(data[i]['근무 종료시간'], 'HH:mm', true).isValid() && !moment(data[i]['근무 종료시간'], 'H:mm', true).isValid()) {
									data[i]['근무 종료시간'] = data[i]['근무 종료시간'].indexOf('#') > -1 ? data[i]['근무 종료시간'] : '#' + data[i]['근무 종료시간'] + '#';
									result = false;
								}
							}
						}
						if (validTableData?.length > 0 && result == true) {
							for (let i = 0; i < data.length; i++) {
								let updatedKeyData = {};
								excelKeys.forEach((key, index) => {
									updatedKeyData[key] = data[i][Object.keys(data[i])[index]].trim();
								});
								updatedKeyData['rowNum'] = i.toString();
								updatedKeyData['ccCode'] = !!updatedKeyData['ccCode1'] ? updatedKeyData['ccCode1'] : !!updatedKeyData['ccCode2'] ? updatedKeyData['ccCode2'] : updatedKeyData['ccCode3'];
								data[i] = updatedKeyData;
							}
						}

						console.log('work data >> ', data);

						resolve({ data: data, result: result });
					};
					reader.readAsBinaryString(file);
					break;
				case 'constr':
					excelKeys = ['name', 'companyNumber', 'date', 'constrSite', 'constrRate', 'constrRank', 'constrType', 'constrYear', 'constrGrade', 'constrMgr'];
					// 공사수당 업로드
					reader.onload = (e) => {
						let result = true;
						let target = e.target.result;
						const wb = xlsx.read(target, { type: 'binary' });
						const sheetname = wb.SheetNames[0];
						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false, defval: ' ' }); // 데이터가 없는 셀의 기본값 공백(' ')으로 설정

						for (let i = 0; i < data.length; i++) {
							if (Object.keys(data[i]).includes('근무 시작시간')) {
								result = false;
								return resolve({ msg: 'notMatchingType' });
							}
							// 컬럼별 validation
							if (!moment(data[i]['일자'], 'YYYY-MM-DD', true).isValid()) {
								data[i]['일자'] = validTableData?.length > 0 ? data[i]['일자'] : moment(data[i]['일자'], 'YYYY-MM-DD').format('YYYY-MM-DD');
								data[i]['일자'] = data[i]['일자'].indexOf('#') > -1 ? data[i]['일자'] : '#' + data[i]['일자'] + '#';
								result = false;
							}
						}
						if (validTableData?.length > 0 && result == true) {
							for (let i = 0; i < data.length; i++) {
								let updatedKeyData = {};
								excelKeys.forEach((key, index) => {
									updatedKeyData[key] = data[i][Object.keys(data[i])[index]].trim();
								});
								updatedKeyData['rowNum'] = i.toString();
								data[i] = updatedKeyData;
							}
						}
						resolve({ data: data, result: result });
					};
					reader.readAsBinaryString(file);
					break;
				default:
					// 직원등록,직원초대 유효성 체크
					//휴대폰번호 / 입사일 / 이메일
					console.log('default');
					reader.onload = (e) => {
						let target = e.target.result;
						const wb = xlsx.read(target, { type: 'binary' });
						const sheetname = wb.SheetNames[0];
						let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { raw: false });

						// const patternPhone = new RegExp("01[016789]-[0-9]{2,3}-[0-9]{3,4}");
						//const patternPhone = new RegExp("01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}");
						const patternPhone = /01[016789]-[0-9]{3,4}-[0-9]{4}/;
						const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
						let emails = [];
						let result = true;

						for (let i = 0; i < data.length; i++) {
							let verificationData = {};
							//휴대폰 번호 형식
							if (!patternPhone.test(data[i]['휴대폰번호'])) {
								data[i]['휴대폰번호'] = data[i]['휴대폰번호'].indexOf('#') > -1 ? data[i]['휴대폰번호'] : '#' + data[i]['휴대폰번호'] + '#';
								result = false;
							}
							//입사일 유효성 체크
							//MM/DD/YY

							// 이메일 유효성체크 준비
							let email = data[i]['이메일'].replace(/ /g, ''); // 공백 제거
							//이메일 중복 체크

							if (emails.find((x) => x === email)) {
								data[i]['이메일'] = '중복';
								result = false;
							} else {
								emails.push(email);
							}
							//이메일 형식 체크
							if (email.length < 6 || !regExpEmail.test(email)) {
								data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ? data[i]['이메일'] : '#' + data[i]['이메일'] + '#';
								result = false;
							}
						}
						resolve({ data: data, result: result });
					};
					reader.readAsBinaryString(file);
					break;
			}
		});
	} else {
		showNotification('유효성 검사', '파일이 존재하지 않습니다.', 'danger');
		return false;
	}
}

export async function excelDownload(jsonDataList, key, options) {
	const xlsx = require('xlsx');
	let workSheetDatas = [];
	let sheetName;
	console.log('jsonDataList >> ', jsonDataList);
	switch (key) {
		case 'userList':
			sheetName = '사원정보';
			jsonDataList.map((x) => {
				let attnedType = [];
				x?.group?.area?.gps && attnedType.push('GPS');
				x?.secomNumber && attnedType.push('출입연동');
				x?.group?.area?.wifi && attnedType.push('WIFI');

				let deviceModel = [];
				deviceModel = x.device.map((y) => y.os);
				deviceModel = new Set(deviceModel);
				deviceModel = [...deviceModel];

				let state = '';
				switch (x.state) {
					case 'SUCCESS':
						state = '재직';
						break;
					case 'LEAVE':
						state = '퇴직';
						break;
					case 'REST':
						state = '휴직';
						break;						
					case 'REQUEST':
						state = '가입 요청';
						break;
					case 'DENY':
						state = '요청 거절';
						break;
				}

				let depart = x.departments?.find((x) => x.main);

				let workSheetData = {
					이름: x.user?.name || '',
					사번: x.companyNumber || '',
					이메일: x.user?.email || '',
					휴대폰: x.user?.phone || '',
					부서: depart?.department?.name,
					소속그룹: x.group?.name || '',
					직위: x.rank?.name || '',
					직책: x.position?.name || '',
					인증방식: attnedType?.join('/') || '',
					연차: `${((x.restInfo?.rest || 0) - (x.restInfo?.userest || 0)).toFixed(1)} / ${(x.restInfo?.rest).toFixed(1)}`,
					권한: x.role?.name || '',
					디바이스: deviceModel?.join('/') || '',
					근무상태: state,
				};
				workSheetDatas.push(workSheetData);
			});
			break;
		default:
			break;
	}
	let wb = xlsx.utils.book_new();
	let ws = xlsx.utils.json_to_sheet(workSheetDatas);
	xlsx.utils.book_append_sheet(wb, ws, sheetName);
	xlsx.writeFile(wb, `${moment().format('YYYY-MM-DD')}_${sheetName}.xlsx`);
}

// 글자수 자르기
export function truncateString(str, maxLength) {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	} else {
		return str;
	}
}

export const getLineUser = (approvalList = []) => {
	const userList = [];
	approvalList?.forEach((approval) => {
		approval.line.forEach((user) => {
			userList.push(user.user);
		});
	});
	return userList;
};

export const convertMinsToHrsMins = (mins) => {
	let h = Math.floor(mins / 3600);
	let m = Math.floor((mins % 3600) / 60);
	let s = Math.floor(mins % 60);
	//let h = Math.floor(mins / (60 * 60));
	//let m = mins % (60);
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};

export function excelSerialDateToJSDate(excelSerialDate) {
	const daysBeforeUnixEpoch = 70 * 365 + 19;
	const hour = 60 * 60 * 1000;
	return new Date(Math.round((excelSerialDate - daysBeforeUnixEpoch) * 24 * hour) + 12 * hour);
}

export function excelSerialTimeToJSTime(excelSerialDate) {
	// 0.333333333 > 08:00
	return convertMinsToHrsMins(Math.round(excelSerialDate * (24 * 60 * 60)));
}
