import React from 'react'
import Modal, {ModalBody, ModalFooter} from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import {useTranslation} from "react-i18next";
import imgNotice from "../../../../assets/img/deadline/img_notice.png";

const VacationPolicyConfirmModal = ({ isOpen, setIsOpen, modalTitle, modalText }) => {
  const { t } = useTranslation(['translation', 'menu'])

  return (
    <Modal
      isCentered
      isStaticBackdrop
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      size={'sm'}
    >
      <div className='modal-wraped mx-3'>
        <ModalBody>
          <div className='text-center mt-2'>
            <img src={imgNotice} alt="경고 아이콘" />
            <h5 className='fw-bold mt-4 lh-base mt-2'>휴가 정책 변경</h5>
            <p className='text-muted mb-3'>
              변경한 기준이 적용됩니다.
              <br />이대로 저장하시겠어요?
            </p>
          </div>   
        </ModalBody>
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            style={{
              borderRadius: 7,
            }} 
            color='light'
            isOutline
            className='w-50'
            onClick={() => setIsOpen(false)}
          >
            <span>{t('취소')}</span>
          </Button>
          <Button
            style={{
              borderRadius: 7,
            }} 
            color='info'
            className='w-50'
            onClick={() => setIsOpen(false)}
          >
            <span>{t('저장')}</span>
          </Button>
        </ModalFooter>
    </div>
    </Modal>
  )
}

export default React.memo(VacationPolicyConfirmModal)