import React, { useCallback, useEffect, useRef, useState } from 'react';
import { adminsMenu } from '../../../../menu';
import moment from 'moment';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../../models';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../../components/bootstrap/Breadcrumb';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Popovers from '../../../../components/bootstrap/Popovers';
// import Input from '../../../components/bootstrap/forms/Input';
// import { DateRange } from 'react-date-range';
// import * as locales from 'react-date-range/dist/locale';

import WorkingScheduleComponents from './components/workingScheduleComponents';
// import Card, { CardBody, CardFooter, CardFooterRight } from '../../../components/bootstrap/Card';
// import WorkScheduleTemplate from './components/modal/WorkScheduleTemplate';
// import CompanyService from '../../../services/CompanyService';
import useTourStep from '../../../../hooks/useTourStep';
import { useTour } from '@reactour/tour';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';

import ResetSchdule from '../../../../assets/resetSchdule.svg';
import SaveSchdule from '../../../../assets/saveSchdule.svg';
import useMinimizeAside from '../../../../hooks/useMinimizeAside';
import WorkScheduleExcelModal from '../../../AuthMenu/Work/components/modal/WorkScheduleExcelModal';

const WorkingScheduleManagement = (props) => {
	
	useMinimizeAside();
	useTourStep(0);

	const { company, user } = useMst();
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef(null);

	// const [selectionRange, setSelectionRange] = useState([
	// 	{
	// 		startDate: moment().startOf('isoWeek').toDate(),
	// 		endDate: moment().endOf('isoWeek').toDate(),
	// 		key: 'selection',
	// 	},
	// ]);
	// const [tempSelectionRange, setTempSelectionRange] = useState([
	// 	{
	// 		startDate: moment().startOf('isoWeek').toDate(),
	// 		endDate: moment().endOf('isoWeek').toDate(),
	// 		key: 'selection',
	// 	},
	// ]);

	const [rankSort, setRankSort] = useState('desc');
	const [isModal, setIsModal] = useState(false); // 템플릿 모달
	const [timelineRef, setTimelineRef] = useState(null);
	const { setIsOpen, setCurrentStep } = useTour();
	const [workPlanExcelModal, setWorkPlanExcelModal] = useState(false)
	const [excelItems, setExcelltems] = useState([])
	const [excelLoading, setExcelLoading] = useState(false);

	const [templateLoading, setTemplateLoading] = useState(true)

	const [isPopupModal, setIsPopupModal] = useState({
		is: false,
		icon: '',
		title: '',
		messge: '',
		confirm: () => {},
		confirmLabel: '',
	});

	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	// const handlePopState = (e) => {
		
	// 	const returnVal = window.confirm('저장되지 않은 데이터가 있습니다. 저장 후 종료해주세요.');
	// 		if(returnVal) {
	// 			window.history.back();
	// 		}
	// 		return false;
	// };

	// const handleBeforeUnload = (e) => {				
		
	// 	const returnVal = window.confirm('저장되지 않은 데이터가 있습니다. 저장 후 종료해주세요.');
	// 		e.returnValue = returnVal;
	// 		return returnVal;
	// }

	// useEffect(() => {
	// 	window.addEventListener('popstate', handlePopState);
	// 	window.addEventListener('beforeunload', handleBeforeUnload);

	// 	return () => {
	// 		window.removeEventListener('popstate', handlePopState);
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, []);

	useEffect(() => {
		// if(excelLoading == true && excelItems.length > 0){
		// 	setExcelltems([])
		// }
	}, [excelItems]);


	return (
		<PageWrapper className='full' title={t(adminsMenu.workingManagement.subMenu.workingScheduleManagement.text)}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={adminsMenu.workingManagement.path}>
							{t(adminsMenu.workingManagement.text)}
						</BreadcrumbItem>
						<BreadcrumbItem tag='nav' to={adminsMenu.workingManagement.subMenu.workingScheduleManagement.path}>
							{t(adminsMenu.workingManagement.subMenu.workingScheduleManagement.text)}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
				<SubHeaderRight className={'text-nowrap'}>
					<Button
						type={'button'}
						color={'light'}
						className={'bg-l10-info border-light'}
						icon={'info'}
						rounded={1}
						isLight={true}
						onClick={() => {
							setCurrentStep(0);
							setIsOpen(true);
						}}
					/>
					<Button
						type={'button'}
						color={'info'}
						className={'bg-l10-info border-info'}
						icon={'RotateLeft'}
						rounded={1}
						isLight={true}
						onClick={() => {
							setIsPopupModal({
								is: true,
								icon: (
									<p className='text-center py-3'>
										<img src={ResetSchdule} width={97} />
									</p>
								),
								title: (
									<p className='text-center fs-5'>
										<strong>근무 일정</strong> <span className='text-info fw-bold'>초기화</span>
									</p>
								),
								messge: (
									<p className='text-center'>
										초기화 하시겠습니까?
										<br /> 입력했던 내용은 되돌릴 수 없습니다.
									</p>
								),
								confirm: () => { 
									if(ref.current)
										ref.current.reset();
										setIsPopupModal({
											is: false,
											icon: '',
											title: '',
											messge: '',
											confirm: () => {},
											confirmLabel: '',
										});										
										
								},
								confirmLabel: '초기화',
							});
						}}
					/>
					<Button type={'button'} color={'info'} className={'bg-l10-info border-info'} rounded={1} isLight={true} onClick={() => {}}>
						리포트
					</Button>
					{ user?.me?.permissions?.detailRole?.schedule?.confirm ? (
							<>
								<Button
									type={'button'}
									color={'info'}
									className={'border-info text-white'}
									rounded={1}
									onClick={() => {

										setIsPopupModal({
											is: true,
											icon: (
													<p className='text-center py-3'>
														<img src={SaveSchdule} width={58} />
													</p>
											),
											title: (
													<p className='text-center fs-5'>
														<strong>근무일정</strong> <span className='text-info fw-bold'>등록</span>
													</p>
											),
											messge: (
													<p className='text-center'>
														작성하신 근무표를 저장합니다.
														<br /> 그대로 저장 하시겠습니까?
													</p>
											),
											confirm: () => {
												if (ref.current) {
													ref.current.saveForm();
												}
												setIsPopupModal({
													is: false,
													icon: '',
													title: '',
													messge: '',
													confirm: () => {},
													confirmLabel: '',
												});

											},
											confirmLabel: '확정하기',
										});


									}}>
									근무등록 {/* <Icon icon='KeyboardArrowDown' color='white' /> */}
								</Button>
							</>
					) : (
							<></>
					)}
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid' className='full pt-0'>
				{/* 헤더 */}
				<div className='p-3'>
					<div className='d-flex flex-row justify-content-between align-items-center text-nowrap flex-wrap'>
						<div className='d-flex flex-row align-items-center justify-content-start'>
							<div className='fs-5 fw-bold'>근무일정</div>
							{/* <div className='ms-2 mb-1'>
								<Popovers trigger={'hover'} desc={<>asdasd</>}>
									<Icon icon='InfoOutline' color='light' size='lg' />
								</Popovers>
							</div> */}
						</div>
						<div className='d-flex flex-row align-items-center justify-content-start'>
							{ user?.me?.permissions?.detailRole?.schedule?.excelUpload ? (
									<>
										<Button type={'button'} color={'success'} isDisable={templateLoading} icon='FileUpload' className='border-light' rounded={1} isLight={true} onClick={() => {setWorkPlanExcelModal(true)}}>
											엑셀 업로드
										</Button>
									</>
							) : (
								<></>
								)}

							{/* <Button type={'button'} color={'light'} icon='DateRange' className='border-light' rounded={1} isLight={true} onClick={() => {}}>
								스케줄근무 관리
							</Button> */}
							<Button
								type={'button'}
								color={'light'}
								icon='Add'
								className='border-light ms-1 add-templete'
								rounded={1}
								isLight={true}
								onClick={() => {
									setIsModal(true);
									if (ref.current) ref?.current?.resetForm();
								}}>
								템플릿 생성
							</Button>
							{/* <Button type={'button'} color={'light'} icon='Gear' className='border-light ms-1' rounded={1} isLight={true} onClick={() => {}}>
								편집
							</Button> */}
						</div>
					</div>
				</div>

				<Modal
					isCentered={true}
					size={isPopupModal?.size || 'sm'}
					isOpen={isPopupModal?.is}
					setIsOpen={() => {
						// setIsPopupModal({
						// 	...isPopupModal,
						// 	is: !isPopupModal?.is,
						// });
					}}
					>
					<ModalBody className='row'>
						<div>
							{isPopupModal?.icon || null}
							{isPopupModal?.title || null}
							{isPopupModal?.messge || null}
						</div>
					</ModalBody>
					<ModalFooter className='d-flex flex-row justify-content-center align-items-center'>
					
							<Button
								type='button'
								className='border border-1 border-light rounded-1'
								size='lg'
								onClick={() => {
									setIsPopupModal({
										is: false,
										icon: '',
										title: '',
										messge: '',
										confirm: () => {},
										confirmLabel: '',
									});
								}}>
								취소
							</Button>
							<Button type='button' size='lg' color='info' className='rounded-1' onClick={isPopupModal?.confirm}>
								{isPopupModal?.confirmLabel}
							</Button>
					
					</ModalFooter>
				</Modal>

				<WorkingScheduleComponents isModal={isModal} setIsModal={setIsModal} ref={ref} excelItems={excelItems} excelLoading={excelLoading} setExcelLoading={setExcelLoading} isPopupModal={isPopupModal} setTemplateLoading={setTemplateLoading}/>
				<WorkScheduleExcelModal isModalOpen={workPlanExcelModal} setIsModalOpen={setWorkPlanExcelModal} setExcelltems={setExcelltems} setExcelLoading={setExcelLoading}/>
			</Page>
		</PageWrapper>
	);
};

export default WorkingScheduleManagement;
