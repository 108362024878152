import React, { useContext, forwardRef } from 'react';
import Modal from '../../../../components/bootstrap/Modal';
import ThemeContext from '../../../../contexts/themeContext';
import { t } from 'i18next';

const ApprovalDetailModal = forwardRef((props) => {
	const { mobileDesign } = useContext(ThemeContext);
	return (
		<>
			<Modal size='xl' isOpen={props?.isOpen} setIsOpen={props?.setIsOpen}>
				<div className='modal-body'>
					<div className='page-wrapper container-fluid full px-0 px-sm-3'>
						<div style={{backgroundColor: 'rgb(255, 255, 255)'}}>
							<div className='subheader row justify-content-end'>
								<div className='subheader-right col-sm-auto'>
									<div className='print-report'>
										<div className='text-end d-flex flex-row justify-content-end align-items-center'>
											<button type='button' className='btn'>
												<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon--material svg-icon' data-name='Material--LocalPrintshop'>
													<path d='M0 0h24v24H0V0z' fill='none'></path>
													<path d='M8 5h8v3H8zm11 5H5c-.55 0-1 .45-1 1v4h2v-2h12v2h2v-4c0-.55-.45-1-1-1zm-1 2.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z' opacity='0.3'></path>
													<path d='M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 14H8v-4h8v4zm4-4h-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4z'></path>
													<circle cx='18' cy='11.5' r='1'></circle>
												</svg>
												<span>인쇄</span>
											</button>
											<button type='button' className='btn'>
												<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon--material svg-icon' data-name='Material--ContentCopy'>
													<path d='M0 0h24v24H0V0z' fill='none'></path>
													<path d='M8 7h11v14H8z' opacity='0.3'></path>
													<path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z'></path>
												</svg>
												<span>URL 복사</span>
											</button>
											<button type='button' className='btn'>
												<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon--material svg-icon' data-name='Material--close'>
													<path d='M0 0h24v24H0V0z' fill='none'></path>
													<path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'></path>
												</svg>
												<span>닫기</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='page full pt-0 container-fluid'>
							<div className='row report-template'>
								<div className='col-12'>
									<div className='card shadow-none mb-0 mb-sm-3'>
										<div className='card-header my-2 justify-content-center'>
											<h5 className='card-title fs-2'>특근 신청</h5>
										</div>
										<div className='card-body px-0 px-sm-3 pb-0 py-sm-3'>
											<div className='d-flex flex-wrap flex-sm-nowrap flex-sm-row justify-content-between row'>
												<div className='col-12 col-md-4'>
													<table className='table border border-1 border-dark user-select-none'>
														<tbody>
															<tr>
																<th className='bg-light' style={{width: '100px'}}>
																	기안 부서
																</th>
																<td>
																	<span>IDR</span>
																</td>
															</tr>
															<tr>
																<th className='bg-light'>기안자</th>
																<td>
																	<span>문희인</span>
																	<small className='ms-1'>대표이사</small>
																</td>
															</tr>
															<tr>
																<th className='bg-light'>기안일자</th>
																<td>
																	<span>2024-01-24 (수)</span>
																</td>
															</tr>
															<tr>
																<th className='bg-light'>참조</th>
																<td></td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className='col-12 col-md-6 d-flex flex-column align-items-end justify-content-end'>
													<table className='table table-bordered align-middle border border-1 border-dark text-cetner w-auto'>
														<tbody>
															<tr>
																<td rowSpan='4' className='bg-light text-center fw-bold'>
																	결재
																</td>
															</tr>
															<tr className=''>
																<td style={{maxWidth: '100px', width: '100px'}}>
																	<div className='text-center'>
																		<small className='ms-1'>대표이사</small>
																	</div>
																</td>
																<td style={{maxWidth: '100px', width: '100px'}}>
																	<div className='text-center'>
																		<small className='ms-1'>대표이사</small>
																	</div>
																</td>
															</tr>
															<tr className='text-center'>
																<td colSpan='1' className='text-center' style={{maxHeight: '100px', height: '100px'}}>
																	<div className='d-flex flex-column justify-content-end h-100'>
																		<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																			<div className=''>
																				<div>
																					<div>
																						<span className='text-muted' style={{fontSize: '12px'}}>
																							결재 대기중
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className='small'></div>
																	</div>
																</td>
																<td colSpan='1' className='text-center' style={{maxHeight: '100px', height: '100px'}}>
																	<div className='d-flex flex-column justify-content-end h-100'>
																		<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																			<div className=''>
																				<div></div>
																			</div>
																		</div>
																		<div className='small'></div>
																	</div>
																</td>
															</tr>
															<tr className=''>
																<td className='text-center'>
																	<div className='d-flex justify-content-center align-items-center'>
																		<span className='text-muted small text-nowrap mr-2'>문희인</span>
																	</div>
																</td>
																<td className='text-center'>
																	<span className='text-muted small text-nowrap'>심원섭</span>
																</td>
															</tr>
														</tbody>
													</table>
													<table className='table table-bordered align-middle border border-1 border-dark text-cetner w-auto'>
														<tbody>
															<tr>
																<td rowSpan='4' className='bg-light text-center fw-bold'>
																	합의
																</td>
															</tr>
															<tr className=''>
																<td style={{maxWidth: '100px', width: '100px'}}>
																	<div className='text-center'>
																		<small className='ms-1'>부장</small>
																	</div>
																</td>
																<td style={{maxWidth: '100px', width: '100px'}}>
																	<div className='text-center'>
																		<small className='ms-1'>사원</small>
																	</div>
																</td>
															</tr>
															<tr className='text-center'>
																<td className='text-center ' style={{maxHeight: '100px', height: '100px'}}>
																	<div className='d-flex flex-column justify-content-end h-100'>
																		<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																			<div></div>
																		</div>
																		<div className='small'></div>
																	</div>
																</td>
																<td className='text-center ' style={{maxHeight: '100px', height: '100px'}}>
																	<div className='d-flex flex-column justify-content-end h-100'>
																		<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																			<div></div>
																		</div>
																		<div className='small'></div>
																	</div>
																</td>
															</tr>
															<tr className=''>
																<td className='text-center'>
																	<span className='text-muted small text-nowrap'>박세은</span>
																</td>
																<td className='text-center'>
																	<span className='text-muted small text-nowrap'>김ㅇㅇ1</span>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
											<div className='border-top border-dark mt-5 d-flex align-items-center'>
												<table className='table table-modern mx-0 mx-sm-5 my-sm-5 mt-3 mb-0 w-100'>
													<tr className=''>
														<td>
															<div className='d-flex align-items-start'>
																<div className='align-items-start'>
																	<table className='table table-modern'>

																		{/* 20240124 - 탄력근무 관련 퍼블 디자인 추가 */}
																		<tr>
																			<td className='align-top' style={{minWidth: '50px'}}><span className='fw-bold align-top'>{t('일정')}</span></td>
																			<td>
																				<div className='d-flex text-center flex-wrap'>
																					{
																						['01.08 (월)', '01.09 (화)', '01.10 (수)', '01.11 (목)','01.12 (금)','01.13 (토)', '01.14 (일)', 
																						'01.15 (월)', '01.16 (화)', '01.17 (수)', '01.18 (목)','01.19 (금)','01.20 (토)', '01.21 (일)'].map((v, i) =>{
																							return (
																								<div className='border border-dark' style={mobileDesign ? {width : 'calc(100% / 4)'} : {width : 'calc(100% / 7)'}} key={i}>
																									<div className='bg-l25-dark'>{v}</div>
																									<div className={!mobileDesign && 'p-3'}><sapn>07:00 - 16:00 (8시간)</sapn></div>
																								</div>
																							)
																						})
																					}
																				</div>
																			</td>
																		</tr>
																		{/* 20240124 - 탄력근무 관련 퍼블 디자인 추가 */}

																		<tr>
																			<td>
																				<span className='fw-bold'>기간</span>
																			</td>
																			<td>
																				2024-01-21 ~ 2024-01-21 (1일)
																				<br />
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<span className='fw-bold'>근무지</span>
																			</td>
																			<td>본사</td>
																		</tr>
																		<tr>
																			<td>
																				<span className='fw-bold'>시간</span>
																			</td>
																			<td> 00:00-09:00</td>
																		</tr>
																		<tr>
																			<td>
																				<span className='fw-bold'>휴일대체</span>
																			</td>
																			<td>신청안함 | OT 차감 (8시간)</td>
																		</tr>
																		<tr>
																			<td>
																				<span className='fw-bold'>사유</span>
																			</td>
																			<td>ㅇㅇ</td>
																		</tr>
																	</table>
																</div>
															</div>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
});

export default ApprovalDetailModal;
