import React, {forwardRef} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";


const EmptyModal = forwardRef((props, ref) => {
    return (
        <>
            <Modal
                id="example-modal"
                isOpen={props?.isOpen}
                setIsOpen={props?.setIsOpen}
                titleId="example-title"
                isScrollable
                isCentered
            >
                <ModalHeader setIsOpen={props?.setIsOpen}>
                    <ModalTitle>
                        Modal Title
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <p>
                        Modal Contents
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="info"
                        onClick={()=>{props?.setIsOpen(false)}}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
})

export default EmptyModal;
