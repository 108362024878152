import React, { forwardRef, useEffect, useImperativeHandle, useState, useCallback, useRef, useLayoutEffect, useContext } from 'react';
import { useFormik } from 'formik';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../../../components/bootstrap/Modal_v2';
import { t } from 'i18next';
import { useMst } from '../../../../models';
import creature_3 from '../../../../assets/img/creature_3.png';
import showNotification from '../../../../components/extras/showNotification';
import CompanyService from '../../../../services/CompanyService';
import UserService from '../../../../services/UserService';
import AttendService from '../../../../services/AttendService';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import Input from '../../../../components/bootstrap/forms/Input';
import moment from 'moment';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import Select from '../../../../components/bootstrap/forms/Select';
import SortableTreeModal from '../../Approval/components/SortableTreeModal';
import ThemeContext from '../../../../contexts/themeContext';
import Card, { CardBody, CardFooter } from '../../../../components/bootstrap/Card';
import success from "../../../../assets/img/SuccessIcon.svg";
import Popovers from '../../../../components/bootstrap/Popovers';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import TimePickerModal from '../components/TimePickerModal';
import { Picker } from 'react-mobile-picker-scroll';
import UserReqRecordEditTimeSettingModal from '../components/UserReqRecordEditTimeSettingModal';

const UserReqRecordEditModal = (props) => {
	const { data, isOpen, setIsOpen } = props;
	const { user, company } = useMst();
	const tomorrow = new Date(new Date());
	tomorrow.setDate(new Date().getDate() + 1);

	useEffect(() => {}, [data]);
	const popRef = useRef({});
	const [selectDateArry, setSelectDateArry] = useState([]);
	const [approvalList, setApprovalList] = useState([]);
	const [referenceModalOpen, setReferenceModalOpen] = useState(false);
	const [myEvent, setMyEvent] = useState({});
	const [chagneDateRangeYear, setChagneDateRangeYear] = useState(moment(new Date()).year());
	const [openCalendar, setOpenCalendar] = useState(false);
	const [openSelectTimeModal, setOpenSelectTimeModal] = useState(false);
	const [checkStartOrEnd, setCheckStartOrEnd] = useState('');
	const [startTimeSelect, setStartTimeSelect] = useState(false);
	const [endTimeSelect, setEndTimeSelect] = useState(false);
	const [selectedWorkDate, setSelectedWorkDate] = useState('');
	const { mobileDesign } = useContext(ThemeContext);

	const [openSuccessRequestModal, setOpenSuccessRequestModal] = useState(false);

	const [tempSelectionRange, setTempSelectionRange] = useState([
		{
			startDate: new Date(), // addDays(new Date(), -7)
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const _convertDateNm = (_date) => {
		const _yymm = moment(_date).format('MM/DD');
		const _day = moment(_date).day();
		let _dayNm = '(일)';

		if (_day === 0) {
			_dayNm = '(일)';
		} else if (_day === 1) {
			_dayNm = '(월)';
		} else if (_day === 2) {
			_dayNm = '(화)';
		} else if (_day === 3) {
			_dayNm = '(수)';
		} else if (_day === 4) {
			_dayNm = '(목)';
		} else if (_day === 5) {
			_dayNm = '(금)';
		} else if (_day === 6) {
			_dayNm = '(토)';
		}

		const _dateNm = _yymm + _dayNm;

		return _dateNm;
	};

	const refresh = async () => {
		if (data[0]?.type === 'WORK') {
			await AttendService.getLogAttend(
				0,
				moment(data[0].row?.date || data[0].start)
					.startOf('M')
					.format('YYYY-MM-DD'),
				moment(data[0].row?.date || data[0].start)
					.endOf('M')
					.format('YYYY-MM-DD'),
				company.get.id,
				'day',
				user.me.id
			).then((res) => {
				if (res?.data?.length > 0) {
					let events = res?.data[0];
					let thisEvent = events?.find((i) => i?.attend && i?.attend?.id === data[0]?.row?.id);
					setMyEvent(thisEvent);
				}
			});
		}
		await UserService.getApproval(company.get.id, { userId: user.id, departId: company.get?.departInfos[0]?.departId || '', option: 'up' }).then((res) => {
			setApprovalList(res?.data || []);
		});
	};

	useEffect(() => {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const requestWork = useFormik({
		initialValues: {
			id: 0, // 0 픽스
			attendId: data[0]?.row?.id || 0,
			startDate: moment(data[0].start).format('YYYY-MM-DD'),
			startTime: data[0]?.type === 'WORK' && data[0].stime ? moment(data[0].stime).format('HH:mm') : '',
			companyId: company.get.id,
			endDate: moment(data[0].start).format('YYYY-MM-DD'),
			endTime: data[0]?.type === 'WORK' && data[0].etime ? moment(data[0].etime).format('HH:mm') : '',
			etc: '',
			approvals: [],
			isApproval: false, // false 픽스
			ccUsers: [],
			option: {},
		},
		validate: (values) => {
			const errors = {};

			if (values.startTime === '' || values.endTime === '') {
				errors.startTime = '시간설정은 필수입니다';
			}
			// if (values.endTime && (values.startTime > values.endTime)) {
			// 	errors.startTime = '출근시간은 퇴근시간보다 늦을 수 없습니다'
			// }
			// if (values.endTime === '') {
			//   errors.endTime = '시간설정은 필수입니다';
			// }
			if (!values.etc) {
				errors.etc = '사유는 필수입니다';
			}
			if (values.approvals.length === 0) {
				errors.approvals = '결재라인 선택은 필수입니다';
			}
			return errors;
		},
		onSubmit: async (values) => {
			let sendData = {};
			if (data[0]?.type !== 'WORK') {
				sendData = {
					id: values.id, // 0 픽스
					startDate: values.startDate,
					startTime: values.startTime,
					companyId: values.companyId,
					endDate: values.endDate,
					endTime: values.endTime,
					etc: values.etc,
					approvals: values.approvals,
					isApproval: false, // false 픽스
					ccUsers: values.ccUsers,
					option: values.option,
				};
			}
			await CompanyService.modifyWorkRecord(data[0]?.type !== 'WORK' ? sendData : values)
				.then((response) => {
					// console.log('response >> ',response)
					if (response?.data) {
						//showNotification(`근무기록변경 신청`, `근무기록변경 신청이 완료되었습니다`, 'info');
						setOpenSuccessRequestModal(true)
						// setToggleInfoEventCanvas(false)
						// setEventAdding(false)
						// reFresh();
					} else showNotification(`근무기록변경 신청`, response.message, 'danger');
				})
				.catch((error) => {
					// console.log('error >>',error)
					if (error) showNotification(`근무기록변경 신청`, `오류가 발생했습니다 다시 시도하세요`, 'danger');
				});
		},
	});

	const approvailLineUser = requestWork?.values?.approvals[0]?.line[0]?.user;

	const countApprovalUsers = requestWork?.values?.approvals
		?.map((i) => i.line)
		.reduce((accumulator, currentArray) => {
			return accumulator + currentArray.length;
		}, 0);

	const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black',
			// width: 250}
		};
	};

	const disableRequestBtn = requestWork?.values?.startTime === '' || requestWork?.values?.endTime === '' || requestWork?.values?.etc === '' || requestWork?.values?.approvals.length === 0;

	const handleTimeSetting = (time) => {
		setOpenSelectTimeModal(true);
		setCheckStartOrEnd(time);
	}


	return (
		<>
			{!mobileDesign ? (
				<>
				{/** PC 버전 */}
					<Modal isCentered={true} size='lg' isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop>
						<ModalHeader setIsOpen={setIsOpen} className='user-modal-header'>
							<ModalTitle id='tour-title'>{t('근무기록 수정요청')}</ModalTitle>
						</ModalHeader>
						<ModalBody className='work-modal-body'>
							<div style={{ width: '100%', height: '58px', border: '1px solid #D9D9D9' }}>
								<div className='d-flex justify-content-center align-items-center work-title'>
									<div className='work-image-wrapper'>
										<img className='work-image' alt='avatar1' src={creature_3} style={{ width: 45, height: 45 }} />
									</div>
									{user.me?.name} {user?.me?.userRank || ''}
								</div>
								<div className='d-flex align-items-center h-100 ps-3'>{moment(data[0].start).format('MM월 DD일 dd요일 근무')}</div>
							</div>
							<div className='work-content-wrapper d-flex flex-wrap row'>
								<div className='work-content-left-wrapper col-md-6'>
									<div className='work-content-left text-truncate' style={{ marginTop: 40 }}>
										<div className=''>
											<FormGroup>
												<Label className={'fw-bold'} style={{ color: 'black' }}>
													{t('변경 시간 설정')}
												</Label>
												<span className='text-danger'>&nbsp;*</span>
												<div className='d-flex justify-content-around mt-4 flex-wrap'>
													<div>
														<span className='text-muted fw-normal'>{t('출근')}</span>
														<div className='d-flex justify-space-around'>
															<div className='work-content-cal d-flex align-items-center work-gray-text ' style={{ backgroundColor: '#D9D9D9' }}>
																<span style={{ marginLeft: '14px', marginRight: '20px' }}>{moment(requestWork?.values?.startDate).format('YYYY. MM. DD')}</span>
																<Icon icon='CalendarToday' size='lg' />
															</div>
															<div>
																<Input
																	id='startTime'
																	className='work-content-cal d-flex align-items-center work-gray-text'
																	type='time'
																	value={requestWork?.values?.startTime}
																	onChange={requestWork.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className='d-flex justify-content-around mt-4'>
													<div>
														<span className='text-muted fw-normal'>{t('퇴근')}</span>
														<div className='d-flex justify-space-around'>
															<div className='d-flex align-items-center'>
																<Input
																	id='endDate'
																	className='work-content-cal d-flex align-items-center work-gray-text'
																	type='date'
																	min={moment(requestWork?.values.startDate).format('YYYY-MM-DD')}
																	max={moment(requestWork?.values.startDate).add(1, 'day').format('YYYY-MM-DD')}
																	value={moment(requestWork?.values?.endDate).format('YYYY-MM-DD')}
																	onChange={requestWork.handleChange}
																/>
															</div>
															<div>
																<Input
																	id='endTime'
																	className='work-content-cal d-flex align-items-center work-gray-text'
																	type='time'
																	value={requestWork?.values?.endTime}
																	onChange={requestWork.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
												{(requestWork?.values?.startTime === '' || requestWork?.values?.endTime === '') && (
													<div className='text-danger mt-2 d-flex align-items-center'>
														<Icon icon='InfoOutline' className='mx-2' />
														<div>{t('시간설정은 필수입니다') + '.'}</div>
													</div>
												)}
											</FormGroup>
										</div>
									</div>
								</div>
								<div className='work-content-right-wrapper col-md-6'>
									<div className='work-content-right'>
										<div className='work-detail-content-wrapper'>
											<div className='work-detail-content-title d-flex align-items-center'>
												<span className='work-req-vac-txt-g fw-bold'>{t('사유')}</span>
												<span className='text-danger'>&nbsp;*</span>
											</div>
											<div className='work-detail-content'>
												<textarea
													id='etc'
													className='form-control'
													rows='3'
													style={{
														justifyContent: 'center',
														alignItems: 'center',
														borderRadius: 8,
														borderWidth: 1,
														borderColor: '#D9D9D9',
														backgroundColor: 'white',
														padding: '10px 10px 10px 10px',
														marginRight: 20,
														resize: 'none',
														color: '#808080',
													}}
													placeholder={t('사유입력 (선택)')}
													value={requestWork?.values?.etc}
													onChange={requestWork.handleChange}
												/>
												{requestWork?.values?.etc === '' && (
													<div className='text-danger mt-2 d-flex align-items-center'>
														<Icon icon='InfoOutline' className='mx-2' />
														<div>{t('사유는 필수입니다') + '.'}</div>
													</div>
												)}
											</div>
										</div>
										<div className='work-detail-content-wrapper' style={{ marginTop: '16px' }}>
											<div className='work-detail-content-title d-flex align-items-center'>
												<span className='work-req-vac-txt-g fw-bold'>{t('결재라인')}</span>
												<span className='text-danger'>&nbsp;*</span>
											</div>

											<div className='work-detail-content'>
												<Select
													style={newStyle(requestWork?.values?.approvals?.length === 0)}
													list={[...approvalList]?.map((v) => {
														return { value: v.id, label: v.name };
													})}
													placeholder={t('결재라인을 선택해주세요')}
													onChange={(e) => {
														const approval = approvalList.find((v) => parseInt(v.id) === parseInt(e.target.value));
														requestWork.setFieldValue('approvals', approval?.lines || approval?.line || []);
														requestWork.setFieldValue('option', approval?.option || {});
														requestWork.setFieldValue('ccUsers', approval?.ccUsers || []);
													}}
												/>
												{requestWork?.values?.approvals?.length === 0 ? (
													<div className='text-danger mt-2'>
														<Icon icon='InfoOutline' className='mx-2' />
														<span>{t('결재라인 선택은 필수입니다') + '.'}</span>
													</div>
												) : null}
											</div>
										</div>
										<div className='user-detail-content-wrapper' style={{ marginTop: '7px' }}>
											<div className='user-detail-content-title'></div>
											<div className='user-detail-content'>
												{requestWork?.values?.approvals && requestWork?.values?.approvals.length > 0 && (
													<Button
														// disabled
														style={{
															borderRadius: 8,
															borderWidth: 1,
															borderColor: '#D9D9D9',
															backgroundColor: '#F8F9FA',
															padding: '10px 10px 10px 10px',
															marginRight: 20,
															width: '100%',
														}}
														onClick={() => {
															requestWork.setFieldValue('approvals', []);
															setApprovalList([]);
															refresh();
														}}>
														<div className='d-flex justify-content-around align-items-center'>
															<span className=' text-muted'>{`${approvailLineUser?.name} ${approvailLineUser?.rank} ${countApprovalUsers > 1 ? '외 ' + (countApprovalUsers - 1) + ' 인' : ''}`}</span>
															<Icon icon='Cancel' size='lg' />
														</div>
													</Button>
												)}
											</div>
										</div>
										<div className='work-detail-content-wrapper' style={{ marginTop: '16px' }}>
											<div className='work-detail-content-title d-flex align-items-center'>
												<span className='work-req-vac-txt-g fw-bold'>{t('참조')}&nbsp;</span>
											</div>
											<div className='work-detail-content'>
												<Button
													type={'button'}
													style={{
														borderRadius: 8,
													}}
													isOutline={true}
													color={'info'}
													onClick={() => {
														setReferenceModalOpen(true);
													}}>
													<div className='d-flex align-items-center'>
														<Icon icon='Add' size='lg' color='info' />
														<span className='custom-write-button-margin'>{t('추가하기')}</span>
													</div>
												</Button>
											</div>
										</div>
										<div className='work-detail-content-wrapper' style={{ marginTop: '6px' }}>
											<div className='work-detail-content-title'></div>
											<div className='work-detail-content d-flex flex-wrap'>
												{requestWork?.values?.ccUsers?.map((v, i) => {
													return (
														<Button
															style={{
																borderRadius: 6,
																borderWidth: 1,
																borderColor: '#D9D9D9',
																margin: 5,
																backgroundColor: '#F8F9FA',
															}}
															key={`ccUsers-${i}`}
															className='py-1 px-2'
															onClick={() => {
																let newCcUsers = [...requestWork?.values?.ccUsers];
																newCcUsers.splice(requestWork?.values?.ccUsers[i], 1);

																requestWork.setFieldValue('ccUsers', newCcUsers);
															}}>
															<Icon icon='Visibility' size='md' style={{ color: 'grey' }} />
															<span className='mx-3'>
																{v.name} {requestWork.values.ccUsers.length - 1 > i}
															</span>
															<Icon icon='Close' size='md' />
														</Button>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
						<ModalFooter className='justify-content-end'>
							<Button
								style={{
									border: '1px solid #FE637C',
									borderRadius: 7,
									backgroundColor: '#FE637C',
									color: 'white',
									padding: '10px 15px 10px 15px',
								}}
								disabled={disableRequestBtn}
								onClick={() => {
									requestWork.handleSubmit();
								}}>
								{t('근무기록 수정요청')}
							</Button>
						</ModalFooter>

						<SortableTreeModal
							title={t('참조자 선택')}
							isOpen={referenceModalOpen}
							setIsOpen={setReferenceModalOpen}
							disabledList={requestWork?.values?.approvals}
							list={requestWork.values.ccUsers}
							setList={(list) => {
								requestWork.setFieldValue('ccUsers', list);
							}}
						/>
					</Modal>
				</>
			) : (
				<>
				{/** 모바일 버전 */}
					<Modal isCentered={true} size='lg' isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop isScrollable fullScreen={'sm'}>
						<ModalHeader className={!mobileDesign && 'user-modal-header'}>
							<ModalTitle className='fw-bold' id='tour-title'>
								{t('근무기록 수정요청')}
							</ModalTitle>
						</ModalHeader>
						<ModalBody className='work-modal-body'>
							<Card className='custom-box-shadow rounded-2' style={{ marginBottom: '24px' }}>
								<CardBody>
									<p className='mb-0'>{t('수정일')}</p>
									<p className='text-black fw-bold mb-0 mt-2'>
										<span>{moment(data[0].start).format('MM월 DD일 dd요일 근무')}</span>
									</p>
								</CardBody>
							</Card>
							<div>
								{/* 출근시간 */}
								<FormGroup className='d-flex mt-3'>
									<div className='col-3'>
										<Label className='mt-2 fw-bold text-black'>{t('출근시간')}</Label>
										<span className='text-danger fs-3'>&nbsp;*</span>
									</div>
									<div className='col-9 text-nowrap text-truncate'>
										<div className='d-flex justify-content-between'>
											<Button
												className='d-flex align-items-center justify-content-center'
												style={{
													width: 'calc(50% - 5px)',
													borderRadius: 8,
													borderWidth: 1,
													borderColor: '#D9D9D9',
													padding: '10px',
													color: '#808080',
												}}>
												<span className='me-2'>{moment(requestWork?.values?.startDate).format('YYYY. MM. DD')}</span>
												<Icon icon='CalendarToday' size='lg' />
											</Button>
											<Button
												id='startTime'
												style={{
													width: 'calc(50% - 5px)',
													borderRadius: 8,
													borderWidth: 1,
													borderColor: '#D9D9D9',
													padding: '10px',
													color: '#808080',
												}}
												//type='time'
												//value={requestWork?.values?.startTime}
												onClick={()=> {
													handleTimeSetting('START');
												}}
											>
												{requestWork.values.startTime && moment(requestWork.values.startTime, 'HH:mm').isValid() ? moment(requestWork.values.startTime, 'HH:mm').format('A HH:mm') :  '-- : --'}
											</Button>
										</div>
									</div>
								</FormGroup>
								<FormGroup className='d-flex mt-3'>
									<div className='col-3'>
										<Label className='mt-2 fw-bold text-black'>{t('퇴근시간')}</Label>
										<span className='text-danger fs-3'>&nbsp;*</span>
									</div>
									<div className='col-9 text-nowrap text-truncate'>
										<div className='d-flex justify-content-between'>
											<Button
												id='endDate'
												className='d-flex align-items-center justify-content-center'
												style={{
													width: 'calc(50% - 5px)',
													borderRadius: 8,
													borderWidth: 1,
													borderColor: '#D9D9D9',
													padding: '10px',
													color: '#808080',
												}}
												onClick={() => {
													setOpenCalendar(true);
												}}
												>
													<span className='me-2'>{moment(requestWork?.values?.endDate).format('YYYY. MM. DD')}</span>
													<Icon icon='CalendarToday' size='lg' />
												</Button>
											<Button
												id='endTime'
												style={{
													width: 'calc(50% - 5px)',
													borderRadius: 8,
													borderWidth: 1,
													borderColor: '#D9D9D9',
													padding: '10px',
													color: '#808080',
												}}
												onClick={()=> {
													handleTimeSetting('END');
												}}
											>
												{requestWork.values.endTime && moment(requestWork.values.endTime, 'HH:mm').isValid() ? moment(requestWork.values.endTime, 'HH:mm').format('A HH:mm') :  '-- : --'}
											</Button>
										</div>
										{(requestWork?.values?.startTime === '' || requestWork?.values?.endTime === '') && (
											<div className='text-danger mt-1'>
												<Icon icon='InfoOutline' className='me-2' />
												<span>{t('시간 선택은 필수입니다')}</span>
											</div>
										)}
									</div>
								</FormGroup>

								<FormGroup className='d-flex mt-3'>
									<div className='col-3'>
										<Label className='mt-2 fw-bold text-black'>{t('사유')}</Label>
										<span className='text-danger fs-3'>&nbsp;*</span>
									</div>
									<div className='col-9'>
										<textarea
											id='etc'
											className='form-control'
											rows='3'
											style={newStyle()}
											placeholder={t('사유를 입력해주세요')}
											value={requestWork?.values?.etc}
											onChange={requestWork.handleChange}
										/>

										{requestWork?.values?.etc === '' && (
											<div className='text-danger mt-1'>
												<Icon icon='InfoOutline' className='me-2' />
												<span>{t('사유는 필수입니다')}</span>
											</div>
										)}
									</div>
								</FormGroup>

								<FormGroup className='d-flex mt-3'>
									<div className='col-3'>
										<Label className='mt-2 fw-bold text-black'>{t('결재라인')}</Label>
										<span className='text-danger fs-3'>&nbsp;*</span>
									</div>
									<div className='col-9 text-nowrap text-truncate'>
										<Select
											style={{
												borderRadius: 8,
												borderWidth: 1,
												borderColor: '#D9D9D9',
												padding: '10px',
												color: '#808080',
											}}
											list={[...approvalList]?.map((v) => {
												return { value: v.id, label: v.name };
											})}
											placeholder={t('결재라인을 선택해주세요')}
											onChange={(e) => {
												const approval = approvalList.find((v) => parseInt(v.id) === parseInt(e.target.value));
												requestWork.setFieldValue('approvals', approval?.lines || approval?.line || []);
												requestWork.setFieldValue('option', approval?.option || {});
												requestWork.setFieldValue('ccUsers', approval?.ccUsers || []);
											}}
										/>
										{requestWork?.values?.approvals?.length === 0 ? (
											<div className='text-danger mt-1'>
												<Icon icon='InfoOutline' className='me-2' />
												<span>{t('결재라인 선택은 필수입니다')}</span>
											</div>
										) : null}
										<div className='work-detail-content mt-2'>
											{requestWork?.values?.approvals && requestWork?.values?.approvals.length > 0 && (
												<Button
													// disabled
													style={{
														borderRadius: 8,
														borderWidth: 1,
														borderColor: '#D9D9D9',
														backgroundColor: '#F8F9FA',
														padding: '10px 10px 10px 10px',
														marginRight: 20,
														width: '100%',
													}}
													onClick={() => {
														requestWork.setFieldValue('approvals', []);
														setApprovalList([]);
														refresh();
													}}>
													<div className='d-flex justify-content-around align-items-center'>
														<span className=' text-muted'>{`${approvailLineUser?.name} ${approvailLineUser?.rank} ${countApprovalUsers > 1 ? '외 ' + (countApprovalUsers - 1) + ' 인' : ''}`}</span>
														<Icon icon='Cancel' size='lg' />
													</div>
												</Button>
											)}
										</div>
									</div>
								</FormGroup>

								<FormGroup className='d-flex mt-3'>
									<div className='col-3'>
										<Label className='mt-2 fw-bold text-black'>{t('참조')}</Label>
									</div>
									<div>
										<Button
											type={'button'}
											style={{
												borderRadius: 8,
											}}
											isOutline
											color={'info'}
											onClick={() => {
												setReferenceModalOpen(true);
											}}>
											<div className='d-flex align-items-center'>
												<Icon icon='Add' size='lg' color='info' />
												<span className='custom-write-button-margin'>{t('추가하기')}</span>
											</div>
										</Button>
										<div className='' style={{ marginTop: '6px' }}>
											<div className='d-flex flex-wrap'></div>
											{requestWork?.values?.ccUsers?.map((v, i) => {
												return (
													<Button
														style={{
															borderRadius: 6,
															borderWidth: 1,
															borderColor: '#D9D9D9',
															margin: 5,
															backgroundColor: '#F8F9FA',
														}}
														key={`ccUsers-${i}`}
														className='py-1 px-2'
														onClick={() => {
															let newCcUsers = [...requestWork?.values?.ccUsers];
															newCcUsers.splice(requestWork?.values?.ccUsers[i], 1);

															requestWork.setFieldValue('ccUsers', newCcUsers);
														}}>
														<Icon icon='Visibility' size='md' style={{ color: 'grey' }} />
														<span className='mx-3'>
															{v.name} {requestWork.values.ccUsers.length - 1 > i}
														</span>
														<Icon icon='Close' size='md' />
													</Button>
												);
											})}
										</div>
									</div>
								</FormGroup>
							</div>
						</ModalBody>
						<ModalFooter className='justify-content-center flex-nowrap'>
							<Button
								color='info'
								isOutline
								className='py-3 w-100'
								onClick={() => {
									props?.setIsOpen(false);
								}}>
								<span>{t('닫기')}</span>
							</Button>
							<Button
								color='info'
								disabled={disableRequestBtn}
								onClick={() => {
									requestWork.handleSubmit();
								}}
								className='py-3 w-100'>
								<span>{t('수정요청')}</span>
							</Button>
						</ModalFooter>
						<SortableTreeModal
							title={t('참조자 선택')}
							isOpen={referenceModalOpen}
							setIsOpen={setReferenceModalOpen}
							disabledList={requestWork?.values?.approvals}
							list={requestWork.values.ccUsers}
							setList={(list) => {
								requestWork.setFieldValue('ccUsers', list);
							}}
						/>
					</Modal>


					{/* 모바일 시간설정모달 */}
					<UserReqRecordEditTimeSettingModal
						isOpen={openSelectTimeModal}
						setIsOpen={setOpenSelectTimeModal}
						data={requestWork}
						checkStartOrEnd={checkStartOrEnd}
					/>

					{/** 모바일 달력 모달 */}
					<Modal 
						size='lg'
						isOpen={openCalendar}
						setIsOpen={setOpenCalendar}
						titleId="example-title"
						isScrollable
						// isStaticBackdrop
						isBottomed
						>
						<ModalHeader setIsOpen={setOpenCalendar}>
							<ModalTitle>{t('날짜 선택')}</ModalTitle>
						</ModalHeader>
						<ModalBody>
						<div className='row' /* ref={popRef} */>
								<Card className='shadow-none mb-0'>
									<CardBody className=''>
										<div className=''>
										<Calendar
											locale={locales['ko']}
											date={selectedWorkDate == '' ? new Date(moment(requestWork?.values.startDate).format('YYYY-MM-DD')) : selectedWorkDate}
											onShownDateChange={(e)=>{
												// console.log('moment(e).year()', moment(e).year())
												setChagneDateRangeYear(moment(e).year())}
											}
											minDate={new Date(moment(requestWork?.values.startDate).format('YYYY-MM-DD'))}
											maxDate={new Date(moment(requestWork?.values.startDate).add(1, 'day').format('YYYY-MM-DD'))}
											onChange={(item) => {
												setSelectedWorkDate(item);
												console.log("item", item)
											}}
											/>
										</div>
									</CardBody>
									<CardFooter className='py-0 my-0 d-flex justify-content-center align-items-center'>
										<Button
											type='button'
											color='info'
											className='col-12 py-3'
											style={{borderRadius: 7}}
											onClick={() => {
												if(selectedWorkDate){
													requestWork.setFieldValue('endDate', moment(selectedWorkDate).format('YYYY-MM-DD'));
													setOpenCalendar(false);
												}
											}}>
											<span className='fw-bold'>{t('선택완료')}</span>
										</Button>
									</CardFooter>
								</Card>
						</div>
						</ModalBody>
					</Modal>
				</>
			)}

			{/* 근무 신청 성공 모달 */}
			<Modal isCentered isStaticBackdrop setIsOpen={setOpenSuccessRequestModal} isOpen={openSuccessRequestModal} size={'sm'}>
				<div className='modal-wraped mx-3'>
					<ModalHeader />
					<ModalBody>
						<div className='text-center'>
							<img src={success} alt='리스트 아이콘' width={60} />
							<h5 className='fw-bold mt-4 lh-base'>
								근무 기록 변경 신청 <span className='text-success'>완료</span>
							</h5>
							<p className='text-muted mb-0'>
								신청 현황 및 내역은
								<br />
								전자결재에서 확인해주세요.
							</p>
						</div>
					</ModalBody>
					<ModalFooter className='justify-content-center'>
						<Button
							className='w-100'
							style={{
								borderRadius: 7,
							}}
							color='success'
							onClick={() => {
								setOpenSuccessRequestModal(false);
								setIsOpen(false);
							}}>
							<span className='px-3'>{t('확인')}</span>
						</Button>
					</ModalFooter>
				</div>
			</Modal>
	
		</>
	);
};
export default UserReqRecordEditModal;
