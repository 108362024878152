import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Breadcrumb, {BreadcrumbItem} from "../../../../components/bootstrap/Breadcrumb";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {SubHeaderCenter, SubHeaderLeft, SubHeaderRight} from "../../../../layout/SubHeader/SubHeader";
import Page from "../../../../layout/Page/Page";

import moment from 'moment';
import {usersMenu} from "../../../../menu";
import {CalendarTodayButton, getLabel, getUnitType, getViews} from "../../../../components/extras/calendarHelper";
import {Views} from "react-big-calendar";
import ThemeContext from "../../../../contexts/themeContext";
import tinycolor from "tinycolor2";
import {useMst} from "../../../../models";
import {useTranslation} from "react-i18next";
import {remainingRests} from "../../../../lib/Util";
import Button from "../../../../components/bootstrap/Button";
// img
import Icon from "../../../../components/icon/Icon";
import PageRow from "../../../../layout/Page/PageRow";
import WorkCalendars from "./WorkCalenders";
import Popovers from "../../../../components/bootstrap/Popovers";

import '../../../AuthMenu/Work/components/css/workCalender.scss';
import '../../../AuthMenu/Vacation/css/_cancelConfirmModal.scss';
import Avatar from "../../../../components/Avatar";
import Card, {CardBody, CardHeader, CardLabel, CardTitle} from "../../../../components/bootstrap/Card";
import Badge from "../../../../components/bootstrap/Badge";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import Alert from "../../../../components/bootstrap/Alert";

import workBad from '../../../AuthMenu/Work/User/svg/bad.svg';
import workGood from '../../../AuthMenu/Work/User/svg/good.svg';
import workEnough from '../../../AuthMenu/Work/User/svg/enough.svg';
import workStatistics from '../../../AuthMenu/Work/User/svg/workStatistics.svg';
import workNoStatistics from '../../../AuthMenu/Work/User/svg/workNoStatistics.svg';

import calenderData from './calender.data'
import AttendService from "../../../../services/AttendService";
import WorkSchduleService from "../../../../services/WorkSchduleService";
import CompanyService from "../../../../services/CompanyService";
import {t} from "i18next";

const WorkCalender = (props) => {
    const mainColor = '#5A6CFA';
    const redColor = '#f94449';
    const yellowColor = '#F1B44C';
    const greenColor = '#1FB25F';
    const { mobileDesign } = useContext(ThemeContext);


    const bgColor = tinycolor(mainColor).lighten(30).toHexString();
    const bgRedColor = tinycolor(redColor).lighten(30).toHexString();
    const bgYellowColor = tinycolor(yellowColor).lighten(30).toHexString();
    const bgGreenColor = tinycolor(greenColor).lighten(30).toHexString();

    const { company, user } = useMst();
    const { t } = useTranslation(['translation', 'menu']);
    const ref = useRef(null);
    const selectRef = useRef(null);
    const views = getViews();
    const dashboardState = !location?.state?.id && location?.state?.dashboardSelectedData ? location?.state?.dashboardSelectedData : null;

    const initialViewMode = Views.MONTH || '';
    const initialDate = dashboardState ? (dashboardState.start ? moment(dashboardState.start).format('YYYY-MM-DD') : moment()) : moment();
    const [viewMode, setViewMode] = useState(initialViewMode);
    const [date, setDate] = useState(initialDate);
    const [eventItem, setEventItem] = useState(null);
    const [weekData, setWeekData] = useState({});
    const [monthWorkData, setMonthWorkData] = useState({});
    const [navigateEApprovalModalOpen, setNavigateEApprovalModalOpen] = useState(false);
    const [eventAdding, setEventAdding] = useState(false);
    const unitType = getUnitType(viewMode);
    const calendarDateLabel = getLabel(date, viewMode);

    const [filterMenu, setFilterMenu] = useState(false);
    const [keyword, setKeyword] = useState({ id: 1, name: '전체보기' });
    const [isModal, setIsModal] = useState(false); // 템플릿 모달
    const [isModal2, setIsModal2] = useState(false); // 템플릿 모달
    const [isJobApplyModal, setIsJobApplyModal] = useState(false); // Job Apply 모달
    const [isOverTimeApplyModal, setIsOverTimeApplyModal] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isVacationApplyModal, setIsVacationApplyModal] = useState(false);
    const [isWorkRegModal, setIsWorkRegModal] = useState(false);
    const [restTypeInfo, setRestTypeInfo] = useState({});
    const [list, setList] = useState([]);
    const [preventDoubleRequest, setPreventDoubleRequest] = useState(false);
    const [holidayList, setHolidayList] = useState([]);
    const [deadlines, setDeadlines] = useState([]);
    const [workCnfrmModalOpen, setWorkCnfrmModalOpen] = useState(false);

    const [step, setStep] = useState(1);
    const [flexibeSelectDate, setFlexibleSelectDate] = useState({ range: '', startDate: '', endDate: '' });
    const [fiexibleCancelModal, setFlexibleCancelModal] = useState(false); //탄력근무 근무등록 취소 모달
    const [flexibleRequestModal, setFlexibleRequestModal] = useState(false); // 탄력근무 기간설정 모달
    const [flexibleDisappearModal, setFlexibleDisappearModal] = useState(false); //탄력근무 계획 입력 설명 모달
    const [flexDeatilTime, setFlexDeatilTime] = useState({ startTime: '', endTime: '', startDate: '', endDate: '', btnName: '', data: [] });
    const [flexDeatilArr, setFlexDeatilArr] = useState([]);
    const [eventItems, setEventItems] = useState([]);
    const [flexTimeNullModalOpen, setFlexTimeNullModalOpen] = useState(false);
    const [flexTimeOver, setFlexTimeOver] = useState(false);
    const [flexSave, setFlexSave] = useState(false);
    const [flexAvg, setFlexAvg] = useState('');
    const [flexAll, setFlexAll] = useState(0);
    const [approvalList, setApprovalList] = useState([]);
    const totalRemainRest = remainingRests(restTypeInfo?.rest || 0) // 총 잔여

    const templeteRef = useRef(null);
    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);
    const [templates, setTemplates] = useState([]);
    const [selectTemplate, setSelectTemplate] = useState({});
    const keywordData = [
        { value: 1, label: '전체보기' },
        { value: 2, label: '직위' },
        { value: 3, label: '부서' },
        { value: 4, label: '초대 상태' },
        { value: 5, label: '이름' },
    ];

    const [pageRowType, setPageRowType] = useState('A');

    const [myEvents, setMyEvents] = useState([...calenderData]);
    const [thisWeekWorkState, setThisWeekWorkState] = useState({
        badge: '',
        sentence: '',
        color: '',
        icon: null,
    });
    const [holydays, setHoloydays] = useState([]);
    const [offsiteWorkhrs, setOffsiteWorkhrs] = useState(0);
    const [draggedEvent, setDraggedEvent] = useState();
    const [workTime, setWorkTime] = useState({
        work: [{ time: 0 }],
        businessOffsite: [{ time: 0 }],
        over: [{ time: 0 }],
    });
    const [successRestDay, setSuccessRestDay] = useState({
        day: 0,
        hrs: 0,
    });
    const [userGroup, setUserGroup] = useState({});

    const reFresh = useCallback(async (date) => {
        // console.log('refresh!!! ', date)
        await AttendService.getLogAttend(
            0,
            moment(date).startOf('month').startOf('isoWeek').format('YYYY-MM-DD'),
            moment(date).endOf('month').endOf('isoWeek').format('YYYY-MM-DD'),
            company.get.id,
            'day',
            user.me.id
        ).then(async (res) => {
            if (res?.data?.length > 0) {
                let events = [];

                let attendCategories = res.data[0]?.filter((i) => i.attend && moment(i?.start || i?.date).format('YYYY-MM') === moment(date).format('YYYY-MM')); //.filter(item => item.attend.dates.history[0].attendCategory);

                let businessOffsiteWork = [...attendCategories].filter((i) => i.attend.dates.history[0].attendCategory?.name === '출장' || i.attend.dates.history[0].attendCategory?.name === '외근');
                let normalWork = [...attendCategories].filter((i) => businessOffsiteWork.findIndex((v) => v.attend.id == i.attend.id) === -1);

                // 근무
                const totalNormalWorkSecondsDifference = normalWork.reduce((total, currentItem) => {
                    // const startTime = moment(currentItem?.stimeReal);
                    // const endTime = moment(currentItem?.etimeReal);
                    // const secondsDifference = endTime.diff(startTime, 'seconds');
                    // return total + secondsDifference;
                    return total + ((currentItem?.attend?.workingTime || 0 + currentItem?.attend?.nightTime || 0) - (currentItem?.attend?.restTime || 0));
                }, 0);

                // 외근, 출장
                const totalbusinessOffsiteWorkSecondsDifference = businessOffsiteWork.reduce((total, currentItem) => {
                    // console.log('businessOffsiteWork : ', currentItem);
                    // const startTime = moment(currentItem?.stimeReal);
                    // const endTime = moment(currentItem?.etimeReal);
                    // const secondsDifference = endTime.diff(startTime, 'seconds');
                    // return total + secondsDifference;
                    return total + ((currentItem?.attend?.workingTime || 0 + currentItem?.attend?.nightTime || 0) - (currentItem?.attend?.restTime || 0));
                }, 0);

                // 연장
                const totalOverTimeSecondsDifference = attendCategories?.reduce((total, currentItem) => {
                    return total + ((currentItem?.attend?.workingOverTime || 0) + (currentItem?.attend?.nightOverTime || 0) - (currentItem?.attend?.restOverTime || 0));
                }, 0);

                setWorkTime({
                    work: [{ time: totalNormalWorkSecondsDifference }, ...normalWork],
                    businessOffsite: [{ time: totalbusinessOffsiteWorkSecondsDifference }, ...businessOffsiteWork],
                    over: [{ time: totalOverTimeSecondsDifference }, ...attendCategories],
                });

                res.data[0]?.map((r, index) => {
                    // attend 있는것만 띄우기. attend 없이 request만 있는것은 아직 신청 진행중인것.
                    // 연장은 제외! 그 외 다른 근무들은 모두 근무로 표기.
                    if (r.attend) {
                        // console.log('>>>>>>>>>>>>', r);

                        events.push({
                            id: `attendIds-${r.attend.id}`, // 중복 안되도록 attend에서 값
                            title: '근무', // 근무 아니면 request안에 name
                            type: 'WORK', // type 은 없어도 됨.
                            start: moment(r.start).startOf('d'), // stime etime으로 -> 이건 실제 근무 시간 x 표기용
                            end: moment(r.start).endOf('d'),
                            stime: moment(r.start), // 실제 근무 시간들
                            etime: moment(r.end), // 실제 근무 시간들
                            color: '#556EE6',
                            row: r?.attend || {},
                        });
                    }
                });

                // 계획 근무 불러오기
                const schdules = await WorkSchduleService.list({
                    companyId: company.get.id,
                    userId: user?.me?.id,
                    sdate: moment(date).startOf('month').startOf('isoWeek').format('YYYY-MM-DD'),
                    edate: moment(date).endOf('month').endOf('isoWeek').format('YYYY-MM-DD'),
                });

                if (schdules?.data) {
                    const items = (schdules?.data || [])?.map((item) => ({
                        ...item,
                        start_time: moment(moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')),
                        end_time: moment(moment(item.end_time).format('YYYY-MM-DD HH:mm:ss')),
                    }));

                    setList(items);
                    items?.map((i, index) => {
                        events.push({
                            id: i.id,
                            title: '계획근무',
                            type: 'WORK_SCHDULE',
                            start: moment(i.start_time).startOf('D'),
                            end: moment(i.start_time).endOf('D'),
                            stime: i.start_time,
                            etime: i.end_time,
                            color: '#556EE6',
                        });
                    });
                }

                // 연차 정보
                const rest = await CompanyService.getVacationStatus({
                    companyId: company.get.id,
                    userId: user.me.id,
                    sdate: moment(date).startOf('month').startOf('isoWeek').format('YYYY-MM-DD'),
                    edate: moment(date).endOf('month').endOf('isoWeek').format('YYYY-MM-DD'),
                });

                if (rest?.data) {
                    // console.log(rest?.data)
                    rest?.data?.map((i, index) => {
                        events.push({
                            id: i?.id,
                            title: i?.restday?.name || '',
                            type: i?.type || '',
                            state: i?.state || '',
                            start: i?.startTime ? moment(`${moment(i.startDate).format('YYYY-MM-DD')} ${i.startTime}`, 'YYYY-MM-DD HH:mm') : moment(i.startDate).startOf('D'),
                            end: i?.endTime ? moment(`${moment(i.endDate).format('YYYY-MM-DD')} ${i.endTime}`, 'YYYY-MM-DD HH:mm') : moment(i.endDate).endOf('D'),
                            stime: i?.startTime ? moment(`${moment(i.startDate).format('YYYY-MM-DD')} ${i.startTime}`, 'YYYY-MM-DD HH:mm') : moment(i.startDate).startOf('D'),
                            etime: i?.endTime ? moment(`${moment(i.endDate).format('YYYY-MM-DD')} ${i.endTime}`, 'YYYY-MM-DD HH:mm') : moment(i.endDate).endOf('D'),
                            color: '#44BDA3',
                            etc: i?.etc,
                            memo: i?.memo,
                            duration: i?.duration,
                            deduction: i?.deduction,
                            approvals: i?.approvals,
                        });
                    });
                }

                let thisMonth = [...rest?.data]?.filter((i) => moment(i?.duration[0]).format('YYYY-MM') === moment(date).format('YYYY-MM'));
                const totalDeduction = thisMonth.reduce((total, item) => {
                    const deduction = item?.deduction || 0;
                    const durationLength = item?.duration?.length || 0;

                    return total + (deduction > 0 ? deduction : durationLength);
                }, 0);

                let day = Math.floor(totalDeduction);
                let hrs = (totalDeduction - Math.floor(totalDeduction)) / 0.125;

                setSuccessRestDay({
                    day: day,
                    hrs: hrs,
                });

                // const data = await AttendService.attendVacationList(company.id,  moment(date).startOf('month').startOf('isoWeek').format('YYYY-MM-DD'), moment(date).endOf('month').endOf('isoWeek').format('YYYY-MM-DD'), 'substitutedRest', [user.me.id]);
                // if(data?.data[0]) {
                // 	// if(data?.data[0]?.holiReplace?.length > 0) {
                // 		data?.data[0]?.holiReplace?.map((i, index) => {
                // 			events.push({
                // 				id: `holiReplace-${index + 1}`,
                // 				type: 'holiReplace',
                // 				start: i
                // 			});
                // 		});
                // 	}
                // }

                if (step !== 2) {
                    setMyEvents(events);
                } else if (step == 2) {
                    if (eventItems) {
                        const mappedFlexWorkData = eventItems.map((item) => {
                            const startDate = moment(item?.start).format('YYYY-MM-DD');
                            const endDate = moment(item?.end).format('YYYY-MM-DD');
                            const startTime = moment(item?.stime).format('HH:mm');
                            const endTime = moment(item?.etime).format('HH:mm');

                            return {
                                id: `attendFlexible-$${moment(item.start).valueOf()}${Math.floor(100000 + Math.random() * 900000)}`,
                                title: '탄력근무',
                                type: 'WORK',
                                start: moment(startDate).startOf('D'),
                                end: moment(endDate).endOf('d'),
                                stime: moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm'),
                                etime: moment(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm'),
                                color: '#556EE6',
                            };
                        });
                        // console.log('mappedFlexWorkData');
                        setMyEvents([...events, mappedFlexWorkData]);
                    }
                }
                forceUpdate();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, company.get.id, user.me.id]);

    const [seletedWork, setSeletedWork] = useState({});

    const convertMinsToHrsMins = useCallback(
        (mins) => {
            let h = Math.floor(mins / 3600);
            let m = Math.floor((mins % 3600) / 60);
            let s = Math.floor(mins % 60);
            //let h = Math.floor(mins / (60 * 60));
            //let m = mins % (60);
            h = h < 10 && h > 0 ? '0' + h : h;
            m = m < 10 ? '0' + m : m;
            return t(`{{hour}}시간 ${m > 0 ? m + '분' : ''}`, { hour: h, minute: m });
        }, [t]);

    const workPercentage = (workTime.work[0].time || 0) / (3600 * 209);
    const overPercentage = (workTime.over[0].time || 0) / (3600 * 209);
    const businessOffsitePercentage = (workTime?.businessOffsite[0].time || 0) / (3600 * 209);
    const totalMontlyWorkProgressPercentage = (workPercentage + overPercentage + businessOffsitePercentage) * 100;

    const CustomWorkCalendar = useCallback(() => {
        return (
            <WorkCalendars
                reFresh={reFresh}
                events={myEvents}
                setMyEvents={setMyEvents}
                // onDropFromOutside={onDropFromOutside}
                // customOnDragOver={customOnDragOver}
                // moveEvent={moveEvent}
                // newEvent={newEvent}
                setIsWorkRegModal={setIsWorkRegModal}
                setSeletedWork={setSeletedWork}
                todate={date}
                holydays={holidayList}
                step={step}
                setStep={setStep}
                flexibeSelectDate={flexibeSelectDate}
                groups={userGroup}
                flexDeatilTime={flexDeatilTime}
                setFlexDeatilTime={setFlexDeatilTime}
                eventItems={eventItems}
                setEventItems={setEventItems}
                flexDeatilArr={flexDeatilArr}
                setFlexDeatilArr={setFlexDeatilArr}
            />
        );
    }, [
        reFresh,
        myEvents,
        // onDropFromOutside,
        // customOnDragOver,
        // moveEvent,
        // newEvent,
        setIsWorkRegModal,
        setSeletedWork,
        date,
        holidayList,
        flexibeSelectDate,
        step,
        userGroup,
        flexDeatilTime,
        setFlexDeatilTime,
        eventItems,
        setEventItems,
        flexDeatilArr,
        setFlexDeatilArr,
    ]);

    useEffect(() => {
        // setStep(2)
        // setFlexibleSelectDate({ range: "2주", startDate: "2024-01-21T15:00:00.000Z", endDate: "2024-02-03T15:00:00.000Z" });
        // setDate("2024-01-23T15:00:00.000Z");
    }, []);

    return (
        <div className='d-flex flex-wrap w-100' style={mobileDesign && step == 2 ? {marginBottom: '50px'} : {}}>
            <PageWrapper title={t(usersMenu.WorkingSchedule.text)} className='col-lg-9 p-0'>
                <SubHeader className={'w-auto m-0'}>
                    <SubHeaderLeft className='col-12 col-md-4'>
                        <Breadcrumb list={null} tag={'nav'}>
                            <BreadcrumbItem tag={'nav'} to={usersMenu.WorkingSchedule.path}>
                                {t(usersMenu.WorkingSchedule.text)}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </SubHeaderLeft>
                    <SubHeaderCenter className='col-6 col-md-4 justify-content-md-center'>
                        <CalendarTodayButton unitType={unitType} date={date} setDate={setDate} viewMode={viewMode} calendarDateLabel={calendarDateLabel}/>
                    </SubHeaderCenter>
                    <SubHeaderRight  className='col-6 col-md-4 justify-content-end'>
                        {step == 2 && (
                            <Button
                                type={'button'}
                                color={'light'}
                                rounded={1}
                                isOutline={true}
                                onClick={() => {
                                    setFlexibleCancelModal(true);
                                }}>
                                취소
                            </Button>
                        )}
                        {step !== 2 && (
                            <>
                                {deadlines?.length > 0 && // 근태마감이 존재하면
                                    <Button
                                        type={'button'}
                                        color={deadlines?.length === 0 ? 'dark' : 'info'}
                                        className={'text-white'}
                                        icon='EventAvailable'
                                        rounded={1}
                                        isDisable={deadlines?.length === 0}
                                        style={deadlines?.length === 0 ? { opacity: 0.5 } : {}}
                                        onClick={() => setWorkCnfrmModalOpen(true)}>
                                        {t('확정하기')}
                                    </Button>
                                }
                                <Button
                                    type={'button'}
                                    color={'info'}
                                    className={'border-info text-white'}
                                    rounded={1}
                                    isDisable={step == 2}
                                    onClick={() => {
                                        setIsJobApplyModal(true);
                                        /* if(ref.current)
                                    ref?.current?.resetForm(); */
                                    }}>
                                    <Icon icon='Mode' color='white' />
                                    {}
                                    &nbsp;&nbsp;근무신청 <Icon icon='KeyboardArrowDown' color='white' />
                                </Button>
                            </>
                        )}
                        {step !== 1 ? (
                            <Button
                                type={'button'}
                                color={'primary'}
                                rounded={1}
                                onClick={() => {
                                    //alert('준비중입니다.');
                                    if (step == 2) {
                                        if ((flexWorkAllTime()?.totalAvg || 0) / 3600 > 40 || flexWorkAllTime()?.week == true) {
                                            setFlexTimeOver(true);
                                            setFlexibleRequestModal(false);
                                            setFlexTimeNullModalOpen(false);
                                        } else if ((flexWorkAllTime()?.totalAvg || 0) / 3600 <= 0) {
                                            setFlexTimeNullModalOpen(true);
                                            setFlexTimeOver(false);
                                            setFlexibleRequestModal(false);
                                        } else {
                                            setFlexAvg(convertMinsToHrsMins((flexWorkAllTime()?.totalAvg || 0)));
                                            const flexAllResult = (flexWorkAllTime()?.totalWork || 0);
                                            setFlexAll(flexAllResult);
                                            setFlexibleRequestModal(true);
                                            setFlexTimeNullModalOpen(false);
                                            setFlexTimeOver(false);
                                        }
                                    }
                                    /* if(ref.current)
                                ref?.current?.resetForm(); */
                                }}>
                                등록하기
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SubHeaderRight>
                </SubHeader>

                <Page container='fluid' className='full px-0 px-md-2 p-2 m-0 bg-l25-light'>
                    <div className={`authmenu-work-calender-wrap ${mobileDesign ? 'vh-100 px-3' : 'h-100 rounded' } p-0 m-0 bg-white border border-1 border-light`}>
                        <CustomWorkCalendar />
                    </div>
                </Page>
            </PageWrapper>

            <PageRow container='fluid' className={`col-lg-3 ${pageRowType === 'A' ? 'work-main-left' : 'work-main-left-dis-none'}`}>
                <div className='work-main-left-inner'>
                    <div className={`${mobileDesign ? 'd-none' : null }`}>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <div className='p-4 d-flex flex-row align-items-center justify-content-start'>
                                <Avatar srcSet='' src='' userName={user?.me?.name || ''} size={40} color={'#e7e7e7'} textSizeRatio={10} maxInitials={2} fgColor={'dark'} />
                                <div className='row work-main-user-leftmargin10'>
                                    <span className='work-main-user-name'>{user?.me?.name || ''}</span>
                                    <span className='work-main-user-sub-name'>
                                        {company?.get?.userDepartment} {company?.get?.userRank}
                                    </span>
                                </div>
                            </div>
                            <div className='cursor-pointer link-dark work-main-sub-icon-div'>
                                <Icon icon={'Notifications'} style={{ color: 'black' }} />
                            </div>
                        </div>
                        <hr className='work-main-hr'></hr>
                    </div>

                    <div className='mt-4'>
                        <div className='p-2 rounded border border-light mx-4 my-1'>
                            <div className='d-flex flex-row'>
                                <Card stretch className='work-main-left-card'>
                                    <CardHeader>
                                        <CardLabel>
                                            <CardTitle className={'work-main-card-body-title'}>{t('이번주 근무')}</CardTitle>
                                        </CardLabel>
                                        {/* <Icon
                                            icon='Settings'
                                            color='primary'
                                            size='lg'
                                            className={'cursor-pointer link-dark'}
                                            isLight
                                            // onClick={() => {
                                            // 	// setIsModal(true);
                                            // 	if(ref.current)
                                            // 		ref?.current?.resetForm();
                                            // }}
                                        /> */}
                                    </CardHeader>
                                    <CardBody className={'work-main-card-body-topmargin-20'}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '7' }}>
                                                <div className={'work-main-card-body-time mb-3'}>
                                                    {convertMinsToHrsMins(
                                                        weekData.weekWorkTime > 0 || weekData.weekWeeklyWorkTime > 0 || weekData.weekNightTime > 0 || weekData.weekWeeklyNightTime > 0
                                                            ? (weekData?.weekWorkTime || 0) +
                                                            (weekData?.weekWorkOverTime || 0) +
                                                            (weekData?.weekNightTime || 0) +
                                                            (weekData?.weekNightOverTime || 0) +
                                                            (weekData?.weekWeeklyWorkTime || 0) +
                                                            (weekData?.weekWeeklyWorkOverTime || 0) +
                                                            (weekData?.weekWeeklyNightTime || 0) +
                                                            (weekData?.weekWeeklyNightOverTime || 0) -
                                                            ((weekData?.weekRestTime || 0) + (weekData?.weekRestOverTime || 0) + (weekData?.weekWeeklyRestTime || 0) + (weekData?.weekWeeklyRestOverTime || 0))
                                                            : 0
                                                    )}
                                                    {/* ls 임시 주석처리 */}
                                                    {/* <Popovers
                                                        trigger={'hover'}
                                                        placement={'bottom'}
                                                        desc={
                                                            <span className='work2-main-total-working-time-text work-main-card-body-topmargin5'>
                                                                남은 연장근무시간 :{' '}
                                                                <span className='work2-main-total-working-time-text-bold'>
                                                                    {convertMinsToHrsMins(
                                                                        3600 * 12 -
                                                                            ((weekData?.weekWorkOverTime || 0) + (weekData?.weekNightOverTime || 0) + (weekData?.weekWeeklyWorkOverTime || 0) + (weekData?.weekWeeklyNightOverTime || 0)) -
                                                                            ((weekData?.weekRestOverTime || 0) + (weekData?.weekWeeklyRestOverTime || 0))
                                                                    )}
                                                                </span>
                                                            </span>
                                                        }>
                                                        <Icon icon='info' color='light' />
                                                    </Popovers> */}
                                                </div>
                                                {/* <div className={'work-main-card-body-time'}>32시간</div> */}
                                                <div className={'work-main-card-body-topmargin10'}>
                                                    <Badge color={thisWeekWorkState?.color} rounded='1' style={{ backgroundColor: thisWeekWorkState?.color }}>
                                                        {thisWeekWorkState?.badge}
                                                    </Badge>
                                                    <span className={'work-main-card-body-text'}>{thisWeekWorkState?.sentence}</span>
                                                </div>
                                            </div>
                                            <div
                                                className={'work-main-card-body-img-div cursor-pointer link-dark'}
                                                onClick={() => {
                                                    // setIsModal(true);
                                                    /* if(ref.current)
                                            ref?.current?.resetForm(); */
                                                }}>
                                                {thisWeekWorkState?.icon && <img src={thisWeekWorkState.icon} className={''}></img>}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='p-2 rounded border border-light mx-4 my-1'>
                            <div className='d-flex flex-row'>
                                <Card stretch className='work-main-left-card'>
                                    <CardHeader>
                                        <CardLabel>
                                            <CardTitle className={'work-main-card-body-title'}>근무시간 통계</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody className={'work-main-card-body-topmargin-20'}>
                                        <div className='d-flex justify-space-between'>
                                            <div style={{ flex: '8', marginRight: 5 }}>
                                                <div style={{ flex: '8', marginRight: 5 }}>
                                                    <div className={'work-main-card-body-time d-flex'}>
                                                        <div>
                                                            <span> 평균 0 시간</span>
                                                        </div>
                                                    </div>

                                                    <Tooltips title={<div className='text-start p-3'>초과 시 근무 신청이 불가능합니다.</div>} placement='bottom'>
                                                        <div>
                                                            <Alert color='warning' isOutline className='bg-l10-warning flex-nowrap mt-3 py-2 px-2' style={{ borderRadius: '8px' }}>
                                                                <div className='me-2'>
                                                                    <Icon icon='WarningAmber' color='warning' />
                                                                </div>
                                                                <p className='mb-0 text-dark fw-bold'>
                                                                    평균 <span className='text-warning'>40</span>시간 초과{' '}
                                                                    <span className='fw-light text-warning' style={{ fontSize: '11px' }}>
                                                                    근무시간 확인
                                                                </span>
                                                                </p>
                                                            </Alert>
                                                        </div>
                                                    </Tooltips>
                                                    <Tooltips title={<div className='text-start p-3'>초과 시 근무 신청이 불가능합니다.</div>} placement='bottom'>
                                                            <div>
                                                                <Alert color='danger' isOutline className='bg-l10-danger flex-nowrap py-2 px-2' style={{ borderRadius: '8px' }}>
                                                                    <div className='me-2'>
                                                                        <Icon icon='WarningAmber' color='danger' />
                                                                    </div>
                                                                    <p className='mb-0 text-dark fw-bold'>
                                                                        특정 주 <span className='text-danger'>48</span>시간 초과{' '}
                                                                        <span className='fw-light text-danger' style={{ fontSize: '11px' }}>
                                                                        근무시간 확인
                                                                    </span>
                                                                    </p>
                                                                </Alert>
                                                            </div>
                                                        </Tooltips>
                                                </div>
                                                <div style={{flex: '8', marginRight: 5}}>
                                                    <div className={'work-main-card-body-time d-flex'}>
                                                        <div>
                                                            <span>
                                                                {convertMinsToHrsMins(
                                                                    (monthWorkData?.work || 0) +
                                                                    (monthWorkData?.night || 0) +
                                                                    (monthWorkData?.over || 0) +
                                                                    (monthWorkData?.nightOver || 0) -
                                                                    ((monthWorkData?.rest || 0) + (monthWorkData?.restOver || 0))
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <Popovers trigger={'hover'} desc={<>{t('상세보기')}</>}>
                                                                <Icon
                                                                    icon='ArrowForwardIos'
                                                                    color='Default'
                                                                    size='lg'
                                                                    className={'cursor-pointer link-dark work-main-user-leftmargin10'}
                                                                    isLight
                                                                    onClick={() => {
                                                                        //setIsModal(true);
                                                                        // TODO: 상세보기 임시 주석처리 (요청 2024.01.14)
                                                                        // setPageRowType('B');
                                                                    }}
                                                                />
                                                            </Popovers>
                                                        </div>
                                                        <div className='progress work-main-card-body-progress'>
                                                            <div
                                                                className='progress-bar'
                                                                role='progressbar'
                                                                style={{width: `${totalMontlyWorkProgressPercentage}%`}}
                                                                aria-valuenow={totalMontlyWorkProgressPercentage}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={'work-main-card-body-img-div cursor-pointer link-dark'}
                                                onClick={() => {
                                                    //setIsModal(true);

                                                    // TODO: 상세보기 임시 주석처리 (요청 2024.01.14)
                                                    // if (step !== 2) {
                                                    // 	setPageRowType('B');
                                                    // }
                                                }}>
                                                {/*<img src={workNoStatistics} className={'work-main-card-body-img'}></img>*/}
                                                <img src={workStatistics} className={'work-main-card-body-img'}></img>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </PageRow>

            <PageRow container='fluid' className={`col-lg-3 ${pageRowType === 'B' ? 'work-main-left' : 'work-main-left-dis-none'}`}>
                <div className='work-main-left-inner'>
                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <div className='p-4 d-flex flex-row align-items-center justify-content-start'>
                            <div
                                className='ms-2 mb-1 cursor-pointer link-dark'
                                onClick={() => {
                                    setPageRowType('A');
                                }}>
                                <Popovers trigger={'hover'} desc={<>Back</>}>
                                    <Icon icon='ArrowBackIos' size='sm' />
                                </Popovers>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 d-flex flex-row align-items-center justify-content-start work-main-card-body-topmargin-20'>
                        <div className='row work-main-user-leftmargin10'>
							<span className='work2-main-user-name'>
								{user?.me?.name || ''} <span className='work2-main-user-sub-name'>님의</span>
							</span>
                            <span className='work2-main-user-sub-name'>근무리포트</span>
                        </div>
                    </div>
                    <div className='p-4 d-flex flex-row align-items-center justify-content-start work-main-card-body-topmargin-20'>
                        <div className='row work-main-user-leftmargin10'>
                            <span className='work2-main-total-working-time-text'>총 근무 시간</span>
                            <span className='work2-main-total-working-time'>{convertMinsToHrsMins((workTime.work[0].time || 0) + (workTime.over[0].time || 0) + (workTime?.businessOffsite[0].time || 0))}</span>
                            <div className='work-main-card-body-topbottommargin15'>
                                <div className='progress'>
                                    <div className='progress-bar' role='progressbar' style={{ width: `${workPercentage * 100}%` }} aria-valuenow={workPercentage} aria-valuemin={0} aria-valuemax={100}></div>
                                    <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{ width: `${overPercentage * 100}%`, backgroundColor: '#FE637C' }}
                                        aria-valuenow={overPercentage}
                                        aria-valuemin={0}
                                        aria-valuemax={100}></div>
                                    <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{ width: `${businessOffsitePercentage * 100}%`, backgroundColor: '#F1B44C' }}
                                        aria-valuenow={businessOffsitePercentage}
                                        aria-valuemin={0}
                                        aria-valuemax={100}></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='mt-2 p-4 d-flex flex-row align-items-center justify-content-start work-main-card-body-topmargin-20'>
                        <div className='row work-main-user-leftmargin10 work-main-user-width100'>
                            <div className='work2-main-total-working-time-text'>카테고리별 시간</div>
                            <div className='work-main-card-body-topmargin20'>
                                <div className='d-flex flex-row'>
                                    <div className='templeteBox work-main-user-width100'>
                                        <div className='p-4 rounded border border-light'>
                                            <div className='work2-main-margin-bottom15'>
												<span className='work2-main-rate-icon-span'>
													<Icon icon={'DateRange'} color={null} style={{ color: tinycolor(mainColor).darken(30).toHexString() }} size='lg' />
												</span>
                                            </div>
                                            <div className='work2-main-category-title'>근무</div>
                                            <div className='work-main-card-body-topmargin10 work2-main-category-subtitle'>{convertMinsToHrsMins(workTime.work[0].time || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='templeteBox work-main-user-width100 work-main-user-leftmargin10'>
                                        <div className='p-4 rounded border border-light'>
                                            <div className='work2-main-margin-bottom15'>
												<span className='work2-main-rate-icon-span-r'>
													<Icon icon={'LocalFireDepartment'} color='danger' size='lg' />
												</span>
                                            </div>
                                            <div className='work2-main-category-title'>연장</div>
                                            <div className='work-main-card-body-topmargin10 work2-main-category-subtitle'>
                                                {convertMinsToHrsMins(workTime.over[0].time || 0)}
                                                {/* {convertMinsToHrsMins((monthWorkData?.over || 0 + monthWorkData?.nightOver || 0) - monthWorkData?.restOver)} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row work-main-card-body-topmargin10'>
                                    <div className='templeteBox work-main-user-width100'>
                                        <div className='p-4 rounded border border-light'>
                                            <div className='work2-main-margin-bottom15'>
												<span className='work2-main-rate-icon-span-y'>
													<Icon icon={'LocalTaxi'} color='warning' size='lg' />
												</span>
                                            </div>
                                            <div className='work2-main-category-title'>출장</div>
                                            <div className='work-main-card-body-topmargin10 work2-main-category-subtitle'>{convertMinsToHrsMins(workTime?.businessOffsite[0].time || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='templeteBox work-main-user-width100 work-main-user-leftmargin10'>
                                        <div className='p-4 rounded border border-light'>
                                            <div className='work2-main-margin-bottom15'>
												<span className='work2-main-rate-icon-span-g'>
													<Icon icon={'FlightTakeoff'} color='success' size='lg' />
												</span>
                                            </div>
                                            <div className='work2-main-category-title'>연차</div>
                                            <div className='work-main-card-body-topmargin10 work2-main-category-subtitle'>
                                                {(successRestDay.day || 0) + t('일')}&nbsp;
                                                {(successRestDay.hrs || 0) + t('시간')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='mt-4'></hr>
                    <div className='p-4 d-flex flex-row align-items-center justify-content-start work-main-card-body-topmargin-20'>
                        <div className='row work-main-user-leftmargin10 work-main-user-width100'>
                            <div className='work-main-card-body-topmargin10'>
                                <div className='row work2-main-display-flex'>
                                    <div className='work2-main-flex7'>
										<span className='work2-main-work-list-span'>
											<Icon icon={'circle'} fill={mainColor} color={mainColor} style={{ color: mainColor }} />
										</span>
                                        <span className='work2-main-work-list-text1'>근무시간</span>
                                    </div>
                                    <div className='work2-main-work-list-text2'>{convertMinsToHrsMins(workTime.work[0].time || 0)}</div>
                                </div>
                            </div>

                            <hr className='work-main-hr2'></hr>
                            <div className='work-main-card-body-topmargin10'>
                                <div className='row work2-main-display-flex'>
                                    <div className='work2-main-flex7'>
										<span className='work2-main-work-list-span'>
											<Icon icon={'circle'} fill={redColor} color={redColor} style={{ color: redColor }} />
										</span>
                                        <span className='work2-main-work-list-text1'>연장시간</span>
                                    </div>
                                    <div className='work2-main-work-list-text2'>{convertMinsToHrsMins(workTime.over[0].time || 0)}</div>
                                    {/* <div className='work2-main-work-list-text2'>{'+' + convertMinsToHrsMins((monthWorkData?.over || 0 + monthWorkData?.nightOver || 0) - monthWorkData?.restOver)}</div> */}
                                </div>
                            </div>
                            <hr className='work-main-hr2'></hr>
                            <div className='work-main-card-body-topmargin10'>
                                <div className='row work2-main-display-flex'>
                                    <div className='work2-main-flex7'>
										<span className='work2-main-work-list-span'>
											<Icon icon={'circle'} fill={yellowColor} color={yellowColor} style={{ color: yellowColor }} />
										</span>
                                        <span className='work2-main-work-list-text1'>출장</span>
                                    </div>
                                    <div className='work2-main-work-list-text2'>+{convertMinsToHrsMins(workTime?.businessOffsite[0].time || 0)}</div>
                                </div>
                            </div>
                            <hr className='work-main-hr2'></hr>
                            <div className='work-main-card-body-topmargin10'>
                                <div className='row work2-main-display-flex'>
                                    <div className='work2-main-flex7'>
										<span className='work2-main-work-list-span'>
											<Icon icon={'circle'} fill={greenColor} color={greenColor} style={{ color: greenColor }} />
										</span>
                                        <span className='work2-main-work-list-text1'>연차</span>
                                    </div>
                                    <div className='work2-main-work-list-text2'>
                                        +{(successRestDay.day || 0) + t('일')}&nbsp;
                                        {(successRestDay.hrs || 0) + t('시간')}
                                    </div>
                                </div>
                            </div>
                            <hr className='work-main-hr2'></hr>
                        </div>
                    </div>
                </div>
            </PageRow>
            
            <PageRow 
                container='fluid' 
                className={`${mobileDesign ? null : 'd-none' } p-4 justify-content-between gap-2`}
                style={
                    (mobileDesign && step == 2) ? {
                        position: 'fixed',
						bottom: 0,
						zIndex: 1050,
						backgroundColor: '#ffffff'
                    } : {}
                }
            >
                <Button
                    className='w-50 py-3'
                    color="info"
                    isOutline
                >
                    <span className='mx-4'>{t('취소')}</span>
                </Button>
                <Button
                    className='w-50 py-3'
                    color="info"
                >
                    <span className='mx-4'>{t('등록하기')}</span>
                </Button>
            </PageRow>
        </div>
    )
}

export default WorkCalender;
