import { types } from 'mobx-state-tree';
import { demoPages } from '../menu';
import AlarmService from '../services/AlarmService';

const NotificationList = types
    .model({
        id: types.optional(types.number, 0),
        title: types.optional(types.string, ''),
        date: types.optional(types.string, ''),
        data: types.optional(types.string, ''),
        message: types.optional(types.string, ''),
        type: types.optional(types.string, ''),
        read: types.optional(types.boolean, false),
    })

export const Notifiction = types
    .model('Notifiction', {
        isOpen: types.optional(types.boolean, false),
        badge: types.optional(types.number, 0),
        list: types.optional(types.array(NotificationList), []),
    })
    .views((self) => ({
		get get() {
            return {...self}
		},
	}))
    .actions((self) => ({
        async setData(data) {
			self.list = data;
		},
        async setBadge(no) {
            self.badge = no;
        },
        async getRefresh(companyId) {
            await AlarmService.alarm(companyId).then( res => {
                // if(res === undefined) {
                //     //console.log('error')
                //     window.location.replace(`/${demoPages.login.path}`)
                // }
                if(res.data) {
                    let data = res.data
                    data.forEach((item, index) => {
                        //console.log(item)
                        data[index].data = item.data !== "" ? JSON.stringify(item.data) : "";
                    });
                    self.setData(data);
                    // self.setData(res.data)
                    self.setBadge(res.data.filter(v=>v.read === false).length);
                }
            }).catch(()=>{
                //console.log('error')
                //window.location.replace(`/${demoPages.login.path}`)
            })
        },
    }))