import { APIClient } from "../lib/apiHelper"

const {get, post, put, remove} = new APIClient()

export default {
    /**
     * 근로정보 조회
     * @param {*} companyId 
     * @returns 
     */
    list: (companyId, id=null) => get(`/workScheduleTemplate/${companyId}${id ? '/'+id : ''}`),
    /**
     * 근로정보 추가
     * @param {*} workSystem 
     * @returns 
     */
    add: workSystem => post('/workScheduleTemplate', workSystem),
    /**
     * 근로정보 수정
     * @param {*} workSystem 
     * @returns 
     */
    modify: workSystem => put('/workScheduleTemplate', workSystem),
    /**
     * 근로정보 삭제
     * @param {*} id 
     * @returns 
     */
    delete: id => remove('/restday?id='+id),
}
