import React from 'react';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/bootstrap/Button';


const UserWorkSummaryCard = () => {
	const { t } = useTranslation(['translation', 'menu']);
	return (
		<Card className='custom-box-shadow rounded-2' stretch style={{backgroundColor: '#3139BB'}}>
			<CardBody className='text-white text-center'>
				<p className='text-white-50'>
					<sapn>name</sapn>{t('님의 오늘의 근무')}
				</p>
				<p className='fs-5 fw-normal'>
					<sapn>7.5<b className='ms-2'>{t('수')}</b></sapn>
				</p>
				<div className='fs-5 d-flex align-items-center justify-content-center'>
					<p className='me-3 mb-0 fw-normal'>
						{/* 근무 or 휴일 */}
						<sapn>{t('근무')}</sapn>
						{/* 휴일 일땐 시간 안나옴 */}
						<span className='ms-2'>00:00 ~ 00:00</span>
						{/* 일정 복수일경우 */}
						<span className='ms-3 text-white-50'>+1</span>
					</p>
					<Button 
						icon='MoreVert'
						color='info'
						rounded='circle'
						className='border-0'
						style={{
							backgroundColor: 'rgba(217, 217, 217, 0.3)'
						}}
					/>
				</div>
			</CardBody>
		</Card>
	);
};
export default React.memo(UserWorkSummaryCard);
