const calenderData = [
    {
        "id": "attendIds-3511",
        "title": "근무",
        "type": "WORK",
        "start": "2023-12-31T15:00:00.000Z",
        "end": "2024-01-01T14:59:59.999Z",
        "stime": "2023-12-31T23:30:00.000Z",
        "etime": "2024-01-01T08:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-01",
            "createdAt": "2024-01-03 11:46:39",
            "updatedAt": "2024-01-03 11:46:39",
            "id": 3511,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2023-12-31T23:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2023-12-31T23:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-01T08:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-01T08:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-01T08:30:00.000Z",
                "workStartTime": "2024-01-01 08:30:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 30600,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 1800,
            "realRestOverTime": 0,
            "weekly": true,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3509",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-01T15:00:00.000Z",
        "end": "2024-01-02T14:59:59.999Z",
        "stime": "2024-01-01T23:30:00.000Z",
        "etime": "2024-01-02T08:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-02",
            "createdAt": "2024-01-03 11:44:34",
            "updatedAt": "2024-01-03 11:44:34",
            "id": 3509,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-01T23:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": "1234",
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-01T23:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-02T08:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": "1234",
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-02T08:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-02T09:00:00.000Z",
                "workStartTime": "2024-01-02 08:30:00"
            },
            "tardy": true,
            "tardyTime": 30060,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 30600,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 1800,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3510",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-02T15:00:00.000Z",
        "end": "2024-01-03T14:59:59.999Z",
        "stime": "2024-01-02T23:30:00.000Z",
        "etime": "2024-01-03T08:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-03",
            "createdAt": "2024-01-03 11:45:37",
            "updatedAt": "2024-01-03 11:45:38",
            "id": 3510,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-02T23:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": "1234",
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-02T23:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1234"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-03T08:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337,
                                2
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": null,
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2023-12-20 14:26:47",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": false,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": false,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": "1234",
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-03T08:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1234"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-03T09:00:00.000Z",
                "workStartTime": "2024-01-03 08:30:00"
            },
            "tardy": true,
            "tardyTime": 30060,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 30600,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 1800,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3622",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-03T15:00:00.000Z",
        "end": "2024-01-04T14:59:59.999Z",
        "stime": "2024-01-03T23:00:00.000Z",
        "etime": "2024-01-04T12:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-04",
            "createdAt": "2024-01-15 13:59:45",
            "updatedAt": "2024-01-15 13:59:46",
            "id": 3622,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-03T23:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 10:40:13",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": true
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-03T21:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-04T12:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 10:40:13",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": true
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-04T12:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-04 17:00:00",
                "workStartTime": "2024-01-04 08:00:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 19800,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 1800,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3536",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-04T15:00:00.000Z",
        "end": "2024-01-05T14:59:59.999Z",
        "stime": "2024-01-04T21:30:00.000Z",
        "etime": "2024-01-05T09:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-05",
            "createdAt": "2024-01-08 10:42:56",
            "updatedAt": "2024-01-08 10:42:56",
            "id": 3536,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-04T21:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-04T21:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-05T09:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-05T09:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-05T09:00:00.000Z",
                "workStartTime": "2024-01-05 06:30:00"
            },
            "tardy": true,
            "tardyTime": 22860,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 41400,
            "nightTime": 0,
            "workingOverTime": 1800,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 9000,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3537",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-05T15:00:00.000Z",
        "end": "2024-01-06T14:59:59.999Z",
        "stime": "2024-01-06T10:00:00.000Z",
        "etime": "2024-01-06T11:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-06",
            "createdAt": "2024-01-08 10:43:23",
            "updatedAt": "2024-01-08 10:44:19",
            "id": 3537,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-06T10:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-06T10:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-06T11:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-06T11:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-06 20:30:00",
                "workStartTime": "2024-01-06 19:00:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 5400,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 0,
            "restOverTime": 0,
            "realWorkingTime": 1800,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 0,
            "realRestOverTime": 0,
            "weekly": true,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3538",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-06T15:00:00.000Z",
        "end": "2024-01-07T14:59:59.999Z",
        "stime": "2024-01-07T10:30:00.000Z",
        "etime": "2024-01-07T12:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-07",
            "createdAt": "2024-01-08 10:45:07",
            "updatedAt": "2024-01-08 10:45:08",
            "id": 3538,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-07T10:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-07T10:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-07T12:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "00:09",
                                    "18:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-08 09:04:52",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-07T12:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-07 21:30:00",
                "workStartTime": "2024-01-07 19:30:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 7200,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 0,
            "restOverTime": 0,
            "realWorkingTime": 5400,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 0,
            "realRestOverTime": 0,
            "weekly": true,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3625",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-07T15:00:00.000Z",
        "end": "2024-01-08T14:59:59.999Z",
        "stime": "2024-01-08T00:00:00.000Z",
        "etime": "2024-01-08T14:00:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-08",
            "createdAt": "2024-01-15 14:27:42",
            "updatedAt": "2024-01-15 14:27:46",
            "id": 3625,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-08T00:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-08T00:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "특근"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-08T14:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-08T14:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "특근"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-08T08:00:00.000Z",
                "workStartTime": "2024-01-08 09:00:00"
            },
            "tardy": true,
            "tardyTime": 3600,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 28800,
            "nightTime": 0,
            "workingOverTime": 18000,
            "nightOverTime": 3600,
            "restTime": 3600,
            "restOverTime": 1800,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 14400,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3626",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-08T15:00:00.000Z",
        "end": "2024-01-09T14:59:59.999Z",
        "stime": "2024-01-09T00:06:00.000Z",
        "etime": "2024-01-09T14:48:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-09",
            "createdAt": "2024-01-15 14:27:46",
            "updatedAt": "2024-01-15 14:27:47",
            "id": 3626,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-09T00:06:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-09T00:06:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "근무"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-09T14:48:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-09T14:48:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "근무"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-09T08:00:00.000Z",
                "workStartTime": "2024-01-09 09:06:00"
            },
            "tardy": true,
            "tardyTime": 3960,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 28440,
            "nightTime": 0,
            "workingOverTime": 18000,
            "nightOverTime": 6480,
            "restTime": 3600,
            "restOverTime": 1800,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 13680,
            "realNightOverTime": 3600,
            "realRestTime": 3600,
            "realRestOverTime": 1800,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3627",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-09T15:00:00.000Z",
        "end": "2024-01-10T14:59:59.999Z",
        "stime": "2024-01-10T00:00:00.000Z",
        "etime": "2024-01-10T10:00:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-10",
            "createdAt": "2024-01-15 14:27:47",
            "updatedAt": "2024-01-17 09:46:37",
            "id": 3627,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "gps": {
                            "type": "Point",
                            "coordinates": [
                                null,
                                null
                            ]
                        },
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "gps2": {
                            "lat": null,
                            "lng": null
                        },
                        "time": "2024-01-10T00:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-10T00:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    },
                    {
                        "gps": {
                            "type": "Point",
                            "coordinates": [
                                null,
                                null
                            ]
                        },
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "gps2": {
                            "lat": null,
                            "lng": null
                        },
                        "time": "2024-01-10T10:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-10T10:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-10T08:00:00.000Z",
                "workStartTime": "2024-01-10 09:00:00"
            },
            "tardy": true,
            "tardyTime": 3600,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 28800,
            "nightTime": 0,
            "workingOverTime": 7200,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3628",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-10T15:00:00.000Z",
        "end": "2024-01-11T14:59:59.999Z",
        "stime": "2024-01-10T23:00:00.000Z",
        "etime": "2024-01-11T08:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-11",
            "createdAt": "2024-01-15 14:27:48",
            "updatedAt": "2024-01-17 10:04:22",
            "id": 3628,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "gps": {
                            "type": "Point",
                            "coordinates": [
                                null,
                                null
                            ]
                        },
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "gps2": {
                            "lat": null,
                            "lng": null
                        },
                        "time": "2024-01-10T23:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-10T23:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "문테스트중 분으로만 가능"
                        },
                        "differentPlace": false
                    },
                    {
                        "gps": {
                            "type": "Point",
                            "coordinates": [
                                null,
                                null
                            ]
                        },
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "gps2": {
                            "lat": null,
                            "lng": null
                        },
                        "time": "2024-01-11T08:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-11T08:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "문테스트중 분으로만 가능"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-11 17:00:00",
                "workStartTime": "2024-01-11 08:00:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 1800,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 30600,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 1800,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3629",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-11T15:00:00.000Z",
        "end": "2024-01-12T14:59:59.999Z",
        "stime": "2024-01-11T23:57:00.000Z",
        "etime": "2024-01-12T10:56:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-12",
            "createdAt": "2024-01-15 14:27:49",
            "updatedAt": "2024-01-15 14:27:49",
            "id": 3629,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-11T23:57:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-11T23:57:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-12T10:56:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-12T10:56:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-12T08:00:00.000Z",
                "workStartTime": "2024-01-12 08:57:00"
            },
            "tardy": true,
            "tardyTime": 3420,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 28980,
            "nightTime": 0,
            "workingOverTime": 10560,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 6960,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3630",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-20T15:00:00.000Z",
        "end": "2024-01-21T14:59:59.999Z",
        "stime": "2024-01-21T04:00:00.000Z",
        "etime": "2024-01-21T07:00:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-21",
            "createdAt": "2024-01-15 16:23:06",
            "updatedAt": "2024-01-15 16:23:06",
            "id": 3630,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-21T04:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-21T04:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "탄력"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-21T07:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-21T07:00:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "탄력"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-21 16:00:00",
                "workStartTime": "2024-01-21 13:00:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 10800,
            "nightTime": 0,
            "workingOverTime": 0,
            "nightOverTime": 0,
            "restTime": 0,
            "restOverTime": 0,
            "realWorkingTime": 7200,
            "realNightTime": 0,
            "realWorkingOverTime": 0,
            "realNightOverTime": 0,
            "realRestTime": 0,
            "realRestOverTime": 0,
            "weekly": true,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3632",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-21T15:00:00.000Z",
        "end": "2024-01-22T14:59:59.999Z",
        "stime": "2024-01-21T23:30:00.000Z",
        "etime": "2024-01-22T09:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-22",
            "createdAt": "2024-01-15 16:24:11",
            "updatedAt": "2024-01-15 16:24:11",
            "id": 3632,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-21T23:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-21T23:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-22T09:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-22T09:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-22T08:00:00.000Z",
                "workStartTime": "2024-01-22 08:30:00"
            },
            "tardy": true,
            "tardyTime": 1800,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 30600,
            "nightTime": 0,
            "workingOverTime": 5400,
            "nightOverTime": 0,
            "restTime": 3600,
            "restOverTime": 0,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 1800,
            "realNightOverTime": 0,
            "realRestTime": 3600,
            "realRestOverTime": 0,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": "attendIds-3634",
        "title": "근무",
        "type": "WORK",
        "start": "2024-01-28T15:00:00.000Z",
        "end": "2024-01-29T14:59:59.999Z",
        "stime": "2024-01-28T23:00:00.000Z",
        "etime": "2024-01-29T17:30:00.000Z",
        "color": "#556EE6",
        "row": {
            "date": "2024-01-29",
            "createdAt": "2024-01-15 16:43:10",
            "updatedAt": "2024-01-15 16:43:11",
            "id": 3634,
            "userId": 7,
            "companyId": 9,
            "dates": {
                "state": [],
                "details": {},
                "history": [
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-28T23:00:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "S",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-28T21:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": "1010",
                            "name": "연차"
                        },
                        "differentPlace": false
                    },
                    {
                        "area": {
                            "id": 41,
                            "gps": {
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "EPSG:4326"
                                    }
                                },
                                "type": "Point",
                                "coordinates": [
                                    37.5458134849297,
                                    126.955904056286
                                ]
                            },
                            "code": "",
                            "name": "본사",
                            "wifi": null,
                            "radius": 100,
                            "userId": null,
                            "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                            "overseas": false,
                            "bluetooth": null,
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "addressDetail": "13층"
                        },
                        "time": "2024-01-29T17:30:00.000Z",
                        "group": {
                            "id": 12,
                            "area": {
                                "id": 41,
                                "gps": {
                                    "crs": {
                                        "type": "name",
                                        "properties": {
                                            "name": "EPSG:4326"
                                        }
                                    },
                                    "type": "Point",
                                    "coordinates": [
                                        37.5458134849297,
                                        126.955904056286
                                    ]
                                },
                                "code": "",
                                "name": "본사",
                                "wifi": null,
                                "radius": 100,
                                "userId": null,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "overseas": false,
                                "bluetooth": null,
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "addressDetail": "13층"
                            },
                            "name": "기본0",
                            "users": [
                                2,
                                7,
                                9,
                                10,
                                11,
                                12,
                                334,
                                335,
                                337
                            ],
                            "areaId": 41,
                            "areaIds": [],
                            "area_id": 41,
                            "company": {
                                "id": 9,
                                "tel": "0269250602",
                                "logo": "",
                                "name": "IDRSYSTEMS",
                                "uuid": "5000",
                                "userId": 7,
                                "address": "서울 마포구 만리재로 47 (신공덕동, 공덕코어)",
                                "ceoName": "",
                                "user_id": 7,
                                "postCode": null,
                                "restDate": null,
                                "restType": false,
                                "createdAt": "2023-12-07 10:38:56",
                                "faxNumber": null,
                                "updatedAt": "2023-12-20 17:55:28",
                                "restPromote": false,
                                "restUseUnit": "HALF",
                                "approvalSelf": false,
                                "addressDetail": "13층",
                                "restGraceYear": 0,
                                "restRoundYear": "ROUND",
                                "restGraceMonth": 0,
                                "restOptionYear": 1,
                                "securityDevice": false,
                                "restDestoryYear": true,
                                "restOptionMonth": null,
                                "restDestoryMonth": true,
                                "corporationNumber": "",
                                "companyAnniversary": "2023-12-07 10:38:56",
                                "registrationNumber": "2118845959",
                                "restAccrualTransfer": false,
                                "restAccrualTransferMax": 0,
                                "restNegativeAccrualMax": 0,
                                "restAllowNegativeAccrual": false
                            },
                            "startAt": "2023-12-07",
                            "workDay": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "coreTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "gotoWork": [
                                "",
                                ""
                            ],
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "08:00",
                                    "17:00"
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 14:29:19",
                            "outerWork": true,
                            "tardyTime": "",
                            "updatedAt": "2024-01-15 14:25:59",
                            "beforeWork": "",
                            "company_id": 9,
                            "getOffWork": [
                                "",
                                ""
                            ],
                            "workSystem": {
                                "id": 19,
                                "memo": null,
                                "name": "표준근로제",
                                "subName": "",
                                "workAvg": "max",
                                "overUnit": "w",
                                "vacation": [],
                                "workHour": 40,
                                "workUnit": "w",
                                "companyId": 9,
                                "createdAt": "2023-12-07 10:38:56",
                                "nightUnit": "d",
                                "updatedAt": "2023-12-07 10:38:56",
                                "company_id": 9,
                                "maxWorkAvg": "max",
                                "settleHour": 12,
                                "settleUnit": "m",
                                "specialUse": false,
                                "maxWorkHour": 40,
                                "maxWorkUnit": "w",
                                "overMaxHour": 12,
                                "overMinHour": 0,
                                "useHoilyDay": true,
                                "nightMaxHour": 8,
                                "nightMinHour": 0,
                                "specialMaxHour": 8,
                                "specialMaxUnit": "w",
                                "specialYearDay": 90
                            },
                            "paidRestDay": [
                                7
                            ],
                            "autoClockOut": "",
                            "workSystemId": 19,
                            "workTimeOver": [
                                "",
                                ""
                            ],
                            "duplicateWork": true,
                            "isHolidayWork": true,
                            "work_system_id": 19,
                            "requestWorkTime": [
                                "09:00",
                                "18:00"
                            ],
                            "isRecognizedWork": false,
                            "isHolidayWorkOver": true,
                            "isSuccessWorkOver": false
                        },
                        "state": "E",
                        "ccCode": "",
                        "restday": {
                            "id": 117,
                            "end": {
                                "unit": "d",
                                "value": 0
                            },
                            "use": null,
                            "code": null,
                            "memo": null,
                            "name": "근무",
                            "paid": true,
                            "type": "DEFAULT",
                            "alarm": {},
                            "color": null,
                            "onOff": true,
                            "start": {
                                "unit": "y",
                                "value": 0
                            },
                            "allDay": {
                                "use": false,
                                "time": 0
                            },
                            "areaId": null,
                            "dayOff": false,
                            "gender": null,
                            "option": "REQUEST",
                            "userId": null,
                            "user_id": null,
                            "document": null,
                            "paidInfo": {
                                "paidType": "UNPAID",
                                "paidUnit": "d",
                                "paidValue": 0
                            },
                            "parentId": null,
                            "restAuto": false,
                            "restTime": [
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ],
                                [
                                    [
                                        "",
                                        ""
                                    ]
                                ]
                            ],
                            "workTime": [
                                [
                                    "",
                                    ""
                                ]
                            ],
                            "companyId": 9,
                            "createdAt": "2023-12-07 10:38:56",
                            "deduction": 0,
                            "parent_id": null,
                            "updatedAt": "2023-12-07 10:38:56",
                            "company_id": 9,
                            "isWorkOver": false,
                            "maxWorkOver": 0,
                            "shortcutKey": null,
                            "isHolidayWork": false,
                            "isRecognizedWork": false,
                            "recognizedWorkTime": 0
                        },
                        "realTime": "2024-01-29T17:30:00.000Z",
                        "attendMemo": "",
                        "attendType": "",
                        "attendCategory": {
                            "code": null
                        },
                        "differentPlace": false
                    }
                ],
                "afterWork": false,
                "beforeWork": false,
                "workEndTime": "2024-01-29 17:00:00",
                "workStartTime": "2024-01-29 08:00:00"
            },
            "tardy": false,
            "tardyTime": 0,
            "earlyTime": 0,
            "realEarlyTime": 0,
            "workingTime": 32400,
            "nightTime": 0,
            "workingOverTime": 18000,
            "nightOverTime": 16200,
            "restTime": 3600,
            "restOverTime": 3600,
            "realWorkingTime": 32400,
            "realNightTime": 0,
            "realWorkingOverTime": 23400,
            "realNightOverTime": 14400,
            "realRestTime": 3600,
            "realRestOverTime": 3600,
            "weekly": false,
            "vacationId": null,
            "vacationType": null,
            "histSeq": null,
            "cnfrmWorker": null,
            "constrSite": null,
            "constrRate": null,
            "constrType": null,
            "constrYear": null,
            "constrGrade": null,
            "constrMgr": null,
            "constrRank": null,
            "user_id": 7,
            "company_id": 9,
            "vacation_id": null
        }
    },
    {
        "id": 3276,
        "title": "연차",
        "type": "REST",
        "state": "SUCCESS",
        "start": "2024-01-22T15:00:00.000Z",
        "end": "2024-01-25T14:59:59.999Z",
        "stime": "2024-01-22T15:00:00.000Z",
        "etime": "2024-01-25T14:59:59.999Z",
        "color": "#44BDA3",
        "etc": "",
        "memo": "",
        "duration": ["2024-01-23", "2024-01-24", "2024-01-25"],
        "deduction": 0,
        "approvals": [{
            "line": [{
                "date": null,
                "memo": "",
                "sort": "SEQ",
                "type": "APPROVAL",
                "user": {
                    "head": false,
                    "name": "강태종",
                    "rank": "대표이사",
                    "rankId": 91,
                    "userId": 7,
                    "profile": "",
                    "position": 0,
                    "readDate": "",
                    "companyId": 9,
                    "department": "IDRSYSTEMS",
                    "positionId": 10,
                    "departmentId": 7
                },
                "order": 1,
                "state": "REQUEST",
                "itemType": "item",
                "sussDate": "",
                "companyId": 9,
                "otherUser": {}
            }], "sort": "SEQ", "type": "APPROVAL", "itemType": "container"
        }, {
            "line": [{
                "date": null,
                "memo": "",
                "sort": "SEQ",
                "type": "APPROVAL",
                "user": {
                    "head": false,
                    "name": "임승환",
                    "rank": "부장",
                    "rankId": 90,
                    "userId": 11,
                    "profile": "",
                    "position": 0,
                    "readDate": "",
                    "companyId": 9,
                    "department": "개발팀",
                    "positionId": 10,
                    "departmentId": 20
                },
                "order": 2,
                "state": "REQUEST",
                "itemType": "item",
                "sussDate": "",
                "companyId": 9,
                "otherUser": {}
            }], "sort": "SEQ", "type": "APPROVAL", "chosen": false, "itemType": "container"
        }]
    }
]

export default calenderData