import {APIClient} from "../lib/apiHelper";

const {get, post, put, remove} = new APIClient()

export default {
    /**
     * 근태 마감 리스트
     */
    list: (companyId) => get('/deadline/list?companyId=' + companyId),
    /**
     * 근태 마감 상세 리스트
     */
    detailList: (deadlineId) => get(`/deadline/detailList?deadlineId=${deadlineId}`),
    /**
     * 근태 마감 추가
     */
    create: (params) => post('/deadline/add', params),
    /**
     * 근태 마감 확정 - 관리자
     */
    updateDeadlineConfirm: (params) => put('/deadline/updateDeadlineConfirm', params),
    /**
     * 근태 마감 대상자 수정
     */
    updateUsers: (params) => put('/deadline/updateUsers', params),
    /**
     * 근무기록 수정 가능 여부
     */
    attendUpdatable: (params) => get(`/deadline/attendUpdatable?userId=${params.userId}&companyId=${params.companyId}&eventStartDate=${params.eventStartDate}&eventEndDate=${params.eventEndDate}&admin=${params.admin || false}`),
    /**
     * 근무기록 수정 가능 여부 - 관리자
     */
    updateAttendByConfirm: (attendId) => get(`deadline/updateAttendByConfirm?attendId=${attendId}`),
    updateAttendByDeadline: (params) => get(`deadline/updateAttendByDeadline?userId=${params.userId}&companyId=${params.companyId}&day=${params.day}`),
    /**
     * 사용자 대시보드 알림용 근태 마감 조회
     */
    listForNotice: (params) => get(`/deadline/list/forNotice?userId=${params.userId}&companyId=${params.companyId}${params.forConfirm ? '&forConfirm=true' : ''}`),
    /**
     * 마감 대상 직원 목록 조회
     * */
    getUserList: (companyId = '', text = '', state = '', type='') =>
        get(`/deadline/userList?companyId=${companyId}&text=${text}&state=${state}&type=${type}`),
    /**
    * 근태 마감 확정
    */
    confirm: (params) => put('/deadline/confirm', params),
    /**
     * 근태마감 알림모달 다시보지 않기
     */
    dismissModal: (params) => put('/deadline/dismissModal', params),
    /**
     * 마감 엑셀 다운로드
     */
    deadlineExcelDownload: (deadlineId, checked) => get(`/deadline/excel/download?deadlineId=${deadlineId}&checked=${checked}`)
}