import { APIClient } from "../lib/apiHelper"

const {get, post, put, remove} = new APIClient()

export default {
    /**
     * 근로정보 조회
     * @param {*} companyId 
     * @returns 
     */
    list: (search) => get(`/workSchedule?${search ? new URLSearchParams(search).toString() : ''}`),
    /**
     * 근로정보 추가
     * @param {*} workSystem 
     * @returns 
     */
    add: workSchedule => post('/workSchedule', workSchedule),
    /**
     * 근로정보 수정
     * @param {*} workSystem 
     * @returns 
     */
    modify: workSchedule => put('/workSchedule', workSchedule),
    /**
     * 근로정보 삭제
     * @param {*} id 
     * @returns 
     */
    delete: id => remove('/workSchedule?id='+id),
    /**
     * 근로정보 조회 - 권한 설정
     * @param {*} companyId
     * @returns
     */
    getScheduleList: (search) => get(`/workSchedule/byPermission?${search ? new URLSearchParams(search).toString() : ''}`)
}
