import React, { Component, createRef, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import 'moment-timezone';

import Timeline, { TimelineHeaders, SidebarHeader, DateHeader, TimelineMarkers, CustomMarker, TodayMarker, CursorMarker, CustomHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../../../AuthMenu/Work/components/css/workingSchedule.scss';
import CompanyService from '../../../../../services/CompanyService';
import { useMst } from '../../../../../models';
import Avatar from '../../../../../components/Avatar';
import Icon from '../../../../../components/icon/Icon';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import { getItem, removeItem, setItem } from '../../../../../lib/localstorage';
import GroupService from '../../../../../services/GroupService';
import WorkingSchduleDraggable from '../../../../AuthMenu/Work/components/libs/workingSchduleDraggable';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Popovers from '../../../../../components/bootstrap/Popovers';
import Card, { CardBody, CardFooter, CardFooterRight } from '../../../../../components/bootstrap/Card';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useTranslation } from 'react-i18next';
import { getTextColorByBackgroundColor } from '../../../../../lib/Util';
import tinycolor from 'tinycolor2';
import WorkScheduleTemplate from '../../../../AuthMenu/Work/components/modal/WorkScheduleTemplate';
import Badge from '../../../../../components/bootstrap/Badge';
import RenderGroupUser from '../../../../AuthMenu/Work/components/modal/RenderGroupUser';
import WorkSchduleService from '../../../../../services/WorkSchduleService';
import ThemeContext from '../../../../../contexts/themeContext';
 
const getIcon = (type) => {
	//const icon = (data.type === 'WORK' || data.type === 'WORK_CUSTOM') ? 'DateRange' : 'FlightTakeoff';
	switch (type) {
		case 'WORK':
		case 'WORK_CUSTOM':
		case 'WORK_SCHDULE':
			return 'DateRange';
		case 'REST':
		case 'REST_CUSTOM':
		case 'REST_SCHDULE':
			return 'FlightTakeoff';
		case 'OUTSIDE_CUSTOM':
			return 'BusinessCenter';
		case 'BUSINESS_CUSTOM':
			return 'DirectionsCarFilled';
		default:
			// OFF
			return 'Bed';
	}
};

const checkType = (type) => {
	switch (type) {
		case 'WORK_SCHDULE':
			return 'work';
		case 'REST_SCHDULE':
			return 'rest';
		case 'DAYOFF':
			return 'dayoff';
	}
}


const renderMaxTimeText = (timeInfos) => {
	//{timeInfos?.type === 'max' ? '최대' : '평균'} {timeInfos?.max}{renderTypeText(timeInfos?.unit || '')}
	const type = timeInfos?.maxWorkAvg === 'max' ? '최대' : '평균';
	switch (timeInfos.maxWorkUnit) {
		case 'h':
			return `${type} ${timeInfos?.maxWorkHour}H`;
		case 'd':
			return `일 ${type} ${timeInfos?.maxWorkHour}H`;
		case 'm':
			return `월 ${type} ${timeInfos?.maxWorkHour}H`;
		case 'w':
			return `주 ${type} ${timeInfos?.maxWorkHour}H`;
		default:
			return '';
	}
};

const renderDefaultTimeText = (timeInfos) => {
	//{timeInfos?.type === 'max' ? '최대' : '평균'} {timeInfos?.max}{renderTypeText(timeInfos?.unit || '')}
	const type = timeInfos?.workAvg === 'max' ? '최대' : '평균';
	switch (timeInfos.workUnit) {
		case 'h':
			return `${type} ${timeInfos?.workHour}H`;
		case 'd':
			return `일 ${type} ${timeInfos?.workHour}H`;
		case 'm':
			return `월 ${type} ${timeInfos?.workHour}H`;
		case 'w':
			return `주 ${type} ${timeInfos?.workHour}H`;
		default:
			return '';
	}
};

const convertSeconde2HourMinute2 = (mins) => {
	let h = Math.floor(mins / 3600);
	let m = Math.floor((mins % 3600) / 60);
	let s = Math.floor(mins % 60);
	//let h = Math.floor(mins / (60 * 60));
	//let m = mins % (60);
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};

const WorkingScheduleComponents = forwardRef((props, ref) => {
	moment.locale('ko');
	moment.tz.setDefault('Asia/Seoul');

	const { company } = useMst();
	const { schduleUpdate, setSchduleUpdate } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);
	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	// console.log('selectionRange', selectionRange);
	const popRef = useRef({});
	const templeteRef = useRef(null);

	const [users, setusers] = useState([]);
	const [openGroups, setOpenGroups] = useState({});
	const [userlength, setUsersLength] = useState(0);
	const [filteredUser, setFilteredUser] = useState([]);
	// const [workGroups, setWorkGroups] = useState([]);
	const [selectedWorkGroup, setSelectedWorkGroup] = useState({
		name: '전체',
		idx: 0,
		groupId: 'group-all',
	});
	const [newFilteredGroup, setNewFilteredGroup] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(80);
	const [headerDate, setHeaderDate] = useState([]);

	const [selectionRange, setSelectionRange] = useState({
		startDate: moment().startOf('isoWeek').valueOf(),
		endDate: moment().endOf('isoWeek').valueOf(),
		key: 'selection',
	});
	const [tempSelectionRange, setTempSelectionRange] = useState([
		{
			startDate: moment().startOf('isoWeek').toDate(),
			endDate: moment().endOf('isoWeek').toDate(),
			key: 'selection',
		},
	]);

	const [defaultTimeStart, setdefaultTimeStart] = useState(moment()); //useState((moment(selectionRange?.startDate) || moment().startOf('isoWeek')).valueOf());
	const [defaultTimeEnd, setdefaultTimeEnd] = useState(moment()); //useState((moment(selectionRange?.endDate) || moment().endOf('isoWeek')).valueOf());

	// const defaultTimeRange = defaultTimeEnd - defaultTimeStart;
	// const defualtViewRange = moment().subtract(0, 'w').endOf('d') - moment().add(0, 'w').startOf('d');

	const [holiday, setHoliday] = useState([]);
	const refs = useRef(null);

	const [originalTemplates, setOriginalTemplates] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [selectTemplate, setSelectTemplate] = useState({});
	const [selectItems, setSelectItems] = useState({});
	const [selectColumns, setSelectColumns] = useState(null);
	const selectItem = useRef(null);

	const [rankSort, setRankSort] = useState('desc');

	const [items, setItems] = useState([]);
	const [originalitems, setOriginalItems] = useState([]);
	const [maxZoom, setMaxZoom] = useState(0);

	const prevItemsRef = useRef([]);
	const [plannedWorkLocal, setPlannedWorkLocal] = useState();
	const [localDataLoaded, setLocalDataLoaded] = useState(false);
	const [filterType, setFilterType] = useState('work');	

	const scrollRef = useRef({
		current: null,
	});
	const onScrollRef = (ref) => {
		scrollRef.current = ref;
	};
	const timelineRef = useRef(); //createRef();

	useImperativeHandle(ref, () => ({
		saveForm: async () => {
			const reItem = items?.filter(v=>v.id.indexOf('row-') === -1);
			await WorkSchduleService.add(reItem).then((res) => {
				// removeItem('plannedWorkLocal-' + company.get.id)
				refreshItems();
				forceUpdate();
			});
		},
		resetForm: () => {
			// console.log('?????')
			if (templeteRef?.current) {
				templeteRef?.current?.resetForm();
				// removeItem('plannedWorkLocal-' + company.get.id)
			}
		},
		reset: () => {
			// let result = [];
			// items?.map((item) => {
			// 	if (item?.id.indexOf('row') > -1) {
			// 		result.push({
			// 			...item,
			// 			id: item?.id.replace('row', 'delete'),
			// 		});
			// 	}
			// });
			//setItems(result);
			// removeItem('plannedWorkLocal-' + company.get.id)
			refreshItems();
			forceUpdate();
		},
		refresh: () => {
			refresh();
		},
	}));

	// useEffect(() => {
	// 	console.log('selectColumns', selectColumns);
	// }, [selectColumns]);

	const getGroupTime = (user) => {
		return [...users].find((v) => v.id === user.parent) || null;
	};

	const getSchduleTime = useCallback(
		(user) => {
			
			/*
				{
				"title": "단축근무",
				"reduceWorkTime": 4,
				"reduceWorkType": "육아기 단축"
				}
			*/

			// console.log('items', moment(selectionRange.startDate).format('YYYYMMDD'), moment(selectionRange.endDate).format('YYYYMMDD'))
			const group = [...users].find((v) => v?.id === user.parent);			
			const mySchdule = [...items]
				?.filter((v) => {
					return v?.id.indexOf('delete') === -1;
				})
				?.filter((v) => v.group == user.userId)
				?.filter((v) => {
					// console.log(
					// 	'>>',
					// 	moment(v['start_time']).format('YYYY-MM-DD'),
					// 	moment(selectionRange.startDate).format('YYYY-MM-DD'),
					// 	moment(selectionRange.endDate).format('YYYY-MM-DD'),
					// 	moment(moment(v['start_time']).format('YYYY-MM-DD')).isBetween(moment(selectionRange.startDate).format('YYYY-MM-DD'), moment(selectionRange.endDate).format('YYYY-MM-DD'), 'day')
					// );
					return (
						moment(moment(v['start_time']).format('YYYY-MM-DD')).isBetween(moment(selectionRange.startDate).format('YYYY-MM-DD'), moment(selectionRange.endDate).format('YYYY-MM-DD'), 'day') &&
						moment(moment(v['end_time']).format('YYYY-MM-DD')).isBetween(moment(selectionRange.startDate).format('YYYY-MM-DD'), moment(selectionRange.endDate).format('YYYY-MM-DD'), 'day')
					);
				});

				// console.log('group', user.userId, group);

			// 각 단축 그룹은 가상의 그룹이라, 각 사용자별 group 정보 생성해줄것..

			// console.log('mySchdule', mySchdule);
			let result = { ...group.workSystem, time: 0 };
			// console.log('result', result);
			// console.log('group', group)

			// 정산주기 = 근무그룹 시작일자 ~
			const groupStart = moment(group.startAt);

			const settle = {
				hour: group?.workSystem?.settleHour || 8,
				unit: group?.workSystem?.settleUnit || 'd',
			};

			// useHoilyDay
			// 일 주 월  단위..
			switch (settle?.unit) {
				case 'd':
					break;
				case 'w':
					break;
				case 'm':
					break;
			}
			const settleDay = moment().diff(groupStart, settle.unit);
			// 나누고
			// 현재까지 주기별 일자 계산 로직
			// startAt / 주기 = 범위

			// schedules 는 근로제 범위만큼 필터해서 가져오기

			// 근무, 연장 별도 계산 확인!!

			// 외근, 출장 간주여부에 따른 시간
			// 시간여부에 따른 계산
			// 평균 최대에 따라 값 변동

			// 그룹 설정값에 따른
			// 평균 근로시간
			// 최대 근로시간 등 정보 리턴

			// 기본근로시간 체크
			// 연장근로시간 체크
			// 심야 근로시간 체크
			// 특별연장?
			// 최대 근로시간
			// 휴일포함이냐

			// 근로시스템 안의 검증 값 포함하도록~

			// 탄력으로 인한 평균이냐
			// 기본 근무시간이냐

			const time = mySchdule?.reduce(
				(pre, cur, index) => {
					if (cur.row.type !== 'REST' && cur.row.type !== 'REST_CUSTOM' && cur.row.type !== 'REST_SCHDULE' && cur.row.type !== 'DAYOFF') {
						if (cur?.row?.restAuto === true) {
							if (cur?.row?.restTime[moment(cur.start_time).format('E') - 1][0][0] !== '') {
								// 지정
								//
								cur?.row?.restTime[moment(cur.start_time).format('E') - 1].map((times) => {
									if (moment(times[0], 'HH:mm') >= moment(cur.start_time) && moment(times[1], 'HH:mm') <= moment(cur.end_time)) {
										// console.log('this ALL', moment(times[0], 'HH:mm').format('HH:mm'), moment(times[1], 'HH:mm').format('HH:mm'));
										pre.rest += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
										// console.log("= ", moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second'))
									} else if (moment(times[0], 'HH:mm') >= moment(cur.start_time) && moment(times[0], 'HH:mm') <= moment(cur.end_time)) {
										// console.log('this S', moment(times[0], 'HH:mm').format('HH:mm'));
										pre.rest += moment(cur.end_time).diff(moment(times[0], 'HH:mm'), 'second');
										// console.log("= ", moment(e).diff(moment(times[0], 'HH:mm'), 'second'))
									} else if (moment(times[1], 'HH:mm') <= moment(cur.end_time) && moment(times[1], 'HH:mm') >= moment(cur.start_time)) {
										// console.log('this E', moment(times[1], 'HH:mm').format('HH:mm'));
										pre.rest += moment(times[1], 'HH:mm').diff(moment(cur.start_time), 'second');
										// console.log("= ", moment(times[1], 'HH:mm').diff(moment(s), 'second'))
									} else {
										// console.log('...')
									}
								});
							} else {
								// 자동
								pre.rest.push(Math.floor(moment(cur.end_time).diff(moment(cur.start_time), 'second') / (270 * 60)) * (30 * 60));
							}
						}
						pre.work.push(parseInt(moment(cur.end_time).diff(moment(cur.start_time), 'second')));
						return pre;
					} else {
						return pre;
					}
				},
				{ work: [], rest: [], over: [] }
			);
			// console.log("====================", time);
			// 최대시간?

			const workSum = time?.work?.reduce((a, b) => a + b, 0);
			const restSum = time?.rest?.reduce((a, b) => a + b, 0);

			if ((group?.workSystem?.workAvg || 'avg') === 'avg') {
				result = { ...result, time: (workSum / time?.work?.length || 0) - (restSum / time?.rest?.length || 0) };
			} else {
				result = { ...result, time: workSum - restSum }; //convertSeconde2HourMinute2();
			}
			return result;
		},
		[items, users, selectionRange]
	);

	const refresh = async () => {

		// setOpenGroups({});
		setusers([]);

		// const resUserList = await CompanyService.userList(company.get.id, '', perPage, currentPage, []).then((res) => {
		// 	return res?.data || {};
		// });
		// let u = [
		// 	...(resUserList?.rows?.map((u) => {
		// 		return { ...u, title: u?.user?.name || '', userId: u?.userId, id: u?.group?.id, height: 60 };
		// 	}) || []),
		// ];
		// u = u?.filter(v=>v.state === 'SUCCESS');
		const HolyRange = getRange(moment(selectionRange.startDate), moment(selectionRange.endDate), 'd');
		// console.log('HolyRange', HolyRange)

		let newGroups = [
			{
				id: 'group-baby',
				title: '임신기 단축',
				name: '임신기 단축',
				// height: 'auto',
				root: true,
				parent: null,
				users: [],
			},
			{
				id: 'group-children',
				title: '육아기 단축',
				name: '육아기 단축',
				// height: 'auto',
				root: true,
				parent: null,
				users: [],
			},
			{
				id: 'group-family',
				title: '가족돌봄 단축',
				name: '가족돌봄 단축',
				// height: 'auto',
				root: true,
				parent: null,
				users: [],
			},
			{
				id: 'group-flex',
				title: '탄력근무',
				name: '탄력근무',
				// height: 'auto',
				root: true,
				parent: null,				
				users: [],
			},
		];
		let items = [];
		let openGroups = {};

		// console.log('-----------------------------', response.data);
		// const schdules = await WorkSchduleService.list({companyId: company.get.id, sdate: moment(selectionRange.startDate).format('YYYY-MM-DD'), edate: moment(selectionRange.endDate).format('YYYY-MM-DD')});
		// if(schdules?.data)
		// 	items = schdules.data;
		// 스케줄근로제
		const response = await GroupService.searchSchdule({
			companyId: company.get.id,
			isUser: true,
			startDate: moment(selectionRange.startDate).format('YYYY-MM-DD'),
			endDate: moment(selectionRange.endDate).format('YYYY-MM-DD'),
		});
		response?.data
			// ?.filter((g) => g.workSystem.name.indexOf('스케줄') > -1)
			?.map((group) => {
				// console.log('group', group)
				// console.log('paidRestDay', group.paidRestDay)

				// schduleGrops user는 없지만, virtualGroup에 속한 user만 있을 때, isVirtualDataExist 임시데이터 확인!
				// if (group.isVirtualDataExist) {
				// 	return;
				// }

				const thisUserHoly = [...HolyRange]?.filter((v) => {
					// console.log('EEEEE', v.format('E'));
					return group.paidRestDay.indexOf(parseInt(v.format('E'))) > -1;
				});
				// console.log('thisUserHoly', thisUserHoly)
				newGroups.push(
					Object.assign({}, group, {
						id: 'group-' + parseInt(group.id),
						title: group?.name || '',
						// height: 'auto',
						root: true,
						parent: null,
					})
				);
				openGroups['group-' + group.id] = true;
				// group?.users?.map((user) => {
				// 	newGroups.push({
				// 		...user,
				// 		isRoot: false,
				// 		parent: 'group-' + group.id,
				// 		title: user?.user?.name || '',
				// 		name: user?.user?.name || '',
				// 		id: 'group-' + group.id + '-' +user?.userId,

				// 		// height: 'auto',
				// 	});
				// 	// 주휴일 확인 아이템 추가
				// 	thisUserHoly?.map((holy) => {
				// 		// console.log('holy', holy.startOf('d').format('YYYY.MM.DD HH:mm:ss'));
				// 		const isCheckTime = [...items]?.find(
				// 			(v) =>
				// 				v.group === user?.userId &&
				// 				((moment(v['start_time']) >= moment(moment(holy.startOf('d').format('YYYY-MM-DD HH:mm:ss'))) &&
				// 					moment(v['start_time']) <= moment(moment(holy.startOf('d').format('YYYY-MM-DD HH:mm:ss')))) ||
				// 					(moment(v['end_time']) >= moment(moment(holy.endOf('d').format('YYYY-MM-DD HH:mm:ss'))) && moment(v['end_time']) <= moment(moment(holy.endOf('d').format('YYYY-MM-DD HH:mm:ss')))))
				// 		);
				// 		// console.log('>>>', user?.userId, isCheckTime);
				// 		if (!isCheckTime?.id) {
				// 			// items.push({
				// 			// 	row: {
				// 			// 		id: `temp-holiday`,//-${moment().valueOf()}`,
				// 			// 		companyId: company.get.id,
				// 			// 		name: '휴일',
				// 			// 		type: 'DAYOFF',
				// 			// 		code: 'F',
				// 			// 		color: {"hex": "#000000", "hsl": {"a": 1, "h": 233.25, "l": 0, "s": 0}, "hsv": {"a": 1, "h": 233.25, "s": 0, "v": 0}, "rgb": {"a": 1, "b": 0, "g": 0, "r": 0}, "oldHue": 233.25, "source": "hex"},
				// 			// 		workTime: [['', '']],
				// 			// 		restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]],
				// 			// 		restAuto: true,
				// 			// 		deduction: 0,
				// 			// 		paid: true,
				// 			// 		onOff: true,
				// 			// 		paidInfo: {
				// 			// 			paidType: 'PAID',
				// 			// 			paidUnit: 'd',
				// 			// 			paidValue: 0,
				// 			// 		},
				// 			// 		start: {
				// 			// 			unit: 'y',
				// 			// 			value: 0,
				// 			// 		},
				// 			// 		end: {
				// 			// 			unit: 'd',
				// 			// 			value: 0,
				// 			// 		},
				// 			// 		memo: '휴일',
				// 			// 		option: 'REQUEST',
				// 			// 		use: '',
				// 			// 		dayOff: false,
				// 			// 		gender: '',
				// 			// 		document: '',
				// 			// 		allDay: {
				// 			// 			use: true,
				// 			// 			time: 8,
				// 			// 		},
				// 			// 		company_id: company.get.id,
				// 			// 	},
				// 			// 	id: `temp-holiday-${self.crypto.randomUUID()}`,
				// 			// 	title: '휴일',
				// 			// 	group: user?.userId,
				// 			// 	start_time: moment(holy.startOf('d').format('YYYY-MM-DD HH:mm:ss')),
				// 			// 	end_time: moment(holy.endOf('d').format('YYYY-MM-DD HH:mm:ss')),
				// 			// 	icon: 'Bed',
				// 			// 	itemProps: {
				// 			// 		// 'data-tip': 'Drag & drop is working',
				// 			// 		className: `fw-bold text-dark`,
				// 			// 		style: {
				// 			// 			backgroundColor: tinycolor(templates[0]?.color?.hex || '#5A6CFA').lighten(30).toHexString(),
				// 			// 		},
				// 			// 	},
				// 			// })
				// 		}
				// 	});
				// });
			});

		if (response?.virtualGroup) {
			Object.keys(response?.virtualGroup)?.map((v) => {
				// console.log(v, response?.virtualGroup[v]);
				openGroups['group-' + v]?.users?.length > 0 ? true : false;
				response?.virtualGroup[v]?.map((user) => {
					// console.log('uuuu', 'group-' + v, user?.userId, user?.name || '');
					const index = newGroups.findIndex((g) => g.id === 'group-' + v);
					if (index > -1) {
						newGroups[index].users.push(user);
					}
					// console.log('push A')
					// newGroups.push({
					// 	...user,
					// 	isRoot: false,
					// 	parent: 'group-' + v,
					// 	title: user?.user?.name || '',
					// 	name: user?.user?.name || '',
					// 	id: 'group-' + v + '-' + user?.userId,
					// 	// height: 'auto',
					// });
					// console.log('push B', {
					// 	...user,
					// 	isRoot: false,
					// 	parent: 'group-' + v,
					// 	title: user?.user?.name || '',
					// 	name: user?.user?.name || '',
					// 	id: 'group-' + v + '-' + user?.userId,
					// 	// height: 'auto',
					// })
				});
			});
			// newGroups.push({
			// 	...user,
			// 	isRoot: false,
			// 	parent: group.id,
			// 	title: user?.user?.name || '',
			// 	id: user?.userId,

			// 	// height: 'auto',
			// });
		}

		// console.log('newGroups', newGroups);
		setOpenGroups(openGroups);
		setusers(newGroups);
		// setWorkGroups(newGroups);
		await CompanyService.getHoliday(company.get.id).then((res) => {
			setHoliday(res?.data || []);
		});

		// forceUpdate();

		// const dates = [];
		// let prevDate = defaultTimeStart;
		// while (prevDate < moment(defaultTimeEnd)) {
		// 	let d = moment(prevDate);
		// 	dates.push({
		// 		id: moment(d).format('YYYYMMDD'),
		// 		date: d,
		// 	});
		// 	prevDate = moment(prevDate).add(1, 'days');
		// }
		// setHeaderDate(dates);
	};

	const customSort = useCallback((arr) => {
		// console.log('customSort arr', arr);
		const withoutNull = arr?.filter((item) => item?.shortcutKey && item?.shortcutKey !== null);
		// console.log('withoutNull', withoutNull);
		withoutNull.sort((a, b) => parseInt(a.shortcutKey) - parseInt(b.shortcutKey));
		// console.log('withoutNull sort', withoutNull);
		const sortedArr = [...withoutNull, ...arr?.filter((item) => item?.shortcutKey === null)];
		// console.log('sortedArr', sortedArr);

		// console.log('customSort retrun arr', sortedArr);
		return sortedArr;
	}, []);

	const refreshTemplete = useCallback(async () => {

		let templates = [{
			id: `temp-holiday`,//-${moment().valueOf()}`,
			companyId: company.get.id,
			name: 'DAYOFF',
			type: 'DAYOFF',
			code: 'F',
			color: {"hex": "#D7D7D7", "hsl": {"a": 1, "h": 233.25, "l": 0, "s": 0}, "hsv": {"a": 1, "h": 233.25, "s": 0, "v": 0}, "rgb": {"a": 1, "b": 0, "g": 0, "r": 0}, "oldHue": 233.25, "source": "hex"},
			workTime: [['', '']],
			restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]],
			restAuto: true,
			deduction: 0,
			paid: true,
			onOff: true,
			shortcutKey: 1,
			paidInfo: {
				paidType: 'PAID',
				paidUnit: 'd',
				paidValue: 0,
			},
			start: {
				unit: 'y',
				value: 0,
			},
			end: {
				unit: 'd',
				value: 0,
			},
			memo: '휴일',
			option: 'REQUEST',
			use: '',
			dayOff: false,
			gender: '',
			document: '',
			allDay: {
				use: true,
				time: 8,
			},
			company_id: company.get.id,
		}];

		const res = await CompanyService.getUserRestType({ companyId: company.get.id, typeArray: ['WORK_SCHDULE', 'REST_SCHDULE'] });
		// .then((res) => {
		// });
		if (res) {
			// const defaulHoliday = {
			// 	id: `temp-holiday`, //-${moment().valueOf()}`,
			// 	companyId: company.get.id,
			// 	name: 'OFF',
			// 	type: 'DAYOFF',
			// 	code: 'F',
			// 	color: { hex: '#000000', hsl: { a: 1, h: 233.25, l: 0, s: 0 }, hsv: { a: 1, h: 233.25, s: 0, v: 0 }, rgb: { a: 1, b: 0, g: 0, r: 0 }, oldHue: 233.25, source: 'hex' },
			// 	workTime: [['', '']],
			// 	restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]],
			// 	restAuto: true,
			// 	deduction: 0,
			// 	paid: true,
			// 	onOff: true,
			// 	paidInfo: {
			// 		paidType: 'PAID',
			// 		paidUnit: 'd',
			// 		paidValue: 0,
			// 	},
			// 	start: {
			// 		unit: 'y',
			// 		value: 0,
			// 	},
			// 	end: {
			// 		unit: 'd',
			// 		value: 0,
			// 	},
			// 	memo: 'OFF',
			// 	option: 'MANUAL',
			// 	use: '',
			// 	dayOff: false,
			// 	gender: '',
			// 	document: '',
			// 	shortcutKey: null,
			// 	allDay: {
			// 		use: true,
			// 		time: 8,
			// 	},
			// 	company_id: company.get.id,
			// };
			templates = [...templates, ...res?.data || []];
			// setTemplates(customSort(res?.data || []));
			// setTemplates(customSort([...[defaulHoliday], ...(res?.data || [])]));
			// forceUpdate();			
		}

		setOriginalTemplates(templates);
		if (props?.setIsModal) {
			props?.setIsModal(false);
		}
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, company.get.id, forceUpdate, setTemplates]);

	function getRange(startDate, endDate, type) {
		let fromDate = moment(startDate);
		let toDate = moment(endDate);
		let diff = toDate.diff(fromDate, type);
		let range = [];
		for (let i = 0; i <= diff; i++) {
			range.push(moment(startDate).add(i, type));
		}
		return range;
	}

	// const handlePopState = (e) => {
	// 	if(items?.length > 0 && items.find(v=>v.id.indexOf('temp') > -1)) {
			
	// 	}
	// 	console.log('페에지 벗어나기?')
	// 	history.pushState(null, '', location.href);		
	// 	const returnVal = confirm('저장되지 않은 데이터가 있습니다. 저장 후 종료해주세요.');
	// 		if(returnVal) {
	// 			window.removeEventListener('popstate', handlePopState);
	// 			window.removeEventListener('beforeunload', handleBeforeUnload);
	// 			window.history.back();
	// 		}
	// };

	// const handleBeforeUnload = (e) => {				
	// 	if(items?.length > 0 && items.find(v=>v.id.indexOf('temp') > -1)) {
			
	// 	}
	// 	console.log('페이지 벗어나기?')
	// 	const returnVal = confirm('B 저장되지 않은 데이터가 있습니다. 저장 후 종료해주세요.');
	// 		e.returnValue = returnVal;
	// 		return returnVal;
	// }

	useEffect(() => {
		refreshTemplete();

		// if((props?.isModal || false) === false) {
		// 	console.log('set evnet A')
		// window.addEventListener('keydown', handleKeyDown);
		// window.addEventListener('keyup', handleKeyUp);
		// window.addEventListener('mousedown', handleMouseClick);
		
		// window.addEventListener('popstate', handlePopState);
		// window.addEventListener('beforeunload', handleBeforeUnload);
		// } else {
		// 	console.log('remove evnet A')
		// 	window.removeEventListener('keydown', handleKeyDown);
		// 	window.removeEventListener('keyup', handleKeyUp);
		// 	window.removeEventListener('mousedown', handleMouseClick);
		// }
		return () => {
			// console.log('remove evnet A');
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
			// window.removeEventListener('mousedown', handleMouseClick);
			
			// history.pushState(null, '', location.href);
			// window.removeEventListener('popstate', handlePopState);
			// window.removeEventListener('beforeunload', handleBeforeUnload);
			
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// console.log('items chage', items);

		if (selectItem) {
			selectItem.current = {
				...selectItem.current,
				items: items || [],
			};
		}
		setSchduleUpdate(items);
		// if(timelineRef.current) {
		// 	// console.log(timelineRef.current)			
		// 	// timelineRef.current.updater();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, selectItem]);

	// useEffect(() => {
	// 	//초기화 또는 확정 시 local 초기화
	// 	if (props?.isPopupModal) {
	// 		if (props?.isPopupModal?.confirmLabel == '초기화' && props?.isPopupModal?.is == true) {
	// 				removeItem('plannedWorkLocal-' + company.get.id)
	// 				refreshItems();
	// 				forceUpdate();
	// 		}else if(props?.isPopupModal?.confirmLabel == '확정하기' && props?.isPopupModal?.is == true){
	// 			removeItem('plannedWorkLocal-' + company.get.id)
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props?.isPopupModal]);

	useEffect(() => {
		const handleUnload = (event) => {
			// 페이지 새로고침
			// removeItem('plannedWorkLocal-' + company.get.id)
		};
	
		window.addEventListener('beforeunload', handleUnload);
	
		return () => {
			window.removeEventListener('beforeunload', handleUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// localStorage에 저장
		if (props?.excelItems?.length > 0) {
			const firstDate = Object.keys(props?.excelItems[0]).filter((key) => key !== '이름' && key !== '사번');
			// console.log('firstDate', firstDate);
			// setSelectionRange({
			// 	key: 'selection',
			// 	startDate: moment(firstDate[0]).toDate(),
			// 	endDate: moment(firstDate[firstDate.length - 1]).toDate(),
			// });

			const newItems = [];
			// console.log('-------------------------', users);
			let newUsers = [...users]?.filter((v) => v.workSystem?.workAvg);

			// console.log('newUsers', newUsers);

			for (const groupUser of newUsers) {
				for (const user of groupUser.users) {
					const userId = user.userId;
					const matchingExcelData = props?.excelItems.find((excelRecord) => { 
						const nameMatch = user.user?.name === excelRecord.이름;
						const companyNumberMatch = excelRecord?.사번 !== undefined && user?.companyNumber !== undefined && String(user.companyNumber) === String(excelRecord.사번);

						return nameMatch && companyNumberMatch;
					});

					if (matchingExcelData) {
						const dateKeys = Object.keys(matchingExcelData).filter((key) => key !== '이름' && key !== '사번');

						dateKeys.forEach((key) => {							
							
							const tmpKey = [key];
							// console.log('tmpKey', tmpKey);

							tmpKey?.forEach((dateKey) => {
								const dataCode = dateKey.trim();
								// console.log('dataCode', dataCode);
								try {
									const original_code = String(matchingExcelData[dataCode]);									
									let tmpCode = [];
									if(original_code?.indexOf(',') > -1) {
										tmpCode = original_code.split(',');
									} else {
										tmpCode = [original_code];
									}
									tmpCode.forEach((code) => {
										if (code !== '' && dataCode) {
											const matchingTemplate = originalTemplates.find((template) => String(template.code) === String(code));

											if (matchingTemplate) {
												const icon = getIcon(matchingTemplate.type);
												const mainColor = matchingTemplate.color?.hex || '#5A6CFA';
												const bgColor = tinycolor(mainColor).lighten(30).toHexString();

												const formattedDate = dataCode.replace(/-/g, ' ').trim();
												const parsedDates = moment(formattedDate, 'YYYY MM DD');

												const startTime = moment(matchingTemplate?.workTime[0][0], 'HH:mm');
												const startDatetime = moment(parsedDates).hour(startTime.hour()).minute(startTime.minute());
												const endTime = moment(matchingTemplate?.workTime[0][1], 'HH:mm');
												let endD;
												if (matchingTemplate?.workTime[0][1]) {
													endD = startTime.isAfter(endTime)
														? moment(parsedDates.add(1, 'day')).hour(endTime.hour()).minute(endTime.minute())
														: moment(parsedDates).hour(endTime.hour()).minute(endTime.minute());
												} else {
													endD = moment(parsedDates).endOf('day');
												}
			
												const excelMapping = {
													id: `temp-holiday-${moment().valueOf()}-${Math.floor(100000 + Math.random() * 900000)}`,
													companyId: company.get.id,
													company_id: company.get.id,
													...matchingTemplate,
												};

												const addData = {
													id: `temp-${matchingTemplate.type.toLowerCase()}-${moment().valueOf()}-${Math.floor(100000 + Math.random() * 900000)}`,
													start_time:  moment(moment(startDatetime).format('YYYY-MM-DD HH:mm:ss')),
													end_time: moment(moment(endD).format('YYYY-MM-DD HH:mm:ss')),
													title: matchingTemplate.name || '',
													group: userId,
													companyId: company.get.id ,
													icon,
													type: checkType(matchingTemplate.type),
													row: excelMapping,
													itemProps: {
														className: 'fw-bold text-dark',
														style: {
															backgroundColor: bgColor,
														},
													},
												};

												

												const isCheckTime = [...items]?.find( 
													(v) =>
														v.group === userId &&
														v.type === (checkType(matchingTemplate.type)) &&
														((moment(v['start_time']) >= moment(addData.start_time) && moment(v['start_time']) <= moment(addData.start_time)) ||
															(moment(v['end_time']) >= moment(addData.end_time) && moment(v['end_time']) <= moment(addData.end_time)))
												);						
												if(!isCheckTime?.id)			
													newItems.push(addData);
												// console.log('CHECK >>>', userId,  items, isCheckTime);
											}
										}
									});
								} catch (e) {
									console.log('check templete error', e);
									// continue;
								}
							});
						});
					}
				}
			}
			if(newItems.length > 0) {
				setItems([...items, ...newItems]) 
				// const existingLocalItems = JSON.parse(getItem("plannedWorkLocal")) || []; 
				// let concatenatedArray = existingLocalItems.concat(newItems);
				// setItem('plannedWorkLocal-' + company.get.id, JSON.stringify(concatenatedArray));
			}


			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.excelItems]); 
 
	// useEffect(() => {

	// 	window.addEventListener('keydown', handleKeyDown);
	// 	window.addEventListener('keyup', handleKeyUp);
	// 	// window.addEventListener('mousedown', handleMouseClick);
	// 	// console.log('add evnet E', window);
	// 	return () => {
	// 		// console.log('remove evnet D', window);
	// 		window.removeEventListener('keydown', handleKeyDown);
	// 		window.removeEventListener('keyup', handleKeyUp);
	// 		// window.removeEventListener('mousedown', handleMouseClick);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [templates]);

	useEffect(() => { 
		// console.log('props?.isModal', props?.isModal);
		if (props?.isModal == true) {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
			// window.removeEventListener('mousedown', handleMouseClick);
			// console.log('remove evnet B', window);
		}
		 else {
			window.addEventListener('keydown', handleKeyDown);
			window.addEventListener('keyup', handleKeyUp);
			// window.addEventListener('mousedown', handleMouseClick);
			// console.log('set evnet B', window);
		}
		return () => {
			// console.log('remove evnet C');
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
			// window.removeEventListener('mousedown', handleMouseClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.isModal]);

	// useEffect(() => {
	// 	console.log('defaultTimeStart', moment(defaultTimeStart).format('YYYY-MM-DD'));
	// 	console.log('defaultTimeEnd', moment(defaultTimeEnd).format('YYYY-MM-DD'));
	// 	console.log('timelineRef', timelineRef?.current);

	// 	// if(timelineRef?.current)
	// 	// 	timelineRef?.current?.updateScrollCanvas(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf());

	// 	// if(timelineRef.current) {
	// 	// 	console.log(timelineRef.current)
	// 	// 	timelineRef.current.updatingItem();
	// 	// }
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [defaultTimeStart, defaultTimeEnd]);

	// useEffect(() => {
	// 	if (users.length > 0) {
	// 		let u = [...users];
	// 		console.log('>>', u);
	// 		if (rankSort === 'asc') {
	// 			u = u.sort((a, b) => {
	// 				return b.rank?.order - a.rank?.order;
	// 			});
	// 		} else {
	// 			u = u.sort((a, b) => {
	// 				return a.rank?.order - b.rank?.order;
	// 			});
	// 		}
	// 		setusers(u);
	// 		// console.log('>>', u)
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [rankSort]);
 
	const refreshItems = async () => {
		const defaultTimeStart = (selectionRange?.startDate ? moment(selectionRange?.startDate).valueOf() : moment().startOf('isoWeek')).valueOf();
		const defaultTimeEnd = (selectionRange?.endDate ? moment(selectionRange?.endDate).valueOf() : moment().endOf('isoWeek')).valueOf();
		const zoom = defaultTimeEnd - defaultTimeStart;

		setdefaultTimeStart(defaultTimeStart /*  - zoom */);
		setdefaultTimeEnd(defaultTimeEnd /*  - zoom */);

		// console.log('defaultTimeStart', defaultTimeStart);
		// console.log('defaultTimeEnd', defaultTimeEnd);

		setMaxZoom(86400 * 1000 * (moment(defaultTimeEnd).diff(moment(defaultTimeStart), 'days') + 1)); // 86400 * 1000 * 7

		WorkSchduleService.list({ companyId: company.get.id, sdate: moment(selectionRange.startDate).format('YYYY-MM-DD'), edate: moment(selectionRange.endDate).format('YYYY-MM-DD') }).then(
			(schdules) => {
				if (schdules?.data) {
					const items = (schdules?.data || [])?.map((item) => {
						return { ...item, start_time: moment(moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')), end_time: moment(moment(item.end_time).format('YYYY-MM-DD HH:mm:ss')) };
					});

					// const stored = JSON.parse(getItem('plannedWorkLocal-' + company.get.id));
					// if (stored !== null) {
					// 	const updatedItems = [...stored]
					// 	if (updatedItems.length > 0) {
					// 		let newFiltered = updatedItems.map((i) => {
					// 			return {
					// 				...i,
					// 				start_time: moment(moment(i.start_time).format('YYYY-MM-DD HH:mm:ss')), 
					// 				end_time: moment(moment(i.end_time).format('YYYY-MM-DD HH:mm:ss')), 
					// 			};
					// 		});
					// 		setItems([...items, ...newFiltered]);
					// 	}
					// } else {
						setItems(items);
					// }
				}
				// console.log('timelineRef', timelineRef);
				// if(timelineRef?.current)
				// 	timelineRef?.current?.updateScrollCanvas(moment(selectionRange.startDate).valueOf(), moment(selectionRange.endDate).valueOf())
				forceUpdate();
			}
		);

	}

	useEffect(() => {
		// console.log('>>>>>>>>>>>>>>>>>>>', selectionRange)
		const fetchData = async () => {
			props?.setTemplateLoading(true);
			await refresh();
			await refreshItems();
			props?.setTemplateLoading(false);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectionRange, forceUpdate]);

	const handleItemMove = useCallback(
		(itemId, dragTime, newGroupOrder) => {
			const group = newFilteredGroup[newGroupOrder];
			const thisItem = items?.find((item) => item.id === itemId);
			// if ((thisItem?.canMove || false) === true) {
			// console.log(group);
			if (thisItem?.start_time > moment().valueOf()) {
				const newItem = items?.map((item) =>
					item.id === itemId
						? Object.assign({}, item, {
								id: item.id.indexOf('row-') > -1 ? item.id.replace('row', 'modify') : item.id,
								start_time: dragTime,
								end_time: dragTime + (item.end_time - item.start_time),
								group: group.id,
						  })
						: item
				);
				// 점검

				setItems(newItem);

				// } else {
				// 	return false;
				// }
				setSelectItems({});
				// console.log('Moved', itemId, dragTime, newGroupOrder);
			}
		}, [newFilteredGroup, items]
	);

	const handleItemDrag = useCallback(
		({ eventType, itemId, time, edge, newGroupOrder }) => {
			const group = newFilteredGroup[newGroupOrder];
			// console.log('itemId', itemId);
			const thisItem = items?.find((item) => item.id === itemId);
			//{ item: item, group: this.state.groups[newGroupOrder], time }
			setSelectItems({ item: thisItem, time: moment(time), group: group });
		}, [newFilteredGroup, items]
	);

	const handleItemResize = useCallback(
		(itemId, time, edge) => {
			// console.log('Resized', itemId, time, edge);
			const thisItem = items?.find((item) => item.id === itemId);
			// console.log('handleItemResize', thisItem);
			if (thisItem?.start_time > moment().valueOf()) {
				if ((thisItem?.canResize || false) === true) {
					setItems(
						items?.map((item) =>
							item.id === itemId
								? Object.assign({}, item, {
										id: 'modify-' + item.id,
										start_time: edge === 'left' ? moment(time) : item.start_time,
										end_time: edge === 'left' ? item.end_time : moment(time),
								  })
								: item
						)
					);
				} else {
					// console.log('no resize');
					return false;
				}
				setSelectItems({});
				forceUpdate();
			}
		}, [items, forceUpdate]
	);

	const handleTimeChange = useCallback(
		(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
			// console.log(
			// 	'onTimeChange: ',
			// 	moment(visibleTimeStart).format('YYYY-MM-DD HH:mm:ss'),
			// 	moment(visibleTimeEnd).format('YYYY-MM-DD HH:mm:ss'),
			// 	moment(selectionRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
			// 	moment(selectionRange.endDate).format('YYYY-MM-DD HH:mm:ss')
			// );

			const minTime = moment(selectionRange.startDate).valueOf();
			const maxTime = moment(selectionRange.endDate).valueOf();

			let defaultTimeStart = '';
			let defaultTimeEnd = '';
			// setSelectionRange({
			// 	...selectionRange,
			// 	startDate: visibleTimeStart,
			// 	endDate: visibleTimeEnd
			// })

			// forceUpdate();
			// setdefaultTimeStart(visibleTimeStart);
			// setdefaultTimeEnd(visibleTimeEnd);

			if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
				// console.log('A', minTime, maxTime)
				// updateScrollCanvas(minTime, maxTime)
				defaultTimeStart = minTime;
				defaultTimeEnd = maxTime;
			} else if (visibleTimeStart < minTime) {
				// console.log('B', minTime, minTime + (visibleTimeEnd - visibleTimeStart))
				// updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
				defaultTimeStart = minTime;
				defaultTimeEnd = minTime + (visibleTimeEnd - visibleTimeStart);
			} else if (visibleTimeEnd > maxTime) {
				// console.log('C', maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
				// updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
				defaultTimeStart = maxTime - (visibleTimeEnd - visibleTimeStart);
				defaultTimeEnd = maxTime;
			} else {
				// console.log('D', visibleTimeStart, visibleTimeEnd)
				// updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
				defaultTimeStart = visibleTimeStart;
				defaultTimeEnd = visibleTimeEnd;
			}
			setdefaultTimeStart(defaultTimeStart);
			setdefaultTimeEnd(defaultTimeEnd);
			updateScrollCanvas(defaultTimeStart, defaultTimeEnd);

			//   setMaxZoom(86400 * 1000 * (moment(defaultTimeEnd).diff(moment(defaultTimeStart), 'days') + 1)); // 86400 * 1000 * 7
			// updateScrollCanvas(defaultTimeStart, defaultTimeEnd);
			forceUpdate();
			// console.log('check calendar', defaultTimeStart, defaultTimeEnd);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[selectionRange, forceUpdate]
	); // selectionRange

	const toggleGroup = useCallback(
		(id) => {
			setOpenGroups({
				...openGroups,
				[id]: !openGroups[id],
			});
	}, [openGroups]);


	const flattenTree = (tree=[], result = []) => {
		tree?.map((node) => {
			result.push(node);
			node?.users?.map((user) => {
				result.push({
					...user,
						isRoot: false,
						parent: node.id,
						title: user?.user?.name || '',
						name: user?.user?.name || '',
						id: user?.userId, //node.id + '-' + 
				});
			});
			// if (node?.users) {
			// 	flattenTree(node, result);
			// }
		});
		return result;
	};

	const [timer, setTimer] = useState(0); // 디바운싱 타이머
	const debounce = (e) => {
		const time = 1000;
		if (timer) {
			// console.log('clearTimeout');
			clearTimeout(timer);
		}
		if (e.target.value != '') {
			const newTimer = setTimeout(() => {
				// console.log('search', e.target.value);
				// console.log('users', users) // groups

				let newfilter = [...users]
				// .filter((g) => g.root || openGroups[g.parent])
				.map((group) => {
					// console.log('group > ', group)
					return Object.assign({}, group, {
						title: group.root ? (
							<div className='d-flex flex-row justify-content-between align-items-center' /* onClick={() => toggleGroup(group.id)} */ style={{ cursor: 'pointer' }}>
								<div className='text-truncate'>
									{/* {openGroups[group.id] ? <Icon icon='remove' color='dark' /> : <Icon icon='add' />} */}
									<span className='fw-bold fs-6'>{group.title}</span>
									{group?.workSystem?.name && <span className='small ms-2'>({group?.workSystem?.name || ''})</span>}
								</div>
								<div>
									<span className='fw-bold'>{(group?.users || []).length}</span> 명
								</div>
							</div>
						) : (
							<div>{group.title}</div>
						),
					});
				});
				if (selectedWorkGroup.name !== '전체') {
					newfilter = newfilter.filter((i) => i.parent === selectedWorkGroup.groupId || i.id === selectedWorkGroup.groupId);
				}

				const newGroups = [...newfilter]?.map((group) => {
					group.users = group.users.filter((user) => 
						user?.user?.name?.indexOf(e.target.value) > -1
						|| user?.companyNumber?.indexOf(e.target.value) > -1
						|| user?.employType?.indexOf(e.target.value) > -1
						|| user?.department?.department?.name?.indexOf(e.target.value) > -1
						|| user?.rank?.name?.indexOf(e.target.value) > -1

					);
					return group;
				});
		
				const sortedArray = flattenTree(newGroups);
				setNewFilteredGroup(sortedArray);
					
			}, time);
			setTimer(newTimer);
		} else {
			let newfilter = [...users]
			// .filter((g) => g.root || openGroups[g.parent])
			.map((group) => {
				// console.log('group > ', group)
				return Object.assign({}, group, {
					title: group.root ? (
						<div className='d-flex flex-row justify-content-between align-items-center' /* onClick={() => toggleGroup(group.id)} */ style={{ cursor: 'pointer' }}>
							<div className='text-truncate'>
								{/* {openGroups[group.id] ? <Icon icon='remove' color='dark' /> : <Icon icon='add' />} */}
								<span className='fw-bold fs-6'>{group.title}</span>
								{group?.workSystem?.name && <span className='small ms-2'>({group?.workSystem?.name || ''})</span>}
							</div>
							<div>
								<span className='fw-bold'>{(group?.users || []).length}</span> 명
							</div>
						</div>
					) : (
						<div>{group.title}</div>
					),
				});
			});

		// console.log('newfilter2', newfilter);

		if (selectedWorkGroup.name !== '전체') {
			newfilter = newfilter.filter((i) => i.parent === selectedWorkGroup.groupId || i.id === selectedWorkGroup.groupId);
		}

		const sortedArray = flattenTree(newfilter);
		// console.log('sortedArray', sortedArray);
		// console.log('newfilter', newfilter);
		setNewFilteredGroup(sortedArray);
		}
	};
	

	useEffect(() => {
		let newfilter = [...users]
			// .filter((g) => g.root || openGroups[g.parent])
			.map((group) => {
				// console.log('group > ', group)
				return Object.assign({}, group, {
					title: group.root ? (
						<div className='d-flex flex-row justify-content-between align-items-center' /* onClick={() => toggleGroup(group.id)} */ style={{ cursor: 'pointer' }}>
							<div className='text-truncate'>
								{/* {openGroups[group.id] ? <Icon icon='remove' color='dark' /> : <Icon icon='add' />} */}
								<span className='fw-bold fs-6'>{group.title}</span>
								{group?.workSystem?.name && <span className='small ms-2'>({group?.workSystem?.name || ''})</span>}
							</div>
							<div>
								<span className='fw-bold'>{(group?.users || []).length}</span> 명
							</div>
						</div>
					) : (
						<div>{group.title}</div>
					),
				});
			});

		// console.log('newfilter2', newfilter);

		if (selectedWorkGroup.name !== '전체') {
			newfilter = newfilter.filter((i) => i.parent === selectedWorkGroup.groupId || i.id === selectedWorkGroup.groupId);
		}

		const sortedArray = flattenTree(newfilter);
		// console.log('sortedArray', sortedArray);
		// console.log('newfilter', newfilter);
		setNewFilteredGroup(sortedArray);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openGroups, users, selectedWorkGroup]);
	// useEffect(()=> {
	// 	if(selectedWorkGroup.name === '전체') {
	// 		setUsersLength(newGroups.length);
	// 		setusers([...newGroups]);
	// 	} else {
	// 		let filter = [...users];
	// 		filter = filter.filter(i => !i.workSystem).length;

	// 		setUsersLength(filter);
	// 		let filteredUsers = newGroups?.filter(user => user.name === selectedWorkGroup.name || selectedWorkGroup?.id === user.parent);
	// 		setusers(filteredUsers);
	// 	}
	// 		// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	},[selectedWorkGroup, newGroups]);

	// 	useEffect(()=> {
	// 		let user = users?.filter(i => !i.workSystem).length || 0;
	// 		setFilteredUser(user);
	// 	},[users])

	const handleItemDrop = useCallback(
		({ data, start, groupKey }) => {
			// console.log('>> data >>', moment(start).format('YYYY-MM-DD'), data, groupKey);

			const thisItem = newFilteredGroup?.find((v) => v.id === groupKey);
			if (!thisItem) {
				return;
			}
			if (!thisItem?.userId) {
				return;
			}
			// console.log('>>>>>>>', thisItem)
			let item = [...items];

			const end = start + 1000 * 60 * 60 * 24;
			let startDay = moment(start).day();
			let endDay = moment(end).day();
			// work Time 이 있다면~
			if (data?.workTime[0][0] != '') {
				startDay = moment(`${moment(start).format('YYYY-MM-DD')} ${data?.workTime[0][0]}:00`);
				endDay = moment(
					`${moment(start)
						.add(data?.workTime[0][0] > data?.workTime[0][1] ? 1 : 0, 'd')
						.format('YYYY-MM-DD')} ${data?.workTime[0][1]}:00`
				);
				item.canResize = false;
			} else {
				if (data?.allDay?.use === true) {
					startDay = moment(start).startOf('d').format('YYYY-MM-DD HH:mm:ss');
					endDay = moment(start).endOf('d').format('YYYY-MM-DD HH:mm:ss');
					item.canResize = false;
				} else {
					startDay = moment(start).format('YYYY-MM-DD HH:mm:ss');
					endDay = moment(end).format('YYYY-MM-DD HH:mm:ss');
					item.canResize = true;
				}
			}

			// console.log('>>>>>>', data);

			const isCheckTime = [...items]?.find(
				(v) =>
					v.group === groupKey &&
					v.type === (checkType(data.type))  &&
					((moment(v['start_time']).format('YYYY-MM-DD') >= moment(startDay).format('YYYY-MM-DD') && moment(v['start_time']).format('YYYY-MM-DD') <= moment(startDay).format('YYYY-MM-DD')) ||
						(moment(v['end_time']).format('YYYY-MM-DD') >= moment(startDay).format('YYYY-MM-DD') && moment(v['end_time']).format('YYYY-MM-DD') <= moment(startDay).format('YYYY-MM-DD')))
			);
			// console.log(">>>", groupKey, isCheckTime)
			if (isCheckTime?.id) return;

			const mainColor = data?.color?.hex || '#5A6CFA';
			const bgColor = tinycolor(mainColor).lighten(30).toHexString();
			const textColor = getTextColorByBackgroundColor(mainColor);
			const icon = getIcon(data.type);

			// drop 내부정보 포함 하도록~


			let newAddItem = {
				id: `temp-${data.type.toLowerCase()}-${moment().valueOf()}`,
				start_time: moment(moment(startDay).format('YYYY-MM-DD HH:mm:ss')),
				end_time: moment(moment(endDay).format('YYYY-MM-DD HH:mm:ss')),
				group: parseInt(groupKey),
				companyId: company.get.id,
				//   group: parseInt(groupKey, 10) + 1,
				title: data?.name || '',
				icon,
				row: data,
				type: checkType(data.type),
				itemProps: {
					// 'data-tip': 'Drag & drop is working',
					className: `fw-bold text-dark`,
					style: {
						backgroundColor: bgColor,
					},
				}
			}

			setItems([
				...item,
				newAddItem
			]);


			// const existingLocalItems = JSON.parse(getItem("plannedWorkLocal")) || [];
			// existingLocalItems.push(newAddItem);
			// // setItem('plannedWorkLocal-' + company.get.id, JSON.stringify(existingLocalItems));

			forceUpdate();
		}, [newFilteredGroup, items, forceUpdate, company]
	);

	// const handleMouseClick = useCallback((e) => {
	// 	e.preventDefault();

	// 	// console.log('handleMouseClick', e);
	// 	// if(e?.ctrlKey === true) {
	// 	// 	console.log('Ctrl + Mouse Click', selectItem.current);
	// 	// 	// selectItem 에 따라뺴고 넣기
	// 	// 	selectItem.current.append = true;
	// 	// 	// if(selectItem.current && selectItem.current.items.length > 0) {
	// 	// 		// console.log('>>>>>', selectItem.current)
	// 	// 	// 	// 뺴고 넣기
	// 	// 	// 	if(selectItem?.current?.thisItem) {
	// 	// 	// 		const checkIndex = (selectItem?.current?.selectId || []).indexOf(selectItem?.current?.thisItem);
	// 	// 	// 		console.log(checkIndex)
	// 	// 	// 		if(checkIndex > -1) {
	// 	// 	// 			//	있으니 뺴자
	// 	// 	// 			selectItem?.current?.selectId.splice(checkIndex, 1);
	// 	// 	// 		} else {
	// 	// 	// 			selectItem.current.selectId = [...(selectItem?.current?.selectId || []), selectItem?.current?.thisItem];
	// 	// 	// 		}
	// 	// 	// 		// delete selectItem?.current?.thisItem;
	// 	// 	// 	}
	// 	// 	// 	console.log('+++', selectItem?.current)
	// 	// 	// 	// forceUpdate();
	// 	// 	// }

	// 	// }
	// }, []);

	const handleKeyUp = useCallback(
		(e) => {
			e.preventDefault();

			if(props?.isModal === true)
				return true;

			// console.log('handleKeyUp', e);
			if (e.keyCode === 17) {
				selectItem.current.append = false;
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectItem]); // selectItem

	const handleKeyDown = useCallback(
		(e) => {
			e.preventDefault();

			// console.log('handleKeyDown', selectItem?.current, e);
			// forceUpdate();

			if(props?.isModal === true)
			return true;

			if (e.keyCode === 17) {
				// Ctrl
				selectItem.current.append = true;
			}

			if (e.keyCode === 65 && e.ctrlKey === true) {
				//Ctrl + A
				// console.log('Ctrl + A?', selectItem);
				if (selectItem.current && selectItem.current.items.length > 0) {
					// console.log('>>>>>', timelineRef?.current?.state?.visibleTimeStart);

					selectItem.current = {
						// 보이는 범위까지만 선택되도록! (timelineRef.current.state.canvasTime 참조)
						selectId:
							selectItem?.current?.selectId?.length === selectItem?.current?.items?.length
								? []
								: selectItem.current.items
										?.filter(
											(item) =>
												moment(moment(item['start_time']).format('YYYY-MM-DD')).isBetween(
													moment(timelineRef?.current?.state?.visibleTimeStart).format('YYYY-MM-DD'),
													moment(timelineRef?.current?.state?.visibleTimeEnd).format('YYYY-MM-DD'),
													'day'
												) &&
												moment(moment(item['end_time']).format('YYYY-MM-DD')).isBetween(
													moment(timelineRef?.current?.state?.visibleTimeStart).format('YYYY-MM-DD'),
													moment(timelineRef?.current?.state?.visibleTimeEnd).format('YYYY-MM-DD'),
													'day'
												)
										)
										?.map((item) => item.id) || [],
						items: selectItem.current.items,
					};
				}
			} else if (e.keyCode === 9) {
				// tab
				setFilterType(filterType === 'work' ? 'rest' : 'work');
			} else if (e.keyCode === 17) {
				// Ctrl > mouse Event bind
				// console.log('bind...')
				// handleMouseClick.bind(this, e);
			} else if (selectTemplate?.id && (e.keyCode === 27/*  || e.keyCode === 46 */)) {
				// ESC
				setSelectTemplate({});
				selectItem.current.selectId = [];
			} else if ((selectItem?.current?.selectId?.length || 0) > 0 && (e.keyCode === 46 || e.keyCode === 8)) {
				// DEL, BACKSPACE EKY
				const item = selectItem?.current?.items || [];
				// console.log('>>', item, selectItem?.current?.selectId);

				selectItem?.current?.selectId?.map((id) => {
					const removeIndex = item.findIndex((item) => item.id === id);
					if (removeIndex > -1) {
						// if (item[removeIndex]?.start_time > moment().valueOf()) {
							if (item[removeIndex]?.id?.indexOf('row') > -1) {
								item[removeIndex] = {
									...item[removeIndex],
									id: id.replace('row', 'delete'),
								};
							} else if (item[removeIndex]?.id?.indexOf('modify') > -1) {
								item[removeIndex] = {
									...item[removeIndex],
									id: id.replace('modify', 'delete'),
								};
							} else {
								item.splice(removeIndex, 1);
							}
						// }
					}
				});
				setItems([...item]);
				selectItem.current = {};
			} else if (new RegExp('Digit([0-9])', 'i').test(e.code)) {
				// console.log('digit!!!!');
				const reg = new RegExp('Digit([0-9])', 'i').exec(e.code);
				// console.log('key>', reg[1])

				// 2023.11.09 단축키 지정
				const selectTemplate = templates?.find((v) => v.shortcutKey === parseInt(reg[1]));
				if (selectTemplate) {
					setSelectTemplate(selectTemplate);
				}

				// if (templates?.length > parseInt(reg[1]) - 1) {
				// 	// console.log('set templates[0]', templates[reg[1]-1])
				// 	setSelectTemplate(templates[reg[1] - 1]);
				// }
			} else {
				// console.log('no key event')
				return false;
			}
			// forceUpdate();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [templates, selectItem, selectTemplate, setSelectTemplate, filterType, setFilterType]
	); // templates, selectItem, selectTemplate

	// const AnimatedTimeline = animated(
	// 	({
	// 	  animatedVisibleTimeStart,
	// 	  animatedVisibleTimeEnd,
	// 	  visibleTimeStart,
	// 	  visibleTimeEnd,
	// 	  ...props
	// 	}) => (
	// 	  <Timeline
	// 		visibleTimeStart={animatedVisibleTimeStart}
	// 		visibleTimeEnd={animatedVisibleTimeEnd}
	// 		ref={timelineRef}
	// 		scrollRef={onScrollRef}
	// 		{...props}
	// 	  />
	// 	)
	//   );

	// 페이지 사이즈에 따라 크기 제어할것
	const [viewCount, setViewCount] = useState(9);
	const [startIndex, setStartIndex] = useState(0);

	
    useEffect(() => {
		// console.log('originalTemplates', originalTemplates);
		setTemplates(
			customSort(
				originalTemplates.filter((v) => (v.type === (filterType == 'work' ? 'WORK_SCHDULE' : 'REST_SCHDULE')) || (v.type === 'DAYOFF'))
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterType, originalTemplates]);

	useEffect(() => {
		// console.log('defaultTimeStart', defaultTimeStart, 'defaultTimeEnd', defaultTimeEnd, 'newFilteredGroup', newFilteredGroup);
		// console.log('items', items);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultTimeStart, defaultTimeEnd, newFilteredGroup, items])

	return (
		<>
			<div className='d-flex flex-row overflow-auto mx-4'>
				{[{ name: '전체', idx: 0, groupId: 'group-all', root: true }, ...users]
					?.filter((g) => g.root === true)
					?.map((btn, index) => {
						const isSelectedBtn = selectedWorkGroup.idx === index;
						return (
							<Button
								className='mx-2 text-nowrap'
								key={`workGroup-${index}`}
								rounded={3}
								style={{
									backgroundColor: isSelectedBtn ? '#E8ECFF' : 'white',
									color: isSelectedBtn ? '#556EE6' : 'black',
									borderWidth: 1,
									borderColor: isSelectedBtn ? null : '#D9D9D9',
								}}
								onClick={() => {
									setSelectedWorkGroup({
										name: btn.name,
										idx: index,
										groupId: btn?.id,
									});
								}}>
								{btn.name}
							</Button>
						);
					})}
			</div>
			<div className='mx-4 my-2 p-4' style={{ borderRadius: 7, backgroundColor: '#F6F6FA' }}>
				<span className='fw-bold'>{t(selectedWorkGroup?.name)} &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
				{/* {users
					.filter((g) => g.root || openGroups[g.parent])
					.map((group, index) => {
						return (
								<div key={index} className='d-flex flex-row justify-content-between align-items-center'>
									<div>
										<span className='fw-bold'>
											구성원 : 총 {(group?.users || []).length} 명
										</span>{' '}
									</div>
								</div>
							) 
						})
					} */}
				<span>{t(`구성원 : 총 ${0 || [...newFilteredGroup]?.filter((v) => v.userId)?.length}명`)}</span>
			</div>

			{/* 유형 탭 */}
			<div className='mx-4 my-2'>
				<ButtonGroup className='border border-0 border-bottom border-light px-3'>
					<Button
						type='button'
						className={filterType == 'work' ? 'border border-0 border-bottom border-info' : ''}
						rounded={0}
						onClick={() => {
							setFilterType('work');
						}}>
						근무
					</Button>
					<Button
						type='button'
						className={filterType == 'rest' ? 'border border-0 border-bottom border-info' : ''}
						rounded={0}
						onClick={() => {
							setFilterType('rest');
						}}>
						연차
					</Button>
				</ButtonGroup>
			</div>

			{templates.length > 0 ? (
				<div className='templeteBox'>
					{/* #TODO: overflow 쓰면 z-index 문제 있음.. 수정할것 overflow-scroll */}

					{/* 드래그용 스케줄 컴포넌트 영역 */}
					<div className='p-3 rounded border border-light mx-4 my-1'>
						<div className='d-flex flex-row align-items-center justify-content-between'>
							<div>
								{startIndex > 0 && (
									<Button
										type='button'
										color='light'
										icon='ChevronLeft'
										className='border-light me-3'
										rounded={1}
										isLight={true}
										onClick={() => {
											if (startIndex > 0) {
												setStartIndex(startIndex - 1);
											} else {
												setStartIndex(0);
											}
										}}
									/>
								)}
							</div>
							<div className='d-flex flex-row align-items-center'>
								{[...templates]?.splice(startIndex * viewCount, viewCount)?.map((template, templateIndex) => {
									const mainColor = template?.color?.hex || '#5A6CFA';
									const bgColor = tinycolor(mainColor).lighten(30).toHexString();
									const textColor = getTextColorByBackgroundColor(mainColor);
									const icon = getIcon(template?.type);
									const isSelect = selectTemplate?.id === template?.id;

									return (
										// 드래그용 스케줄 컴포넌트들
										<WorkingSchduleDraggable key={template?.id} handleItemDrop={handleItemDrop} timelineRef={timelineRef} scrollRef={scrollRef} data={{ ...template, icon }}>
											<div
												className={`btn text-nowrap d-flex flex-row align-items-center ${selectTemplate?.id === template?.id ? '' : ''} me-2 py-3 bg-white position-relative`}
												style={{
													borderRadius: 4,
													borderLeftColor: mainColor,
													borderLeftWidth: 3,
													borderTopColor: isSelect ? mainColor : '#D9D9D9',
													borderTopWidth: 1,
													borderRightColor: isSelect ? mainColor : '#D9D9D9',
													borderRightWidth: 1,
													borderBottomColor: isSelect ? mainColor : '#D9D9D9',
													borderBottomWidth: 1,
												}}>
												<Badge color={'success'} className='position-absolute' style={{ top: 4, right: 4 }}>
													{/* {templateIndex + 1} */}
													{template?.shortcutKey || ''}
												</Badge>
												<span
													className='d-flex'
													style={{ width: 34, height: 34, borderRadius: 4, backgroundColor: bgColor, alignItems: 'center', justifyContent: 'center' }}
													onClick={() => {
														if (isSelect) {
															setSelectTemplate({});
														} else {
															setSelectTemplate({ ...template, icon });
														}
													}}>
													<Icon icon={icon} size={'lg'} color={null} style={{ color: tinycolor(mainColor).darken(30).toHexString() }} />
												</span>
												<span
													className='fw-bold ms-2'
													onClick={() => {
														if (isSelect) {
															setSelectTemplate({});
														} else {
															setSelectTemplate({ ...template, icon });
														}
													}}>
													<div className='text-start lh-sm'>{template?.name || ''}</div>
													<div className='fw-light lh-sm text-start font-muted'>{template?.workTime[0][0] ? `${template?.workTime[0][0]} ~ ${template?.workTime[0][1]}` : '-'}</div>
												</span>
												{isSelect && selectTemplate.type !== 'DAYOFF' && (
													<Button
														type='button'
														size='sm'
														className='ms-3'
														style={{ color: mainColor }}
														onClick={() => {
															if (templeteRef?.current) {
																templeteRef?.current?.setFrom(template);
																// window.removeEventListener('keydown', handleKeyDown);
																// window.removeEventListener('keyup', handleKeyUp);
																// window.removeEventListener('mousedown', handleMouseClick);
																props.setIsModal(true);
															}
														}}>
														수정
													</Button>
												)}
											</div>
										</WorkingSchduleDraggable>
									);
								})}
							</div>
							<div>
								{(startIndex + 1) * viewCount < templates.length && (
									<Button
										type='button'
										color='light'
										icon='ChevronRight'
										className='border-light ms-3'
										rounded={1}
										isLight={true}
										onClick={() => {
											if ((startIndex + 1) * viewCount < templates.length) {
												setStartIndex(startIndex + 1);
											}
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='text-center my-3'>스케줄 템플릿이 없습니다.</div>
			)}
			{/* 스케줄 리스트 시작 */}
			<div className='p-3 d-flex flex-row justify-content-center align-items-center mt-2 row'>
				<div className='col-md-4'>
					{/* <Button type={'button'} color={'light'} icon='FilterList' className='border-light' rounded={1} isLight={true} onClick={() => {}}>
						필터
					</Button> */}
					{/* <Button
						type={'button'}
						color={'light'}
						icon={rankSort === 'desc' ? 'ArrowDownward' : 'ArrowUpward'}
						className='border-light ms-1'
						rounded={1}
						isLight={true}
						onClick={() => {
							setRankSort((r) => {
								return r == 'desc' ? 'asc' : 'desc';
							});
						}}>
						직위순
					</Button> */}
				</div>

				{/* 날짜선택 or 검색 영역 */}
				<div className='col-md-4 d-flex flex-row justify-content-center flex-grow-1 text-center'>
					<div className='border border-light rounded'>
						<Button
							className='border-0 border-end rounded-0 border-light p-0'
							type='button'
							icon='ChevronLeft'
							// isDisable={isDisabled}
							onClick={() => {
								setTempSelectionRange([
									{
										key: 'selection',
										startDate: moment(selectionRange.startDate).subtract(1, 'w').startOf('isoWeek').toDate(),
										endDate: moment(selectionRange.startDate).subtract(1, 'w').endOf('isoWeek').toDate(),
									},
								]);
								setSelectionRange({
									key: 'selection',
									startDate: moment(selectionRange.startDate).subtract(1, 'w').startOf('isoWeek').toDate(),
									endDate: moment(selectionRange.startDate).subtract(1, 'w').endOf('isoWeek').toDate(),
								});
							}}
						/>

						<Popovers
							id='selectDate'
							placement={'bottom'}
							ref={popRef}
							desc={
								<div className='row' /* ref={popRef} */>
									<div className='col-xl-12'>
										<Card className='shadow-none w-100 mb-0'>
											<CardBody className='px-0 pb-0 row'>
												<div className='col-sm-9 px-0'>
													<DateRange
														locale={locales['ko']}
														editableDateInputs={true}
														// dragSelectionEnabled={isDisabled}
														dateDisplayFormat='yyyy-MM-dd'
														ranges={tempSelectionRange}
														// maxDate={endOfISOWeek(new Date())}
														// showSelectionPreview={true}
														moveRangeOnFirstSelection={false}
														// retainEndDateOnFirstSelection={false}
														className='d-flex'
														// onRangeFocusChange={(e)=>{
														// 	console.log(e)
														// 	// setIsCalendar(e[1] === 1)
														// }}
														onChange={(item) => {
															// console.log('onChange > ', item);
															// setSelectionRange(item.selection);
															setTempSelectionRange([item.selection]);
														}}
														months={2}
														direction='horizontal'
														//scroll={{enabled: true}}
													/>
												</div>
											</CardBody>
											<CardFooter className='py-0 my-0'>
												<CardFooterRight>
													<Button
														type='button'
														color='success'
														onClick={() => {
															setSelectionRange({
																startDate: moment(tempSelectionRange[0].startDate).valueOf(),
																endDate: moment(tempSelectionRange[0].endDate).hour(23).minute(59).second(59).valueOf(),
															});
															if (popRef.current) popRef.current.onOpen();
														}}>
														{t('조회')}
													</Button>
												</CardFooterRight>
											</CardFooter>
										</Card>
									</div>
								</div>
							}
							// placement='bottom-end'
							className='mw-100'
							trigger='click'>
							<span className='px-5 text-dark fw-bold' style={{ cursor: 'pointer' }}>
								{`${moment(selectionRange.startDate).format('YYYY.M.D')} ~ ${moment(selectionRange.endDate).format(
									`${moment(selectionRange.startDate).format('YYYY') === moment(selectionRange.endDate).format('YYYY') ? '' : 'YYYY.'}M.D`
								)}`}
							</span>
						</Popovers>

						<Button
							className='border-0 border-start rounded-0 border-light p-0'
							type='button'
							icon='ChevronRight'
							// isDisable={isDisabled}
							onClick={() => {
								setTempSelectionRange([
									{
										key: 'selection',
										startDate: moment(selectionRange.endDate).add(1, 'w').startOf('isoWeek').toDate(),
										endDate: moment(selectionRange.endDate).add(1, 'w').endOf('isoWeek').toDate(),
									},
								]);
								setSelectionRange({
									key: 'selection',
									startDate: moment(selectionRange.endDate).add(1, 'w').startOf('isoWeek').toDate(),
									endDate: moment(selectionRange.endDate).add(1, 'w').endOf('isoWeek').toDate(),
								});
							}}
						/>
					</div>
					<Button
						type='button'
						className='border border-light ms-2 text-dark fw-bold'
						color='light'
						// isOutline
						isLight
						// icon='CalendarCheck'
						size='sm'
						onClick={() => {
							setTempSelectionRange([
								{
									key: 'selection',
									startDate: moment().startOf('isoWeek').toDate(),
									endDate: moment().endOf('isoWeek').toDate(),
								},
							]);
							setSelectionRange({
								key: 'selection',
								startDate: moment().startOf('isoWeek').toDate(),
								endDate: moment().endOf('isoWeek').toDate(),
							});
							// setTempSelectionRange([
							// 	{
							// 		key: 'selection',
							// 		startDate: moment().startOf('isoWeek').toDate(),
							// 		endDate: moment().endOf('isoWeek').toDate(),
							// 	},
							// ]);
						}}>
						{t('오늘')}
					</Button>
				</div>
				<div className='col-md-4 d-flex justify-content-end'>
					<div className='d-flex flex-row justify-content-center align-items-center border border-light px-2 bg-l75-light' style={{ borderRadius: 8 }}>
						<Icon icon='Search' size='lg' />
						<Input
							id='scheduleSearch'
							type='text'
							className='border-0 bg-l75-light rounded-0 w-auto'
							placeholder={'검색'}
							// value={searchText}
							onFocus={(e) => {
								// console.log('onFocus', e);
								window.removeEventListener('keydown', handleKeyDown);
								window.removeEventListener('keyup', handleKeyUp);
							}}
							onBlur={(e) => {
								//  console.log('onBlur', e);
								 window.addEventListener('keydown', handleKeyDown);
								 window.addEventListener('keyup', handleKeyUp);
							}}							
							
							// onInput={(e) => {
							// 	console.log('onInput', e);
							// }}
							onChange={(e) => {
								// setSearchText(e.target.value);
								debounce(e);
							}}
						/>
					</div>
				</div>
			</div>

			{/* {selectColumns && (
				<div className='p-3 d-flex flex-row justify-content-start align-items-center mt-2'>
					<ButtonGroup>
						<Button type='button' onClick={() => {}}>
							반복 입력
						</Button>
						<Button type='button' onClick={() => {}}>
							조별 근무 적용
						</Button>
					</ButtonGroup>
				</div>
			)} */}

			{/* <Spring
				to={{
					animatedVisibleTimeStart: defaultTimeStart,
					animatedVisibleTimeEnd: defaultTimeEnd,
				}}>
				{(value) => (
					<AnimatedTimeline */}

			{/* 스케줄 테이블 영역 */}
			<div className='react-calendar-container'>
				{newFilteredGroup.length > 0 && (
					<Timeline
						// ref={(ref) => {
						// 	props.setRef(ref);
						// }}
						ref={timelineRef}
						// traditionalZoom={true}
						scrollRef={onScrollRef}
						groups={newFilteredGroup}
						items={items}
						className='border-0'
						resizeDetector={containerResizeDetector}
						minZoom={60 * 60 * 1000 * 24} // 1 day
						maxZoom={maxZoom} // 1 week
						fixedHeader='fixed'
						// onZoom={(timelineContext, unit) => {
						// 	console.log('onZOom', timelineContext, unit);
						// }}
						// onBoundsChange={(defaultTimeStart, defaultTimeEnd)}
						visibleTimeStart={defaultTimeStart}
						visibleTimeEnd={defaultTimeEnd}
						// defaultTimeStart={defaultTimeStart}
						// defaultTimeEnd={defaultTimeEnd}
						onTimeChange={handleTimeChange}
						horizontalLineClassNamesForGroup={(group) => {
							// 세로
							// console.log('horizontalLineClassNamesForGroup', group);
							let classes = [];

							if (!group?.userId) {
								classes.push('bg-none border-0');
							}

							// if (group.userId === selectColumns) {
							// 	classes.push('bg-l25-danger');
							// }

							return classes;
						}}
						verticalLineClassNamesForTime={(timeStart, timeEnd) => {
							const currentTimeStart = moment(timeStart);
							const currentTimeEnd = moment(timeEnd);

							let classes = [];
							// 가로

							// if (defaultTimeStart > currentTimeStart || defaultTimeEnd < currentTimeEnd) {
							// 	classes.push('bg-light');
							// }

							// 휴일 넣기

							return classes;
						}}
						// traditionalZoom={true}
						// canMove={true}
						// canChangeGroup={true}
						itemHeightRatio={0.7}
						lineHeight={52}
						sidebarWidth={200}
						// sidebarContent={() => {
						// 	return <>asdasdasdasdasdasd</>;
						// }}
						// 테이블 셀 헤더(세로)
						groupRenderer={({ group }) => {
							// console.log('render group ??', group);
							// const timeInfos = group?.user ? getSchduleTime(group) : null;

							return group?.user ? <RenderGroupUser selectionRange={selectionRange} users={newFilteredGroup} group={group} selectColumns={selectColumns} items={items} /> : <div>{group.title}</div>;
						}}
						// 테이블 셀 아이템
						itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
							// console.log('render item', item);

							if (item?.id.indexOf('delete') > -1) return;

							const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

							// 1.restAuto true 일 경우에는 휴게시간 없음
							// 2. restAuto false 일 경우에는 휴게시간 있는데,
							//    1) restTime 값 있으면, 해당요일과 동일할때 휴게시간 제외해주기
							//    2) restTime 값 없으면, 자동 휴게 부여
							// console.log('itemffff', item)
							let restTimeSeconds = 0;
							if(!item?.row.restAuto) { // 휴식버튼 비활성화 (자동)
								if(item.title === '탄력근무' || item.title === '시차출퇴근'){
									if(item.row?.group?.restTime?.length > 0 && item.row?.group?.restTime[moment(item.start_time).format('E')-1][0][0] !== "") {
									// 휴게시간 지정 2022.09.13ㅅ
										item.row?.group?.restTime[moment(item.start_time).format('E')-1].map( times => {
											// console.log(">>> ", times)
											if(times[0] !== '') {
												// 일 시작시간 종료시간
												const stime = moment(item.start_time).format('HH:mm');
												const etime = moment(item.end_time).format('HH:mm');

													let restInWorkTime =
														(
															moment(moment(stime, 'HH:mm')).isSame(moment(times[0], 'HH:mm')) ||
															moment(moment(times[0], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
														) &&
														(
															moment(moment(etime, 'HH:mm')).isSame(moment(times[1], 'HH:mm')) ||
															moment(moment(times[1], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
														);

													restTimeSeconds = restInWorkTime ? moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'seconds') : 0;
													// console.log('restTimeSeconds', restTimeSeconds)
											} else {
													// console.log('휴게시간 자동 계산', moment(item.start_time).format('E'));
											}
										})
									}
								} else {
									if(item.row?.restTime?.length > 0 && item.row?.restTime[moment(item.start_time).format('E')-1][0][0] !== "") {
										// 특정 휴게시간 지정 2022.09.13
											item.row?.restTime[moment(item.start_time).format('E')-1].map( times => {
												// console.log(">>> ", times)
												if(times[0] !== '') {
												
													// 일 시작시간 종료시간
													const stime = moment(item.start_time).format('HH:mm');
													const etime = moment(item.end_time).format('HH:mm');
	
														let restInWorkTime =
															(
																moment(moment(stime, 'HH:mm')).isSame(moment(times[0], 'HH:mm')) ||
																moment(moment(times[0], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
															) &&
															(
																moment(moment(etime, 'HH:mm')).isSame(moment(times[1], 'HH:mm')) ||
																moment(moment(times[1], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
															);
															
														restTimeSeconds = restInWorkTime ? moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'seconds') : 0;
												} else {
														// console.log('휴게시간 자동 계산', moment(item.start_time).format('E'));
												}
											})
											// console.log("휴게시간", restTimeMinutes)
										} else {
											// 근무시간 기준 휴게시간 지정

											let startInData = moment(item.start_time.format('YYYY-MM-DD HH:mm'));
											let endInData = moment(item.end_time.format('YYYY-MM-DD HH:mm'));

											let durationInSeconds = moment.duration(endInData.diff(startInData)).asSeconds();

											let restRealTimeMinutes = durationInSeconds;
											let restTimeMinutes = 0;

											restTimeSeconds =
												Math.floor(
												(parseInt(restRealTimeMinutes)) /
													(270 * 60)
												) *
												(30 * 60);
												// console.log('restTimeSeconds >>', restTimeSeconds)
										}
								}
							} 
							let workTime = moment(item.end_time).diff(moment(item.start_time), 'second');

							const time = workTime - restTimeSeconds ;
							// console.log('time', time)

							const isSelectItem = selectItem.current?.selectId?.indexOf(item.id) > -1 ? true : false;
							const mainColor = item?.itemProps?.style?.backgroundColor || '#5A6CFA';
							const bgColor = isSelectItem === true ? tinycolor(mainColor).darken(50).toHexString() : tinycolor(mainColor).lighten(50).toHexString(); //tinycolor(mainColor).lighten(30).toHexString();
							const textColor = getTextColorByBackgroundColor(bgColor);
							const icon = getIcon(item.type);

							// itemContext.widh 에 따라 코드 혹은 이름 출력

							//style={{borderColor: itemContext.selected ? tinycolor(mainColor).darken(30).toHexString() : null}}
							const itemProps = {
								...item.itemProps,
								// bgColor: mainColor,
								// selectedBgColor: mainColor,
								style: {
									// tinycolor(mainColor).darken(1).toHexString()
									...item.itemProps.style,
									background: isSelectItem === true ? tinycolor(mainColor).darken(30).toHexString() : mainColor,
									border: 0, //`${(isSelectItem == true) ? 1 : 0}px solid ${(isSelectItem == true) ? "#00D084"/* tinycolor(mainColor).darken(100).toHexString() */ : mainColor}`,
									BorderWidth: 0, //`${(isSelectItem == true) ? 1 : 0}px ${(isSelectItem == true) ? 1 : 0}px`,
									// borderStyle: 'dashed',
									// borderColor: (isSelectItem == true) ? tinycolor(mainColor).darken(100).toHexString() : mainColor,
									// BorderWidth: (isSelectItem == true) ? 1 : 0,
								},
							};

							// console.log('itemProps', itemProps);
							// console.log('===', item.id, ' RENDER', item);

							return (
								// 테이블 컨텐츠
								// 최상단에 ".error-schedule-item" class추가시 오류라벨 디자인 변경
								<div {...getItemProps(itemProps)}>
									{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
									<div
										className={`rct-item-content w-100 ${item.id.indexOf('temp-') > -1 ? 'border-dark border-1' : ''}`}
										style={{ maxHeight: `${itemContext.dimensions.height}`, opacity: item.id.indexOf('temp-') > -1 ? 0.5 : 1, borderStyle: item.id.indexOf('temp-') > -1 ? 'dashed' : 'none' }}>
										<div className={`d-flex justify-content-${itemContext.width > 100 ? 'between ms-2' : 'center'}`}>
											<div>
												<>
													{itemContext.width > 24 && (
														<span className='bg-white px-1 py-1 me-2' style={{ borderRadius: 4 }}>
															<Icon icon={item?.icon || icon} color='info' size='sm' />
														</span>
													)}
													{itemContext.width > 100 ? (
														<>
															<span className={`schedule-text fw-bold text-${textColor}`}>{item.title}</span>
														</>
													) : (
														<span className={`schedule-text fw-bold text-${textColor}`}>{item?.row?.code || item.title}</span>
													)}
												</>
											</div>
											{itemContext.width > 100 && (
												<div className='me-2'>
													{item?.row?.type?.indexOf('REST') === -1 && item?.row?.type?.indexOf('DAYOFF') === -1 && (
														<span className='fw-bold border px-2 py-1 border-light small bg-white me-1' style={{ borderRadius: 4 }}>
															{convertSeconde2HourMinute2(time || '0')}
														</span>
													)}
													<Popovers trigger={'hover'} desc={item?.row?.memo || ''}>
														<Icon icon='info' size='lg' color={textColor} />
													</Popovers>
												</div>
											)}
										</div>
									</div>

									<div
										className='text-nowrap px-2 py-0 d-flex flex-column justify-content-start align-items-start'
										style={{
											position: 'absolute',
											top: '50%',
											right: '-5px',
											transform: 'translate(100%, -50%)',
											// background: 'white',
											// borderRadius: 8,
											borderLeft:'2px solid #ccc',
											zIndex: 85,
										}}
									>
										<p className='mb-0 lh-base'>
											<span className='d-inline-block text-black-50'>주간</span><span
											className='d-inline-block text-center' style={{width: '50px'}}>12:30</span>
											<span className='d-inline-block text-black-50'>야간</span><span
											className='d-inline-block text-center' style={{width: '50px'}}>-</span>
										</p>
										<p className='mb-0 lh-base'>
											<span className='d-inline-block text-black-50'>연장</span><span
											className='d-inline-block text-center' style={{width: '50px'}}>12:30</span>
											<span className='d-inline-block text-black-50'>연장</span><span
											className='d-inline-block text-center' style={{width: '50px'}}>11:00</span>
										</p>

									</div>

									{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}

									{/* 드래그시 나타나는 */}
									{selectItems?.item?.id && selectItems?.item?.id === item?.id && (
										<div
											className='text-nowrap p-3 border border-light d-flex flex-column justify-content-start align-items-start'
											style={{
												position: 'absolute',
												top: 50,
												background: 'white',
												borderRadius: 8,
												zIndex: 85,
											}}>
											<span className='fw-bold fs-5 lh-2'>{selectItems?.item?.title || ''}</span>
											{/* <span>{selectItems?.group?.title || ''}</span>									 */}
											<span className='lh-1'>{moment(selectItems.time).format('YYYY.MM.DD HH:mm')}</span>
										</div>
									)}
								</div>
							);
						}}
						// visibleTimeStart={moment().startOf('month')}
						// visibleTimeEnd={moment().endOf('month')}
						showCursorLine={true}
						stackItems={true}
						// fullUpdate
						itemTouchSendsClick={false}
						useResizeHandle={true}
						// sidebarContent={<>asdas</>}
						// sidebarWidth={150}
						canResize={'both'}
						timeSteps={{
							second: 60,
							minute: 1,
							hour: 1,
							day: 1,
							month: 1,
							year: 1,
						}}
						onItemMove={handleItemMove}
						onItemResize={handleItemResize}
						onItemDrag={handleItemDrag}
						onItemSelect={(itemId, e, time) => {
							// const item = items?.find((v) => v?.id === itemId);
							// console.log('onItemSelect', itemId, item, moment().valueOf());
							// if (item?.start_time > moment().valueOf()) {
							setSelectTemplate({});

							const items = selectItem?.current?.selectId || [];
							const removeIndex = items.indexOf(itemId);
							if ((selectItem?.current?.append || false) === true) {
								if (removeIndex > -1) {
									items.splice(removeIndex, 1);
									selectItem.current = {
										...selectItem.current,
										selectId: items,
										thisItem: itemId,
									};
								} else {
									selectItem.current = {
										...selectItem.current,
										selectId: [...new Set([...(selectItem?.current?.selectId || []), itemId])],
										// selectId: [...new Set(item)],
										thisItem: itemId,
										// append: false
									};
								}
							} else {
								selectItem.current = {
									...selectItem.current,
									selectId: removeIndex > -1 ? [] : [itemId],
									// selectId: [...new Set(item)],
									thisItem: itemId,
									// append: false
								};
							}
							forceUpdate();
							// }
							// console.log('>>>>>>', selectItem?.current?.append || false);

							// console.log('after > ', selectItem.current);

							// handleMouseClick.bind(this, e, itemId);

							// 	selectItem.current = {
							// 		selectId: [itemId],
							// 		items: items
							// 	}
							// } else {

							// }
						}}
						onItemClick={(itemId, e) => {
							// const item = items?.find((v) => v?.id === itemId);
							// console.log('onItemClick', itemId, item?.start_time, moment().valueOf());
							// if (item?.start_time > moment().valueOf()) {
							setSelectTemplate({});

							const items = selectItem?.current?.selectId || [];
							const removeIndex = items.indexOf(itemId);
							if ((selectItem?.current?.append || false) === true) {
								if (removeIndex > -1) {
									items.splice(removeIndex, 1);
									selectItem.current = {
										...selectItem.current,
										selectId: items,
										thisItem: itemId,
									};
								} else {
									selectItem.current = {
										...selectItem.current,
										selectId: [...new Set([...(selectItem?.current?.selectId || []), itemId])],
										// selectId: [...new Set(item)],
										thisItem: itemId,
										// append: false
									};
								}
							} else {
								selectItem.current = {
									...selectItem.current,
									selectId: removeIndex > -1 ? [] : [itemId],
									// selectId: [...new Set(item)],
									thisItem: itemId,
									// append: false
								};
							}
							forceUpdate();
							// }
							// console.log('after > ', selectItem.current);
						}}
						onCanvasClick={(groupId, time, e) => {
							// console.log('e', moment(time).format('YYYY-MM-DD'), selectTemplate, groupId, newFilteredGroup, users, items);

							const thisItem = newFilteredGroup?.find((v) => v?.id === groupId);
							// console.log('thisItem', thisItem);
							if (!thisItem) {
								return;
							}
							if (!thisItem?.userId) {
								return;
							}

							if (selectTemplate?.id) {
								const end = time + 1000 * 60 * 60 * 24;
								let startDay = moment(time).day();
								let endDay = moment(time).day();
								let item = [...items];

								if (selectTemplate?.workTime && selectTemplate?.workTime[0][0] != '') {
									startDay = moment(`${moment(time).format('YYYY-MM-DD')} ${selectTemplate?.workTime[0][0]}:00`);
									endDay = moment(
										`${moment(time)
											.add(selectTemplate?.workTime[0][0] > selectTemplate?.workTime[0][1] ? 1 : 0, 'd')
											.format('YYYY-MM-DD')} ${selectTemplate?.workTime[0][1]}:00`
									);
									item.canResize = false;
								} else {
									if (selectTemplate?.allDay?.use === true) {
										startDay = moment(time).startOf('d').format('YYYY-MM-DD HH:mm:ss');
										endDay = moment(time).endOf('d').format('YYYY-MM-DD HH:mm:ss');
										item.canResize = false;
									} else {
										startDay = moment(time).format('YYYY-MM-DD HH:mm:ss');
										endDay = moment(end).format('YYYY-MM-DD HH:mm:ss');
										item.canResize = true;
									}
								}

								// 해당일자에 겹치는 시간대 있는가?
								const isCheckTime = [...items]?.find(
									(v) =>
										v.group === groupId &&
										v.type === checkType(selectTemplate.type) &&
										((moment(v['start_time']) >= moment(startDay) && moment(v['start_time']) <= moment(startDay)) ||
											(moment(v['end_time']) >= moment(endDay) && moment(v['end_time']) <= moment(endDay)))
								);
								// console.log('>>>', groupId, isCheckTime);
								if (isCheckTime?.id) return;

								const mainColor = selectTemplate?.color?.hex || '#5A6CFA';
								const bgColor = tinycolor(mainColor).lighten(30).toHexString();
								const textColor = getTextColorByBackgroundColor(mainColor);
								const icon = selectTemplate.type === 'WORK' || selectTemplate.type === 'WORK_SCHDULE' ? 'DateRange' : 'FlightTakeoff';

								// drop 내부정보 포함 하도록~
								// 클릭시 itmes 에 추가
								// selectData 에 타임값에 따라 자동 시간 부여
								// resize 옵션은 근무에 따라 변동
								// console.log('????????????????????????????')
								setItems([
									...item,
									{
										id: `temp-${(selectTemplate?.type).toLowerCase()}-${moment().valueOf()}`,
										start_time: moment(moment(startDay).format('YYYY-MM-DD HH:mm:ss')),
										end_time: moment(moment(endDay).format('YYYY-MM-DD HH:mm:ss')),
										group: parseInt(groupId),
										//   group: parseInt(groupKey, 10) + 1,
										title: selectTemplate?.name || '',
										icon,
										row: selectTemplate,
										type: checkType(selectTemplate?.type),
										itemProps: {
											// 'data-tip': 'Drag & drop is working',
											className: `fw-bold text-dark`,
											style: {
												backgroundColor: bgColor,
												background: bgColor,
											},
										},
									},
								]);
								forceUpdate();
							} else {
								// console.log('cluck???', groupId, time);
								if (selectColumns === groupId) setSelectColumns(null);
								else setSelectColumns(groupId);
							}

							// 	console.log('canves', groupId, time);
							// 	// 템플릿 클릭 후 클릭시 아이템 적용
							// 	// console.log('timelineRef', timelineRef)
							// 	if (selectTemplate?.workTime) {
							// 		if (selectTemplate?.workTime[0][0] !== '') {
							// 			setItems([
							// 				...items,
							// 				{
							// 					...selectTemplate,
							// 					id: items.length + 1,
							// 					itemProps: {
							// 						className: 'bg-light fw-bold border-0 text-dark',
							// 						style: {
							// 							backgroundColor: '#F1F4FF',
							// 						},
							// 					},
							// 					title: selectTemplate.name,
							// 					group: groupId,
							// 					start_time: moment(moment(time).format(`YYYY-MM-DD ${workTime[0][0]}:00`)),
							// 					end_time: moment(moment(time).format(`YYYY-MM-DD ${workTime[0][1]}:00`)),
							// 				},
							// 			]);
							// 		} else {
							// 			setItems([
							// 				...items,
							// 				{
							// 					...selectTemplate,
							// 					id: items.length + 1,
							// 					itemProps: {
							// 						className: 'bg-light fw-bold border-0 text-dark',
							// 						style: {
							// 							backgroundColor: '#F1F4FF',
							// 						},
							// 					},
							// 					title: selectTemplate.name,
							// 					group: groupId,
							// 					start_time: moment(time),
							// 					end_time: moment(time).add(8, 'h'),
							// 				},
							// 			]);
							// 		}
							// 	}
						}}
						onCanvasDoubleClick={(groupId, time, e) => {
							// 더블클릭시 팝업 // 근무등록? 팝업?
						}}
						onItemDoubleClick={(itemId, e) => {
							// 수정? 기간~
							// 확인 및 수정~
						}}
						// onCanvasContextMenu={({groupId, time, e})=>{
						// 	console.log('onCanvasContextMenu', groupId, time)
						// 	return (
						// 		<div>asdasdadsd</div>
						// 	)
						// }}
						// onTimeChange={(_start, _end, updateScrollCanvas) => {
						//     updateScrollCanvas(
						//         moment(defaultTimeStart).valueOf(),
						//         moment(defaultTimeEnd).valueOf(),
						//     );
						// }}
						buffer={3}

						// {...value}
					>
						<TimelineHeaders>
							{/* <DateHeader unit='year' /> */}
							{/* <DateHeader unit='month' height={50} /> */}
							{/* <DateHeader
							unit='day'						
							// labelFormat='MM/DD'
							// style={{ height: 50, width: 150 }}
							// data={headerDate}
							// intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
							// 	return (
							// 		<div {...getIntervalProps()}>
							// 			{intervalContext.intervalText}
							// 			{data}
							// 		</div>
							// 	);
							// }}
						/> */}
						{/* 테이블 헤더 */}
							<CustomHeader height={50} unit='day'>
								{({ headerContext: { intervals }, getRootProps, getIntervalProps, showPeriod, data }) => {
									return (
										<div {...getRootProps()}>
											{intervals.map((interval) => {
												// console.log('interval', interval);
												const holi = holiday?.find((h) => moment(h.date).format('YYYY-MM-DD') === moment(interval.startTime).format('YYYY-MM-DD'));
												// if (holi) console.log('>>>', holi);
												const intervalStyle = {
													// lineHeight: '50px',
													height: '50px',
													textAlign: 'center',
													justifyContent: 'center',
													alignItems: 'center',
													borderRight: '1px solid #e7e7e7',
													cursor: 'pointer',
													backgroundColor: 'white',
													// color: 'white',
												};
												return (
													// 테이블 헤더 셀 하나
													<div
														key={intervals.startTime}
														// onClick={() => {
														// 	showPeriod(interval.start_time, interval.end_time);
														// }}
														className='d-flex flex-column bg-white'
														{...getIntervalProps({
															interval,
															style: intervalStyle,
														})}>
														{/* 테이블 헤더 날짜 */}
														<div className={`fw-bold ${holi && 'text-danger'}`}>{interval.startTime.format('DD')}</div>
														{/* 테이블 헤더 요일 */}
														<div className={`small ${holi && 'text-danger'}`}>
															{interval.startTime.format('dd')} {holi && <span>({holi?.name || ''})</span>}
															{interval.startTime.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && <span className='small'>(오늘)</span>}
														</div>
													</div>
												);
											})}
										</div>
									);
								}}
							</CustomHeader>
							{/* <DateHeader unit='hour' /> */}

							{/* 테이블 확대하면 나오는 시간 부분 */}
							<CustomHeader height={25} unit='hour'>
								{({ headerContext: { intervals }, getRootProps, getIntervalProps, showPeriod, data }) => {
									// console.log('data', intervals.labelWidth)
									if (intervals[0]?.labelWidth > 15)
										return (
											<div {...getRootProps()}>
												{intervals.map((interval) => {
													const intervalStyle = {
														// lineHeight: '50px',
														height: '25px',
														textAlign: 'center',
														justifyContent: 'center',
														alignItems: 'center',
														borderRight: '1px solid #e7e7e7',
														borderTop: '1px solid #e7e7e7',
														cursor: 'pointer',
														backgroundColor: 'white',
														// color: 'white',
													};
													return (
														<div
															key={intervals.startTime}
															// onClick={() => {
															// 	showPeriod(interval.start_time, interval.end_time);
															// }}
															className='d-flex flex-column bg-white'
															{...getIntervalProps({
																interval,
																style: intervalStyle,
															})}>
															<div className='small'>{interval.startTime.format('HH')}</div>
														</div>
													);
												})}
											</div>
										);
									else return <></>;
								}}
							</CustomHeader>
						</TimelineHeaders>
						<TimelineMarkers>
							{/* 테이블에 커서 올리면 보이는 파란색 선 */}
							<CursorMarker>
								{({ date, styles }) => {
									// console.log('>>>>', styles)
									const customStyles = {
										...styles,
										backgroundColor: '#4d69fa',
										// backgroundImage: 'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
										// borderStyle: 'dashed',
										// borderWidth: 1,
										// borderColor: '#4d69fa',
										width: 1,
									};
									return <div className='cursorMarker' style={customStyles} />;
								}}
							</CursorMarker>
							{/* <TodayMarker>{({ styles, date }) => <div style={{ ...styles, backgroundColor: 'red' }} />}</TodayMarker> */}
							{/* <TimelineMarkers> */}
							{/* {items?.map((marker) => (
								<CustomMarker key={marker.id} date={Number(marker.date)}>
									{({ styles }) => {
										const customStyles = {
											...styles,
											background: `repeating-linear-gradient( 0deg, transparent, transparent 5px, white 5px, red 10px )`,
											width: '1px',
										};
										return <div style={customStyles} />;
									}}
								</CustomMarker>
							))} */}
							{/* </TimelineMarkers> */}
						</TimelineMarkers>
					</Timeline>
				)}
			</div>
			{/* 		</AnimatedTimeline>
				)}
			</Spring> */}
			<WorkScheduleTemplate isOpen={props?.isModal} setIsOpen={props?.setIsModal} refresh={refreshTemplete} selectTemplate={selectTemplate} ref={templeteRef} />
		</>
	);
});

export default WorkingScheduleComponents;
