import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Checks, { ChecksGroup } from "../../../../components/bootstrap/forms/Checks";
import { useMst } from "../../../../models";
import AlarmService from "../../../../services/AlarmService";

const NotificationSetting = () => {

    const { user, company } = useMst();
    const { t } = useTranslation(['translation', 'menu']);

    const [notificationList, setNotificationList] = useState([]);
    const originNotification = useRef({});
    const alarmKey = useRef([]);
    
    //const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    useEffect(() => {
        refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     console.log('list >> ',notificationList)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [notificationList]);

    const refresh = useCallback( async () => {
        await AlarmService.alarmSetting(user.id).then( res => {
            const alarm = res.data.alarm;
            const keys = Object.keys(alarm);
            originNotification.current = alarm;
            alarmKey.current = keys;
            const alarmObjectToArray = keys.map(key => [key, alarm[key]]);
            Object.values(alarm).includes(false)
                ? setNotificationList([['allAlert', false], ...alarmObjectToArray])
                : setNotificationList([['allAlert', true], ...alarmObjectToArray]);    
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const controllAlarmNRequest = useCallback( async props => {
        
        const propKey = props[0];
        const propBool = !props[1];
        const data = {
            userId: user.id,
            alarm: { ...originNotification.current },
        };

        if (propKey === 'allAlert') {
            alarmKey.current.map(key => {
                data.alarm[key] = propBool;
            });
        } else {
            data.alarm[propKey] = propBool;
        }

        await AlarmService.alarmSettingModify(data).then(res => {
            if(res.data)
                refresh();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, originNotification, alarmKey]);


    return (
        <ChecksGroup>
            {/* [2024-01-26] LS 출근 알림, 전체 알림 숨김 (출근을 엑셀업로드나 pc-off로 관리하고 있어서) */}
            {/*{notificationList?.map((item, i) => (*/}
            {notificationList?.filter(item => !['allAlert', 'attend'].includes(item[0]))?.map((item, i) => (
                <Checks
                    className={'mb-2 border border-1 border-dark'}
                    key={'alarm-'+i} 
                    label={t(item[0])} 
                    checked={item[1]} 
                    type={'switch'} // 'checkbox' || 'radio' || 
                    onChange={()=> controllAlarmNRequest(item)}/>
            ))}
        </ChecksGroup>
    );
}
export default React.memo(NotificationSetting);