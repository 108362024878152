import React, { useCallback, useEffect, useState } from 'react'
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/bootstrap/Modal";
import Button from "../../../../../components/bootstrap/Button";
import Label from "../../../../../components/bootstrap/forms/Label";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import Icon from "../../../../../components/icon/Icon";
import Select from '../../../../../components/bootstrap/forms/Select';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import CompanyService from "../../../../../services/CompanyService";
import showNotification from "../../../../../components/extras/showNotification";
import { useMst } from '../../../../../models';
import UserService from "../../../../../services/UserService";
import SortableTreeModal from "../../../Approval/components/SortableTreeModal";
import { checkMoment } from '../../../../../lib/Util';

const WorkRecodeCloseModal = ({ isOpen, setIsOpen, item, changeWorkTime, setIsMapModal, reFresh, setIsModal }) => {
  const { user, company } = useMst();
  const { t } = useTranslation(['translation', 'menu'])
  const [approvalList, setApprovalList] = useState([]);
  const [referenceModalOpen, setReferenceModalOpen] = useState(false);
  const [option, setOption] = useState({});
  const getApprovalLine = useCallback(async () => {
    await UserService.getApproval(company.get.id, { userId: user.id, departId: company.get?.departInfos[0]?.departId || '', option: 'up' }).then((res) => {
      setApprovalList(res?.data || []);
    });
  }, [user.id, company]);
  const restRequest = useFormik({
    initialValues: {
      id: 0,
      restType: '',
      cutOffTime: 0,
      isAllTime: true,
      companyId: company.get.id,
      type: 'REST',
      //dateRange: selectionRange,
      timeRange: ['', ''],
      startAt: '',
      endAt: '',
      etc: '',
      memo: '',
      approvailLine: [],
      option: {},
      isApproval: false,
      ccUsers: [],
    },
    validate: (values) => {
      const errors = {};

      if(values?.dateRange?.length === 0){
        errors.dateRange = t('날짜 선택은 필수입니다');
      }
      if(values?.restType === ''){
        errors.restType = t('휴가유형 선택은 필수입니다');
      }
      /* if (selectedTimeBtn.value > 0) {
        if(attendInfo?.requestWorkTime) {
          if (values.startAt !== '' && values.startAt < attendInfo?.requestWorkTime[0]) {
            errors.startAt = '근무시간 이외 시간은 입력할 수 없습니다';
          }
          if (values.endAt !== '' && values.endAt > attendInfo?.requestWorkTime[1]) {
            errors.endAt = '근무시간 이외 시간은 입력할 수 없습니다';
          }
        }
        if (moment(values.endAt, "HH:mm") < moment(values.startAt, "HH:mm")){
          errors.endAt = '종료시간은 시작시간보다 빠를 수 없습니다';
        }
      }
      */
      // if(values?.approvailLine?.length === 0){
      // 	errors.approvailLine = t('결재라인 선택은 필수입니다');
      // }

      // console.log(errors)
      return errors;
    },
    onSubmit: async (values) => {
      if (values.id === 0) delete values.id;

      // console.log('values >>',values)
      // if(parseFloat(remainingRest) <= 0){
      // 	showNotification(t('휴가신청'), t('잔여 연차를 확인해주세요'), 'danger');
      // } else {
      // if(!cutOffRestday)
      // 	delete values.cutOffTime

      // // endAt 제어..확인하세요..
      // if(values.endAt === 'Invalid date')
      // 	values.endAt = '';

      // if(remainingRest <= 0){
      // 	showNotification(t('휴가신청'), t('잔여휴가를 확인해주세요'), 'danger');
      // } else {
      const forms = new FormData();

      files?.length > 0 && console.log('files >>', files);

      files?.map( file => {
        forms.append('files', file);
      });

      forms.append('data', JSON.stringify(values));

      // return;
      // if(deleteFile?.id) {
      // 	const deleteF = await CompanyService.deleteFile(deleteFile?.id);
      // 	console.log('deleteF', deleteF)
      // }

      await CompanyService.restdayRequest(forms).then((res) => {
        if (res?.data) {
          props.setIsOpen(false);
          setOpenSuccessRequestModal(true);
          // console.log('res >> ', res?.data)
        } else {
          console.log(res);
          showNotification(t('휴가 신청'), t('휴가신청에 문제가 발생 했습니다.'), 'danger');
        }
      });
      // }
    },
  });

  const workRequest = useFormik({
    initialValues: {
      attendId: item.attendId || null,
			userId: item.userId,
			companyId: company.get.id,
			sdate: item?.id ? moment(item.dates.workStartTime).format('YYYY-MM-DD')
					: item?.history && item?.history[0]?.inputTime ? moment(item?.history[0]?.inputTime).format('YYYY-MM-DD') : item.stimeReal ? moment(item.stimeReal).format('YYYY-MM-DD') : moment(checkMoment(item.day[0]), 'YYYY-MM-DD (dd)').format('YYYY-MM-DD'),
			stime: item?.id ? moment(item.dates.workStartTime).format('HH:mm')
					: item?.history && item?.history[1]?.inputTime ?  moment(item?.history[0]?.inputTime).format('HH:mm') : item.stimeReal ? moment(item.stimeReal).format('HH:mm') : null,
			edate: item?.id ? moment(item.dates.workEndTime).format('YYYY-MM-DD')
					: item?.history && item?.history[1]?.inputTime ? moment(item?.history[1]?.inputTime).format('YYYY-MM-DD') : item.etimeReal ? moment(item.etimeReal).format('YYYY-MM-DD') : moment(checkMoment(item.day[0]), 'YYYY-MM-DD (dd)').format('YYYY-MM-DD'),
			etime: item?.id ? moment(item.dates.workEndTime).format('HH:mm')
					: item?.history && item?.history[1]?.inputTime ? moment(item?.history[1]?.inputTime).format('HH:mm')  : item.etimeReal ? moment(item.etimeReal).format('HH:mm') : null,
			sGps: item?.id ? item.gps && item.gps[0] && item.gps[0]?.coordinates ? {
				type: 's',
				lat: item.gps[0]?.coordinates[0] || null,
				lng: item.gps[0]?.coordinates[1] || null
			} : null : null,
			eGps: item?.id ? item.gps && item.gps[1] && item.gps[1]?.coordinates ? {
					type: 'e',
					lat: item.gps[1]?.coordinates[0] || null,
					lng: item.gps[1]?.coordinates[1] || null
			} : null : null,
      etc: !item?.id ? item.etc : '', // 신청 사유
      approvals: item.approvals || [], // 새 결재라인 정보
      option: option || {},
      approvailLine: [],
      ccUsers: item.ccUsers || [], // 참조자들
    },
    onSubmit: async(values) => {
      values.startTime = changeWorkTime[0]
      values.endTime = changeWorkTime[1]
      await CompanyService.modifyWorkRecordRequest4(values).then((response) => {
        if (response.code === 200) {
          showNotification(`근무기록 수정 승인요청`,`근무기록 수정 승인요청이 완료되었습니다`,'info')
          setIsModal(false);
          setIsMapModal(false);
          reFresh([
						{
							key: 'selection',
							startDate: moment(calendarDate[0]?.startDate)
								.toDate(),
							endDate: moment(calendarDate[0]?.endDate)
								.toDate(),
						},
					], 0, 'day');
        } else {
          showNotification(`근무기록 수정 승인요청`, response.message,'danger')
        }
      })
    }
  })

  const approvailLineUser = restRequest?.values?.approvailLine[0]?.line[0]?.user;

  const countApprovalUsers = restRequest?.values?.approvailLine
      ?.map((i) => i.line)
      .reduce((accumulator, currentArray) => {
        return accumulator + currentArray.length;
      }, 0);

  useEffect(() => {
    getApprovalLine();
  }, [getApprovalLine]);

  const newStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D9D9D9',
    backgroundColor: 'white',
    padding: '10px 10px 10px 10px',
    marginRight: 20,
    resize: 'none',
  };
  return (
    <Modal
      isCentered
      isStaticBackdrop
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      size={'md'}
    >
      <div className='modal-wraped mx-3'>
        <ModalHeader className='d-block'>
					<ModalTitle className='fw-bolder'>근무기록 수정</ModalTitle>
          <p className='mt-2'>{t('마감된 근무기록 수정은 승인이 필요합니다.')}</p>
				</ModalHeader>
        <ModalBody>
          <form>
            <div className='row'>
              <Label className='form-label col-form-label col-sm-4 ps-4 fw-bold text-dark'>
                {t('결재라인')}
                <span className='text-danger ms-1'>*</span>
              </Label>
              <div className='col-sm-8'>
                <Select
                  style={newStyle}
                  list={[{ id: 0, name: '' }, ...approvalList]?.map((v) => {
                    return { value: v.id, label: v.name };
                  })}
                  placeholder={t('결재라인을 선택해주세요')}
                  onChange={(e) => {
                    const approval = approvalList.find((v) => parseInt(v.id) === parseInt(e.target.value));
                    restRequest.setFieldValue('approvailLine', approval?.lines || approval?.line || []);
                    restRequest.setFieldValue('option', approval?.option || {});

                    workRequest.setFieldValue('approvals', approval?.lines || approval?.line || []);
									  workRequest.setFieldValue('option', approval?.option || {});
                    restRequest.setFieldValue('ccUsers', approval?.ccUsers || []);
									  setOption(approval?.option || {})
                  }}
				        />
              </div>
            </div>

            <div className='row'>
              <Label className='form-label col-form-label col-sm-4 ps-4 fw-bold text-dark' />
              <div className='col-sm-8'>

                {
                  restRequest?.values?.approvailLine?.length === 0 ?

                  // 결재라인을 선택하지 않았을때
                  <div className='text-danger mt-2'>
                    <Icon icon='InfoOutline' className='mx-2'/>
                    <span>{t('결재라인 선택은 필수입니다')+ '.'}</span>
                  </div> :

                  // 결재라인을 선택하였을때
                  <Button
                    style={{
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      backgroundColor: '#F8F9FA',
                      padding: '10px 10px 10px 10px',
                      marginRight: 20,
                      width: '100%',
                    }}
                    onClick={() => {
                      restRequest.setFieldValue('approvailLine', []);
                      setApprovalList([]);
                      getApprovalLine();
                    }}
                  >
                    <div className='d-flex justify-content-around align-items-center'>
                      <span className='text-muted'>{`${approvailLineUser?.name} ${approvailLineUser?.rank} ${countApprovalUsers > 1 ? '외 ' + (countApprovalUsers - 1) + ' 인' : ''}`}</span>
                      <Icon	icon='Cancel' size='lg' />
                    </div>
                  </Button>
                }
              </div>
            </div>

            <div className='row mt-4'>
              <Label className='form-label col-form-label col-sm-4 ps-4 fw-bold text-dark'>{t('참조')}</Label>
              <div className='col-sm-8'>
                <Button
                  style={{
                    borderRadius: 7,
                  }}
                  color='info'
                  isOutline
                  className='d-flex align-items-center'
                  onClick={() => {
                    setReferenceModalOpen(true);
                  }}
                >
                  <Icon icon='Add' size='lg' className='me-2'/>
                  <span>{t('추가하기')}</span>
                </Button>
              </div>
            </div>

            {/* 추가하기 버튼을 눌러 인원을 추가하였을때 */}
            <div className='row'>
              <Label className='form-label col-form-label col-sm-4 ps-4 fw-bold text-dark' />
              <div className='col-sm-8'>
                {(restRequest?.values?.ccUsers?.map((v, i) => {
                  return (
                      <Button
                          style={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: '#D9D9D9',
                            margin: 5,
                            backgroundColor: '#F8F9FA',
                          }}
                          key={`ccUsers-${i}`}
                          className='py-1 px-2'
                          onClick={() => {
                            let newCcUsers = [...restRequest?.values?.ccUsers];
                            newCcUsers.splice(restRequest?.values?.ccUsers[i], 1);

                            restRequest.setFieldValue('ccUsers', newCcUsers);
                          }}
                      >
                        <Icon icon='Visibility' size='md' style={{ color: 'grey' }} />
                        <span className='mx-3'>
                          {v.name} {restRequest.values.ccUsers.length - 1 > i}
                        </span>
                        <Icon icon='Close' size='md' />
                      </Button>
                    );
                  })
                )||(workRequest?.values?.ccUsers?.map((v, i) => {
                  return (
                      <Button
                          style={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: '#D9D9D9',
                            margin: 5,
                            backgroundColor: '#F8F9FA',
                          }}
                          key={`ccUsers-${i}`}
                          className='py-1 px-2'
                          onClick={() => {
                            let newCcUsers = [...workRequest?.values?.ccUsers];
                            newCcUsers.splice(workRequest?.values?.ccUsers[i], 1);

                            workRequest.setFieldValue('ccUsers', newCcUsers);
                          }}
                      >
                        <Icon icon='Visibility' size='md' style={{ color: 'grey' }} />
                        <span className='mx-3'>
                          {v.name} {workRequest.values.ccUsers.length - 1 > i}
                        </span>
                        <Icon icon='Close' size='md' />
                      </Button>
                    );
                  })
                )}
              </div>
            </div>

            <div className='row my-4'>
              <Label className='form-label col-form-label col-sm-4 ps-4 fw-bold text-dark'>{t('사유')}</Label>
              <div className='col-sm-8'>
                <Textarea
                  name={'etc'}
                  ariaLabel='With textarea'
                  placeholder={t('사유입력 (선택)')}
                  value={workRequest.values.etc ? workRequest.values.etc : ''}
								  onChange={workRequest.handleChange}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              borderRadius: 7,
              border: '1px solid #e6e7e7'
            }}
            onClick={() => setIsOpen(false)}
          >
            <span className='text-muted px-4'>{t('취소')}</span>
          </Button>
          <Button
            style={{
              borderRadius: 7,
            }}
            color='info'
            onClick={() => { 
              workRequest.handleSubmit();
              setIsOpen(false);
            }}
          >
            <span className='px-4'>{t('승인요청')}</span>
          </Button>
        </ModalFooter>
        <SortableTreeModal
            title={t('참조자 선택')}
            isOpen={referenceModalOpen}
            setIsOpen={setReferenceModalOpen}
            disabledList={restRequest?.values?.approvailLine || workRequest?.values?.approvailLine}
            list={restRequest.values.ccUsers || workRequest.values.ccUsers}
            setList={(list) => {
              restRequest.setFieldValue('ccUsers', list);
              workRequest.setFieldValue('ccUsers', list);
            }}
        />
      </div>
    </Modal>
  )
}

export default React.memo(WorkRecodeCloseModal)