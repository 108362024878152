import React, { useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import config from '../../config';
import { useMst } from '../../models';
import Button from '../../components/bootstrap/Button';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import Coupon from './components/Coupon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
const Footer = observer(() => {
	const [ref, { height }] = useMeasure();
	const { user, company, notifiction } = useMst();
	const { t } = useTranslation(['translation', 'menu']);

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();
	const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row align-self-center'>
					<div className='col lh-1 align-self-center'>
						<span className='fw-light col-xs-6 fc-6 text-black-50'>Copyright © 2022 - Version {config.appVersion}-{config.buildVersion}</span>
						
					</div>
					<div className='col-auto'>
						<div
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							{/* <small className='fw-bold'>Pinat Theme</small> */}							
							{/* <span className=' col-xs-6 '>{company.get.companyPeriod &&<span className='text-black-50 align-self-center me-2'>{`${t('쿠폰사용기한: ')} ${company.get.companyPeriod[0]} ~ ${company.get.companyPeriod[1]}`}</span>}
							<Button size='sm' type='button' className='btn btn-light' onClick={()=>{setIsCouponModalOpen(true)}}>{t('새 쿠폰등록')}</Button></span> */}
						</div>
					</div>
				</div>
			</div>
			<Modal isCentered={true} setIsOpen={setIsCouponModalOpen} isOpen={isCouponModalOpen}>
				<ModalBody>
					<Coupon user={user} setIsModalOpen={setIsCouponModalOpen} />
				</ModalBody>
			</Modal>
		</footer>
	);
});

export default Footer;
