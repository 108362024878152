import React, {useState} from 'react';
import SubHeader, {SubHeaderLeft} from "../../../layout/SubHeader/SubHeader";
import Breadcrumb, {BreadcrumbItem} from "../../../components/bootstrap/Breadcrumb";
import Modal, {ModalBody} from "../../../components/bootstrap/Modal";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Button from "../../../components/bootstrap/Button";
import UserReqVacation from "../AuthMenu/Vacation/User/modal/UserReqVacation";
import WorkCalenderOvertimeApplyModal from "../AuthMenu/Work/components/modal/WorkCalenderOvertimeApplyModal";
import WorkCalenderFlextimeApplyModal from "../AuthMenu/Work/components/modal/WorkCalenderFlextimeApplyModal";
import WorkCalenderExtraWorkApplyModal from "../AuthMenu/Work/components/modal/WorkCalenderExtraWorkApplyModal";
import WorkCalenderBusinessTripOffsiteApplyModalPc from "../AuthMenu/Work/components/modal/WorkCalenderBusinessTripOffsiteApplyModal_pc";
import WorkCalenderBusinessTripOffsiteApplyModalMo from "../AuthMenu/Work/components/modal/WorkCalenderBusinessTripOffsiteApplyModal_mo";
import WorkCalenderFlexibleWorkApplyModal from "../AuthMenu/Work/components/modal/WorkCalenderFlexibleWorkApplyModal";
import UserReqRecordEditModal from "../AuthMenu/Work/User/UserReqRecordEditModal";
import RestTypeSelectModal from "../components/modal/RestTypeSelectModal";
import EmptyModal from "../components/modal/EmptyModal";
import BottomModal from "../components/modal/BottomModal";
import TimePickerModal from "../components/modal/TimePickerModal";
import WorkFlexAddModal from "../components/modal/WorkFlexAddModal";
import ApprovalDetailModal from "../components/modal/ApprovalDetailModal";
import CancellRequestCompletedModal from "../components/modal/CancellRequestCompletedModal";
import VacationApprovalCompletedModal from "../components/modal/VacationApprovalCompletedModal";
import WorkApprovalCompletedModal from "../components/modal/WorkApprovalCompletedModal";
import WorkRecordProgressModal from "../components/modal/WorkRecordProgressModal";
import WorkRecordDeleteModal from "../components/modal/WorkRecordDeleteModal";
import WorkReportAsideDeatail from "../AuthMenu/Work/components/WorkReportAsideDeatail";
import VacationPolicyConfirmModal from "../components/modal/VacationPolicyConfirmModal";


const ModalList = () => {
    const [isOpenEmptyModal, setIsOpenEmptyModal] = useState(false);
    const [isOpenBottomModal, setIsOpenBottomModal] = useState(false);
    const [isOpenUserReqVacationModal, setIsOpenUserReqVacationModal] = useState(false);
    const [isOpenWorkCalenderOvertimeApplyModal, setIsOpenWorkCalenderOvertimeApplyModal] = useState(false);
    const [isOpenWorkCalenderFlextimeApplyModal, setIsOpenWorkCalenderFlextimeApplyModal] = useState(false);
    const [isOpenWorkCalenderExtraWorkApplyModal, setIsOpenWorkCalenderExtraWorkApplyModal] = useState(false);
    const [isOpenWorkCalenderBusinessTripOffsiteApplyModalPc, setIsOpenWorkCalenderBusinessTripOffsiteApplyModalPc] = useState(false);
    const [isOpenWorkCalenderBusinessTripOffsiteApplyModalMo, setIsOpenWorkCalenderBusinessTripOffsiteApplyModalMo] = useState(false);
    const [isOpenWorkCalenderFlexibleWorkApplyModal, setIsOpenWorkCalenderFlexibleWorkApplyModal] = useState(false);
    const [isOpenUserReqRecordEditModal, setIsOpenUserReqRecordEditModal] = useState(false);
    const [isOpenRestTypeSelectModal, setIsOpenRestTypeSelectModal] = useState(false);
    const [isOpenTimePickerModal, setIsOpenTimePickerModal] = useState(false);
    const [isOpenWorkFlexAddModal, setIsOpenWorkFlexAddModal] = useState(false);
    const [isOpenApprovalDetailModal, setIsOpenApprovalDetailModal] = useState(false);
    const [isOpenCancellRequestCompletedModal, setIsOpenCancellRequestCompletedModal] = useState(false);
    const [isOpenVacationApprovalCompletedModal, setIsOpenVacationApprovalCompletedModal] = useState(false);
    const [isOpenWorkApprovalCompletedModal, setIsOpenWorkApprovalCompletedModal] = useState(false);
    const [isOpenWorkRecordProgressModal, setIsOpenWorkRecordProgressModal] = useState(false);
    const [isOpenWorkRecordDeleteModal, setIsOpenWorkRecordDeleteModal] = useState(false);
    const [isOpenWorkReportAsideDeatail, setIsOpenWorkReportAsideDeatail] = useState(false);
    const [isOpenVacationPolicyConfirmModal, setIsOpenVacationPolicyConfirmModal] = useState(false);

    return (
        <>
            <PageWrapper>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb>
                            <BreadcrumbItem tag={'nav'} to={'/'}>Modal List</BreadcrumbItem>
                        </Breadcrumb>
                    </SubHeaderLeft>
                </SubHeader>

                <Page container='fluid'>
                    <h4>Sample</h4>
                    <div className="d-flex flex-wrap">
                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenEmptyModal(true)
                            }}
                        >Empty Modal</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenBottomModal(true)
                            }}
                        >Bottom Modal</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenTimePickerModal(true)
                            }}
                        >Time Picker(TimePickerModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkFlexAddModal(true)
                            }}
                        >탄력근무추가(WorkFlexAddModal)</Button>
                    </div>

                    <h4 className='mt-4'>Works</h4>
                    <div className="d-flex flex-wrap">
                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenUserReqVacationModal(true)
                            }}
                        >휴가신청(UserReqVacation)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderOvertimeApplyModal(true)
                            }}
                        >연장근무신청(WorkCalenderOvertimeApplyModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderFlextimeApplyModal(true)
                            }}
                        >시차출퇴근신청(WorkCalenderFlextimeApplyModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderExtraWorkApplyModal(true)
                            }}
                        >특근신청(WorkCalenderExtraWorkApplyModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderBusinessTripOffsiteApplyModalPc(true)
                            }}
                        >출장신청_PC(WorkCalenderBusinessTripOffsiteApplyModal_pc)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderBusinessTripOffsiteApplyModalMo(true)
                            }}
                        >출장신청_모바일(WorkCalenderBusinessTripOffsiteApplyModal_mo)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkCalenderFlexibleWorkApplyModal(true)
                            }}
                        >탄력근무신청(WorkCalenderFlexibleWorkApplyModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenUserReqRecordEditModal(true)
                            }}
                        >근무기록 수정요청(UserReqRecordEditModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenRestTypeSelectModal(true)
                            }}
                        >휴가유형선택(RestTypeSelectModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenApprovalDetailModal(true)
                            }}
                        >전자결재신청(ApprovalDetailModal)</Button>

                    </div>

                    <h4 className='mt-4'>추가모달</h4>
                    <div className="d-flex flex-wrap">
                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenCancellRequestCompletedModal(true)
                            }}
                        >취소요청완료(CancellRequestCompletedModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenVacationApprovalCompletedModal(true)
                            }}
                        >휴가신청완료(VacationApprovalCompletedModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkApprovalCompletedModal(true)
                            }}
                        >근무신청완료(WorkApprovalCompletedModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkRecordProgressModal(true)
                            }}
                        >근무기록등록중(WorkRecordProgressModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkRecordDeleteModal(true)
                            }}
                        >근무기록삭제(WorkRecordDeleteModal)</Button>

                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenVacationPolicyConfirmModal(true)
                            }}
                        >휴가정책변경(VacationPolicyConfirmModal)</Button>

                    </div>

                    <h4 className='mt-4'>OffCanvas</h4>
                    <div className="d-flex flex-wrap">
                        <Button
                            className='mx-2 my-1'
                            color="dark"
                            isOutline
                            onClick={() => {
                                setIsOpenWorkReportAsideDeatail(true)
                            }}
                        >근무기록상세(WorkReportAsideDeatail)</Button>
                    </div>
                </Page>
            </PageWrapper>

            {/* sample 빈모달 */}
            <EmptyModal
                isOpen={isOpenEmptyModal}
                setIsOpen={setIsOpenEmptyModal}
            />

            {/* sample 하단정렬모달 */}
            <BottomModal
                isOpen={isOpenBottomModal}
                setIsOpen={setIsOpenBottomModal}
            />

            {/* sample TimePicker 모달 */}
            <TimePickerModal
                isOpen={isOpenTimePickerModal}
                setIsOpen={setIsOpenTimePickerModal}
            />

            {/* 휴가신청모달 */}
            <UserReqVacation
                isOpen={isOpenUserReqVacationModal}
                setIsOpen={setIsOpenUserReqVacationModal}
            />

            {/* 연장근무신청모달 */}
            <WorkCalenderOvertimeApplyModal
                isOpen={isOpenWorkCalenderOvertimeApplyModal}
                setIsOpen={setIsOpenWorkCalenderOvertimeApplyModal}
            />

            {/* 시차출퇴근신청모달 */}
            <WorkCalenderFlextimeApplyModal
                isOpen={isOpenWorkCalenderFlextimeApplyModal}
                setIsOpen={setIsOpenWorkCalenderFlextimeApplyModal}
            />

            {/* 특근신청모달 */}
            <WorkCalenderExtraWorkApplyModal
                isOpen={isOpenWorkCalenderExtraWorkApplyModal}
                setIsOpen={setIsOpenWorkCalenderExtraWorkApplyModal}
            />

            {/* 출장신청모달(pc) */}
            <WorkCalenderBusinessTripOffsiteApplyModalPc
                isOpen={isOpenWorkCalenderBusinessTripOffsiteApplyModalPc}
                setIsOpen={setIsOpenWorkCalenderBusinessTripOffsiteApplyModalPc}
            />

            {/* 출장신청모달(모바일) */}
            <WorkCalenderBusinessTripOffsiteApplyModalMo
                isOpen={isOpenWorkCalenderBusinessTripOffsiteApplyModalMo}
                setIsOpen={setIsOpenWorkCalenderBusinessTripOffsiteApplyModalMo}
            />

            {/* 탄력근무신청모달 */}
            <WorkCalenderFlexibleWorkApplyModal
                isOpen={isOpenWorkCalenderFlexibleWorkApplyModal}
                setIsOpen={setIsOpenWorkCalenderFlexibleWorkApplyModal}
            />

            {/* 근무기록수정요청모달 */}
            <UserReqRecordEditModal
                isOpen={isOpenUserReqRecordEditModal}
                setIsOpen={setIsOpenUserReqRecordEditModal}
            />

            {/* 휴가유형선택모달 */}
            <RestTypeSelectModal
                isOpen={isOpenRestTypeSelectModal}
                setIsOpen={setIsOpenRestTypeSelectModal}
            />

            {/* 탄력근무추가모달 */}
            <WorkFlexAddModal
                isOpen={isOpenWorkFlexAddModal}
                setIsOpen={setIsOpenWorkFlexAddModal}
            />

            {/* 전자결재신청모달  */}
			<ApprovalDetailModal
				isOpen={isOpenApprovalDetailModal}
				setIsOpen={setIsOpenApprovalDetailModal}
			/>

            {/* 취소요청완료모달 */}
            <CancellRequestCompletedModal
                isOpen={isOpenCancellRequestCompletedModal}
                setIsOpen={setIsOpenCancellRequestCompletedModal}
            />

            {/* 휴가신청완료모달 */}
            <VacationApprovalCompletedModal
                isOpen={isOpenVacationApprovalCompletedModal}
                setIsOpen={setIsOpenVacationApprovalCompletedModal}
            />

            {/* 근무신청완료모달 */}
            <WorkApprovalCompletedModal
                isOpen={isOpenWorkApprovalCompletedModal}
                setIsOpen={setIsOpenWorkApprovalCompletedModal}
            />

            {/* 근무기록등록중모달 */}
            <WorkRecordProgressModal 
                isOpen={isOpenWorkRecordProgressModal}
                setIsOpen={setIsOpenWorkRecordProgressModal}
            />

            {/* 근무기록삭제모달 */}
            <WorkRecordDeleteModal
                isOpen={isOpenWorkRecordDeleteModal}
                setIsOpen={setIsOpenWorkRecordDeleteModal}
            />

            {/* 휴가정책변경모달 */}
            <VacationPolicyConfirmModal
                isOpen={isOpenVacationPolicyConfirmModal}
                setIsOpen={setIsOpenVacationPolicyConfirmModal}
            />

            <OffCanvas isBackdrop={true} isBodyScroll={true} placement={'start'} isOpen={isOpenWorkReportAsideDeatail} setOpen={() => {}}>
				<OffCanvasHeader setOpen={setIsOpenWorkReportAsideDeatail}>
					<OffCanvasTitle>근무기록 상세</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<WorkReportAsideDeatail
						close={setIsOpenWorkReportAsideDeatail}
						setIsMapModal={setIsOpenWorkReportAsideDeatail}
					/>
				</OffCanvasBody>
			</OffCanvas>

        </>
    )
}

export default ModalList;
