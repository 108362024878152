import React, { useState, useContext, useLayoutEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMeasure, useWindowSize } from 'react-use';
import Badge from '../../../../components/bootstrap/Badge';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import ThemeContext from '../../../../contexts/themeContext';
import Button from '../../../../components/bootstrap/Button';
import Portal from '../../../../layout/Portal/Portal';
import useDarkMode from '../../../../hooks/useDarkMode';
import NotificationList from '../../../common/Headers/components/NotificationList';
import NotificationSetting from '../../../common/Headers/components/NotificationSetting';
import { useMst } from '../../../../models';
import { useTranslation } from 'react-i18next';
import AlarmService from '../../../../services/AlarmService';
import showNotification from '../../../../components/extras/showNotification';
import Logo from '../../../../components/Logo_v2';

import iconHamburger from '../../../../assets/img/icon/icon_hamburger.svg'
import iconAlarm from '../../../../assets/img/icon/icon_alarm.svg'
import iconAlarmOn from '../../../../assets/img/icon/icon_alarm_on.svg'

export const HeaderLeft = ({ children, className }) => {
	return <div className={classNames('header-left', 'col-md', className)}>{children}</div>;
};
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderLeft.defaultProps = {
	className: null,
};

export const HeaderRight = ({ children, className }) => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--header-right-height', `${height}px`);

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
			{children}
		</div>
	);
};
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderRight.defaultProps = {
	className: null,
};

const Header = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { themeStatus } = useDarkMode();

	const windowsWidth = useWindowSize().width;
	const [refMobileHeader, sizeMobileHeader] = useMeasure();
	const [refHeader, sizeHeader] = useMeasure();

	const { user, company, notifiction } = useMst();
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { t } = useTranslation(['translation', 'menu']);
	const [ settingFlag, setSettingFlag ] = useState(false);

	const root = document.documentElement;
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`);
	root.style.setProperty('--header-height', `${sizeHeader.height}px`);

	const {
		asideStatus,
		setAsideStatus,
		leftMenuStatus,
		setLeftMenuStatus,
		rightMenuStatus,
		setRightMenuStatus,
		asideSubStatus,
		setAsideSubStatus,
	} = useContext(ThemeContext);

	const allReadHandler = useCallback(async()=> {
		// console.log('userId, companyId')
		await AlarmService.alarmReadAll({userId : user.id, companyId : company.get.id}).then(response => {
			if(response.data){
				// console.log('response >>',response)
				showNotification('알람','알람을 모두 읽었습니다.','info');
				notifiction.getRefresh(company.get.id);
				setOffcanvasStatus(false);
			}else{
				showNotification('알람','모두읽기 실패.','danger')
			}
		})
	},[user, company, notifiction])

	useLayoutEffect(() => {
		if (
			(asideStatus || leftMenuStatus || rightMenuStatus) &&
			windowsWidth < process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE
		)
			document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	});

	return (
		<>
			<header ref={refMobileHeader} className='mobile-header bg-white border-bottom border-light'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between align-items-center'>
						<div>
							<Logo height={25} />
						</div>
						<div>
							<Button
								className='p-2'
								onClick={() => setOffcanvasStatus(true)}
								aria-label='Notifications'
							>
								<img src={notifiction?.get.badge > 0 ? iconAlarmOn : iconAlarm } />
							</Button>		
							<Button
								className='p-2'
								onClick={() => {
									setAsideStatus(!asideStatus);
									setAsideSubStatus(!asideSubStatus);
									setLeftMenuStatus(false);
									setRightMenuStatus(false);
								}}
							>
								<img src={iconHamburger} />
							</Button>							
							<OffCanvas id='notificationCanvas' titleId='offcanvasExampleLabel' placement='end' isOpen={offcanvasStatus} setOpen={setOffcanvasStatus}>
								<OffCanvasHeader setOpen={setOffcanvasStatus}>
									<OffCanvasTitle className='w-100 d-flex justify-content-between' id='offcanvasExampleLabel'>
										<div>
											{t('알림')} ({notifiction?.get.badge})			
										</div>
										<div>
											<Button color='info' size='sm' onClick={() => allReadHandler()}>
												{'모두 읽음'}
											</Button>										
											<Button
												color='light'
												icon={!settingFlag ? 'GearFill' : 'Bell'}
												isOutline={true}
												className='border-0'
												onClick={() => {
													setSettingFlag(!settingFlag);
												}}
											/>
										</div>
									</OffCanvasTitle>
								</OffCanvasHeader>
								<OffCanvasBody>{settingFlag ? <NotificationSetting /> : <NotificationList />}</OffCanvasBody>
							</OffCanvas>
						</div>
					</div>
				</div>
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}>
				<div className='container-fluid'>
					<div className='row d-flex align-items-center'>
						{children}
						{(leftMenuStatus || rightMenuStatus) && (
							<Portal>
								<div
									role='presentation'
									className={classNames('header-overlay', {
										'header-overlay-left-menu': leftMenuStatus,
										'header-overlay-right-menu': rightMenuStatus,
									})}
									onClick={() => {
										setAsideStatus(false);
										setAsideSubStatus(false);
										setLeftMenuStatus(false);
										setRightMenuStatus(false);
									}}
								/>
							</Portal>
						)}
					</div>
				</div>
			</header>
		</>
	);
};
Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
};
Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
};

export default Header;
