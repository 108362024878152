import moment from 'moment';
import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Popovers from '../../../components/bootstrap/Popovers';
import Chart from '../../../components/extras/Chart';
import Icon from '../../../components/icon/Icon';
import useRequest from '../../../hooks/useRequest';
import { adminsMenu } from '../../../menu';
import { useMst } from '../../../models';
import CompanyService from '../../../services/CompanyService';
import showNotification from '../../../components/extras/showNotification';

const ApprovalGraph = () => {
	const navigate = useNavigate();
	const ref = useRef();
	const { t } = useTranslation(['translation', 'menu']);
	const { company } = useMst();
	const { responseData, loading, request } = useRequest([]);
	const [loading2, setLoading2] = useState(false);
	const [originApprovalList, setOriginApprovalList] = useState([]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [approvalList, setApprovalList] = useState([]);
	const [approvalCount, setApprovalCount] = useState({
		rest : 0,
		over : 0,
		work : 0
	});
	const [graphValues, setGraphValues] = useState({
		series: [
			{
				name: t('상신 횟수'),
				data: [],
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					download: false,
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '60%',
				},
			},
			dataLabels: {
				enabled: true,
				offsetX: -6,
				style: {
					fontSize: '12px',
					colors: ['#fff'],
				},
			},
			xaxis: {
				categories: [],
				labels: {
					formatter: function (val) {
						return val.toFixed(0);
					},
				},
			},
		},
	});
	const columns = useMemo(() => [{ name: '기안자' }, { name: '기안일' }, { name: '결재 유형' }], []);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedwidth: true,
			}),
		[]
	);

	useEffect(()=> {
		let rest = approvalList?.filter(i => i.type?.includes('REST'));
		let over = approvalList?.filter(i => i.subtype === '연장');
		let work = approvalList?.filter(i => i.subtype !== '연장' && i.type.includes('WORK'));
		setApprovalCount({
			rest : rest?.length ||0,
			over : over?.length || 0,
			work : work?.length || 0
		})
	}, [approvalList])

	useEffect(() => {
		request(
			CompanyService.getAdminRequestList,
			[company.get.id],
			(res) => (res?.data ? { data: res.data.filter((data) => data.state === 'REQUEST') } : { data: [] }),
			(error) => {
				showNotification(t('결재 상신 현황'), t('결재 상신 현황을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
			}
		);
	}, [company, request, t]);

	useEffect(() => {
		setLoading2(true);
		console.log(`responseData=${responseData}`)
		if (responseData.length > 0) {
			const sortedByCreatedAt = responseData.sort((a, b) => {
				if (a.createdAt === null || b.createdAt === null) {
					return;
				} else {
					return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
				}
			});
			setOriginApprovalList(sortedByCreatedAt);
			setApprovalList(sortedByCreatedAt);
			const approvalArray = sortedByCreatedAt.reduce((allApp, curApp) => {
				if (curApp.state === 'REQUEST') {
					if (curApp.restday?.name) {
						const alreadyExist = allApp.findIndex((app) => app.key === curApp.restday?.name);
						if (alreadyExist !== -1) {
							allApp[alreadyExist].value = allApp[alreadyExist].value + 1;
						} else {
							allApp.push({ key: curApp.restday?.name, value: 1 });
						}
					}
					return allApp;
				}
			}, []);
			const sortedByRequestCount = approvalArray.sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0));
			graphValues.options.chart.events = {
				dataPointMouseEnter: function (event) {
					event.target.style.cursor = 'pointer';
				},
				dataPointSelection: (event, chartContext, config) => {
					setApprovalList(
						sortedByCreatedAt.filter(
							(approval) =>
								approval.state === 'REQUEST' &&
								approval.subtype === graphValues.options.xaxis.categories[config.dataPointIndex]
						)
					);
					setIsFiltered(true);
				},
			};
			let graphValuesRef = { ...graphValues };
			graphValuesRef.series[0].data = sortedByRequestCount.map((req) => req.value);
			graphValuesRef.options.xaxis.categories = sortedByRequestCount.map((req) => req.key);
			// console.log(sortedByRequestCount)
			graphValues.options.chart.height = 70 + sortedByRequestCount.length * 20;
			setGraphValues(graphValuesRef);
		}
		setLoading2(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responseData]);

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			switch (columnIndex) {
				case 0: // 기안자
					data = (
						<Popovers title={t('사유')} desc={approvalList[rowIndex - 1]?.etc || ' - '} trigger='hover'>
							{`${approvalList[rowIndex - 1]?.user}${
								approvalList[rowIndex - 1]?.rank ? `(${t(approvalList[rowIndex - 1]?.rank)})` : ''
							}` || '-'}
						</Popovers>
					);
					break;
				case 1: // 기안일moment(item.createdAt).format('YYYY-MM-DD')
					data = `${moment(approvalList[rowIndex - 1]?.createdAt).format('YYYY-MM-DD')}` || '-';
					break;
				case 2: // 기안 유형
					data = `${t(approvalList[rowIndex - 1]?.subtype)}` || '-';
					break;
			}

			return data;
		},
		[approvalList, t]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => {
			return (
				<CellMeasurer
					key={`row-${rowIndex}-${columnIndex}`}
					cache={cellCache}
					parent={parent}
					columnIndex={columnIndex}
					rowIndex={rowIndex}
					style={style}>
					{rowIndex === 0 ? (
						<div style={style} className='d-flex justify-content-center py-3 text-wrap'>
							{t(columns[columnIndex].name)}
						</div>
					) : (
						<div
							style={style}
							className={`d-flex justify-content-center align-items-center p-2 text-wrap cursor-pointer ${
								rowIndex % 2 === 0 ? 'bg-l50-light' : ''
							}`}
							onClick={() => {
								navigate(`/${adminsMenu.approvalDocument.path}`, {
									state: {
										id: approvalList[rowIndex - 1]?.id,
									},
								});
							}}>
							{renderText({
								columnIndex,
								rowIndex,
								parent,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[cellCache, columns, renderText, navigate, t, approvalList]
	);

	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			<CardHeader className='rounded-2'>
				<CardLabel>
					<CardTitle
						className={'cursor-pointer'}
						onClick={() => {
							navigate(adminsMenu.approvalDocument.path);
						}}>
						{t('결재 대기 목록')}
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Badge color='light' className='text-wrap'>
						<span className='text-dark'>{t('총 상신 수')} : </span>
						<span className='fw-bold text-info fs-6'>{approvalList?.length || 0}</span>
					</Badge>
					{isFiltered ? (
						<Button
							color='primary'
							size='sm'
							isOutline
							onClick={() => {
								setApprovalList(originApprovalList);
								setIsFiltered(false);
							}}>
							{t('전체 보기')}
						</Button>
					) : (
						<></>
					)}
				</CardActions>
			</CardHeader>
			<CardBody
				ref={ref}
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{(loading || loading2) && (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				)}
				{!loading &&
					!loading2 &&
					(graphValues.series[0].data.length > 0 &&
					graphValues.options.xaxis.categories.length > 0 &&
					approvalList.length > 0 ? (
						<div className='row'>
							<div className='col-md-6 overflow-auto'>
								{/* <Chart
									series={graphValues.series}
									options={graphValues.options}
									type={graphValues.options.chart.type}
									height={graphValues.options.chart.height}
								/> */}
								<div className='d-flex py-4 bg-l10-info rounded-1'>
									<div className='d-flex flex-column justify-content-around col-4'>
										<div className='d-flex justify-content-center'>
											<span className=''>{t('휴가')}</span>
										</div>
										<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
											<span className='text-info me-1'>{approvalCount.rest || 0}</span>{t('건')}
										</div>
									</div>
									<div
										className='d-flex flex-column justify-content-around col-4'
										style={{
											borderLeft: '1px solid #D9D9D9',
											borderRight: '1px solid #D9D9D9',
										}}>
										<div className='d-flex justify-content-center'>
											<span className=''>{t('연장')}</span>
										</div>
										<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
											<span className='text-info me-1'>{approvalCount.over || 0}</span>{t('건')}
										</div>
									</div>
									<div className='d-flex flex-column justify-content-around col-4'>
										<div className='d-flex justify-content-center'>
											<span className=''>{t('근무')}</span>
										</div>
										<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
										<span className='text-info me-'>{approvalCount.work || 0}</span>{t('건')}
										</div>
									</div>
								</div>
							</div>
							<div className='dashboard-approval-table col-md-6 dashboard-height overflow-scroll'>
								<table className='w-100' style={{tableLayout: 'fixed'}}>
									{
										approvalList.map((v, i) => {
											let data = {
												bg: '',
												fc: '',
												name: ''
											};

											if(v?.type === 'WORK') {
												if(v?.subtype === '시차출퇴근' || v?.subtype === '탄력' || v?.subtype === '특근') {
													data = {
														bg: '#EEECF4',
														fc: '#1A0166',
														name: '근무'
													}
												} else if (v?.subtype === '연장') {
													data = {
														bg: '#FDEAEC',
														fc: '#66020D',
														name: '연장'
													}
												} else if (v?.subtype === '출장') {
													data = {
														bg: '#FDF9EA',
														fc: '#661A02',
														name: '출장'
													}
												}
											} else if(v?.type?.includes('REST')) {
												data = {
													bg: '#EEF4EC',
													fc: '#096601',
													name: '휴가'
												}
											} else {
												data = {
													bg: '#EEECF4',
													fc: '#1A0166',
													name: v.subtype
												}
											}
											return (
												<tr 
													key={i}
													className='border-bottom border-light'
													style={{cursor:'pointer'}}
													onClick={() => {
														navigate(`/${adminsMenu.approvalDocument.path}`, {
															state: {
																id: approvalList[i]?.id,
																startDate: moment(v?.startDate).format('YYYY-MM-DD'),
																endDate: moment(v?.endDate).format('YYYY-MM-DD'),
																type: 'approvalReportAll'
															},
														});
													}}
												>
													<td className='py-3'>
														<p className='mb-0 fw-bold' 
														style={{
															minWidth: '40px',
															overflow: 'hidden',
  														whiteSpace: 'nowrap',
  														textOverflow: 'ellipsis',
  														wordBreak: 'break-all'
														}}>
															{v.user} {v.rank}
														</p>
													</td>
													<td className='py-3'>
														<p className='mb-0 text-muted' style={{minWidth: '110px'}}>
															<Icon icon='CalendarToday' className='me-2' />
															<span>{moment(v.createdAt).format('YYYY-MM-DD')}</span>
														</p>
													</td>
													<td className='py-3' style={{minWidth: '60px'}}>
														<Badge className={`px-2`} color={data?.bg} style={{backgroundColor: data?.bg}}>
															<span className='fs-6' 
															style={{color: data?.fc}}>{t(data?.name)}</span>
														</Badge>
													</td>
												</tr>
											)
										})
									}
								</table>
							</div>
						</div>
					) : (
						<div className='h-100 d-flex flex-column justify-content-center align-items-center text-muted'>
							<Icon size={'6x'} icon={'CheckCircle'} />
							<p className='mt-5'>{t('요청중인 결재가 없습니다.')}</p>
						</div>
					))}
			</CardBody>
		</Card>
	);
};

export default ApprovalGraph;
