import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../../../contexts/themeContext';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import iconExtend from '../../../../assets/img/icon/icon_extend.svg';
import iconFlexible from '../../../../assets/img/icon/icon_flexible.svg';
import iconFuture from '../../../../assets/img/icon/icon_future.svg';
import iconHoliday from '../../../../assets/img/icon/icon_holiday.svg';
import iconBusiness from '../../../../assets/img/icon/icon_business.svg';
import iconOvertime from '../../../../assets/img/icon/icon_overtime.svg';

const UserJobApplyCard = () => {
  const WorkRequestList = [
		{ img: iconExtend, type: '연장근무'},
		{ img: iconFlexible, type: '탄력근무'},
		{ img: iconFuture, type: '시차출퇴근'},
		{ img: iconHoliday, type: '휴가'},
		{ img: iconBusiness, type: '출장'},
		{ img: iconOvertime, type: '특근'}
	]
  const { t } = useTranslation(['translation', 'menu']);
  const { mobileDesign } = useContext(ThemeContext);

  const WorkList = () => {
    return (
      <div className='d-flex flex-wrap' style={{columnGap: '15px'}}>
        {
          WorkRequestList.map((item) => {
            return (
              <Card
                className='custom-box-shadow cursor-pointer rounded-1'
                stretch
                key={item}
                style={{width: 'calc((100% / 3) - 10px)'}}
                onClick={() => {
                  // 각각의 근무신청유형 모달들 나옴
                }}
              >
                <CardBody className='text-white text-center px-0'>
                  <div className='pt-2 pt-md-0'>
                    <img src={item.img} style={mobileDesign ? { width: '36px', height: '36px' } : { width: '50px', height: '50px' }} alt={`${item.type} 신청 버튼`} />
                  </div>
                  <p className='fs-6 text-black fw-bold mt-3'>{item.type}</p>
                </CardBody>
              </Card>
            )
          })
        }
      </div>
    )
  }

	return (
    <>
      {
        mobileDesign ?
        // 모바일일때 보여주는 화면
        WorkList() : 
        // pc일때 보여주는 화면
        <Card stretch style={{backgroundColor:'transparent', boxShadow:'none'}}>
          <CardHeader className='flex-row' style={{backgroundColor:'transparent', boxShadow:'none'}}>
            <CardLabel>
              <CardTitle className={'cursor-pointer'}>
                {t('근무신청')}
              </CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody className='dashboard-short-height p-0 d-flex align-items-center justify-content-center'>
            {WorkList()}
          </CardBody>
        </Card>
      }
    </>
	);
};
export default React.memo(UserJobApplyCard);
