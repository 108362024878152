import React, {forwardRef, useEffect, useState} from 'react';
import Modal, {ModalBody, ModalFooter} from "../../../../components/bootstrap/Modal_v2";
// import Modal, {ModalBody, ModalFooter} from '../../../../components/bootstrap/Modal';
import Button from "../../../../components/bootstrap/Button";
import Picker from 'react-mobile-picker-scroll';
import {t} from "i18next";
import moment from 'moment';

// react-mobile-picker-scroll
// https://github.com/dsmalicsi/react-mobile-picker-scroll?tab=readme-ov-file
const TimePickerModal = forwardRef((props, ref) => {

  // console.log('props', props)
    const initValueGroups = {
        hh: '00',
        mm: '00',
    }

    const [optionGroups, setOptionGroup] = useState({
      hh: [],
      mm: []
    });

    const [valueGroups, setValueGroups] = useState({initValueGroups});
    
    const handleChange = ( name, value) => {
      setValueGroups((prevValueGroups) => ({
        ...prevValueGroups,
        [name]: value
      }))
    }
 
    const timeSetting = () => {
      let hrs = [];
      let mins = [];
      
      for (let i = 0; i < 25; i++) { 
        hrs.push(moment('00', 'HH').clone().add(i, 'hour').format('HH'));
      } 
      if(props?.specialWork === 'EXTRA') {
        mins = ['00', '30']
      } else {
        for (let i = 0; i < 6; i++) { 
          mins.push(moment('00', 'mm').clone().add(i * 10, 'minutes').format('mm'));
        } 
      }
      
      setOptionGroup({
        hh: hrs,
        mm: mins,
      });
    }

    const handleSubmit = (type) => {
      let hr = moment(valueGroups?.hh, 'HH').format('HH');
      let min = moment(valueGroups?.mm, 'mm').format('mm');

      let formmatedTime = moment(`${hr}${min}`, 'HHmm').format('HH:mm');
      
      if(type === 'START') {
        props.data.setFieldValue('startAt', formmatedTime);
        if(props?.specialWork === 'EXTRA') {
          props?.data.setFieldValue('endAt', moment(formmatedTime, 'HH:mm').add(9, 'hour').format('HH:mm'));
        }
      } else {
        props.data.setFieldValue('endAt', formmatedTime);
      }
      props?.setIsOpen(false);
    }

    useEffect(()=> {
      timeSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
      if (props?.specialWork === 'EXTRA' || props?.specialWork === 'REST' ) {
        let hrs = [];
    
        for (let i = 0; i < 25; i++) {
          hrs.push(moment('00', 'HH').clone().add(i, 'hour').format('HH'));
        }
    
        if(props?.specialWork === 'REST') {
          setOptionGroup({
            hh: hrs,
            mm: ['00', '30'],
          });
        } else if (props?.specialWork === 'EXTRA') {
          if (props?.checkStartOrEnd === 'END') {
            if ((props?.data?.values?.startAt)?.includes('30')) {
              setOptionGroup({
                hh: hrs,
                mm: ['30'],
              });
            } else {
              setOptionGroup({
                hh: hrs,
                mm: ['00'],
              });
            }
          } else {
            setOptionGroup({
              hh: hrs,
              mm: ['00', '30'],
            });
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data?.values?.startAt, props?.checkStartOrEnd, props?.specialWork]);

    useEffect(()=> {
      if(!props?.isOpen){
        setValueGroups({
          hh: '00',
          mm: '00',
        })
      }
    }, [props.isOpen]);

    return (
        <>
            <Modal
                id=""
                titleId=""
                isOpen={props?.isOpen}
                setIsOpen={props?.setIsOpen}
                isScrollable
                isBottomed
            >
                <ModalBody>
                    <div>
                        <Picker
                            optionGroups={optionGroups}
                            valueGroups={valueGroups}
                            onChange={handleChange}
                            itemHeight={40}
                            height={160}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='w-100 py-3'
                        color="info"
                        isDisable={!valueGroups?.hh && !valueGroups?.mm}
                        onClick={() =>{
                          handleSubmit(props?.checkStartOrEnd)
                        }}
                       >
                        <span>{t('선택완료')}</span>
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
})

export default TimePickerModal;
