import React from 'react';
import Breadcrumb, {BreadcrumbItem} from "../../../components/bootstrap/Breadcrumb";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {SubHeaderLeft} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";


const EmptyPage = () => {
    return (
        <>
            <PageWrapper>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb>
                            <BreadcrumbItem tag={'nav'} to={'/'}>Empty Page</BreadcrumbItem>
                        </Breadcrumb>
                    </SubHeaderLeft>
                </SubHeader>

                <Page container='fluid'>
                    EmptyPage Contents Area
                </Page>
            </PageWrapper>
        </>
    )
}

export default EmptyPage;
