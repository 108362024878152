import {get, unset} from "lodash";
import {adminCompnaySettingMenu, adminsMenu} from "../menu";

const menuRoleMaps = [
  {
    rolePath: 'work.workGroup.access',
    menuPath: 'workingManagement.subMenu.workingGroupManagement',
    menuType: 'admin',
    pathnames: ['/work/group']
  },
  {
    rolePath: 'work.attend.access',
    menuPath: 'workingManagement.subMenu.workingLog',
    menuType: 'admin',
    pathnames: ['/work/log']
  },
  {
    rolePath: 'work.area.access',
    menuPath: 'workingManagement.subMenu.workingPlaceManagement',
    menuType: 'admin',
    pathnames: ['/work/place']
  },
  {
    rolePath: 'work.substitutedRest.access',
    menuPath: 'workingManagement.subMenu.holidayWorkLog',
    menuType: 'admin',
    pathnames: ['/holidayWork/log']
  },
  {
    rolePath: 'work.schedule.access',
    menuPath: 'workingManagement.subMenu.workingScheduleManagement',
    menuType: 'admin',
    pathnames: ['/work/schedule']
  },
  {
    rolePath: 'work.deadline.access',
    menuPath: 'workingManagement.subMenu.workDeadlinePage',
    menuType: 'admin',
    pathnames: ['/work/deadline']
  },
  {
    rolePath: 'user.list.access',
    menuPath: 'memberManagement.subMenu.memberList',
    menuType: 'admin',
    pathnames: ['/member/list']
  },
  {
    rolePath: 'rest.status.access',
    menuPath: 'memberManagement.subMenu.restStatus',
    menuType: 'admin',
    pathnames: ['/rest/status']
  },
  {
    rolePath: 'rest.manage.access',
    menuPath: 'memberManagement.subMenu.restManagement',
    menuType: 'admin',
    pathnames: ['/rest/list']
  },
  {
    rolePath: 'rest.promote.access',
    menuPath: '',
    menuType: 'admin',
    pathnames: ['']
  },
  {
    rolePath: 'setting.company.access',
    menuPath: 'setting.subMenu.companySetting',
    menuType: 'admin',
    pathnames: ['/company/setting']
  },
  {
    rolePath: 'setting.position.access',
    menuPath: 'positionSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/position/setting']
  },
  {
    rolePath: 'setting.rank.access',
    menuPath: 'rankSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/rank/setting']
  },
  {
    rolePath: 'setting.holiday.access',
    menuPath: 'vacationSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/vacation/setting']
  },
  {
    rolePath: 'setting.rest.access',
    menuPath: 'restSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/rest/setting']
  },
  {
    rolePath: 'setting.work.access',
    menuPath: 'workSystemSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/workSystem/setting']
  },
  {
    rolePath: 'setting.role.access',
    menuPath: 'roleSettingPage',
    menuType: 'adminCompSetting',
    pathnames: ['/role/setting']
  },
  {
    rolePath: 'setting.role.access',
    menuPath: 'setting.subMenu.departmentManagement',
    menuType: 'admin',
    pathnames: ['/company/department']
  },
  {
    rolePath: 'setting.role.access',
    menuPath: 'setting.subMenu.security',
    menuType: 'admin',
    pathnames: ['/company/security']
  },
]

export const getRoleFilteredMenu = (menuRole, menuType) => {
  let maps = menuRoleMaps.filter(v => v.menuType === menuType)
  let menu = {}

  if (menuType === 'admin')
    menu = adminsMenu
  else if (menuType === 'adminCompSetting')
    menu = adminCompnaySettingMenu

  maps.forEach(map => {
    if (!get(menuRole, map.rolePath))
      unset(menu, map.menuPath)
  })
  // 환경설정 메뉴 권한이 모두 없는경우, 메뉴에서 제외하도록 함.
  // 근무관리 / 직원 관리에 대해서도 해야 하지만 환경설정만 일단 체크
  if (menuType === 'admin') {
    const companyAccess = menuRole ? Object.values(menuRole?.setting).find(sf => sf.access === true) : false
    if(!companyAccess) {
      unset(menu, 'setting')
    }
  }

  return menu
}

export const isPermittedPage = (menuRole, pathname) => {
  const map = menuRoleMaps.find(v => v.pathnames.includes(pathname))
  return !!map ? get(menuRole, map.rolePath) : true
}
