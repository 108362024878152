import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter, ModalTitle } from '../../../../components/bootstrap/Modal';
import { useTranslation } from 'react-i18next'; //번역관련
import Button from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Input from '../../../../components/bootstrap/forms/Input';
import SortableTree, { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import FileExplorerTheme from '@nosferatu500/theme-file-explorer';
import Checks from '../../../../components/bootstrap/forms/Checks';
import { useMst } from '../../../../models';
import DepartmentService from '../../../../services/DepartmentService';
import Badge from '../../../../components/bootstrap/Badge';
import Alert from '../../../../components/bootstrap/Alert';
import { getLineUser } from '../../../../lib/Util';

const SortableTreeModal = ({ title, isOpen, setIsOpen, disabledList, list, setList }) => {
	
  const [searchedItem, setSearchedItem] = useState([]);
	const [treeUser, setTreeUser] = useState([]);
	const [disableuserList, setDisableUserList] = useState([]);
	const [selectedTreeUser, setSelectedTreeUser] = useState([]); 
	const [timer, setTimer] = useState(0); // 디바운싱 타이머
	const { company } = useMst();
	const searchRef = useRef(null);
	const { t } = useTranslation(['translation', 'menu']); //번역관련

  const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

  const treeUserToList = (treeUser) => {
		const resultList = []

		const findUser = (topTree) => {
			if(topTree.type === 'user')
					resultList.push(topTree)
			else{
				if(topTree?.children){
					for(const child of topTree?.children){
						findUser(child)
					}
				}
			}
			
		}
		findUser(treeUser[0])
		return resultList;

	}

  // const PinatTreeRander = ({topNode, depth}) => {
  //   return (
  //     <>
  //     {topNode?.children?.length > 0? 
  //     (
  //       <>
  //       <div>{addnbsp(depth)}<Icon icon={'KeyboardArrowDown'}/>{topNode?.title}</div>
  //       {topNode?.children?.map((children, childrenIndex) => (
  //         <PinatTreeRander key={childrenIndex} topNode={children} depth={children?.depth || depth}/>
  //       ))}
  //       </>
  //     )
  //     :(topNode?.userId ?
  //       <TreeUserRenderer user={topNode} depth={depth}/>
  //       :<div>{addnbsp(depth)}<Icon icon={'KeyboardArrowDown'}/>{topNode?.title}</div>
        
  //     )
  //     }
  //     </>
  //   )
  // }
  // const TreeUserRenderer = ({user, depth}) => {
  //   return (
  //     <div className='d-flex flex-row'>
  //         {addnbsp(depth)}
  //         <Checks
  //           type={'checkbox'}
  //           label={`${user.title} | ${t(user.userRank)}`}
  //           checked={[...selectedTreeUser].map((data) => data.userId).includes(user?.userId)}
  //           disabled={disableuserList.map((data) => data.userId).includes(user?.userId)}
  //           onChange={() => {
  //             const data = {
  //               rankId: user?.userRankData?.id || 0,
  //               userId: user?.userId,
  //               rank: user?.userRank,
  //               companyId: user.companyid,
  //               positionId: user?.userPositionData?.id || 0,
  //               departmentId: user?.userDepartmentsData?.departmentId || 0,
  //               department: user?.userDepartment,
  //               head : user?.userDepartmentData?.head|| false,
  //               name: user?.name,
  //             };
  //             if (selectedTreeUser.map((data) => data.userId).includes(user?.userId)) {
  //               setSelectedTreeUser([...selectedTreeUser.filter((data) => data.userId !== user?.userId)]);
  //             } else {
  //               setSelectedTreeUser([...selectedTreeUser, data]);
  //             }
  //           }}
  //         />
  //       </div>
  //   )
  // }

  const addnbsp = (count) => {
    let returnStr = '';
    for(let a = 0; a < count; a++){
      returnStr += '\u00A0\u00A0';
    }
    return returnStr
  }

	const getTreeUser = async () => {
		await DepartmentService.getDepartmentTreeUser(company.get.id).then((res) => {
			if (res.data) {
				//console.log('res.data >>', res.data);
        setTreeUser(toggleExpandedForAll({ treeData: res.data, expanded: false }));
				setSearchedItem(toggleExpandedForAll({ treeData: res.data, expanded: false }));
        // console.log('>>>', toggleExpandedForAll({ treeData: res.data, expanded: true }))
        
      }
		});
	};

	const debounce = (e) => {
		const time = 1000;
		if (timer) {
			console.log('clearTimeout');
			clearTimeout(timer);
		}
		if (e.target.value != '') {
			const newTimer = setTimeout(() => {
				const arr = [];
				const newGetSearchNodes = (topNode, searchText) => {
					const { children = [] } = topNode;

					if (
						Object.values(topNode).some((v) => {
							if (v) return v.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1;
						})
					) {
						arr.push(topNode);
					} else {
						for (const child of children) {
							const foundArr = newGetSearchNodes(child, searchText);
							arr.concat(foundArr);
						}
					}
					return;
				};
				newGetSearchNodes(treeUser[0], e.target.value, arr);
				setSearchedItem(toggleExpandedForAll({ treeData: [{ ...treeUser[0], children: arr }], expanded: true }));
			}, time);
			setTimer(newTimer);
		} else {
			setSearchedItem([...treeUser]);
		}
	};

	useEffect(() => {
		if(isOpen){
      setDisableUserList(getLineUser(disabledList))
      setSelectedTreeUser([...list])
    }else{
      setSelectedTreeUser([])
    }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

  useEffect(() => {
    getTreeUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list])

  // useEffect(() => {
  //   console.log('searchedItem', searchedItem);
  // }, [searchedItem]);

	return (
		<Modal isOpen={isOpen} size='md' isCentered isStaticBackdrop={true}>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Alert color='light'>
					<small>{'결재라인에 포함된 인원은 선택하실수 없습니다.'}</small>
				</Alert>
				<div className='d-flex flex-row'>
					<div className={'col-sm-5 h-25 position-relative'}>
						<Button
							type='button'
							className='position-absolute top-0 end-0'
							style={{ zIndex: 1 }}
							onClick={() => {
								setSearchedItem([...treeUser]);
								searchRef.current.value = '';
							}}>
							<Icon icon='Close' size='lg' />
						</Button>
						<div>
							<Input
								ref={searchRef}
								placeholder={t('검색어를 입력하세요') + '...'}
								onChange={(e) => {
									debounce(e);
								}}
							/>
						</div>
						<div style={{ maxHeight: 'calc(100vh - 500px)', overflowY: 'auto', display: 'grid'}}>
							{/* <PinatTreeRander topNode={searchedItem[0]} depth={searchedItem[0]?.depth}/> */}
              {searchedItem.length > 0 &&
							<SortableTree
								style={{ minHeight: 500, borderWidth: 2 }}
								treeData={searchedItem}
								onChange={setSearchedItem}
								maxDepth={10}
								getNodeKey={({ node }) => node.id}
								canDrag={false}
								theme={FileExplorerTheme}
								generateNodeProps={(nodes) => {
									// console.log('nodes >> ', nodes);
									let nodeProps = {
										onClick: async (e) => {
											e.preventDefault();
											if (nodes?.node?.userId) {
												let selectusers = [...selectedTreeUser];
												// console.log('before selectusers', selectusers);
												const data = {
													rankId: nodes?.node?.userId?.userRankData?.id || 0,
													userId: nodes?.node?.userId,
													rank: nodes?.node?.userRank,
													companyId: nodes?.node?.companyid,
													positionId: nodes?.node?.userPositionData?.id || 0,
													departmentId: nodes?.node?.userDepartmentsData?.departmentId || 0,
													department: nodes?.node?.userDepartment,
													head: nodes?.node?.userDepartmentData?.head || false,
													name: nodes?.node?.name,
												};
												if (selectusers.find((data) => parseInt(data.userId) === parseInt(nodes?.node?.userId))) {
													selectusers = selectusers.filter((data) => parseInt(data.userId) !== parseInt(nodes?.node?.userId));
												} else {
													selectusers = [...selectusers, data];
												}
												// console.log('after selectedTreeUser', selectusers);
												setSelectedTreeUser(selectusers);
												// forceUpdate();
											}
										},
										title: (
											<div className='d-flex flex-row justify-content-center align-items-center'>
												{nodes?.node?.userId && (
													<Checks
														id={nodes?.node?.id}
														type={'checkbox'}
														// label={`${nodes?.node?.title} | ${t(nodes?.node?.userRank)}`}
														checked={selectedTreeUser.find((data) => data.userId === nodes?.node?.userId)}
														disabled={disableuserList.find((data) => data.userId === nodes?.node?.userId)}
														// onChange={() => {
														// 	const data = {
														// 		rankId: nodes?.node?.userId?.userRankData?.id || 0,
														// 		userId: nodes?.node?.userId,
														// 		rank: nodes?.node?.userRank,
														// 		companyId: nodes?.node?.companyid,
														// 		positionId: nodes?.node?.userPositionData?.id || 0,
														// 		departmentId: nodes?.node?.userDepartmentsData?.departmentId || 0,
														// 		department: nodes?.node?.userDepartment,
														// 		head: nodes?.node?.userDepartmentData?.head || false,
														// 		name: nodes?.node?.name,
														// 	};
														// 	if (selectedTreeUser.map((data) => data.userId).includes(nodes?.node?.userId)) {
														// 		setSelectedTreeUser(selectedTreeUser.filter((data) => data.userId !== nodes?.node?.userId));
														// 	} else {
														// 		setSelectedTreeUser([...selectedTreeUser, data]);
														// 	}
														//   forceUpdate();
														// }}
													/>
												)}
												{nodes?.node?.title || ''}
												{nodes?.node?.userId && ` | ${nodes?.node?.userRank || ''}`}
												{nodes?.node?.companyNumber && <span className='ms-2 small fw-light'>({nodes?.node?.companyNumber})</span>}
											</div>
										),
									};
									return nodeProps;
								}}
							/>
              }
						</div>
					</div>
					<div className={'col-sm-7'}>
						<div className='ms-5'>
							{selectedTreeUser?.map((selectedUser, index) => (
								<div className={'mb-2'} key={`selectedUser-${index}`}>{`${selectedUser.name} | ${t(selectedUser.rank)}`}</div>
							))}
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					type='button'
					color='danger'
					onClick={() => {
						setIsOpen(false);
					}}>
					{t('취소')}
				</Button>
				<Button
					type='button'
					color='info'
					onClick={() => {
						setList([...selectedTreeUser]);
						setIsOpen(false);
					}}>
					{t('대문자적용')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default SortableTreeModal;
