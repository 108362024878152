import React, { forwardRef } from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/bootstrap/Modal_v2";
import Button from "../../../../components/bootstrap/Button";
import {t} from "i18next";

const RestTypeSelectModal = forwardRef((props, ref) => {
  const pubData = [
    {txt: '월차', date: '2일 4시간'},
    {txt: '연차', date: '10일'},
    {txt: '공가', date: '1일'},
    {txt: '장기근속휴가', date: '1일'}
  ]
  return (
    <>
      <Modal
        size='lg'
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        titleId="example-title"
        isScrollable
        isBottomed
      >
        <ModalHeader setIsOpen={props?.setIsOpen} className='user-modal-header'>
          <ModalTitle>
            {t('휴가유형 선택')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>
            {
              pubData.map((v,i) => {
                return(
                  <div 
                    className='border-light border-bottom py-3 d-flex justify-content-between align-items-center text-dark cursor-pointer'
                    key={i}
                  >
                    <sapn>{t(v.txt)}</sapn>
                    <sapn className='fw-bold'>{t(v.date)}</sapn>
                  </div>
                )
              })
            }
          </div>
        </ModalBody> 
        <ModalFooter>
          <Button
            className='w-100 py-3'
            color="info"
            isDisable
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('선택완료')}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default RestTypeSelectModal;
