import React, { useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Card, {
	CardTitle,
	CardActions,
	CardHeader,
	CardBody,
} from '../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { setItem, getItem, removeItem } from '../../../lib/localstorage';
import AuthService from '../../../services/AuthService';
import showNotification from '../../../components/extras/showNotification';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../models';

const SendMailModal = (props) => {
	//console.log(props);

	const { t } = useTranslation(['translation', 'menu']);
	const { company } = useMst();
	const [isOpenFindUser, setIsOpenFindUser] = useState(false); // 사용자 관리 모달
	const [selectedUser, setSelectedUser] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);
	const [result, setResult] = useState(null);

	useEffect(() => {
		setSelectedUser(props.selectedUser);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedUser]);

	useEffect(() => {
		//console.log(props.isOpenSendMail)
		if (props.isOpenSendMail) setResult(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isOpenSendMail]);

	return (
		<Modal
			setIsOpen={props.setIsOpenSendMail}
			isOpen={props.isOpenSendMail}
			isStaticBackdrop={false}
			isScrollable={true}
			isCentered={true}
			// fullScreen={true}
		>
			<ModalHeader setIsOpen={props.setIsOpenSendMail}>
				<ModalTitle>{t('근태 메일 발송')}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card className='w-100 h-100 rounded-0' shadow='none' borderSize={1} isScrollable={true}>
					<CardBody isScrollable={true}>
						<div className='table-responsive'>
							<table className='table'>
								<thead>
									<tr>
										<td>#</td>
										<td>{t('이름')}</td>
										<td>{t('직책')}</td>
										<td>{t("이메일")}</td>
										<td>{t("성공여부")}</td>
									</tr>
								</thead>
								<tbody>
									{selectedUser?.map((searchUser, i) => {
										//console.log(searchUser)
										return (
											<tr key={`user-${searchUser?.userId}`}>
												<td>{i + 1}</td>
												<td>
													<span className=''>{searchUser.user.name}</span>
												</td>
												<td>
													{searchUser.rank ? (
														<span className='fw-light'>{` ${t(searchUser.rank.name)}`}</span>
													) : (
														'-'
													)}
												</td>
												<td>
													<span className='fw-light'>{` ${searchUser.user.email}`}</span>
												</td>
												<td>
													{t(result
														? result?.findIndex((v) => v === searchUser.user.email) > -1
															? t('실패')
															: t('성공')
														: t('대기'))}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</CardBody>
				</Card>
				<div className='text-start'>
					<Button color={'info'} onClick={props.setIsOpenFindUser}>
						{t('인원선택')}
					</Button>
				</div>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button
						type='button'
						color='success'
						className={'px-5'}
						isDisable={isDisabled}
						onClick={async () => {
							if (selectedUser.length == 0) {
								showNotification(
									t('근태보고 메일 발송'),
									t('메일을 보낼 사람을 선택해주세요.'),
									'danger',
								);
							} else {
								setItem('mailSelectedUser', JSON.stringify(selectedUser));
								setIsDisabled(true);
								await AuthService.attendReportMail2({
									companyId: company.get.id,
									selectedUser: selectedUser,
									attendData: props.attendData,
								}).then((response) => {
									setIsDisabled(false);
									setResult(response.data.res);
									// if (Array.isArray(response.data.res)) {
									//     showNotification('근태보고 메일 발송 실패', '전송 실패 목록 :' + response.data.res.join(), 'danger');
									// } else {
									//     response.data.res ?
									//         showNotification('근태보고 메일 발송', response.data.message, 'info')
									//         :
									//         showNotification('근태보고 메일 발송', response.data.message, 'danger')
									// }
								});
							}
						}}>
						{t('발송')}
					</Button>
					{/* <Button
                        type='button'
                        color='light'
                        onClick={() => {
                            props.setIsOpenSendMail()
                        }}>
                        {t('닫기')}
                    </Button> */}
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);
};
export default React.memo(SendMailModal);
