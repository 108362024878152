import React from 'react';
import Breadcrumb, {BreadcrumbItem} from "../../../components/bootstrap/Breadcrumb";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {SubHeaderLeft} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Badge from "../../../components/bootstrap/Badge";
import Popovers from "../../../components/bootstrap/Popovers";
import {useTranslation} from "react-i18next";


const EtcPage = () => {
    const { t } = useTranslation(['translation', 'menu']);
    return (
        <>
            <PageWrapper>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb>
                            <BreadcrumbItem tag={'nav'} to={'/'}>Etc Page</BreadcrumbItem>
                        </Breadcrumb>
                    </SubHeaderLeft>
                </SubHeader>

                <Page container='fluid'>
                    <h4>근무기록 라벨</h4>
                    <div className="label-working-log">
                        <div className="mb-4 text-start d-flex flex-row align-items-center ps-3">
                            <Popovers trigger={'hover'} desc={t('근무 기록변경')}>
                                <div className='lead'>
                                    <Badge rounded={1} color='info' isLight className={'fw-light p-1'}>
                                        {t('변')}
                                    </Badge>
                                </div>
                            </Popovers>
                        </div>

                        <div className="mb-4 text-start d-flex flex-row align-items-center ps-3">
                            <Popovers trigger={'hover'} desc={t('휴일근로')}>
                                <div className='lead'>
                                    <Badge rounded={1} color='secondary' isLight className={'fw-light p-1'}>
                                        {t('휴')}
                                    </Badge>
                                </div>
                            </Popovers>
                        </div>
                    </div>

                    <h4>스계줄관리 라벨</h4>
                    <div className="label-schedule">
                        <div className="mb-4">
                            <div className="react-calendar-timeline">
                                <div title="20110270사번" className="rct-item fw-bold text-dark"
                                     style={{
                                         fontSize: '12px',
                                         color: 'white',
                                         cursor: 'pointer',
                                         background: 'rgb(255, 227, 150)',
                                         border: '0px',
                                         zIndex: '80',
                                         // position: 'absolute',
                                         position: 'relative',
                                         boxSizing: 'border-box',
                                         // left: '1899.33px',
                                         // top: '319.8px',
                                         width: '195.377px',
                                         height: '36.4px',
                                         lineHeight: '36.4px'
                                     }}>
                                    <div className="rct-item-handler rct-item-handler-left rct-item-handler-resize-left"
                                         style={{
                                             position: 'absolute',
                                             width: '24px',
                                             maxWidth: '20%',
                                             minWidth: '2px',
                                             height: '100%',
                                             top: '0px',
                                             left: '0px',
                                             cursor: 'pointer',
                                             zIndex: '88'
                                         }}></div>
                                    <div className="rct-item-content w-100 border-dark border-1"
                                         style={{opacity: '0.5', borderStyle: 'dashed'}}>
                                        <div className="d-flex justify-content-between ms-2">
                                            <div><span className="bg-white px-1 py-1 me-2"
                                                       style={{borderRadius: '4px'}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                        viewBox="0 0 24 24"
                                                        width="1em"
                                                        className="svg-icon--material svg-icon svg-icon-sm text-info"
                                                        data-name="Material--DateRange"><path d="M0 0h24v24H0V0z"
                                                                                              fill="none"></path><path
                                                        d="M5 8h14V6H5z" opacity="0.3"></path><path
                                                        d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path></svg></span><span
                                                className="schedule-text fw-bold text-dark">20110270사번</span></div>
                                            <div className="me-2"><span
                                                className="fw-bold border px-2 py-1 border-light small bg-white me-1"
                                                style={{borderRadius: '4px'}}>18:06</span><span data-only-ref="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                width="1em"
                                                className="svg-icon--material svg-icon svg-icon-lg text-dark"
                                                data-name="Material--info"><path d="M0 0h24v24H0V0z" fill="none"></path><path
                                                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z"
                                                opacity="0.3"></path><path
                                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="text-nowrap px-2 py-0 d-flex flex-column justify-content-start align-items-start"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '-5px',
                                            transform: 'translate(100%, -50%)',
                                            borderLeft: '2px solid rgb(204, 204, 204)',
                                            zIndex: '85'
                                        }}>
                                        <p className="mb-0 lh-base"><span
                                            className="d-inline-block text-black-50">주간</span><span
                                            className="d-inline-block text-center"
                                            style={{width: '50px'}}>12:30</span><span
                                            className="d-inline-block text-black-50">야간</span><span
                                            className="d-inline-block text-center" style={{width: '50px'}}>-</span></p>
                                        <p
                                            className="mb-0 lh-base"><span
                                            className="d-inline-block text-black-50">연장</span><span
                                            className="d-inline-block text-center"
                                            style={{width: '50px'}}>12:30</span><span
                                            className="d-inline-block text-black-50">연장</span><span
                                            className="d-inline-block text-center" style={{width: '50px'}}>11:00</span>
                                        </p>
                                    </div>
                                    <div
                                        className="rct-item-handler rct-item-handler-right rct-item-handler-resize-right"
                                        style={{
                                            position: 'absolute',
                                            width: '24px',
                                            maxWidth: '20%',
                                            minWidth: '2px',
                                            height: '100%',
                                            top: '0px',
                                            right: '0px',
                                            cursor: 'pointer',
                                            zIndex: '88'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="react-calendar-timeline">
                                <div title="20110270사번" className="rct-item fw-bold text-dark"
                                     style={{
                                         fontSize: '12px',
                                         color: 'white',
                                         cursor: 'pointer',
                                         background: 'rgb(247, 161, 183)',
                                         border: '0px',
                                         zIndex: '80',
                                         // position: 'absolute',
                                         position: 'relative',
                                         boxSizing: 'border-box',
                                         // left: '1899.33px',
                                         // top: '319.8px',
                                         width: '195.377px',
                                         height: '36.4px',
                                         lineHeight: '36.4px'
                                     }}>
                                    <div className="rct-item-handler rct-item-handler-left rct-item-handler-resize-left"
                                         style={{
                                             position: 'absolute',
                                             width: '24px',
                                             maxWidth: '20%',
                                             minWidth: '2px',
                                             height: '100%',
                                             top: '0px',
                                             left: '0px',
                                             cursor: 'pointer',
                                             zIndex: '88'
                                         }}></div>
                                    <div className="rct-item-content w-100 border-dark border-1"
                                         style={{opacity: '0.5', borderStyle: 'dashed'}}>
                                        <div className="d-flex justify-content-between ms-2">
                                            <div><span className="bg-white px-1 py-1 me-2"
                                                       style={{borderRadius: '4px'}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                        viewBox="0 0 24 24"
                                                        width="1em"
                                                        className="svg-icon--material svg-icon svg-icon-sm text-info"
                                                        data-name="Material--DateRange"><path d="M0 0h24v24H0V0z"
                                                                                              fill="none"></path><path
                                                        d="M5 8h14V6H5z" opacity="0.3"></path><path
                                                        d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path></svg></span><span
                                                className="schedule-text fw-bold text-dark">20110270사번</span></div>
                                            <div className="me-2"><span
                                                className="fw-bold border px-2 py-1 border-light small bg-white me-1"
                                                style={{borderRadius: '4px'}}>18:06</span><span data-only-ref="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                width="1em"
                                                className="svg-icon--material svg-icon svg-icon-lg text-dark"
                                                data-name="Material--info"><path d="M0 0h24v24H0V0z" fill="none"></path><path
                                                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z"
                                                opacity="0.3"></path><path
                                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="rct-item-handler rct-item-handler-right rct-item-handler-resize-right"
                                        style={{
                                            position: 'absolute',
                                            width: '24px',
                                            maxWidth: '20%',
                                            minWidth: '2px',
                                            height: '100%',
                                            top: '0px',
                                            right: '0px',
                                            cursor: 'pointer',
                                            zIndex: '88'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="react-calendar-timeline">
                                <div title="20110270사번" className="rct-item fw-bold text-dark"
                                     style={{
                                         fontSize: '12px',
                                         color: 'white',
                                         cursor: 'pointer',
                                         background: 'rgba(240, 146, 175, 0.5)',
                                         border: '0px',
                                         zIndex: '80',
                                         // position: 'absolute',
                                         position: 'relative',
                                         boxSizing: 'border-box',
                                         animation: 'rct-item-box-shadow 2s 1s infinite linear alternate',
                                         // left: '1899.33px',
                                         // top: '319.8px',
                                         width: '195.377px',
                                         height: '36.4px',
                                         lineHeight: '36.4px',
                                         boxShadow: '0px 0px 10px red',

                                     }}>
                                    <div className="rct-item-handler rct-item-handler-left rct-item-handler-resize-left"
                                         style={{
                                             position: 'absolute',
                                             width: '24px',
                                             maxWidth: '20%',
                                             minWidth: '2px',
                                             height: '100%',
                                             top: '0px',
                                             left: '0px',
                                             cursor: 'pointer',
                                             zIndex: '88'
                                         }}></div>
                                    <div className="rct-item-content w-100 border-danger border-1"
                                         style={{borderStyle: 'dashed'}}>
                                        <div className="d-flex justify-content-between ms-2">
                                            <div><span className="bg-white px-1 py-1 me-2"
                                                       style={{borderRadius: '4px'}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                        viewBox="0 0 24 24"
                                                        width="1em"
                                                        className="svg-icon--material svg-icon svg-icon-sm text-info"
                                                        data-name="Material--DateRange"><path d="M0 0h24v24H0V0z"
                                                                                              fill="none"></path><path
                                                        d="M5 8h14V6H5z" opacity="0.3"></path><path
                                                        d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path></svg></span><span
                                                className="schedule-text fw-bold text-danger">20110270사번</span></div>
                                            <div className="me-2"><span
                                                className="fw-bold border px-2 py-1 border-light small bg-white me-1"
                                                style={{borderRadius: '4px'}}>18:06</span><span data-only-ref="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                width="1em"
                                                className="svg-icon--material svg-icon svg-icon-lg text-dark"
                                                data-name="Material--info"><path d="M0 0h24v24H0V0z" fill="none"></path><path
                                                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z"
                                                opacity="0.3"></path><path
                                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="rct-item-handler rct-item-handler-right rct-item-handler-resize-right"
                                        style={{
                                            position: 'absolute',
                                            width: '24px',
                                            maxWidth: '20%',
                                            minWidth: '2px',
                                            height: '100%',
                                            top: '0px',
                                            right: '0px',
                                            cursor: 'pointer',
                                            zIndex: '88'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="react-calendar-timeline">
                                <div title="20110270사번" className="rct-item fw-bold text-dark"
                                     style={{
                                         fontSize: '12px',
                                         color: 'white',
                                         cursor: 'pointer',
                                         background: 'red',
                                         border: '0px',
                                         zIndex: '80',
                                         // position: 'absolute',
                                         position: 'relative',
                                         boxSizing: 'border-box',
                                         // left: '1899.33px',
                                         // top: '319.8px',
                                         width: '195.377px',
                                         height: '36.4px',
                                         lineHeight: '36.4px',
                                         //  filter: 'drop-shadow(0px 0px 5px red)'
                                         //  filter: 'brightness(90%) contrast(150%)',
                                         //  backgroundBlendMode: 'multiply'

                                     }}>
                                    <div className="rct-item-handler rct-item-handler-left rct-item-handler-resize-left"
                                         style={{
                                             position: 'absolute',
                                             width: '24px',
                                             maxWidth: '20%',
                                             minWidth: '2px',
                                             height: '100%',
                                             top: '0px',
                                             left: '0px',
                                             cursor: 'pointer',
                                             zIndex: '88'
                                         }}></div>
                                    <div className="rct-item-content w-100 border-danger border-1"
                                         style={{borderStyle: 'dashed'}}>
                                        <div className="d-flex justify-content-between ms-2">
                                            <div><span className="bg-white px-1 py-1 me-2"
                                                       style={{borderRadius: '4px'}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" height="1em"
                                                        viewBox="0 0 24 24"
                                                        width="1em"
                                                        className="svg-icon--material svg-icon svg-icon-sm text-info"
                                                        data-name="Material--DateRange"><path d="M0 0h24v24H0V0z"
                                                                                              fill="none"></path><path
                                                        d="M5 8h14V6H5z" opacity="0.3"></path><path
                                                        d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path></svg></span><span
                                                className="schedule-text fw-bold text-white">20110270사번</span></div>
                                            <div className="me-2"><span
                                                className="fw-bold border px-2 py-1 border-light small bg-white me-1"
                                                style={{borderRadius: '4px'}}>18:06</span><span data-only-ref="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                width="1em"
                                                className="svg-icon--material svg-icon svg-icon-lg text-dark"
                                                data-name="Material--info"><path d="M0 0h24v24H0V0z" fill="none"></path><path
                                                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z"
                                                opacity="0.3"></path><path
                                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="rct-item-handler rct-item-handler-right rct-item-handler-resize-right"
                                        style={{
                                            position: 'absolute',
                                            width: '24px',
                                            maxWidth: '20%',
                                            minWidth: '2px',
                                            height: '100%',
                                            top: '0px',
                                            right: '0px',
                                            cursor: 'pointer',
                                            zIndex: '88'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="react-calendar-timeline">
                                <div title="20110270사번" className="rct-item fw-bold text-dark"
                                     style={{
                                         fontSize: '12px',
                                         color: 'white',
                                         cursor: 'pointer',
                                         background: 'rgba(200,0,0,1)',
                                         border: '0px',
                                         zIndex: '80',
                                         // position: 'absolute',
                                         position: 'relative',
                                         boxSizing: 'border-box',
                                         animation: 'rct-item-box-shadow 2s 1s infinite linear alternate',
                                         // left: '1899.33px',
                                         // top: '319.8px',
                                         width: '195.377px',
                                         height: '36.4px',
                                         lineHeight: '36.4px',
                                         //  filter: 'drop-shadow(0px 0px 5px red)'
                                         //  filter: 'brightness(90%) contrast(150%)',
                                         //  backgroundBlendMode: 'multiply'

                                     }}>
                                    <div className="rct-item-handler rct-item-handler-left rct-item-handler-resize-left"
                                         style={{
                                             position: 'absolute',
                                             width: '24px',
                                             maxWidth: '20%',
                                             minWidth: '2px',
                                             height: '100%',
                                             top: '0px',
                                             left: '0px',
                                             cursor: 'pointer',
                                             zIndex: '88'
                                         }}></div>
                                    <div className="rct-item-content w-100 border-danger border-1"
                                         style={{borderStyle: 'dashed'}}>
                                        <div className="d-flex justify-content-between ms-2">
                                            <div><span className="bg-white px-1 py-1 me-2"
                                                       style={{borderRadius: '4px'}}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                    width="1em"
                                                    className="svg-icon--material svg-icon svg-icon-sm text-info"
                                                    data-name="Material--DateRange"><path d="M0 0h24v24H0V0z"
                                                                                          fill="none"></path><path
                                                    d="M5 8h14V6H5z" opacity="0.3"></path><path
                                                    d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z"></path></svg></span>
                                                <span
                                                    className="schedule-text fw-bold text-white text-decoration-line-through">20110270사번</span>
                                            </div>
                                            <div className="me-2"><span
                                                className="fw-bold border px-2 py-1 border-light small bg-white me-1"
                                                style={{borderRadius: '4px'}}>18:06</span><span data-only-ref="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"
                                                width="1em"
                                                className="svg-icon--material svg-icon svg-icon-lg text-dark"
                                                data-name="Material--info"><path d="M0 0h24v24H0V0z" fill="none"></path><path
                                                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z"
                                                opacity="0.3"></path><path
                                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="rct-item-handler rct-item-handler-right rct-item-handler-resize-right"
                                        style={{
                                            position: 'absolute',
                                            width: '24px',
                                            maxWidth: '20%',
                                            minWidth: '2px',
                                            height: '100%',
                                            top: '0px',
                                            right: '0px',
                                            cursor: 'pointer',
                                            zIndex: '88'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </PageWrapper>
        </>
    )
}

export default EtcPage;
