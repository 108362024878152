import { types } from 'mobx-state-tree';
import moment from 'moment';
import { extraMenu } from '../menu';
import CompanyService from '../services/CompanyService';

// 개인 연차 정보
const restInfo = types
    .model({
        extraRestDay: types.optional(types.number, 0),
		joinDate: types.optional(types.string, ''),
		joinYear: types.optional(types.number, 0),
		lastdate: types.optional(types.string, ''),
		lastday: types.optional(types.number, 0),
		reqRest: types.optional(types.number, 0),
		rest: types.optional(types.number, 0),
		restM: types.optional(types.number, 0),
		//restTable: types.optional(types.array, []),
		restY: types.optional(types.number, 0),
		resttype: types.optional(types.string, ''),
		resttype2: types.optional(types.number, 0),
		susRest: types.optional(types.number, 0),
		userest: types.optional(types.number, 0),
		reqMonthRest: types.optional(types.number, 0),
		susMonthRest: types.optional(types.number, 0),
    });

// 개인 조직 정보
const departInfo = types
	.model({
		departId: types.optional(types.number, 0),
		departName: types.optional(types.string, ''),		
		positionName: types.optional(types.string, ''),
		head: types.optional(types.boolean, false),
		main: types.optional(types.boolean, false),
	})



export const Company = types
	.model('Company', {
		id: types.optional(types.number, 0),
		uuid: types.maybeNull(types.string),
		name: types.maybe(types.string),
		tel: types.maybeNull(types.string),
		logo: types.maybeNull(types.string),
		address: types.maybe(types.string),		
		addressDetail: types.maybeNull(types.string),
		createdAt: types.maybe(types.string),
		udpatedAt: types.maybe(types.string),
		companyNumber: types.maybe(types.string),
		isAdmin: types.maybeNull(types.boolean),
		isDoc: types.maybeNull(types.boolean),
		isSetting: types.maybeNull(types.boolean),
		ownerUserId: types.maybeNull(types.number),
		companyPeriod: types.maybeNull(types.array(types.string)),
		restType: types.maybeNull(types.boolean),
		restDate: types.maybeNull(types.string),
		securityDevice: types.maybeNull(types.boolean),
		companyAnniversary: types.maybeNull(types.string),
		
		// 회사 휴가 옵션
		restOptionMonth: types.maybeNull(types.string),
		restDestoryMonth: types.maybeNull(types.boolean),
		restGraceMonth: types.maybeNull(types.integer),

		restOptionYear: types.maybeNull(types.integer),
		restRoundYear: types.maybeNull(types.string),
		restDestoryYear: types.maybeNull(types.boolean),
		restGraceYear: types.maybeNull(types.integer),
		
		restUseUnit: types.maybeNull(types.string),
		restPromote: types.maybeNull(types.boolean),

		restInfo: types.optional(types.string, '{}'), // restInfo
		departInfos: types.optional(types.array(departInfo), []),	

		nickName: types.maybe(types.string),
		employType: types.maybe(types.string),

		userRank: types.maybe(types.string),
		userDepartment: types.maybe(types.string),
		approvalSelf: types.maybeNull(types.boolean),

		//연차설정 
		restAccrualTransfer: types.maybeNull(types.boolean),
		restAccrualTransferMax : types.maybeNull(types.integer),
		restAccrualTransferGrace : types.maybeNull(types.integer),
		restAllowNegativeAccrual :  types.maybeNull(types.boolean),
		restNegativeAccrualMax : types.maybeNull(types.integer),

		// 개인연차옵션 (미설정시 회사설정)
		userRestType: types.maybeNull(types.boolean),
        userRestDate: types.maybeNull(types.string),
        userRestOptionYear: types.maybeNull(types.integer),
        userRestRoundYear: types.maybeNull(types.string),
	})
	.views((self) => ({
		get get() {
			return { ...self, restInfo: JSON.parse(self.restInfo) };
		},
	}))
	.actions((self) => ({
		async selectCompany(id) {
			//console.log('select > ', id)
			const response = await CompanyService.selectCompany(id);
			// console.log("selectCompany >> ", response)
/*
2023-12-06 16:10:45: tmp : {
  id: 8,
  userId: 5,
  uuid: null,
  name: '미유',
  tel: '1234',
  postCode: null,
  address: '서울 중구 다산로 지하 115 (신당동, 약수역 6호선)',
  addressDetail: '',
  restType: false,
  restDate: null,
  restOptionMonth: null,
  restDestoryMonth: true,
  restGraceMonth: 0,
  restOptionYear: 1,
  restRoundYear: 'ROUND',
  restDestoryYear: true,
  restGraceYear: 0,
  restUseUnit: 'HALF',
  restPromote: false,
  securityDevice: false,
  logo: '',
  ceoName: '',
  registrationNumber: '',
  corporationNumber: '',
  createdAt: 2023-12-06T06:16:11.733Z,
  companyAnniversary: 2023-12-06T06:16:11.733Z,
  approvalSelf: false,
  faxNumber: null,
  restAccrualTransfer: false,
  restAccrualTransferMax: 0,
  restAllowNegativeAccrual: false,
  restNegativeAccrualMax: 0,
  updatedAt: 2023-12-06T06:16:11.733Z,
  deletedAt: null,
  user_id: 5,
  restTypeList: [
    restday {
      dataValues: [Object],
      _previousDataValues: [Object],
      uniqno: 1,
      _changed: Set(0) {},
      _options: [Object],
      isNewRecord: false
    }
  ],
  roleName: '대표 관리자',
  permission: [ 'ADMIN' ],
  isAdmin: true,
  userCount: 1,
  role: {
    id: 21,
    companyId: 8,
    name: '대표 관리자',
    permission: [ 'ADMIN' ],
    permissions: { menuRole: [Object], basicRole: [Object], detailRole: [Object] },
    type: '',
    createdAt: 2023-12-06T06:16:11.747Z,
    updatedAt: 2023-12-06T06:16:11.747Z,
    deletedAt: null,
    company_id: 8
  },
  companyPeriod: [ '23.12.06', '24.01.06' ],
  ownerUserId: 5,
  admin: '',
  userRank: '대표이사',
  nickName: '',
  userDepartment: '',
  userRestType: false,
  userRestDate: null,
  userRestOptionYear: 1,
  userRestRoundYear: 'ROUND',
  heads: []
}
*/


			if(response.data?.id) {
				// console.log(">>>", response.data);
				self.setData(response.data);
				// self.setIsAdmin(false);
				self.setIsAdmin(response.data.isAdmin);		
				self.setDepartInfo(response.data.departments);

			} else {				
				window.location.replace(extraMenu.changeCompany.path);
			}
		},
		async getDepartInfo() {
			try { 
				const response = await CompanyService.selectCompany(self.id);
				self.setDepartInfo(response.data.departments)
			} catch (err) {
				//
			}
		},
		async setRestInfo(data) {			
			// console.log('setRestInfo > ', data);
			self.restInfo = JSON.stringify(data);
		},
		async setDepartInfo(data) {
			self.departInfos = data
		},		
		async setData(data) {
			self = Object.assign(self, data);
		},
		async setIsAdmin(data) {
			self.isDoc = data;			
		},
		async setCompanyPeriod(data) {
			self.companyPeriod = data;
		},
		async logout() {
			self.id = 0;
			self.name = '';
			self.uuid = '';
			self.tel = '';
			self.logo = '';
			self.address = '';
			self.addressDetail = '';
			self.createdAt = '';
			self.udpatedAt = '';
			self.isAdmin = false;
			self.isSetting = true;
			self.ownerUserId = 0;
			self.companyPeriod = ['', ''];
			self.securityDevice = false;
			self.companyNumber = '';
			self.employType = '';

			self.restOptionMonth = '';
			self.restDestoryMonth = true;
			self.restGraceMonth = 0;
			self.restOptionYear = 0;
			self.restRoundYear = 'ROUND';
			self.restDestoryYear = true;
			self.restGraceYear = 0;
			self.restUseUnit = 'HALF';
			self.restPromote = false;

			self.restInfo = '{}';
			self.departInfos = [];		
			
			self.nickName = '';
			self.userRank = '';
			self.userDepartment = '';

			self.userRestType = null;
			self.userRestDate = null;
			self.userRestOptionYear = null;
			self.userRestRoundYear = null;

			self.approvalSelf = false;

		},
	}));
