import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import interact from 'interactjs';
import { coordinateToTimeRatio } from 'react-calendar-timeline/lib/lib/utility/calendar';
import {
  getSumOffset,
  getSumScroll,
} from 'react-calendar-timeline/lib/lib/utility/dom-helpers';
import PropTypes from 'prop-types';

class WorkingSchduleDraggable extends Component {

	static propTypes = {
		handleItemDrop: PropTypes.func,
		timelineRef: PropTypes.object,
		scrollRef: PropTypes.shape({
			current: PropTypes.object,
		}),
		children: PropTypes.node,
		data: PropTypes.object,
	};
	handleItemDrop = (e) => {
		const {
			props: {
				timelineRef,
				scrollRef: { current: scrollRef },
				data,
			},
		} = this;
		const { canvasTimeStart, visibleTimeStart, visibleTimeEnd, groupTops, width, groups } = timelineRef.current.state;
		console.log('timelineRef.current', timelineRef.current)

		const canvasWidth = width * 3;
		const zoom = visibleTimeEnd - visibleTimeStart;
		const canvasTimeEnd = zoom * 3 + canvasTimeStart;
		const ratio = coordinateToTimeRatio(canvasTimeStart, canvasTimeEnd, canvasWidth);

		const { offsetLeft, offsetTop } = getSumOffset(scrollRef);
		const { scrollLeft, scrollTop } = getSumScroll(scrollRef);
		const { pageX, pageY } = e;

		const x = pageX - offsetLeft + scrollLeft;
		const y = pageY - offsetTop + scrollTop;

		const start = x * ratio + canvasTimeStart;

		let groupKey = '';
		for (const key of Object.keys(groupTops)) {
			const groupTop = groupTops[key];
			if (y > groupTop) {
				groupKey = groups[key].id;
			} else {
				break;
			}
		}

		if (groupKey === '' || pageX < offsetLeft || pageX > offsetLeft + width) {
			return;
		}

		this.props.handleItemDrop({ data, start, groupKey });
	};

	componentDidMount = () => {
		let x, y;
		this.interactable = interact(this.item.current)
			.draggable({ enabled: true })
			.on('dragstart', (e) => {
				({ pageX: x, pageY: y } = e);
			})
			.on('dragmove', (e) => {
				const { pageX, pageY } = e;
				e.target.style.transform = `translate(${pageX - x}px, ${pageY - y}px)`;
			})
			.on('dragend', (e) => {
				e.target.style.transform = '';
				this.handleItemDrop(e);
			});
	};
	componentWillUnmount() {
		this.interactable.unset();
	}

	item = createRef();

	render() {
		return <div ref={this.item}>{this.props.children}</div>;
	}
}
  
export default WorkingSchduleDraggable