import { getRelativePath } from 'mobx-state-tree';
import { APIClient } from '../lib/apiHelper';

const get = new APIClient().get;
const post = new APIClient().post;
const put = new APIClient().put;
const remove = new APIClient().delete;

export default {
	/**
	 * 근무기록 수정요청
	 * @param modifyInfo - {attendId, attendDate, attendMemo, leaveId, leaveDate, leaveMemo}
	 */
	modifyWorkRequest: (modifyInfo) => put('/attend/modify-request', modifyInfo),

	/**
	 * 근무기록 수정요청 리스트
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	modifyWorkRequestList: (companyId) => get('/attend/modify-request?companyId=' + companyId),
	/**
	 * 휴가 요청 리스트
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	restDayRequestList: (companyId) => get('/attend/restday-request?companyId=' + companyId),
	/**
	 * 근태 승인 관리
	 * @param id
	 * @param ok
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	attentionRequestConfirm: (id, ok) => put('/attend/request-confirm', { id, ok }),
	/**
	 * 기간별 근무 기록
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	workRecord: (companyId, groupId, startDate = null, endDate = null) =>
		get('/attend?companyId=' + companyId + '&groupId=' + groupId + (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '')),
	/**
	 * 오늘 내 근무 기록
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	todayWorkRecord: (companyId) => get('/attend/today?companyId=' + companyId),
	/**
	 * 오늘의 근무 통계
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	todayEmployTypeCount: (companyId, groupId, sdate, edate, type) =>
		get('/attend/statistics/today?companyId=' + companyId + '&groupId=' + groupId + '&type=' + type + '&sdate=' + sdate + '&edate=' + edate),
	/**
	 * 기간별 유저 근무 통계
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	usersWorkStatistic: (companyId, startDate = null, endDate = null) => get('/attend/statistics?companyId=' + companyId + (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '')),
	/**
	 * 기간별 근무 통계
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	workStatistic: (companyId, startDate = null, endDate = null) => get('/attend/statistics/month?companyId=' + companyId + (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '')),
	/**
	 * 유저 연차 사용 현황
	 * @param companyId
	 */
	userRestDayList: (companyId) => get('/attend/restday?companyId=' + companyId),
	/**
	 * 기간별 유저 근무 기록
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param userId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	userWorkday: (companyId, startDate = null, endDate = null, userId) =>
		get('/attend/workday?companyId=' + companyId + (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '') + '&userId=' + userId),
	/**
	 * 출퇴근 기록하기
	 * @param companyId
	 * @param memo
	 * @param attendType
	 * @param lat
	 * @param lng
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	attend: (companyId, memo, attendType, lat, lng) => post('/attend', { companyId, memo, attendType, lat, lng }),
	/**
	 * 출퇴근 기록 (웹용)
	 * @returns
	 */
	attendWeb: (companyId, memo, attendType) => post('/attend/web', { companyId, memo, attendType }),
	/**
	 * 기간별 유저 근무 기록 (어드민)
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	adminWorkday: (companyId, startDate = null, endDate = null) => get('/attend/workday/admin?companyId=' + companyId + (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : '')),
	/**
	 * 그룹별 유저 근무 기록 (어드민)
	 * @param groupId
	 * @param date
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	groupWorkday: (groupId, sdate = null, edate = null, companyId, type) =>
		get('/attend/group/admin?groupId=' + groupId + '&sdate=' + sdate + '&edate=' + edate + '&companyId=' + companyId + '&type=' + type),

	/**
	 * 그룹별 유저 근무 기록2 (어드민)
	 * @param {*} companyId
	 * @returns
	 */
	getLogAdmin: (groupId, sdate = null, edate = null, companyId, type, userId, page) =>
		get('/attend/log/admin?groupId=' + groupId + '&sdate=' + sdate + '&edate=' + edate + '&companyId=' + companyId + '&type=' + type + '&userId=' + userId + '&page=' + page),
	/**
	 * 근무기록 New 버젼..
	 * @param {*} data
	 * @returns
	 */
	getLogAdmin2: (data) => post('/attend/log/admin2', data),
	getLogAttend: (groupId, sdate = null, edate = null, companyId, type, userId) =>
		get('/attend/log?groupId=' + groupId + '&sdate=' + sdate + '&edate=' + edate + '&companyId=' + companyId + '&type=' + type + '&userId=' + userId),

	attendLogWeeklyList: (sdate = null, edate = null, use = '', companyId) => get('/attend/log/weekly?sdate=' + sdate + '&edate=' + edate + '&companyId=' + companyId + '&use=' + use),

	//휴가대상자 리스트
	attendVacationList: (companyId, startDate = null, endDate = null, type = '', users = null) => get('/vacation?startDate=' + startDate + '&endDate=' + endDate + '&companyId=' + companyId + '&type=' + type + (users ? '&users=' + users : '')),

	//휴가대상자 리스트 - paging
	attendVacationPage: (companyId, startDate = null, endDate = null, type = '', page, size, filters) => get('/vacation/paging?companyId=' + companyId + '&startDate=' + startDate + '&endDate=' + endDate + '&type=' + type + '&page=' + page + '&size=' + size + '&filters=' + (filters ? JSON.stringify(filters) : '')),

	//휴가/보상 부여내역
	attendVacationHistoryList: (companyId, sdate = null, edate = null, type = '') => get('/vacation/history?companyId=' + companyId + '&sdate=' + sdate +'&edate=' + edate + '&type=' + type),

  //휴가/보상 부여/회수
	attendVacationManage: (data) => post('/vacation', data),

	// attendLogWeeklyPush: (id,companyId,type) => put('/attend/log/weekly?id=' +
	// id +
	// '&companyId=' +
	// companyId +
	// '&type=' +
	// type

	// ),

	attendLogWeeklyPush: (data) => put('/attend/log/weekly', data),

  attendTodayInfo: (companyId, userId) => get('attend/todayInfo?companyId=' + companyId, '&userId=' + userId),

	attendNew: (attend) => post('/attend/request', attend),

	excelUpload: (excel) => post('/attend/storage-person-record', excel),

	adminAttendHistory: (companyId) => get('/dashboard/attend-history?companyId=' + companyId),

	requestRestCount: (companyId) => get('/dashboard/requestRestCount?companyId=' + companyId),

	check52: (userId, startTime, endTime, reqType = '연장') =>
		get('/attend/check52?userId=' + userId + '&startTime=' + startTime + '&endTime=' + endTime + '&reqType=' + reqType),

	setWorktimeBySchedule: (data) => post('/attend/log/worktimeBySchedule', data),
	setWorktimeBySchedule2: (data) => post('/attend/log/worktimeBySchedule2', data),
	/** 근무기록 삭제 */
	deleteWorkRecord: (attend) => put('/attend/delete', attend),
};
