import moment from 'moment';
import React, { useEffect, useState, useCallback, memo, useRef, useMemo, useContext } from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import useRequest from '../../../../hooks/useRequest';
import { useMst } from '../../../../models';
import CompanyService from '../../../../services/CompanyService';
import { usersMenu } from '../../../../menu';
import Badge from '../../../../components/bootstrap/Badge';
import Button from '../../../../components/bootstrap/Button';
import Select from '../../../../components/bootstrap/forms/Select';
import { useTranslation } from 'react-i18next';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import showNotification from '../../../../components/extras/showNotification';
import { Base64 } from 'js-base64';
import ThemeContext from '../../../../contexts/themeContext';
const UserApprovalNeedCard = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(30);
	const { user, company } = useMst();
	// const { responseData, loading, request } = useRequest([]);
	const [loading, setLoading] = useState(true);
	const [responseData, setResponseData] = useState([]);
	const [approvalOriginal, setApprovalOriginal] = useState([]);
	const ref = useRef();
	const [selectedDate, setSelectedDate] = useState({
		startDate: moment().startOf('year').format('YYYY-MM-DD'),
		endDate: moment().endOf('year').format('YYYY-MM-DD'),
	});
	const columns = useMemo(() => [{ name: '기안자' }, { name: '직위' }, { name: '유형' }, { name: '기안일' }], []);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);
	const { setAsdieSubMenuStatus, setAsideMenu, setEApproval, eApproval, mobileDesign } = useContext(ThemeContext);
	
	const [tab, setTab] = useState('request')
	const [selectState, setSelectState] = useState(0);

	// const refresh = async () => {
	// 		setLoading(true);
	// 		await CompanyService.getEApproval(company.get.id,user.me.id, {sdate: moment().startOf('year'), edate: moment().endOf('year')}).then((resAgain) => {
	// 			let data = {
	// 					approvalReportAll: resAgain?.data.approvalReportAll, // 결재상신 전체
	// 					approvalReportRequest: resAgain?.data.approvalReportRequest, // 결재진행
	// 					approvalReportSuccess: resAgain?.data.approvalReportSuccess, // 결재완료
						
	// 					approvalReceiptAll: resAgain?.data.approvalReceiptAll, // 결재수신 전체
	// 					approvalReceiptRequest: resAgain?.data.approvalReceiptRequest, // 대기
	// 					approvalReceiptSuccess: resAgain?.data.approvalReceiptSuccess, // 완료
	// 					approvalReceiptListReject: resAgain?.data.approvalReceiptListReject,
	// 					approvalReceiptList: resAgain?.data.approvalReceiptList,
						
	// 					approvalReceiptProxy: resAgain?.data.approvalReceiptProxy,
	// 					approvalReceiptCC: resAgain?.data.approvalReceiptCC,
	// 				};
	// 				setApprovalOriginal(data);
	// 		});
	// 	}
	const setEApprovalFuncCount = (EApprovalData) => {
		let r = {};
		Object.keys(EApprovalData)?.map((v) => {
			r[v] =EApprovalData[v] || 0;
		});
		setEApproval(r);
	}
	const refresh = async () => {
		// setAsdieSubMenuStatus(true);
		// setAsideMenu(company.get.isDoc ? eapproval_admin : eapproval);
		const resApprovalList = await CompanyService.getEApproval(company.get.id, company.get.isDoc ? 0 : user.me.id, {returnType: 'count'});
		
		// await CompanyService.getAbsenteeAll(company.get.id).then((res) => {
		// 	if (res.data) {
		// 		setAbsenteeList(company.get.isDoc ? res.data : res.data.filter((absenteeItem) => absenteeItem.userId === user.me.id || absenteeItem.deputyUserId === user.me.id));
		// 		// setOriginData(company.get.isDoc ? res.data : res.data.filter((absenteeItem) => absenteeItem.userId === user.me.id || absenteeItem.deputyUserId === user.me.id));
		// 	}
		// });

		if(resApprovalList?.data)
			setEApprovalFuncCount(resApprovalList.data)

		// setApprovalOriginal(resApprovalList.data);		
		const remainingRequests = Math.floor((resApprovalList?.data.approvalReportAll || 0) / (perPage * currentPage)) + 1;
		const reqStartNum = currentPage;
		let prev = {
			approvalReportAll: [],
			approvalReportRequest: [],
			approvalReportSuccess: [],

			approvalReceiptAll: [],
			approvalReceiptRequest: [],
			// approvalReceiptSuccess: [],
			// approvalReceiptListReject: [],
			approvalReceiptList: [],

			approvalReceiptProxy: [],
			approvalReceiptCC: []
		};
		for (let i = 0; i < remainingRequests; i++) {
			await CompanyService.getEApproval(company.get.id, company.get.isDoc ? 0 : user.me.id, {size: perPage, page: reqStartNum + i}).then((resAgain) => {
				// console.log('resAgain.data', resAgain.data);
				prev = {
					approvalReportAll: [...prev?.approvalReportAll, ...resAgain?.data.approvalReportAll],
					approvalReportRequest: [...prev?.approvalReportRequest, ...resAgain?.data.approvalReportRequest],
					approvalReportSuccess: [...prev?.approvalReportSuccess, ...resAgain?.data.approvalReportSuccess],
					
					approvalReceiptAll: [...prev?.approvalReceiptAll, ...resAgain?.data.approvalReceiptAll],
					approvalReceiptRequest: [...prev?.approvalReceiptRequest, ...resAgain?.data.approvalReceiptRequest],
					// approvalReceiptSuccess: [...prev?.approvalReceiptSuccess, ...resAgain?.data.approvalReceiptSuccess],
					// approvalReceiptListReject: [...prev?.approvalReceiptListReject, ...resAgain?.data.approvalReceiptListReject],
					approvalReceiptList: [...prev?.approvalReceiptList, ...resAgain?.data.approvalReceiptList],
					
					approvalReceiptProxy: [...prev?.approvalReceiptProxy, ...resAgain?.data.approvalReceiptProxy],
					approvalReceiptCC: [...prev?.approvalReceiptCC, ...resAgain?.data.approvalReceiptCC]
				}
				setApprovalOriginal(prev);
				// setEApprovalFunc(sumObj)
			});
		}
	}
	useEffect(()=> {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let filterList = { ...approvalOriginal };
		filterList = (tab === 'request' ? filterList.approvalReportAll : filterList.approvalReceiptAll);

		if(selectState === '0') { //전체
			filterList = filterList?.filter(i => i.state === 'REQUEST' || i.state !== 'REQUEST');
		} else if (selectState === '1') { //진행
			filterList = filterList?.filter(i => i.state === 'REQUEST');
		} else if (selectState === '2'){ //완료
			filterList = filterList?.filter(i => i.state !== 'REQUEST');
		}

		setResponseData(filterList);
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approvalOriginal, tab, selectState]);


	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const rowData = responseData?.[rowIndex - 1];

			switch (columnIndex) {
				case 0:
					data = (
						<div>
							<Avatar
								className='me-1'
								round
								textSizeRatio={0.1}
								size={30}
								name={rowData?.user?.name || ''}
								src={rowData?.user?.profile || null}
							/>
							{rowData.user?.name}
						</div>
					);
					break;
				case 1:
					data = rowData?.rank?.name || '-';
					break;
				case 2:
					data = rowData?.restday?.type || '-';
					break;
				case 3:
					data = rowData?.createdAt ? moment(rowData.createdAt).format('YYYY-MM-DD') : '-';
					break;
			}

			return data;
		},
		[responseData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(columns[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer ${
							columnIndex === 0 ? 'justify-content-start' : 'justify-content-center'
						} ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
						onClick={() => {
							const cryptoUrl = Base64.encode(`${company.get.id}_${responseData[rowIndex - 1].id}`);
							navigate(`/e-approval/document/${cryptoUrl}`);							
						}}>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, columns, responseData, navigate, renderText, t, company.get.id]
	);

	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			<CardHeader className={`rounded-2 ${mobileDesign && 'd-flex flex-row pb-0'}`}>
				<CardLabel>
					<CardTitle className='cursor-pointer'>
						<sapn onClick={() => setTab('request')} className={`me-2 pb-1 ${tab === 'response' ? 'text-muted' : 'border-2 border-bottom border-info'}`}>{t('요청한 결재')}</sapn>
						<span onClick={() => setTab('response')} className={`mx-2 pb-1 ${tab === 'request' ? 'text-muted' : 'border-2 border-bottom border-info'}`}>{t('받은 결재')}</span>
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Badge color='light' className='text-wrap'>
						<span className='text-dark'>{t('총 상신 수')} : </span>
						<span className='fw-bold text-info fs-6'>{responseData?.length || 0}</span>
					</Badge>
				</CardActions>
			</CardHeader>
			<CardBody
				className={`dashboard-height ${mobileDesign && 'pt-0'}`}
				ref={ref}
				onMouseOver={(e) => {
					if (ref?.current?.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					if(ref?.current) {
						ref.current.scroll = true;
						document.body.style.overflow = 'auto';
						document.body.style.removeProperty('padding-right');
					}
				}}>
				{loading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>데이터를 불러오고 있습니다.</p>
					</div>
				) : responseData?.length < 0 ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<Icon size={'9x'} color={'success'} icon={'CheckCircle'} />
						<p>요청받은 결재가 없습니다.</p>
					</div>
				) : (
					<div>
						<div className='d-flex justify-content-between align-items-center'>
							<div className='d-flex align-items-center'>
								<Icon icon='CalendarToday' color='info' size='lg' className='me-2' />
								<span className='text-info fs-5 fw-bold'>{moment().year() + '년'}</span>
							</div>
							<div>
							<Select
								ariaLabel='select State'
								list={[
									{ value: 0, text: t('전체') },
									{ value: 1, text: t(tab === 'request' ? '진행중' : '대기중') },
									{ value: 2, text: t('완료') },
								]}
								value={selectState}
								onChange={(e) => {
									console.log(e.target.value);
									setSelectState(e.target.value);
									// let list = { ...approvalOriginal };
									// if (e.target.value === 0) {
									// 	list = list?.approvalReportAll;
									// } else if (e.target.value === 1) {
									// 	list = list?.approvalReportRequest;
									// } else {
									// 	list = list?.approvalReportSuccess;
									// }
									// setResponseData(list);
								}}
							/>
							</div>
						</div>
						<div
							className='overflow-scroll mt-4'
							style={{height: 'calc(40vh - 74px)'}}
						>
							{
								 responseData
								 ?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
								 .map((v, i) => {
									 const linesArray = v?.approvals?.map((approval) => approval.line).flat() || [];
									 const approvalIndex = linesArray?.findIndex(
										 (ix) => ix?.user?.userId === (Object.keys(v?.who).length > 0 ? v?.who?.users[0]?.userId : 0)
									 );
								  //responseData?.map((v, i) => {
									//const linesArray = v?.approvals?.map((approval) => approval.line).flat() || [];

									// console.log('Lines array:', linesArray);

									//const approvalIndex = linesArray.findIndex((ix) => ix?.user?.userId === (Object.keys(v?.who).length > 0 ? v?.who?.users[0]?.userId : 0));

									let data = {
										bg: '',
										fc: '',
										name: ''
									};

									if(v?.type === 'WORK') {
										if(v?.restday?.name === '시차출퇴근' || v?.restday?.name === '탄력' || v?.restday?.name === '특근') {
											data = {
												bg: '#EEECF4',
												fc: '#1A0166',
												name: '근무'
											}
										} else if (v?.restday?.name === '연장') {
											data = {
												bg: '#FDEAEC',
												fc: '#66020D',
												name: '연장'
											}
										} else if (v?.restday?.name === '출장') {
											data = {
												bg: '#FDF9EA',
												fc: '#661A02',
												name: '출장'
											}
										}
									} else if(v?.type?.includes('REST')) {
										data = {
											bg: '#EEF4EC',
											fc: '#096601',
											name: '휴가'
										}
									} else {
										data = {
											bg: '#EEECF4',
											fc: '#1A0166',
											name: v.restday?.name
										}
									}
									return (
										<div className={`border border-light rounded py-3 px-4 d-flex align-items-start cursor-pointer ${i == 0 ? '' : 'mt-3'}`} key={i} onClick={()=> {
											navigate(usersMenu.approvalDocument.path, {
												state: {
													id: responseData[i]?.id,
												},
											});
										}}>
											<div className='me-3'>
											<Badge className={`px-2`} color={data?.bg} style=	{{backgroundColor: data?.bg}}>
												<span className='fs-6' 
												style={{color: data?.fc}}>{t(data?.name)}</span>
											</Badge>
											</div>
											<div className='w-100'>
												<div className='d-flex justify-content-between'>
													<span className='fs-5 fw-bold'>{v?.restday === null ? v?.data?.title: v?.restday?.name}</span>
													<span className='text-muted'>{moment(v?.createdAt).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') && Math.abs(moment(v?.createdAt, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'day')) <= 7 ? Math.abs(moment(v?.createdAt, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'day')) == 0 ? '오늘' :Math.abs(moment(v?.createdAt, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'day')) + "일 전" : 
  												moment(v?.createdAt).format('YYYY.MM.DD')}</span>
												</div>
											{tab === 'request' ? 
												<div className='d-flex align-items-center mt-3'>
													<Icon icon='Person' className='me-2 text-muted' />
													<span className='fw-bold'>{approvalIndex === -1 ? linesArray?.length : approvalIndex}</span>
													<span className='mx-1'>/</span>
													<span className='text-info fw-bold'>{linesArray?.length}</span>
												</div>
												:
												<div className='d-flex align-items-center mt-3'>
													<Icon className='me-2 text-muted' icon='Person'/>
													<span className='text-muted'>{v?.user?.name}&nbsp;{v?.rankName}</span>
												</div>
											}
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default memo(UserApprovalNeedCard);
