import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../../components/bootstrap/Modal';
import { useFormik } from 'formik';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Card, { CardLabel, CardBody, CardHeader, CardTitle, CardFooter, CardFooterRight, CardSubTitle, CardActions } from '../../../../../components/bootstrap/Card';
import Icon from '../../../../../components/icon/Icon';
import tinycolor from 'tinycolor2';
import { right } from '@popperjs/core';
import Label from '../../../../../components/bootstrap/forms/Label';
import Popovers from '../../../../../components/bootstrap/Popovers';
import Input from '../../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';
import ApprovalDetailContents from '../../../Approval/components/ApprovalDetailContents';
import overtimeWarning from '../../../../../assets/img/overtime_warning.svg';
const WorkCalenderOvertimeWarningModal = forwardRef((props, ref) => {
    const mainColor = '#5A6CFA';
	const bgColor = tinycolor(mainColor).lighten(30).toHexString();

    const [approvalList, setApprovalList] = useState(props.data); //전체 리스트
    useImperativeHandle(ref, () => ({
		resetForm: () => {
            console.log(props);
		},
		setFrom: (values) => {

		}
	}));
    const form = useFormik({
    });

    const test123 = () => {
        props.setIsOpen(false);
    }
    
    return (
        <>
            <Modal size='sm' isStaticBackdrop={true} isOpen={props?.isOpen} setIsOpen={props?.setIsOpen} isScrollable isCentered>
                <ModalHeader setIsOpen={props?.setIsOpen} style={form?.values?.color?.hex && { backgroundColor: form?.values?.color?.hex || '#5A6CFA' }}>
                    <ModalTitle></ModalTitle>
                </ModalHeader>
                <ModalBody className="modal-body d-flex flex-column align-items-center justify-content-center text-center">
                    <img src={overtimeWarning}></img>
                    <p>
                        <br/>
                        근무 시간 초과<br/>
                        근무 시간 초과로<br/>
						신청할 수 없습니다.
                    </p>
                </ModalBody>
                <ModalFooter className="modal-footer d-flex justify-content-center">
                    <ButtonGroup>
                        <Button type='button' color='light' 
                        onClick={() => {
                            test123();
                        }}
                        >
                            <font color="#556EE6">확인</font>
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </>
    );
});

export default WorkCalenderOvertimeWarningModal;
