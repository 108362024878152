import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
// import {useReactToPrint} from 'react-to-print';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next'; //번역관련
import Card, { CardBody, CardHeader, CardTitle } from '../../../../components/bootstrap/Card';
import { useMst } from '../../../../models';
import Button from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import ApprovalContentsToPrint from './ApprovalContentsToPrint';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CompanyService from '../../../../services/CompanyService';
import Page from '../../../../layout/Page/Page';
import { Base64 } from 'js-base64';
import showNotification from '../../../../components/extras/showNotification';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import moment from 'moment';
import ThemeContext from '../../../../contexts/themeContext';
import secondary from "../../../../assets/img/secondary.svg";
function getTitle (request) {
	if(request?.title !== '' && request?.title !== null) {
		return request?.title;
	} else {
		switch(request.type) {
			case 'RECORD':
				return '근무기록 수정';
			case 'USER_REST':
			case 'REST_TRANSFER':
			case 'REST':
				return `${request?.restday?.name} 신청`;
			case 'WORK':
				return `${request?.restday?.name} 신청`;
			case 'WORK_CANCEL':
			case 'USER_REST_CANCEL':
			case 'REST_CUSTOM_CANCEL':
			case 'REST_TRANSFER_CANCEL':
			case 'REST_CANCEL':
				return `${request?.restday?.name} 신청 취소`;
			default:
				return '-';
		}
	}
};
	
const ApprovalDetailContents = ({ from, userData, setSelectedUser, isModalOpen, setIsModalOpen, isEditFlag, setIsEditFlag, name, setTest, test, setSelectItem,  setApprovalList, approvalList, refresh  }) => {
	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const { company, user } = useMst();
	const navigation = useNavigate();
	const params = useParams();
	const { state } = useLocation();
	const { mobileDesign } = useContext(ThemeContext);
	const componentRef = useRef(null);
	const [requestData1, setRequestData1] = useState(userData);
	const [title, setTitle] = useState('');
	const [isPrint, setIsPrint] = useState(false);
	const [fromDocu, setFrom] = useState(from);

	const [openCancelApproval, setOpenCancelApproval] = useState(false);
	const [CancelRequestCompletedModalOpen, setCancelRequestCompletedModalOpen] = useState(false)
	const [CancelRequestModalType, setCancelRequestModalType] = useState('')
	const [theStateIs, setTheStateIs] = useState({
		return: false,
		cancel: false
	});
	const [etc, setEtc] = useState('');
	// console.log('>>>', props, params, state);
	useEffect(()=> {
		// console.log('requestData1', requestData1);
		if(requestData1?.userId === user.me.id){
			if(requestData1?.state !== 'CANCEL') {
				// if(moment(requestData1?.startDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && moment(requestData1?.startDate).diff(moment().format('YYYY-MM-DD'), 'day') > 0) {
					if (requestData1?.approvals[0]?.line[0]?.state === 'REQUEST') {
						setTheStateIs({
							return: true,
							cancel: false
						});
					} else if (requestData1?.approvals[0]?.line[0]?.state !== 'REQUEST' && !requestData1?.type.includes('CANCEL')) {
						setTheStateIs({
							return: false,
							cancel: true
						});
					}
				// }
			} else {
				setTheStateIs({
					return: false,
					cancel: false
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[requestData1]); 

	useLayoutEffect(() => {
		
		if(params?.id) {
			const [ companyId, id ] = Base64.decode(params?.id).split('_');
			if(company.get.id !== parseInt(companyId)) {
				navigation('/e-approval');
				return;
			}
			CompanyService.getRquestDetail(id).then( res => {
				if(company.get.id === parseInt(res?.data?.companyId)) {
					setRequestData1(res?.data || {});
				} else {
					// 추후 보안쓰~ 접근 권한 등등..
					navigation('/e-approval')
					return;
				}
			})
		}
		if(state) {
			setRequestData1(state);
		}
	}, [params.id, state, navigation, company.get.id]);

	useEffect(() => {
		setTitle(getTitle(requestData1));
	}, [requestData1])

	const requestForApprovals = async (type) => {
		if(type === 'RETURN') {
			// if(type?.includes('REST')){
				const returnApproval = await CompanyService.cancellationRequest(requestData1?.id);
				console.log('returnApproval >>', returnApproval);
				//회수 여부 모달 
				setOpenCancelApproval(false);
				//결재 회수 완료 모달
				setCancelRequestCompletedModalOpen(true)
				setCancelRequestModalType('회수')

				if(fromDocu === 'document') {
					refresh();
					//setIsModalOpen(false);
				}
				setTheStateIs({
					return: false,
					cancel: false
				});
			// }
		} else {
			let data = {detailId: requestData1?.id, etc: etc, state: 'CANCEL'}
			// console.log(data)
			const cancelApproval = await CompanyService.cancellationRequest(requestData1?.id, etc, 'CANCEL');
			console.log('cancelApproval >>', cancelApproval);
			//showNotification(t('기안 취소 요청'), t('기안 취소 요청이 완료되었습니다'), 'info');

			//결재 취소 완료 모달
			setCancelRequestCompletedModalOpen(true)
			setCancelRequestModalType('취소')

			if(fromDocu === 'document') {
				refresh();
				// setIsModalOpen(false);
				// setOpenCancelApproval(false);
			}
			setTheStateIs({
				return: false,
				cancel: false
			});
		}
	}

	return (
		<>
		<PageWrapper className='full px-0 px-sm-3' title={title}>
			<div style={ mobileDesign ? {}  : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>
			<SubHeader className={mobileDesign === false && 'justify-content-end'}>
				<SubHeaderRight>
					<div className='print-report'>
						<div className='text-end d-flex flex-row justify-content-end align-items-center'>
							<ReactToPrint
								trigger={() => (
									<Button type='button'>
										<Icon icon='LocalPrintshop' /> <span>{t('인쇄')}</span>
									</Button>
								)}								
								content={() => componentRef.current}
								onBeforeGetContent={(content) => {
									setIsPrint(true);							  
									return new Promise((resolve) => {
									  setTimeout(resolve, 10);
									});
								}}
								onAfterPrint={() => {
									setIsPrint(false);
								}}
								documentTitle={t('전자결재')}
							/>
							<Button
								type='button'
								onClick={() => {
									if(requestData1) {
										const cryptoUrl = Base64.encode(`${company.get.id}_${requestData1.id}`);
										navigator.clipboard
											.writeText(`${location.origin}/e-approval/document/${cryptoUrl}`)
											.then(() => {
												showNotification(t('알림'), t('복사 되었습니다'), 'light');
											})
											.catch(() => {
												showNotification(t('알림'), t('해당 브라우저에서 복사기능을 지원하지 않습니다.'), 'danger');
											});
									}
									// navigation(`/e-approval/document/${cryptoUrl}`);
								}}>
								<Icon icon='ContentCopy' /> <span>{t('URL 복사')}</span>
							</Button>
							{setIsModalOpen &&
								<Button
									type='button'
									onClick={() => {
										setIsModalOpen(false);
									}}									
								>
									<Icon icon='close'/> <span>{t('닫기')}</span>
								</Button>
							}
						</div>
					</div>
				</SubHeaderRight>
			</SubHeader>
			</div>
			<Page container='fluid' className='full pt-0'>
				{requestData1 &&
					<ApprovalContentsToPrint printRef={componentRef} userData={requestData1} setSelectedUser={setSelectedUser} isEditFlag={isEditFlag} setSelectItem={setSelectItem} setApprovalList={setApprovalList} approvalList={approvalList} isPrint={isPrint} refreshOrigin={refresh} setIsModalOpen={setIsModalOpen}/>
				}
			</Page>
			{(theStateIs.return || theStateIs.cancel) &&
			<div className='text-end'>
				<Button
					color='secondary'
					onClick={()=> {
						setOpenCancelApproval(true);
					}}
					>
						<div className='d-flex align-items-center'>
							<Icon icon={theStateIs.return ? 'Replay' : 'Cancel'}/>
							<span className='m-1'>{t(theStateIs.return ? '회수' : '취소요청')}</span>
						</div>
				</Button>
			</div>
			}

		</PageWrapper>
		{openCancelApproval &&
			<Modal
				isCentered
				size={theStateIs.return ? 'sm' : 'md'}
				isOpen={openCancelApproval}
				setIsOpen={setOpenCancelApproval}>
				<ModalBody>
					{theStateIs.return ?
					<div className='m-3'>
					<div className='d-flex justify-content-center mb-3'>
						<Icon icon='Info' size='3x' style={{color: '#D9D9D9'}} />
					</div>
					<div className='justify-content-center'>
						<div className='fs-4 fw-bold d-flex justify-content-center mb-2'>{t('회수 하시겠어요') + '?'}</div>
						<div className='d-flex justify-content-center mb-1 text-muted'>{t('입력하신 정보는 저장되지 않습니다') + '.'}</div>
					</div>
				</div>
					: 
					<div className='m-4'>
						{/* <div className='d-flex justify-content-center mb-3'>
							<Icon icon='Info' size='3x' />
						</div> */}
						<div className='justify-content-center'>
							<div className='fs-4 fw-bold d-flex justify-content-center mb-2'>{t('결재를 취소하시겠어요') + '?'}</div>
							<div className='d-flex justify-content-center mb-1 text-muted'>{t('결재 취소는 관리자 승인이 필요해요') + '.'}</div>
							<div className='d-flex justify-content-center text-muted'>{t('취소 사유를 입력해주세요') + '.'}</div>
						</div>
						<Textarea className={'my-4'} style={{ height: 200, padding: 20, resize: 'none' }} placeholder={t(`사유를 입력하세요`)} value={etc} onChange={(e) => setEtc(e.target.value)}/>
					</div>
					}
				</ModalBody>
				<ModalFooter>
				{theStateIs.return ?
					<div>
						<Button
							style={{
								border: '1px solid #D9D9D9',
								borderRadius: 7,
								color: '#808080',
								padding: '10px 23px 10px 23px',
							}}
							onClick={()=> {
								setOpenCancelApproval(false);
							}}
						>
							{t('아니요')}
						</Button>
						<Button
							className='mx-4'
							style={{
								border: '1px solid #FE637C',
								borderRadius: 7,
								backgroundColor: '#FE637C',
								color: 'white',
								padding: '10px 15px 10px 15px',
							}}
							onClick={()=> {
								requestForApprovals('RETURN');
							}}
						>
							{t('네, 회수할게요')}
						</Button>
					</div>
					: 
					<div>
						<Button
							style={{
								border: '1px solid #D9D9D9',
								borderRadius: 7,
								color: '#808080',
								padding: '10px 23px 10px 23px',
							}}
							onClick={()=> {
								setOpenCancelApproval(false);
							}}
						>
							{t('아니요')}
						</Button>
						<Button
							className='mx-4'
							style={{
								border: '1px solid #FE637C',
								borderRadius: 7,
								backgroundColor: '#FE637C',
								color: 'white',
								padding: '10px 15px 10px 15px',
							}}
							onClick={()=> {
								requestForApprovals('CANCEL');
								setOpenCancelApproval(false);
							}}
						>
							{t('네, 취소할게요')}
						</Button>
					</div>
					}
				</ModalFooter>
			</Modal>
			}

			<Modal
				isCentered
				isStaticBackdrop
				setIsOpen={setCancelRequestCompletedModalOpen}
				isOpen={CancelRequestCompletedModalOpen}
				size={'sm'}
    	>
      <div className='modal-wraped mx-3'>
        <ModalHeader />
        <ModalBody>
          <div className='text-center'>
            <img src={secondary} alt="리스트 아이콘" width={60} />
            <h5 className='fw-bold mt-4 lh-base'>{CancelRequestModalType == '회수' ? '회수' : '취소 요청'}<span className='text-secondary'>{' 완료'}</span></h5>
						{CancelRequestModalType == '회수' 
						 ? <p className='text-muted mb-0'>{'회수한 내역은 저장되지 않습니다'}</p> 
						 : <p className='text-muted mb-0'>{'신청 현황 및 내역은'} 
               <br/>{'전자결재에서 확인해주세요.'}
            </p>}
          </div>   
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button
            className='w-100'
            style={{
              borderRadius: 7,
            }} 
            color='secondary'
            onClick={() => {
							setCancelRequestCompletedModalOpen(false)
							if(setIsModalOpen){
								setIsModalOpen(false);
								setOpenCancelApproval(false);
							}else{
								navigation(`/e-approval`);
							}
						}}
          >
            <span className='px-3'>{t('확인')}</span>
          </Button>
        </ModalFooter>
			</div>
			</Modal>
		</>
	);
};

export default ApprovalDetailContents;
