import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, List } from 'react-virtualized';
import Badge from '../../../components/bootstrap/Badge';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
	CardTitle,
} from '../../../components/bootstrap/Card';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';

const type = ['출근', '미출근', '출장', '휴가'];

const AttendOffCanvas = ({ attendData, isAttendOffCanvas, setIsAttendOffCanvas }) => {
	const { t } = useTranslation(['translation', 'menu']);

	console.log('attendData', attendData)
	return (
		<OffCanvas
			isBackdrop={true}
			isBodyScroll={true}
			placement={'end'}
			isOpen={isAttendOffCanvas}
			setOpen={setIsAttendOffCanvas}>
			<OffCanvasHeader setOpen={setIsAttendOffCanvas}>
				<OffCanvasTitle id='working'>{t('근무 현황')}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody className='p-0 '>
				<Card stretch hasTab shadow='none'>
					{/* type에 따른 근무 현황 OffCanvas 탭 */}
					{type.map((index, key) => {
						let data = [];
						switch (index) {
							case '출근':
								data = attendData?.attender || [];
								break;
							case '미출근':
								data = attendData?.attendNoter || [];
								break;
							case '출장':
								data = attendData?.outers || [];
								break;
							case '휴가':
								data = attendData?.rester || [];
								break;
						}

						return (
							<CardTabItem
								id={index}
								key={index}
								title={
									<div className='position-relative'>
										{index}
										{data.length > 0 && (
											<Badge color='danger' className={'position-absolute top-0 start-100 translate-middle'}>
												{data.length}
											</Badge>
										)}
									</div>
								}>
								<CardBody className='h-100 p-0'>
									{data?.length <= 0 ? (
										<div className='text-center lead'>{`${index} 기록이 없습니다.`}</div>
									) : (
										<AutoSizer>
											{({ width, height }) => {
												return (
													<List
														width={width}
														height={height}
														rowCount={data.length}
														rowHeight={30}
														rowRenderer={({ index, key, style }) => {
															return (
																<div key={key} style={style} className={`${index % 2 == 0 && 'border-end'}`}>
																	<span className='lead'>{`${index + 1}. ${data[index]?.user?.name || '-'}`}</span>{' '}
																	{`${data[index]?.rank?.name || '-'} - ${
																		data[index]?.department?.department?.name || ''
																	}`}
																</div>
															);
														}}
													/>
												);
											}}
										</AutoSizer>
									)}
								</CardBody>
							</CardTabItem>
						);
					})}
				</Card>
			</OffCanvasBody>
		</OffCanvas>
	);
};

export default memo(AttendOffCanvas);

/* 
const secondsChangeToHMS = useCallback((seconds) => {
	var hour = parseInt(seconds / 3600);
	var min = parseInt((seconds % 3600) / 60);
	var sec = seconds % 60;

	return (
		hour.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0') + ':' + sec.toString().padStart(2, '0')
	);
}, []);
*/

/* 
{
	data?.length > 0 && (
		<div className='overflow-auto'>
			<div className='d-flex align-items-start justify-content-start flex-wrap'>
				{data.map((value, key) => (
					<div
						key={value.userId}
						className={`d-flex flex-column justify-content-center align-items-end py-2 pe-4 mb-3 w-50 border-light ${
							key % 2 == 0 && 'border-end'
						}`}>
						<div>
							<span className='lead'>{`${value.user.name}`}</span> {`${value?.rank?.name || ''}`}
						</div>
						<div className='fw-light'>{value?.department?.name || ''}</div>
						{index == '지각' && value.attend && <div>+ {secondsChangeToHMS(value.attend.tardyTime)}</div>}
					</div>
				))}
			</div>
		</div>
	);
}
{
	data?.length <= 0 && <div className='text-center lead'>데이터가 존재하지 않습니다.</div>;
}
 */
