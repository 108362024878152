import React, { useState, useContext, useEffect } from "react";
import Progress from "./bootstrap/Progress";
import ThemeContext from "../contexts/themeContext";
import Card, { CardBody, CardHeader, CardTitle } from "./bootstrap/Card";

const ProgressLoading  = () => {

    const { isProgressBar, max, value, setValue, setMax, setIsProgressBar } = useContext(ThemeContext);

	useEffect(() => {
		if(max <= value) {
			setIsProgressBar(false);
		}
	}, [max, value, setIsProgressBar])

	useEffect(() => {
		if(!isProgressBar) {
			setValue(0);
			setMax(100);
		}
	}, [isProgressBar, setValue, setMax])

    // console.log(isProgressBar)
    return (
			<>
				{isProgressBar && (
					<div className='position-absolute' style={{ right: 50, bottom: 70, zIndex: 9999, borderWidth: 2 }}>
						<Card shadow='sm' borderSize={1} borderColor='light' className='rounded p-0'>
							<CardHeader borderSize={1} borderColor='light' className='rounded'>
								<CardTitle className='fw-light small'>액셀 파일이 생성중입니다</CardTitle>
							</CardHeader>
							<CardBody>
								<Progress min={0} max={max || 100} value={value || 20} color='success' />
							</CardBody>
						</Card>
					</div>
				)}
			</>
		);
}

export default ProgressLoading;