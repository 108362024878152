import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import Avatar from 'react-avatar';
import { useEffectOnce } from 'react-use';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import { useMst } from '../../../models';
import ThemeContext from '../../../contexts/themeContext';

const MySimpleProfile = observer(() => {
	
	const { user, company } = useMst();
	const { mobileDesign } = useContext(ThemeContext);

	const mainDepartment =
		company.get?.departInfos?.toJSON()?.filter((departmentInfo) => departmentInfo.main === true)?.[0] || {};
	const mainDepartmentName = mainDepartment?.departName || '';
	const mainDepartmentPostionName = mainDepartment?.positionName || '';
	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			{/* <CardHeader className='rounded-2'>
				<CardLabel>
					<CardTitle>
						MY 홈
					</CardTitle>
				</CardLabel>
			</CardHeader> */}
			<CardBody className='d-flex justify-content-center align-items-center'>
				<div className='text-center'>
				{!mobileDesign &&
					<div className='py-1'>						
						<Avatar
							round
							textSizeRatio={0.2}
							size={80}
							className='me-2'
							name={user.me?.name || ''}
							src={user.me?.profile || null} // user.me 스토어에 넣어놓기
						/>						
					</div>
					}
					<div className='fw-bold fs-3 mt-3'>{user.me?.name || ''}</div>
					<div className='fs-5 mt-3'>{`${user?.me?.userDepartment || ''} | ${user?.me?.userRank || ''}`}</div>
					<div className='text-muted border rounded-pill border-light py-2 mt-3 text-center'>
						<Icon className='me-2' icon='AccountCircle' size='lg' />
						<span className='fs-6'>{company?.get?.companyNumber || '-'}</span>
					</div>
					<div className='text-muted border rounded-pill border-light px-4 py-2 mt-3 text-start'>
						<Icon className='me-2' icon='MailOutline' size='lg' />
						<span className='fs-6'>{user?.me?.email || '-'}</span>
					</div>
				</div>
			</CardBody>
		</Card>
	);
});

export default MySimpleProfile;
