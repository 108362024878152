import React, { useCallback, useContext, useEffect, useState } from 'react';
import Badge from '../../../../../components/bootstrap/Badge';
import Avatar from '../../../../../components/Avatar';
import ThemeContext from '../../../../../contexts/themeContext';
import moment from 'moment';

const convertSeconde2HourMinute2 = (mins) => {
	let h = Math.floor(mins / 3600);
	let m = Math.floor((mins % 3600) / 60);
	let s = Math.floor(mins % 60);
	//let h = Math.floor(mins / (60 * 60));
	//let m = mins % (60);
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};

const renderMaxTimeText = (timeInfos) => {
	//{timeInfos?.type === 'max' ? '최대' : '평균'} {timeInfos?.max}{renderTypeText(timeInfos?.unit || '')}
	const type = timeInfos?.maxWorkAvg === 'max' ? '최대' : '평균';
	switch (timeInfos.maxWorkUnit) {
		case 'h':
			return `${type} ${timeInfos?.maxWorkHour}H`;
		case 'd':
			return `일 ${type} ${timeInfos?.maxWorkHour}H`;
		case 'm':
			return `월 ${type} ${timeInfos?.maxWorkHour}H`;
		case 'w':
			return `주 ${type} ${timeInfos?.maxWorkHour}H`;
		default:
			return '';
	}
};

const renderDefaultTimeText = (timeInfos) => {
	//{timeInfos?.type === 'max' ? '최대' : '평균'} {timeInfos?.max}{renderTypeText(timeInfos?.unit || '')}
	const type = timeInfos?.workAvg === 'max' ? '최대' : '평균';
	switch (timeInfos.workUnit) {
		case 'h':
			return `${type} ${timeInfos?.workHour}H`;
		case 'd':
			return `일 ${type} ${timeInfos?.workHour}H`;
		case 'm':
			return `월 ${type} ${timeInfos?.workHour}H`;
		case 'w':
			return `주 ${type} ${timeInfos?.workHour}H`;
		default:
			return '';
	}
};

// 평균
const calculateAverage = (arr) => {
	return arr.reduce((p, c) => p + c, 0) / arr.length;
};

const calculateTypeTime = (arr, type) => { 
	
	// 일정표의 계획시간 기준으로 오버되는 시간은 연장근로시간으로 계산하면 될것 같음.
	// 대신 일정표에서 2주, 1개월, 3개월 단위에서 평균 40시간 체크 잘해야할것!
	switch (type) {		
		case '2w':
			// 평균 1주 근로시간이 40시간 초과 하면 연장근로
			// 특정주의 근로시간이 48시간을 초과 불가 (연장 제외)
			// (1주 + 2주) - (소정근로8시간*5일) = 연장근로
			// 최대 60시간 (48시간 + 12시간)
			break;
		case '3m':
			// 특정일 근로시간이 8시간, 특정주 근로시간이 52시간 초과
			// 최대 64시간 (52시간 + 12시간)

			// 근로일별 근로시간 초과한경우
			// 특정일이나 주의 근로시간이 12시간, 52시간 초과
			// 1주 평균 40시간 초과
			break;
		case '3m6m':

			break;
	}
}


const RenderGroupUser = (props) => {

	const { schduleUpdate } = useContext(ThemeContext); // group은 reRender 안되서 별도로 스케줄정보 받아오기

	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	const { group, /* timeInfos, */ selectColumns, items, users, selectionRange } = props;
	const [isHover, setIsHover] = useState(false);	
	const [timeInfos, setTimeInfos] = useState({});

	// console.log('render	group', props, schduleUpdate);

	const getSchduleTime = (user) => {
			// console.log('items', moment(selectionRange.startDate).format('YYYYMMDD'), moment(selectionRange.endDate).format('YYYYMMDD'))
			const thisGroup = [...users].find((v) => v?.id === user.parent);
			const mySchdule = [...schduleUpdate]
				?.filter((v) => {
					return v?.id.indexOf('delete') === -1;
				})
				?.filter((v) => v.group == user.userId)
				?.filter((v) => {
					return (
						moment(moment(v['start_time'])).isSameOrAfter(moment(selectionRange.startDate)) &&
						moment(moment(v['end_time'])).isSameOrBefore(moment(selectionRange.endDate))
					);
				});

			// console.log('group', user, thisGroup);

			// 각 단축 그룹은 가상의 그룹이라, 각 사용자별 group 정보 생성해줄것..

			// console.log('mySchdule', mySchdule);
			let result = { ...thisGroup?.workSystem, time: 0 };
			if(group.workType === 'flex') {
				result.workSystem = {
					workAvg: 'avg'
				}
			}
			// console.log('result', result);
			// console.log('group', group)

			// 정산주기 = 근무그룹 시작일자 ~
			// const groupStart = moment(thisGroup.startAt);

			// const settle = {
			// 	hour: thisGroup?.workSystem?.settleHour || 8,
			// 	unit: thisGroup?.workSystem?.settleUnit || 'd',
			// };

			// // useHoilyDay
			// // 일 주 월  단위..
			// switch (settle?.unit) {
			// 	case 'd':
			// 		break;
			// 	case 'w':
			// 		break;
			// 	case 'm':
			// 		break;
			// }
			// const settleDay = moment().diff(groupStart, settle.unit);
			// 나누고
			// 현재까지 주기별 일자 계산 로직
			// startAt / 주기 = 범위

			// schedules 는 근로제 범위만큼 필터해서 가져오기

			// 근무, 연장 별도 계산 확인!!

			// 외근, 출장 간주여부에 따른 시간
			// 시간여부에 따른 계산
			// 평균 최대에 따라 값 변동

			// 그룹 설정값에 따른
			// 평균 근로시간
			// 최대 근로시간 등 정보 리턴

			// 기본근로시간 체크
			// 연장근로시간 체크
			// 심야 근로시간 체크
			// 특별연장?
			// 최대 근로시간
			// 휴일포함이냐

			// 근로시스템 안의 검증 값 포함하도록~

			// 탄력으로 인한 평균이냐
			// 기본 근무시간이냐

			// console.log('mySchdule', mySchdule)
			const time = mySchdule?.length > 0 ? mySchdule?.reduce(
				(pre, cur, index) => {
					// console.log('cur >>', cur)
					let restTimeSeconds = 0;

					if (cur.row.type !== 'REST' && cur.row.type !== 'REST_CUSTOM' && cur.row.type !== 'REST_SCHDULE' && cur.row.type !== 'DAYOFF') {
						if (cur?.row?.restAuto === true) { // 수동 휴게시간 : 휴게없음 
							// if (cur?.row?.restTime[moment(cur.start_time).format('E') - 1][0][0] !== '') {
							// 	// 지정
							// 	//
							// 	cur?.row?.restTime[moment(cur.start_time).format('E') - 1].map((times) => {
							// 		if (moment(times[0], 'HH:mm') >= moment(cur.start_time) && moment(times[1], 'HH:mm') <= moment(cur.end_time)) {
							// 			// console.log('this ALL', moment(times[0], 'HH:mm').format('HH:mm'), moment(times[1], 'HH:mm').format('HH:mm'));
							// 			pre.rest += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
							// 			// console.log("= ", moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second'))
							// 		} else if (moment(times[0], 'HH:mm') >= moment(cur.start_time) && moment(times[0], 'HH:mm') <= moment(cur.end_time)) {
							// 			// console.log('this S', moment(times[0], 'HH:mm').format('HH:mm'));
							// 			pre.rest += moment(cur.end_time).diff(moment(times[0], 'HH:mm'), 'second');
							// 			// console.log("= ", moment(e).diff(moment(times[0], 'HH:mm'), 'second'))
							// 		} else if (moment(times[1], 'HH:mm') <= moment(cur.end_time) && moment(times[1], 'HH:mm') >= moment(cur.start_time)) {
							// 			// console.log('this E', moment(times[1], 'HH:mm').format('HH:mm'));
							// 			pre.rest += moment(times[1], 'HH:mm').diff(moment(cur.start_time), 'second');
							// 			// console.log("= ", moment(times[1], 'HH:mm').diff(moment(s), 'second'))
							// 		} else {
							// 			// console.log('...')
							// 		}
							// 	});
							// } else {
							// 	// 자동
							// 	pre.rest.push(Math.floor(moment(cur.end_time).diff(moment(cur.start_time), 'second') / (270 * 60)) * (30 * 60));
							// }
						} else { // 자동 휴게시간
							if(cur.title === '탄력근무' || cur.title === '시차출퇴근') {
								if(cur.row?.group?.restTime?.length > 0 && cur.row?.group?.restTime[moment(cur.start_time).format('E')-1][0][0] !== "") {
									// 휴게시간 지정 2022.09.13
										// cur.row?.group?.restTime[moment(cur.start_time).format('E')-1].map( times => {
										// 	// console.log(">>> ", times)
										// 	if(times[0] !== '') {
											
										// 		// 일 시작시간 종료시간
										// 		const stime = moment(cur.start_time).format('HH:mm');
										// 		const etime = moment(cur.end_time).format('HH:mm');

										// 			let restInWorkTime =
										// 				(
										// 					moment(moment(stime, 'HH:mm')).isSame(moment(times[0], 'HH:mm')) ||
										// 					moment(moment(times[0], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
										// 				) &&
										// 				(
										// 					moment(moment(etime, 'HH:mm')).isSame(moment(times[1], 'HH:mm')) ||
										// 					moment(moment(times[1], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
										// 				);
														
										// 			restTimeSeconds = restInWorkTime ? moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'seconds') : 0;

										// 			pre.rest.push(restTimeSeconds);
										// 			// console.log("휴게시간", restTimeSeconds)
										// 	} else {
										// 			// console.log('휴게시간 자동 계산', moment(cur.start_time).format('E'));
										// 	}
										// })
										pre.rest.push(Math.floor(moment(cur.end_time).diff(moment(cur.start_time), 'second') / (270 * 60)) * (30 * 60));
											}
							} else {
								if(cur.row?.restTime?.length > 0 && cur.row?.restTime[moment(cur.start_time).format('E')-1][0][0] !== "") {
									// 특정 휴게시간 지정 2022.09.13
										cur.row?.restTime[moment(cur.start_time).format('E')-1].map( times => {
											// console.log(">>> ", times)
											if(times[0] !== '') {
											
												// 일 시작시간 종료시간
												const stime = moment(cur.start_time).format('HH:mm');
												const etime = moment(cur.end_time).format('HH:mm');

													let restInWorkTime =
														(
															moment(moment(stime, 'HH:mm')).isSame(moment(times[0], 'HH:mm')) ||
															moment(moment(times[0], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
														) &&
														(
															moment(moment(etime, 'HH:mm')).isSame(moment(times[1], 'HH:mm')) ||
															moment(moment(times[1], 'HH:mm')).isBetween(moment(stime, 'HH:mm'), moment(etime, 'HH:mm'))
														);
														
													restTimeSeconds = restInWorkTime ? moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'seconds') : 0;

													pre.rest.push(restTimeSeconds);
													// console.log("휴게시간", restTimeSeconds)
											} else {
													// console.log('휴게시간 자동 계산', moment(cur.start_time).format('E'));
											}
										})
								} else {
									// 근무시간 기준 휴게시간 지정

									let startInData = moment(cur.start_time.format('YYYY-MM-DD HH:mm'));
									let endInData = moment(cur.end_time.format('YYYY-MM-DD HH:mm'));

									let durationInSeconds = moment.duration(endInData.diff(startInData)).asSeconds();

									let restRealTimeMinutes = durationInSeconds;

									restTimeSeconds =
										Math.floor(
										(parseInt(restRealTimeMinutes)) /
											(270 * 60)
										) *
										(30 * 60);

										pre.rest.push(restTimeSeconds);
										// console.log('restTimeSeconds >>', restTimeSeconds)
								}
							} 

						}
						pre.work.push(parseInt(moment(cur.end_time).diff(moment(cur.start_time), 'second')));
						return pre;
					} else {
						return pre;
					}
				},
				{ work: [], rest: [], over: [] }
			) : { work: [], rest: [], over: [] };
			// console.log("====================time", time);
			// 최대시간?

			const workSum = 0;//time?.work?.length > 0 ? time?.work?.reduce((a, b) => a + b, 0) : 0;
			const restSum = 0;//time?.rest?.length > 0 ? time?.rest?.reduce((a, b) => a + b, 0) : 0;

			if ((result?.workSystem?.workAvg || 'sum') === 'avg') {
				result = { ...result, group: result,  time: (workSum / time?.work?.length || 0) - (restSum / time?.rest?.length || 0) };
			} else {
				result = { ...result, group: result, time: workSum - restSum }; //convertSeconde2HourMinute2();
			}

			// console.log("result ====================", result);

			return result;
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	useEffect(() => {
		
		const timeInfos = getSchduleTime(group);
		// if(schduleUpdate.filter(v=>v.group === group.userId).length > 0)
			// console.log('render group !!!', schduleUpdate.filter(v=>v.group === group.userId), timeInfos);
		setTimeInfos(timeInfos);
		// forceUpdate();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		//border-right: 1px solid #bbb;
		<div className={`d-flex flex-row ps-2 h-100 ${selectColumns === group.userId ? 'bg-l25-danger' : ''}`} style={{ borderRight: '1px solid #bbb' }}>
			<div className=' flex-row align-items-center border border-0 border-end border-light flex-grow-1 ps-2 overflow-hidden' style={{ lineHeight: 'normal', display: isHover ? 'none' : 'flex' }}>
				<Avatar
					textSizeRatio={0.2}
					size={25}
					className='me-2 justify-content-center align-items-center'
					userName={group?.user?.name || ''}
					src={group?.user?.profile || ''}
					srcSet={group?.user?.profile || ''}
				/>
				<div className='d-flex flex-column'>
					<div className='fw-bold text-truncate'>
						{(timeInfos?.time || 0) > 52 * 60 * 60 && (
							<Badge color={'danger'} className={'me-1'}>
								초과
							</Badge>
						)}
						{group?.user?.name || ''}
					</div>
					{/* <div className='small'>{group?.departments?.department?.name || ''}</div> */}
					<div className='small'>{group?.rank?.name || ''}</div>
				</div>
			</div>

			<div
				className='d-flex flex-row px-3 align-items-center'
				style={{ lineHeight: 'normal', minWidth: 70, width: isHover ? '100%' : 70, position: 'relative' }}
				onMouseOver={() => {
					setIsHover(true);
				}}
				onMouseLeave={() => {
					setIsHover(false);
				}}>
				<span className='small fw-normal' style={{position: 'absolute', top: 2, left: 2}}>{timeInfos?.workSystem?.workAvg == 'avg' ? '평균' : '합계'}</span>
				<span className={`fw-bold ${(timeInfos?.time || 0) > 52 * 60 * 60 && 'text-danger'}`}>{convertSeconde2HourMinute2(timeInfos?.time || 0)}</span>
				<span className='mx-2'>|</span>
				<span className='small'>
					기본: {renderDefaultTimeText(timeInfos)}
					<br />
					최대: {renderMaxTimeText(timeInfos)}
				</span>
			</div>
		</div>
	);
};

export default RenderGroupUser;
