import React, {forwardRef} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/bootstrap/Modal";
import Progress from "../../../../components/bootstrap/Progress";
import Icon from "../../../../components/icon/Icon";
import iconDocument from "../../../../assets/img/icon/icon_document.png";


const WorkRecordProgressModal = forwardRef((props) => {
  return (
    <>
      <Modal
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        isStaticBackdrop
        isScrollable
        isCentered
        size='sm'
      >
        <ModalBody>
          <div className='text-center py-3 px-4'>
            <img src={iconDocument} />
            <Progress
	            value={ 80 }
	            min={ 0 }
	            max={ 100 }
	            color='info'
              className='mt-2'
            />
            {/* 등록중 보여지는 메시지 */}
            <p className='fs-5 fw-bold mt-4'>근무기록 등록중</p>
            {/* 등록완료시 보여지는 메시지 or 아이콘 */}
            <p className='fs-5 fw-bold mt-4 d-flex align-items-center justify-content-center'>
              등록완료
              <Icon className='ms-2' icon='CheckCircle' color='success' size='2x' />
            </p>
            <p className='text-muted fs-5'><span className='text-info'>512</span><span className='mx-2'>/</span>1000</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
})

export default WorkRecordProgressModal;
