import React from 'react'
import Modal, {ModalBody, ModalFooter, ModalHeader} from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import {useTranslation} from "react-i18next";
import success from "../../../../assets/img/SuccessIcon.svg";

const WorkApprovalCompletedModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation(['translation', 'menu'])

  return (
    <Modal
      isCentered
      isStaticBackdrop
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      size={'sm'}
    >
      <div className='modal-wraped mx-3'>
        <ModalHeader />
        <ModalBody>
          <div className='text-center'>
            <img src={success} alt="리스트 아이콘" width={60} />
            <h5 className='fw-bold mt-4 lh-base'>근무 신청 <span className='text-success'>완료</span></h5>
            <p className='text-muted mb-0'>
              신청 현황 및 내역은 
              <br/>전자결재에서 확인해주세요.
            </p>
          </div>   
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button
            className='w-100'
            style={{
              borderRadius: 7,
            }} 
            color='success'
            onClick={() => setIsOpen(false)}
          >
            <span className='px-3'>{t('확인')}</span>
          </Button>
        </ModalFooter>
    </div>
    </Modal>
  )
}

export default React.memo(WorkApprovalCompletedModal)