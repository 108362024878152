import React, {useContext, useEffect} from 'react'
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import authService from "../../../services/AuthService";
import showNotification from "../../../components/extras/showNotification";
import {useMst} from "../../../models";
import {useTranslation} from "react-i18next";
import BoardService from "../../../services/BoardService";
import ThemeContext from "../../../contexts/themeContext";
import { Configs } from '../../../models/Configs';
import { demoPages } from '../../../menu';

const AzureLoginCallback = () => {
    const { user, company } = useMst()
    const [searchParams, ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation(['translation', 'menu']);

    const azureLogin = async (code) => {
        const res = await authService.azureLogin({code})

        if (res?.data?.message) {
            // 로그인 실패 시
            showNotification(t('로그인'), t(res?.data?.message), 'danger');
            navigate('/' + demoPages.login.path)
        } else {
            user.authMe().then(async () => {
                await Promise.all([
                    Configs.sendDevice(),
                    user.me.currentCompanyId && company.selectCompany(user.me.currentCompanyId),
                ]);
                if (user.me.id) {
                    user.me.currentCompanyId === 0 || user.me.currentCompanyId === null || user.me.currentCompanyId === undefined ? window.opener.location.replace('/change-company') : window.opener.location.replace('/');
                    window.close();
                }
            }).catch((e) => {
                console.log(e);
                showNotification(t('로그인'), t('로그인에 실패하였습니다.') + t('다시 확인해주세요') + '.', 'danger');
                navigate('/' + demoPages.login.path)
            });
        }
    }

    useEffect(() => {
        const code = searchParams.get('code')
        if (code) azureLogin(code)
        else navigate('/')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])
}

export default AzureLoginCallback
