import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../../components/bootstrap/Modal';
import { useFormik } from 'formik';
import { useMst } from '../../../../../models';
import InputGroup, { InputGroupText } from '../../../../../components/bootstrap/forms/InputGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Label from '../../../../../components/bootstrap/forms/Label';
import Popovers from '../../../../../components/bootstrap/Popovers';
import Icon from '../../../../../components/icon/Icon';
import Checks, { ChecksGroup } from '../../../../../components/bootstrap/forms/Checks';
import moment from 'moment';
import WorkSchduleTemplateService from '../../../../../services/WorkSchduleTemplateService';
import showNotification from '../../../../../components/extras/showNotification';
import { CirclePicker, TwitterPicker } from 'react-color';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../../components/bootstrap/forms/Select';
import Select2 from 'react-select';
import CompanyService from '../../../../../services/CompanyService';
import RestdayService from '../../../../../services/RestdayService';

const checkType = (type) => {
	switch (type) {
		case 'WORK_SCHDULE':
			return 'work';
		case 'REST_SCHDULE':
			return 'rest';
		case 'DAYOFF':
			return 'dayoff';
	}
}

const WorkScheduleTemplate = forwardRef((props, ref) => {

	const { t } = useTranslation(['translation', 'menu']);
	const { company, user } = useMst();
	const [list, setList] = useState([]);
	const [type, setType] = useState('work');
	const [restTypes, setRestTypes] = useState([]);
	const [startTime, setStartTime] = useState([]);
	const [endTime, setEndTime] = useState([]);
	const [userInfo, setUserInfo] = useState([]);
	const [groupInfo, setGroupInfo] = useState({});
	const [restTimeError, setRestTimeError] = useState('')
	const [workTimeError, setWorkTimeError] = useState('')
	const [restStartTime, setRestStartTime] = useState([])
	const [restEndTime, setRestEndTime] = useState([])
	// const refresh = async () => {
	// 	await WorkSchduleTemplateService.list(company.get.id).then((res) => {
	// 		setList(res?.data || []);
	// 	});
	// };
	
	useImperativeHandle(ref, () => ({
		resetForm: () => {
			form.resetForm();
		},
		setFrom: (values) => {
			form.setValues(values);
			setRestStartTime({
				value: moment(values?.workTime[0][0], 'HH:mm').format('HH:mm'),
				label: moment(values?.workTime[0][0], 'HH:mm').format('HH:mm'),
			})
			setRestEndTime({
				value: moment(values?.workTime[0][1], 'HH:mm').format('HH:mm'),
				label: moment(values?.workTime[0][1], 'HH:mm').format('HH:mm'),
			})
			setType(checkType(values.type));
		}
	}));

	const form = useFormik({
		initialValues: {
			// 공통코드
			id: null,
			companyId: company.get.id,
			name: '',
			code: '',
			color: null,
			type: 'WORK_SCHDULE', // _SCHDULE 붙일것
			memo: '', // 설명
			paid: true, // 유급 무급
			deduction: 0, // 차감

			// 근무
			workTime: [['', '']], // 근무시간
			// 여기부터는 추후 구현 기능
			restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]], // 휴식지정
			restAuto: false, // 휴식 수동

			// 연차
			paidInfo: { paidType: 'UNPAID', paidUnit: 'd', paidValue: 0 }, // 급여 지급정보
			option: 'REQUEST', // 휴가 부여 방법
			dayOff: false, // 휴일 포함 여부
			gender: '', // 성별
			document: '', // 증명자료 제출
			start: { unit: 'y', value: 0 }, // 입사후 부여 +시점
			end: { unit: 'd', value: 0 }, // 사용 만료 기한
			use: '', // 분할 사용여부
			onOff: true, // 사용여부

			// 외근
			// 출장
			areaId: null,
			allDay: {
				use: false,
				time: 0,
			}, // 간주근로 여부
			shortcutKey: null, // 단축키
			parentId: null,
		},
		validate: (values) => {
			const errors = {};

			// if(type === 'work'){
				if(values.shortcutKey) {
					if(values.shortcutKey < 2 && values.shortcutKey > 9) {
						errors.shortcutKey = t('단축키는 2~9 사이의 숫자만 입력 가능합니다');
					}
				}
				if (values.name === '') {
					errors.name = t('템플릿 이름을 입력해주세요');
				}
				if (!values.code) {
					errors.code = t('코드를 입력해주세요');
				}
				if(type === 'rest') {
					if(values.parentId === null || values.parentId === '') {
						errors.parentId = t('연차 종류를 선택해주세요');
					}
				}
			// } 
			// else {
			// 	if(values.shortcutKey) {
			// 		if(values.shortcutKey < 2 && values.shortcutKey > 9) {
			// 			errors.shortcutKey = t('단축키는 2~9 사이의 숫자만 입력 가능합니다');
			// 		}
			// 	}
			// 	if (values.name === '') {
			// 		errors.name = t('템플릿 이름을 입력해주세요');
			// 	}
			// 	if (!values.code) {
			// 		errors.code = t('코드를 입력해주세요');
			// 	}
			// 	if(!values.workTime[0][0] || !values.workTime[0][1]) {
			// 		errors.workTime = t('시간을 입력해주세요');
			// 	}
			// 	//연차 필수입력사항 1. 연차종류 2. 연차 시간'
			// }


			// if(type === 'rest') {
			// 	if (values.option == 'YEAR' || values.option == 'MONTH') {
			// 		setOpenOption1(true);
			// 	} else if (values.option == 'REST') {
			// 		setOpenOption2(true);
			// 	} else {
			// 		setOpenOption1(false);
			// 		setOpenOption2(false);
			// 	}
			// }

			return errors;
		},
		onSubmit: async (values) => {
			// console.log(values)
			await RestdayService.add(values).then(res => {
				if (res.data?.id > 0) {
					showNotification(t('템플릿 관리'), t('템플릿 {{TYPE}} 완료 되었습니다', { TYPE: values.id > 0 ? '수정' : '추가' }), 'success');
					refreshTemplete()
					props?.setIsOpen(false);
				} else {
					// showNotification(t('템플릿 관리'), t('템플릿 {{TYPE}} 실패', { TYPE: values.id > 0 ? '수정' : '추가' }), 'danger');
					showNotification(t('템플릿 관리'), t(res.data?.message || '템플릿 {{TYPE}} 실패', { TYPE: values.id > 0 ? '수정' : '추가' }), 'danger');
				}
			})

		},
	});

	console.log('form.errors', Object.keys(form.errors).length > 0)
	useLayoutEffect(() => {
		const getRestTypes = async () => {
			const res = await CompanyService.getUserRestType({ companyId: company.get.id, typeArray: ['REST', 'REST_CUSTOM'] });
			if(res?.data) {
				const types = res?.data || [];
				setRestTypes(
					[
					{value: null, label: '연차 유형 선택'},
					...types?.filter( v => (v.option == 'REQUEST' || v.option == 'MANUAL'))
					]
				);
			}
			
			await CompanyService.userListOne(company.get.id, user.me.id, moment().format('YYYY')).then((user) => {
				setUserInfo(user?.data?.rows[0] || [])
				setGroupInfo(user?.data?.rows[0]?.group || []);
			});

		}
		getRestTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	// useEffect(() => {
	// 	console.log('selectTemplate', props?.selectTemplate);
	// 	if(props?.selectTemplate?.id) {		
	// 		form.setValues(props?.selectTemplate)
	// 		switch(props?.selectTemplate?.type) {
	// 			case 'WORK':
	// 			case 'WORK_CUSTOM':
	// 				setType('work');
	// 				break;
	// 			case 'REST':
	// 			case 'REST_CUSTOM':
	// 				setType('rest');
	// 				break;
	// 			case 'OUTSIDE_CUSTOM':
	// 				setType('outside-work');
	// 				break;
	// 			case 'BUSINESS_CUSTOM':
	// 				setType('business-trip')
	// 				break;
	// 		}			
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props?.selectTemplate])

	useEffect(() => {
		if(type === 'rest'){
			if(form?.values.workTime[0][0] && form?.values.workTime[0][1]){
				let startDate = moment();
				const formattedStartTime = moment(form.values.workTime[0][0], "HHmm");
				const formattedEndTime = moment(form.values.workTime[0][1], "HHmm");

				let endDateChange = startDate.clone();
				if (formattedEndTime.isBefore(formattedStartTime)) {
						endDateChange.add(1, 'days');
				}

				let startInData = moment(`${startDate.format('YYYY-MM-DD')} ${formattedStartTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
				let endInData = moment(`${endDateChange.format('YYYY-MM-DD')} ${formattedEndTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');

				let durationInSeconds = moment.duration(endInData.diff(startInData)).asSeconds();

				let restRealTimeMinutes = durationInSeconds;
				let restTimeMinutes = 0;
				if(!form?.values?.restAuto) {
					if(form?.values?.restTime[moment(startDate).format('E')-1][0][0] === "") {
						restTimeMinutes =
							Math.floor(
							(parseInt(restRealTimeMinutes)) /
								(270 * 60)
							) *
							(30 * 60);
						} else {
						// 시간 범위 체크
						const s2 = moment(formattedStartTime, 'HH:mm');
						const e2 = moment(formattedEndTime, 'HH:mm');
						form?.values?.restTime[moment(startDate).format('E')-1].map( times => {
							// console.log(">>> ", times)
							if(times[0] !== '') {

							if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[1], 'HH:mm') <= moment(e2)) {
								console.log('this ALL', moment(times[0], 'HH:mm').format('HH:mm'), moment(times[1], 'HH:mm').format('HH:mm'))
								restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
								// console.log("= ", moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second'))
							} else if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[0], 'HH:mm') <= moment(e2)) {
								console.log('this S', moment(times[0], 'HH:mm').format('HH:mm'));
								restTimeMinutes += moment(e2).diff(moment(times[0], 'HH:mm'), 'second');
								// console.log("= ", moment(e).diff(moment(times[0], 'HH:mm'), 'second'))
							} else if(moment(times[1], 'HH:mm') <= moment(e2) && moment(times[1], 'HH:mm') >= moment(s2)) {
								console.log('this E', moment(times[1], 'HH:mm').format('HH:mm'))
								restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(s2), 'second');
								// console.log("= ", moment(times[1], 'HH:mm').diff(moment(s), 'second'))
							} else {
								if (
									(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[1], 'HH:mm') <= moment(e2)) ||
									(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[0], 'HH:mm') <= moment(e2)) ||
									(moment(times[1], 'HH:mm') <= moment(e2) && moment(times[1], 'HH:mm') >= moment(s2))
								) {
									restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
								}else{
									restTimeMinutes = 0
								}
							}

							}
						})
					}
					restTimeMinutes = restTimeMinutes > 0 ? restTimeMinutes : 0;
				}

				let workDefaultTime = userInfo?.standardHur || 28800;
				// if(company.get.employType.includes('촉탁')) {
				// 	// 소정시간 구하기
				// 	if(group?.workTime[0][1])
				// 		workDefaultTime = moment.duration(moment(group?.workTime[0][1], 'HH:mm').diff(moment(group?.workTime[0][0], 'HH:mm'))).asSeconds();
				// }
				const duration = parseFloat((restRealTimeMinutes - restTimeMinutes) / workDefaultTime);
				let minutesExistence  = Math.floor(((restRealTimeMinutes - restTimeMinutes)  % 3600) / 60);
				if((restRealTimeMinutes - restTimeMinutes) > workDefaultTime || (formattedStartTime.isSame(formattedEndTime))) {
					setRestTimeError(`${(workDefaultTime/3600)} 시간 초과(휴게시간 제외)는 신청이 불가합니다`)
				}else if(minutesExistence > 0 ) {
					setRestTimeError('신청 시간은(*휴게시간 제외)1시간 단위만 신청 가능합니다');
				}else{
					setRestTimeError('');
				}

				let durationTime = moment(formattedEndTime, 'HH:mm').diff(moment(formattedStartTime, 'HH:mm'), 'hours');
			}
		}

		// [2024.02.06] 근무 템플릿 근무시간 오전 6시 체크
		if (type === 'work' && form?.values.workTime[0][0] && form?.values.workTime[0][1]) {
			// 금일 오전 6시 ~ 익일 오전 6시까지
			const checkStartTime = moment().format('YYYY-MM-DD 06:00:00')
			const checkEndTime = moment().add(1, 'days').format('YYYY-MM-DD 06:00:00')

			const times = formattedTimeString(form?.values.workTime[0][0], form?.values.workTime[0][1], checkStartTime, checkEndTime)

			if (times.startTime.isBefore(checkStartTime)) {
				setWorkTimeError('시작 시간은 오전 6시 이후부터 가능합니다.')
			} else if (times.endTime.isAfter(checkEndTime)) {
				setWorkTimeError('종료 시간은 익일 오전 6시 이전까지 가능합니다.')
			} else {
				setWorkTimeError('')
			}
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.workTime])

	const formattedTimeString = (startTime, endTime, checkStartTime, checkEndTime) => {
		const startDate = moment()
		const formattedStartTime = moment(startTime, "HHmm");
		const formattedEndTime = moment(endTime, "HHmm");

		let startDateChange = startDate.clone();
		let endDateChange = startDate.clone();
		if (formattedEndTime.isBefore(formattedStartTime)) { // 종료시간이 시작시간보다 작으면 +1일
			endDateChange.add(1, 'days');
		}

		const startInData = moment(`${startDate.format('YYYY-MM-DD')} ${formattedStartTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');

		if (startInData.isBefore(checkStartTime)) { // 시작/종료시간이 금일 오전 6시 이전이면 +1일
			endDateChange.add(1, 'days');
			startDateChange.add(1, 'days');
		}

		const startInData2 = moment(`${startDateChange.format('YYYY-MM-DD')} ${formattedStartTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
		const endInData = moment(`${endDateChange.format('YYYY-MM-DD')} ${formattedEndTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');

		return {startTime: startInData2, endTime: endInData}
	}

	const timeStartSetting = () => {
		let time = [];

			for (let i = 0; i < 48; i++) {
				time.push({value: moment('0000', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm'), label: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm')});
			}
			setStartTime([...time]);
	}
	
	const timeEndSetting = () => {
	
		let time = [];

		for (let i = 0; i < 48; i++) {
			time.push({value: moment('0000', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm'), label: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm')});
		}
		setEndTime([...time]);
	};

	useEffect(() => {
		timeStartSetting();
		timeEndSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.workTime[0][0]]);

	useEffect(() => {				
		switch(type) {
			case 'work':
				form.setFieldValue('type', 'WORK_SCHDULE');
				break;
			case 'rest':
				form.setFieldValue('type', 'REST_SCHDULE');
				break;
			case 'outside-work':
				form.setFieldValue('type', 'WORK_SCHDULE');
				refreshArea();
				break;
			case 'business-trip':
				form.setFieldValue('type', 'WORK_SCHDULE');
				refreshArea();
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);


	const [openOption1, setOpenOption1] = useState(false);
	const [openOption2, setOpenOption2] = useState(false);

	// AREA
	const [settingArea, setSettingArea] = useState([]);
	const refreshArea = async () => {
		await CompanyService.getWorkingArea(company.id).then((response) => {
			setSettingArea(response?.data?.filter((data) => data.address != 'outer') || []);
		});
	}

	const getTypeText = (type) => {
		switch(type) {
			case 'work':
			// case 'WORK_CUSTOM':
				return '근무';
			case 'rest':
			// case 'REST_CUSTOM':
				return '연차';
			// case 'OUTSIDE_CUSTOM':
			// 	return '외근';
			case 'business-trip':
				return '출장';
		}
	}

	const refreshTemplete = async() => {
		let templates = [{
			id: `temp-holiday`,//-${moment().valueOf()}`,
			companyId: company.get.id,
			name: 'DAYOFF',
			type: 'DAYOFF',
			code: 'F',
			color: {"hex": "#D7D7D7", "hsl": {"a": 1, "h": 233.25, "l": 0, "s": 0}, "hsv": {"a": 1, "h": 233.25, "s": 0, "v": 0}, "rgb": {"a": 1, "b": 0, "g": 0, "r": 0}, "oldHue": 233.25, "source": "hex"},
			workTime: [['', '']],
			restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]],
			restAuto: true,
			deduction: 0,
			paid: true,
			onOff: true,
			shortcutKey: 1,
			paidInfo: {
				paidType: 'PAID',
				paidUnit: 'd',
				paidValue: 0,
			},
			start: {
				unit: 'y',
				value: 0,
			},
			end: {
				unit: 'd',
				value: 0,
			},
			memo: '휴일',
			option: 'REQUEST',
			use: '',
			dayOff: false,
			gender: '',
			document: '',
			allDay: {
				use: true,
				time: 8,
			},
			company_id: company.get.id,
		}];

		const res = await CompanyService.getUserRestType({ companyId: company.get.id, typeArray: ['WORK_SCHDULE', 'REST_SCHDULE'] });
		// .then((res) => {
		// });
		if (res) {
			// const defaulHoliday = {
			// 	id: `temp-holiday`, //-${moment().valueOf()}`,
			// 	companyId: company.get.id,
			// 	name: 'OFF',
			// 	type: 'DAYOFF',
			// 	code: 'F',
			// 	color: { hex: '#000000', hsl: { a: 1, h: 233.25, l: 0, s: 0 }, hsv: { a: 1, h: 233.25, s: 0, v: 0 }, rgb: { a: 1, b: 0, g: 0, r: 0 }, oldHue: 233.25, source: 'hex' },
			// 	workTime: [['', '']],
			// 	restTime: [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]],
			// 	restAuto: true,
			// 	deduction: 0,
			// 	paid: true,
			// 	onOff: true,
			// 	paidInfo: {
			// 		paidType: 'PAID',
			// 		paidUnit: 'd',
			// 		paidValue: 0,
			// 	},
			// 	start: {
			// 		unit: 'y',
			// 		value: 0,
			// 	},
			// 	end: {
			// 		unit: 'd',
			// 		value: 0,
			// 	},
			// 	memo: 'OFF',
			// 	option: 'MANUAL',
			// 	use: '',
			// 	dayOff: false,
			// 	gender: '',
			// 	document: '',
			// 	shortcutKey: null,
			// 	allDay: {
			// 		use: true,
			// 		time: 8,
			// 	},
			// 	company_id: company.get.id,
			// };
			templates = [...templates, ...res?.data || []];
			// setTemplates(customSort(res?.data || []));
			// setTemplates(customSort([...[defaulHoliday], ...(res?.data || [])]));
			// forceUpdate();			
		}

		props.setOriginalTemplates(templates);
	}

	useEffect(()=> {
		if(!props?.isOpen){
			//form.values.workTime[0][0] = '';
			//form.values.workTime[0][1] = '';
			//form.setFieldValue('workTime', [['', '']]);
			//props?.refresh();
			refreshTemplete()
			setRestStartTime([])
			setRestEndTime([])
			setType('work')
			let reset = ['', '']
			//form.setFieldValue('workType', form.values.workTime);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[props?.isOpen]);

	return (
		<Modal size='md' isStaticBackdrop={true} isOpen={props?.isOpen} setIsOpen={props?.setIsOpen} isScrollable isCentered>
			<ModalHeader setIsOpen={props?.setIsOpen} style={form?.values?.color?.hex && { backgroundColor: form?.values?.color?.hex || '#5A6CFA' }}>
				<ModalTitle>{t(`템플릿 ${form.values.id > 0 ? '수정' : '생성'}`)}</ModalTitle>
			</ModalHeader>
			<div className='d-flex flex-row border-0 border-bottom border-light px-4 mt-3'>
				<Button
					type='button'
					rounded={0}
					size='lg'
					className={type == 'work' ? 'border-0 border-bottom border-info' : ''}
					isDisable={form.values.id > 0}
					onClick={() => {
						form.values.workTime[0][0] = '';
						form.values.workTime[0][1] = '';
						form.setFieldValue('workType', form.values.workTime);
						setType('work');
						form.resetForm();
					}}>
					근무
				</Button>
				<Button
					type='button'
					rounded={0}
					size='lg'
					className={type == 'rest' ? 'border-0 border-bottom border-info' : ''}
					isDisable={form.values.id > 0}
					onClick={() => {
						form.values.workTime[0][0] = '';
						form.values.workTime[0][1] = '';
						form.setFieldValue('workType', form.values.workTime);
						setType('rest');
						form.resetForm();
					}}>
					연차
				</Button>
				{/* <Button
					type='button'
					rounded={0}
					size='lg'
					className={type == 'outside-work' ? 'border-0 border-bottom border-info' : ''}
					isDisable={form.values.id > 0}
					onClick={() => {
						setType('outside-work');
						form.resetForm();
					}}>
					외근
				</Button> */}
				{/* <Button
					type='button'
					rounded={0}
					size='lg'
					className={type == 'business-trip' ? 'border-0 border-bottom border-info' : ''}
					isDisable={form.values.id > 0}
					onClick={() => {
						setType('business-trip');
						form.resetForm();
					}}>
					출장
				</Button> */}
			</div>
			<ModalBody className='h-100 p-0'>
				{/* <div className='d-flex flex-row overflow-auto text-nowrap mt-2'>
						<Button
							className='me-1'
							type='button'
							icon='Add'
							color='light'
							isLight
							onClick={() => {
								form.resetForm();
							}}>
							신규 추가
						</Button>
						{list?.map((item) => {
							return (
								<Button
									size='sm'
									key={item.id}
									type='button'
									icon='MoreTime'
									color={form.values.id !== item.id ? 'light' : 'success'}
									isLight={form.values.id === item.id}
									className='me-1'
									onClick={() => {
										form.setValues(item);
									}}>
									{item.name}
								</Button>
							);
						})}
						{list.length === 0 && <div>{t('템플릿을 추가해주세요')}</div>}
					</div> */}

				<div className='p-4 '>
					<div className='col-sm-12 my-2'>
						{/* 근무 템플릿 <br /> 이름 <br /> 근무시작 근무종료 <br /> 휴게시간 | 수동, 자동 */}
						<form className='' onSubmit={form.handleSubmit}>
							<div className='row'>
								<div className='row mb-3'>
									<div className='col-sm-6'>
										<Label>{'템플릿 명'}</Label>
										<div className='d-flex flex-row justify-content-center align-items-center'>
											{' '}
											{(form?.values?.color?.hex || '') !== '' && <span style={{ width: 8, height: 8, backgroundColor: form?.values?.color?.hex || '#5A6CFA', borderRadius: 16 }} />}
											<Input id='name' name='name' placeHolder={`${getTypeText(type)} 이름`} className='border-1' onChange={form.handleChange} onBlur={form.handleBlur} value={form.values.name} />
										</div>
										{form.touched.name && form.errors.name ? <div className='text-danger m-1'>{t(form.errors.name)}</div> : null}
									</div>

									<div className='col-sm-3'>
										<Label>{'코드'}</Label>
										<Input name='code' placeHolder={`${getTypeText(type)} 코드`} className='border-1' onChange={form.handleChange} onBlur={form.handleBlur} value={form.values.code} />
									{form.touched.name && form.errors.code ? <div className='text-danger text-nowrap m-1'>{t(form.errors.code)}</div> : null}
									</div>

									<div className='col-sm-3'>
										<Label>{'단축키'}</Label>
										<Input
											name='shortcutKey'
											id='shortcutKey'
											type='number'
											min={2}
											max={9}
											placeholder={'단축키'}
											className='border-1'
											onChange={form.handleChange}
											onBlur={form.handleBlur}
											value={form.values.shortcutKey}
										/>
									</div>
								</div>
								

								<div className='row mb-3'>
									<TwitterPicker
										width={'100%'}
										className='shadow-none'
										triangle={'hide'}
										color={form.values.color || '#5A6CFA'}
										colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#9B9B9B']}
										// onSwatchHover={(color) => {
										// 	form.setFieldValue('color', color);
										// }}
										onChangeComplete={(color) => {
											form.setFieldValue('color', color);
											// console.log('color', form.values.color);
										}}
									/>
								</div>

								{(type === 'work' || type === 'outside-work' || type === 'business-trip') && (
									<>
										<div className='row mb-3 px-4'>
											<Textarea
												rows={2}
												value={form.values.memo}
												onChange={(e) => {
													form.setFieldValue('memo', e.target.value, false);
												}}
												placeholder={t(`${getTypeText(type)}에 대한 설명을 입력해주세요`)}
											/>
										</div>

										<div className='row mb-3 px-4'>
											<Label className='form-label col-form-label col-sm-12 d-flex flex-row align-items-center fw-bold text-dark'>
												{t('근무시간 설정')}
												<Popovers trigger={'hover'} desc={'근무시간 여러개 등록시 가까운 시간대에 출근처리 됩니다.'}>
													<div className='ms-2'>
														<Icon icon='InfoOutline' />
													</div>
												</Popovers>
											</Label>
											<div className='col-sm-12'>
												{form.values.workTime?.map((_, wtIndex) => {
													return (
														<div key={`worktime-${wtIndex}`} className='d-flex flex-row align-items-center'>
															<InputGroup className='pb-1' size='sm'>
																<Input
																	type='time'
																	className='me-1'
																	onChange={(e) => {
																		let tmp = [...form.values.workTime];
																		tmp[wtIndex][0] = e.target.value;
																		tmp[wtIndex][1] = moment(e.target.value, 'HH:mm').add(9, 'hour').format('HH:mm');
																		form.setFieldValue('workTime', tmp, false);
																	}}
																	value={form.values.workTime[wtIndex][0]}
																/>
																<Input
																	type='time'
																	onChange={(e) => {
																		let tmp = [...form.values.workTime];
																		tmp[wtIndex][1] = e.target.value;
																		form.setFieldValue('workTime', tmp, false);
																	}}
																	value={form.values.workTime[wtIndex][1]}
																/>
																{form.values.workTime[0][0] !== '' &&
																form.values.workTime.length > 0 &&
																moment(form.values.workTime[wtIndex][1], 'HH:mm') <= moment(form.values.workTime[wtIndex][0], 'HH:mm') ? (
																	<InputGroupText>+1 일</InputGroupText>
																) : (
																	<></>
																)}
																{/* {wtIndex > 0 ? (
																	<Button
																		type='button'
																		color='light'
																		onClick={() => {
																			let tmp = [...form.values.workTime];
																			tmp.splice(wtIndex, 1);
																			form.setFieldValue('workTime', tmp);
																		}}>
																		{t('제거')}
																	</Button>
																) : (
																	<></>
																)} */}
																{/* {wtIndex === form.values.workTime.length - 1 ? (
																	<Button
																		type='button'
																		color='light'
																		onClick={() => {
																			form.setFieldValue('workTime', [...form.values.workTime, ['', '']], false);
																		}}>
																		{t('추가')}
																	</Button>
																) : (
																	<></>
																)} */}
															</InputGroup>
														</div>
													);
												})}
											</div>
											{workTimeError !== '' ? <div className='text-danger m-1'>{workTimeError}</div> : ''}
											{form.touched.name && (form.values.workTime[0][0] === '' || form.values.workTime[0][1] === '') ? <div className='text-danger m-1'>{t('시간을 입력해주세요')}</div> : null}

										</div>

										<div className='row mb-3 px-4'>
											<Label className='form-label col-form-label col-sm-12 d-flex flex-row align-items-center fw-bold text-dark'>
												{t('휴게시간 설정')}
												<Popovers trigger={'hover'} desc={'근무시간에 기반하여 휴게시간을 자동으로 추가하지 않고, 사용자 앱의 휴게 버튼을 눌러 수동으로 휴게시간을 기록할 수 있습니다.'}>
													<div className='ms-2'>
														<Icon icon='InfoOutline' />
													</div>
												</Popovers>
											</Label>
											<div className='col-sm-12'>
												<ButtonGroup className='p-1 bg-light w-100 rounded'>
													<Button
														type='button'
														className={`${form.values.restAuto && 'bg-white'}`}
														rounded={1}
														// isOutline={!form.values.restAuto}
														// color={form.values.restAuto ? 'success' : 'light'}>
														color={form.values.restAuto ? 'white' : 'light'}
														// isLight={form.values.restAuto}
														onClick={() => {
															form.setFieldValue('restAuto', true); //, false);
														}}>
														{t('수동 휴게시간')}
													</Button>
													<Button
														type='button'
														className={`${!form.values.restAuto && 'bg-white'}`}
														rounded={1}
														// isOutline={form.values.restAuto}
														color={!form.values.restAuto ? 'white' : 'light'}
														// isLight={!form.values.restAuto}
														onClick={() => {
															form.setFieldValue('restAuto', false); //, false);
														}}
														// color={!form.values.restAuto ? 'success' : 'light'}
													>
														{t('자동 휴게시간')}
													</Button>
												</ButtonGroup>

												{!form.values.restAuto && (
													<div className='pt-3'>
														<ChecksGroup>
															<Checks
																// name='checkWorkTime'
																type='checkbox'
																checked={((form?.values?.restTime && form?.values?.restTime[0][0][0]) || '') === '' ? true : false}
																className='mb-2'
																onChange={() => {
																	form.setFieldValue('restTime', [[['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]], false);
																}}
																label={
																	<div className='d-flex flex-row align-middle mb-2'>
																		<div className='fw-bold'>{t('근무시간 기준')}</div>
																		<Popovers trigger='hover' desc={<div>{t('근로자가 4시간 이상 근무시 30분 휴게시간을 부여합니다')}</div>}>
																			<Icon icon='InfoOutline' className='ms-1' />
																		</Popovers>
																	</div>
																}
															/>
															<Checks
																// name='checkOurTime'
																type='checkbox'
																className='mb-2'
																checked={((form?.values?.restTime && form?.values?.restTime[0][0][0]) || '') !== '' ? true : false}
																onChange={() => {
																	form.setFieldValue('restTime', [[['12:00', '13:00']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']], [['', '']]], false);
																}}
																label={
																	<div className='d-flex flex-row align-middle mb-2'>
																		<div className='fw-bold'>{t('특정시간 기준')}</div>
																		<Popovers trigger='hover' desc={<div>{t('휴게시간을 직접 지정합니다')}</div>}>
																			<Icon icon='InfoOutline' className='ms-1' />
																		</Popovers>
																	</div>
																}
															/>
														</ChecksGroup>
													</div>
												)}
												{form.values.restAuto && <div className='p-3'>{t('앱의 휴식기능을 이용하여 수동으로 관리할수 있습니다')}</div>}

												{form.values.restTime[0][0][0] !== '' &&
													['월', '화', '수', '목', '금', '토', '일'].map((day, wtIndex) => {
														return (
															<div key={`resttime-${wtIndex}`} className={`mt-2`}>
																{form.values.restTime[wtIndex].map((_, i) => {
																	return (
																		<div key={`resttime-${wtIndex}-${i}`} className='d-flex flex-row align-middle'>
																			<div style={{ width: 100 }} className='text-center'>
																				<ButtonGroup size='sm'>
																					{i === 0 && form.values.restTime[wtIndex][i][0] !== '' && form.values.restTime[wtIndex][i][1] !== '' ? (
																						<Button
																							type='button'
																							color='success'
																							isLight
																							className='text-nowrap'
																							onClick={() => {
																								let tmp = [...form.values.restTime];
																								['월', '화', '수', '목', '금', '토', '일'].map((_, v) => {
																									tmp[v] = [...form.values.restTime[wtIndex]];
																								});
																								form.setFieldValue('restTime', tmp, false);
																							}}>
																							{t('일괄적용')}
																						</Button>
																					) : (
																						<></>
																					)}
																				</ButtonGroup>
																			</div>
																			<InputGroup size='sm'>
																				<InputGroupText>
																					{i === 0 ? <span className={i === 0 ? 'text-success' : 'text-dark'}>{t(day)}</span> : <Icon icon='SubdirectoryArrowRight' size='sm' />}
																				</InputGroupText>
																				<Input
																					type='time'
																					onChange={(e) => {
																						let tmp = [...form.values.restTime];
																						tmp[wtIndex][i][0] = e.target.value;
																						tmp[wtIndex][i][1] = moment(e.target.value, 'HH:mm').add(1, 'hour').format('HH:mm');
																						form.setFieldValue('restTime', tmp, false);
																					}}
																					value={form.values.restTime[wtIndex][i][0]}
																				/>
																				<Input
																					type='time'
																					onChange={(e) => {
																						let tmp = [...form.values.restTime];
																						tmp[wtIndex][i][1] = e.target.value;
																						form.setFieldValue('restTime', tmp, false);
																					}}
																					value={form.values.restTime[wtIndex][i][1]}
																				/>
																			</InputGroup>
																			<div style={{ width: 100 }} className='text-start ms-1'>
																				<ButtonGroup size='sm'>
																					{form.values.restTime[wtIndex].length === i + 1 ? (
																						/* &&
																						form.values.restTime[wtIndex][i][0] !== '' &&
																						form.values.restTime[wtIndex][i][1] !== '' */
																						<>
																							<Button
																								type='button'
																								color='light'
																								className='text-nowrap'
																								onClick={() => {
																									let tmp = [...form.values.restTime];
																									tmp[wtIndex].push(['', '']);
																									form.setFieldValue('restTime', tmp, false);
																								}}>
																								{t('+')}
																							</Button>
																							{i > 0 && (
																								<Button
																									type='button'
																									color='light'
																									className='text-nowrap'
																									onClick={() => {
																										let tmp = [...form.values.restTime];
																										tmp[wtIndex].splice(i, 1);
																										form.setFieldValue('restTime', tmp, false);
																									}}>
																									{t('-')}
																								</Button>
																							)}
																						</>
																					) : (
																						<></>
																					)}
																				</ButtonGroup>
																			</div>
																		</div>
																	);
																})}
															</div>
														);
													})}
											</div>
										</div>
										{form.touched.restTime && form.errors.restTime ? <div className='text-danger'>{t(form.errors.restTime)}</div> : null}
									</>
								)}

								{type === 'rest' && (
									<>
										<div className='row mb-3 px-4'>
											<Textarea
												rows={2}
												value={form.values.memo}
												onChange={(e) => {
													form.setFieldValue('memo', e.target.value, false);
												}}
												placeholder={t('휴가에 대한 설명을 입력해주세요')}
											/>
										</div>

										<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('연차 종류')}</Label>
												<div className='col-sm-8'>
													<Select
														list={restTypes.map((v) => {return {value: v.id, label: v.name}})}
														id='parentId'
														value={form.values.parentId}
														onChange={(e) => {
															form.setFieldValue('parentId', e.target.value);
														}}
														// onChange={form.handleChange}
														onBlur={form.handleBlur}
													/>
											{form.touched.name && form.errors.parentId ? <div className='text-danger m-1'>{t(form.errors.parentId)}</div> : null}
												</div>
											</div>
											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('연차 시간')}</Label>
												<div className='col-sm-8'>
												
															<div className='d-flex flex-row align-items-center'>
																{/* <InputGroup className='pb-1' size='sm'>
																	<Input
																		type='time'
																		className='me-1'
																		onChange={(e) => {
																			let tmp = [...form.values.workTime];
																			tmp[wtIndex][0] = e.target.value;
																			tmp[wtIndex][1] = moment(e.target.value, 'HH:mm').add(9, 'hour').format('HH:mm');
																			form.setFieldValue('workTime', tmp, false);
																		}}
																		value={form.values.workTime[wtIndex][0]}
																	/>
																	<Input
																		type='time'
																		onChange={(e) => {
																			let tmp = [...form.values.workTime];
																			tmp[wtIndex][1] = e.target.value;
																			form.setFieldValue('workTime', tmp, false);
																		}}
																		value={form.values.workTime[wtIndex][1]}
																	/>
																	{form.values.workTime[0][0] !== '' &&
																	form.values.workTime.length > 0 &&
																	moment(form.values.workTime[wtIndex][1], 'HH:mm') <= moment(form.values.workTime[wtIndex][0], 'HH:mm') ? (
																		<InputGroupText>+1 일</InputGroupText>
																	) : (
																		<></>
																	)}
																</InputGroup> */}
																<Select2
																	ariaLabel='Select StartTime'
																	options={startTime}
																	placeholder={t('시작 시간 선택')}
																	value={restStartTime}
																	onChange={(e) => {
																		let tmp = [...form.values.workTime];
																		console.log("form.values.workTime[0][0]", form.values.workTime[0][0])
																		tmp[0][0] = e.value;
																		tmp[0][1] = moment(e.value, 'HH:mm').add(9, 'hour').format('HH:mm');
																		form.setFieldValue('workTime', tmp, false);
																		setRestStartTime({
																			...restStartTime,
																			value: moment(e.value, 'HH:mm').format('HHmm'),
																			label: moment(e.value, 'HH:mm').format('HH:mm'),
																		})
																		setRestEndTime({
																			...restEndTime,
																			value: moment(e.value, 'HH:mm').add(9, 'hour').format('HHmm'),
																			label: moment(e.value, 'HH:mm').add(9, 'hour').format('HH:mm'),
																		})
																	}}
																	style={
																		{
																			padding: '1rem 8rem 1rem 1rem',
																		}
																	}
																	theme={(theme) => ({
																		...theme,
																		borderRadius: 8,
																		borderColor: '#D9D9D9',
																	})}
																/>
																	<Select2
																	ariaLabel='Select EndTime'
																	options={endTime}
																	placeholder={t('종료 시간 선택')}
																  value={restEndTime}
																	onChange={(e) => {
																		let tmp = [...form.values.workTime];
																		tmp[0][1] = moment(e.value, 'HH:mm').format('HH:mm');
																		form.setFieldValue('workTime', tmp, false);
																		setRestEndTime({
																			...restEndTime,
																			value: moment(e.value, 'HH:mm').format('HHmm'),
																			label: moment(e.value, 'HH:mm').format('HH:mm'),
																		})
																	}}
																	style={
																		{
																			padding: '1rem 5rem 1rem 1rem',
																		}
																	}
																	theme={(theme) => ({
																		...theme,
																		borderRadius: 8,
																		borderColor: '#D9D9D9',
																	})}
																/>
															</div>
												{restTimeError !== '' ? <div className='text-danger m-1'>{restTimeError}</div> : ''}
												{form.touched.name && (form.values.workTime[0][0] === '' || form.values.workTime[0][1] === '') ? <div className='text-danger m-1'>{t('시간을 입력해주세요')}</div> : null}
												</div>
											</div>

										{/* <FormGroup label={t('상세 설정')} className='mb-4 fw-bold text-dark px-4'>

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('부여 일수')}</Label>
												<div className='col-sm-8'>
													<InputGroup>
														<Input
															type='number'
															value={form.values.paidInfo.paidValue}
															//step={0.25}
															min={0}
															readOnly={form.values.paidInfo.paidUnit == ''}
															onChange={(e) => {
																form.setFieldValue(
																	'paidInfo',
																	{
																		...form.values.paidInfo,
																		paidValue: e.target.value,
																	},
																	false
																);
															}}
														/>
														<Select
															list={[
																{
																	value: '',
																	label: t('지급 미정'),
																},
																{
																	value: 'd',
																	label: t('대문자일'),
																},
																{
																	value: 'h',
																	label: t('대문자시간'),
																},
																{
																	value: 'm',
																	label: t('분'),
																},
															]}
															value={form.values.paidInfo.paidUnit}
															onChange={(e) => {
																form.setFieldValue(
																	'paidInfo',
																	{
																		...form.values.paidInfo,
																		paidUnit: e.target.value,
																	},
																	false
																);
															}}
														/>
													</InputGroup>
												</div>
											</div>


											{openOption2 && (
												<div className='row mb-2'>
													<Label className='form-label col-form-label col-sm-4'>{t('사용 만료 기한')}</Label>
													<div className='col-sm-8'>
														<InputGroup>
															<Input
																name='end'
																type='number'
																value={form.values.end.value}
																//step={0.25}
																min={0}
																onChange={(e) => {
																	form.setFieldValue(
																		'end',
																		{
																			...form.values.end,
																			value: e.target.value,
																		},
																		false
																	);
																}}
															/>
															<Select
																list={[
																	{
																		value: 'd',
																		label: t('대문자일'),
																	},
																	{
																		value: 'h',
																		label: t('대문자시간'),
																	},
																	{
																		value: 'm',
																		label: t('분'),
																	},
																]}
																value={form.values.end.unit}
																onChange={(e) => {
																	form.setFieldValue(
																		'paidInfo',
																		{
																			...form.values.end,
																			unit: e.target.value,
																		},
																		false
																	);
																}}
															/>
														</InputGroup>
													</div>
												</div>
											)}

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('분할 사용 여부')}</Label>
												<div className='col-sm-8'>
													<ButtonGroup size=''>
														<Button
															type='button'
															color={form.values.use === null ? 'info' : 'light'}
															isLight={form.values.use === null}
															onClick={() => {
																form.setFieldValue('use', null);
															}}>
															{t('연속사용')}
														</Button>
														<Button
															type='button'
															color={form.values.use === 'DAY' ? 'info' : 'light'}
															isLight={form.values.use === 'DAY'}
															onClick={() => {
																form.setFieldValue('use', 'DAY');
															}}>
															{t('대문자일')}
														</Button>
														<Button
															type='button'
															color={form.values.use === 'HALF' ? 'info' : 'light'}
															isLight={form.values.use === 'HALF'}
															onClick={() => {
																form.setFieldValue('use', 'HALF');
															}}>
															{t('반차')}
														</Button>
														<Button
															type='button'
															color={form.values.use === 'TIME' ? 'info' : 'light'}
															isLight={form.values.use === 'TIME'}
															onClick={() => {
																form.setFieldValue('use', 'TIME');
															}}>
															{t('대문자시간')}
														</Button>
													</ButtonGroup>
												</div>
											</div>

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('휴일 포함')}</Label>
												<div className='col-sm-8'>
													<ButtonGroup size=''>
														<Button
															type='button'
															color={form.values.dayOff ? 'info' : 'light'}
															isLight={form.values.dayOff}
															onClick={() => {
																form.setFieldValue('dayOff', true);
															}}>
															{t('포함')}
														</Button>
														<Button
															type='button'
															color={!form.values.dayOff ? 'info' : 'light'}
															isLight={!form.values.dayOff}
															onClick={() => {
																form.setFieldValue('dayOff', false);
															}}>
															{t('미포함')}
														</Button>
													</ButtonGroup>
												</div>
											</div>

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('급여 지급')}</Label>
												<div className='col-sm-8'>
													<ButtonGroup size=''>
														<Button
															type='button'
															color={form.values.paidInfo.paidType === 'PAID' ? 'info' : 'light'}
															isLight={form.values.paidInfo.paidType === 'PAID'}
															onClick={() => {
																form.setFieldValue('paidInfo', {
																	...form.values.paidInfo,
																	paidType: 'PAID',
																});
															}}>
															{t('유급')}
														</Button>
														<Button
															type='button'
															color={form.values.paidInfo.paidType === 'UNPAID' ? 'info' : 'light'}
															isLight={form.values.paidInfo.paidType === 'UNPAID'}
															onClick={() => {
																form.setFieldValue('paidInfo', {
																	...form.values.paidInfo,
																	paidType: 'UNPAID',
																});
															}}>
															{t('무급')}
														</Button>
													</ButtonGroup>
												</div>
											</div>

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('적용 성별')}</Label>
												<div className='col-sm-8'>
													<ButtonGroup size=''>
														<Button
															type='button'
															color={form.values.gender === null ? 'info' : 'light'}
															isLight={form.values.gender === null}
															onClick={() => {
																form.setFieldValue('gender', null);
															}}>
															{t('모두')}
														</Button>
														<Button
															type='button'
															color={form.values.gender === 'MALE' ? 'info' : 'light'}
															isLight={form.values.gender === 'MALE'}
															onClick={() => {
																form.setFieldValue('gender', 'MALE');
															}}>
															{t('남성')}
														</Button>
														<Button
															type='button'
															color={form.values.gender === 'FAMLE' ? 'info' : 'light'}
															isLight={form.values.gender === 'FAMLE'}
															onClick={() => {
																form.setFieldValue('gender', 'FAMLE');
															}}>
															{t('여성')}
														</Button>
													</ButtonGroup>
												</div>
											</div>

											<div className='row mb-2'>
												<Label className='form-label col-form-label col-sm-4'>{t('증명자료 제출')}</Label>
												<div className='col-sm-8'>
													<ButtonGroup size=''>
														<Button
															type='button'
															color={form.values.document === null ? 'info' : 'light'}
															isLight={form.values.document === null}
															onClick={() => {
																form.setFieldValue('document', null);
															}}>
															{t('없음')}
														</Button>
														<Button
															type='button'
															color={form.values.document === 'BEFORE' ? 'info' : 'light'}
															isLight={form.values.document === 'BEFORE'}
															onClick={() => {
																form.setFieldValue('document', 'BEFORE');
															}}>
															{t('사전제출')}
														</Button>
														<Button
															type='button'
															color={form.values.document === 'AFTER' ? 'info' : 'light'}
															isLight={form.values.document === 'AFTER'}
															onClick={() => {
																form.setFieldValue('document', 'AFTER');
															}}>
															{t('사후제출')}
														</Button>
													</ButtonGroup>
												</div>
											</div>
											{openOption1 && (
												<div className='row mb-2'>
													<Label className='form-label col-form-label col-sm-4'>{t('휴가 부여 시점')}</Label>
													<div className='col-sm-8'>
														<Select
															//size='sm'
															name='start'
															list={Array.from(Array(30).keys())
																.filter((v) => v > 0)
																.map((v) => {
																	return {
																		value: parseInt(v),
																		text: v === 1 ? t('입사 당해부터 부여') : t(`${v}년 차부터 부여`),
																	};
																})}
															onChange={(e) => {
																form.setFieldValue(
																	'start',
																	{
																		...form.values.start,
																		value: e.target.value,
																	},
																	false
																);
															}}
															value={form.values.start.value}
														/>
													</div>
												</div>
											)}
										</FormGroup> */}
									</>
								)}

								{(type === 'outside-work' || type === 'business-trip') && (
									<>
										<div className='row mb-3 px-4'>
											<Label className='form-label col-form-label col-sm-12 d-flex flex-row align-items-center fw-bold text-dark'>근무지설정</Label>
											<div className='col-md-12'>
												<Select2
													options={settingArea?.map((area) => {
														return { label: area.name, value: area.id };
													})}
													placeholder='근무지를 선택해주세요.'
													value={[...settingArea]?.find((area) => area.value === form.values.areaId)}
													onChange={(e) => {
														form.setFieldValue('areaId', e.value);
													}}
												/>
											</div>
										</div>

										<div className='row mb-3 px-4'>
											<Label className='form-label col-form-label col-sm-12 d-flex flex-row align-items-center fw-bold text-dark'>
												간주근로 설정 <span className='ms-2 small text-muted'>(선택)</span>
											</Label>
											<div className='col-md-12'>
												<ButtonGroup className='p-1 bg-light w-100 rounded'>
													<Button
														type='button'
														className={`${!form.values.allDay.use && 'bg-white'}`}
														rounded={1}
														// isOutline={!form.values.restAuto}
														// color={form.values.restAuto ? 'success' : 'light'}>
														color={!form.values.allDay?.use ? 'white' : 'light'}
														// isLight={form.values.restAuto}
														onClick={() => {
															let allDay = form.values.allDay;
															allDay.use = false;
															form.setFieldValue('allDay', allDay); //, false);
														}}>
														{t('사용하지 않음')}
													</Button>
													<Button
														type='button'
														className={`${form.values.allDay.use && 'bg-white'}`}
														rounded={1}
														// isOutline={form.values.restAuto}
														color={form.values.allDay.use ? 'white' : 'light'}
														// isLight={!form.values.restAuto}
														onClick={() => {
															let allDay = form.values.allDay;
															allDay.use = true;
															form.setFieldValue('allDay', allDay); //, false);
														}}
														// color={!form.values.restAuto ? 'success' : 'light'}
													>
														{t('사용')}
													</Button>
												</ButtonGroup>
											</div>
											{form.values.allDay.use && (
												<div className='row mt-2'>
													<Label className='form-label col-form-label col-sm-4 d-flex flex-row align-items-center fw-bold text-dark'>
														간주근로 시간 <span className='ms-2 small text-muted'>(선택)</span>
													</Label>
													<div className='col-sm-8'>
														<Input
															type='number'
															className=''
															onChange={(e) => {
																let allDay = form.values.allDay;
																allDay.time = parseInt(e?.target?.value || 0);
																form.setFieldValue('allDay', allDay);
															}}
															value={form?.values?.allDay?.time || 0}
														/>
													</div>
												</div>
											)}
										</div>
									</>
								)}
							</div>

							<div className='d-flex justify-content-between mt-3 w-100'>
								{form.values.id ? (
									<Button
										type='button'
										color='danger'
										isLight
										onClick={async () => {
											await WorkSchduleTemplateService.delete(form.values.id, form.values.type).then((result) => {
												if (result.data?.id) {
													showNotification(t('템플릿 관리'), t('템플릿 {{TYPE}} 완료 되었습니다', { TYPE: '삭제' }), 'success');
													// refresh();
													form.resetForm();
													props?.setIsOpen(false);
												} else {
													showNotification(t('템플릿 관리'), t(result.data?.message || '템플릿 {{TYPE}} 실패', { TYPE: '삭제' }), 'danger');
												}
											});
										}}>
										{t('삭제')}
									</Button>
								) : (
									<div></div>
								)}
								<Button
									type='button'
									color='info'
									isDisable={
											form.isSubmitting || !form.isValid || (form.values.workTime[0][0] === '' || form.values.workTime[0][1] === '')
											|| (type === 'work' && workTimeError !== '') || (type === 'rest' && restTimeError !== '')
										}
									onClick={form.handleSubmit}
								>
									{form.values.id > 0 ? t('수정') : t('저장')}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</ModalBody>
			{/* <ModalFooter className='border-top px-4'>
					<Button
						type='button'
						onClick={() => {
							props.setIsOpen(false);
						}}>
						{t('닫기')}
					</Button>
				</ModalFooter> */}
		</Modal>
	);
});

export default WorkScheduleTemplate;
