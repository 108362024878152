import React from 'react'

const pubMarkupList = [
    {
        path: '/pub/EmptyPage',
        title: 'EmptyPage',
        target: '_blank'
    },
    {
        path: '/pub/ModalList',
        title: 'ModalList',
        target: '_blank'
    },
    {
        path: '/pub/work/calender',
        title: '근무일정',
        target: '_blank'
    },
    {
        path: '/pub/work/schedule',
        title: '스케줄 관리',
        target: '_blank'
    },
    {
        path: '/pub/Layout',
        title: 'PubLayout',
        target: '_blank'
    },
    {
        path: '/pub/UserDashboard',
        title: 'UserDashboard',
        target: '_blank'
    },
    {
        path: '/pub/AdminDashboard',
        title: 'AdminDashboard',
        target: '_blank'
    },
    {
        path: '/pub/Etc',
        title: 'Etc',
        target: '_blank'
    },
]

export default pubMarkupList;