import React, { useContext } from 'react';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../../../../components/bootstrap/Modal';
import { t } from 'i18next';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../../components/bootstrap/forms/Label';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import Icon from '../../../../../components/icon/Icon';
import Button from '../../../../../components/bootstrap/Button';
import Select from '../../../../../components/bootstrap/forms/Select';
import ThemeContext from '../../../../../contexts/themeContext';

const UserReqRecordEditModal = (props) => {
	const { data, isOpen, setIsOpen } = props;
	const { mobileDesign } = useContext(ThemeContext);

	const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black',
			// width: 250}
		}; 
	};

	return (
		<Modal isCentered={true} size='lg' isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop isScrollable fullScreen={'sm'}>
			<ModalHeader className={!mobileDesign && 'user-modal-header'}>
				<ModalTitle className='fw-bold' id='tour-title'>{t('근무기록 수정요청')}</ModalTitle>
			</ModalHeader>
			<ModalBody className='work-modal-body'>
				<Card className='custom-box-shadow rounded-2' style={{marginBottom : '24px'}}>
          <CardBody>
            <p className='mb-0'>{t('수정일')}</p>
            <p className='text-black fw-bold mb-0 mt-2'>
              <span>01.29 (월)</span>
            </p>
          </CardBody>
        </Card>
				<div>
					{/* 출근시간 */}
					<FormGroup className='d-flex mt-3'>
            <div className='col-3'>
              <Label className='mt-2 fw-bold text-black'>{t('출근시간')}</Label>
              <span className='text-danger fs-3'>&nbsp;*</span>
            </div>
            <div className='col-9 text-nowrap text-truncate'>
              <div className='d-flex justify-content-between'>
                <Button
									className='d-flex align-items-center justify-content-center'
                  style={{
                    width: 'calc(50% - 5px)',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                >
                  <span className='me-2'>2024.01.00</span>
									<Icon icon='CalendarToday' size='lg' />
                </Button>
                <Button
                  style={{
                    width: 'calc(50% - 5px)',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                >
                  {t('오전 00:00')}
                </Button> 
              </div>
						</div>
          </FormGroup>
					{/* 퇴근시간 */}
					<FormGroup className='d-flex mt-3'>
            <div className='col-3'>
              <Label className='mt-2 fw-bold text-black'>{t('퇴근시간')}</Label>
              <span className='text-danger fs-3'>&nbsp;*</span>
            </div>
            <div className='col-9 text-nowrap text-truncate'>
              <div className='d-flex justify-content-between'>
                <Button
									className='d-flex align-items-center justify-content-center'
                  style={{
                    width: 'calc(50% - 5px)',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                >
                  <span className='me-2'>2024.01.00</span>
									<Icon icon='CalendarToday' size='lg' />
                </Button>
                <Button
                  style={{
                    width: 'calc(50% - 5px)',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                >
                  {t('오후 00:00')}
                </Button> 
              </div>
              <div className='text-danger mt-1'>
								<Icon icon='InfoOutline' className='me-2' />
								<span>{t('시간 선택은 필수입니다')}</span>
							</div>
						</div>
          </FormGroup>
					{/* 사유 */}
          <FormGroup className='d-flex mt-3'>
            <div className='col-3'>
              <Label className='mt-2 fw-bold text-black'>{t('사유')}</Label>
							<span className='text-danger fs-3'>&nbsp;*</span>
            </div>
            <div className='col-9'>
							<textarea id='etc' className='form-control' rows='3' style={newStyle()} placeholder={t('사유를 입력해주세요')} />
							<div className='text-danger mt-1'>
								<Icon icon='InfoOutline' className='me-2' />
								<span>{t('사유는 필수입니다')}</span>
							</div>
						</div>
          </FormGroup>
					{/* 결제라인 */}
					<FormGroup className='d-flex mt-3'>
            <div className='col-3'>
              <Label className='mt-2 fw-bold text-black'>{t('결재라인')}</Label>
              <span className='text-danger fs-3'>&nbsp;*</span>
            </div>
            <div className='col-9 text-nowrap text-truncate'>
              <Select
								placeholder={t('결재라인을 선택해주세요')}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  padding: '10px',
                  color: '#808080'
                }}
							/>
							<div className='text-danger mt-1'>
								<Icon icon='InfoOutline' className='me-2' />
								<span>{t('결재라인 선택은 필수입니다')}</span>
							</div>
            </div>
          </FormGroup>
          {/* 참조 */}
          <FormGroup className='d-flex mt-3'>
            <div className='col-3'>
              <Label className='mt-2 fw-bold text-black'>{t('참조')}</Label>
            </div>
            <div>
							<Button
								type={'button'}
								style={{
									borderRadius: 8,
								}}
								isOutline
								color={'info'}
              >
								<div className='d-flex align-items-center'>
									<Icon icon='Add' size='lg' color='info' />
									<span className='custom-write-button-margin'>{t('추가하기')}</span>
								</div>
							</Button>
						</div>
          </FormGroup>
        </div>
			</ModalBody>
			<ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="info"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('닫기')}</span>
          </Button>
          <Button
            color="info"
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100'
          >
            <span>{t('수정요청')}</span>
          </Button>
        </ModalFooter>
		</Modal>
	);
};
export default UserReqRecordEditModal;
