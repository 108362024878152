import React, { useContext } from 'react';
import Breadcrumb, {BreadcrumbItem} from "../../../components/bootstrap/Breadcrumb";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {SubHeaderLeft} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import classNames from 'classnames';
import ThemeContext from '../../../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import DashboardHeader from '../common/Headers/DashboardHeader';

const PubLayout = () => {
  const { rightPanel } = useContext(ThemeContext);
  return (
    <>
      <Aside />
      <div
			  className={classNames(
			  	'wrapper',
			  	{ 'wrapper-right-panel-active': rightPanel }
			)}>
        <DashboardHeader />
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Breadcrumb>
                        <BreadcrumbItem tag={'nav'} to={'/'}>PubLayout</BreadcrumbItem>
                    </Breadcrumb>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
              PubLayout
            </Page>
        </PageWrapper>
      </div>
    </>
  )
}

export default PubLayout;
