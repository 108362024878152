// 출장 및 외근 신청
import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useCallback, useRef, useLayoutEffect, useContext } from 'react';
import tinycolor from 'tinycolor2';
import Button, { ButtonGroup } from '../../../../../../components/bootstrap/Button';
import creature_3 from '../../../../../../assets/img/creature_3.png';
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle, CardSubTitle } from '../../../../../../components/bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../../../components/bootstrap/Modal';
import Popovers from '../../../../../../components/bootstrap/Popovers';
import Select from '../../../../../../components/bootstrap/forms/Select';
import Label from '../../../../../../components/bootstrap/forms/Label';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import moment from 'moment';
import Badge from '../../../../../../components/bootstrap/Badge';
import Avatar from '../../../../../../components/Avatar';
import Icon from '../../../../../../components/icon/Icon';
import Input from '../../../../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../../../../components/bootstrap/forms/InputGroup';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../../../../models';
import SuccessIcon from '../../../../../../assets/img/SuccessIcon.svg';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import WorkCalenderOvertimeWarningModal from '../../../../../AuthMenu/Work/components/modal/WorkCalenderOvertimeWarningModal';
import CompanyService from '../../../../../../services/CompanyService';
import UserService from '../../../../../../services/UserService';
import SortableTreeModal from '../../../../../AuthMenu/Approval/components/SortableTreeModal';
import showNotification from '../../../../../../components/extras/showNotification';
import { getCoordToAddress, serachAddress } from '../../../../../../lib/map';
import DaumPostcode from 'react-daum-postcode';
import ThemeContext from '../../../../../../contexts/themeContext';
import Select2 from 'react-select';
const WorkCalendarBusinessTripOffsiteApplyModal = forwardRef((props, ref) => {
	const mainColor = '#5A6CFA';
	const { mobileDesign } = useContext(ThemeContext);

	const bgColor = tinycolor(mainColor).lighten(30).toHexString();
	const { t } = useTranslation(['translation', 'menu']);
	const popRef = useRef({});

	useImperativeHandle(ref, () => ({
		resetForm: () => {
			console.log(props);
		},
		setFrom: (values) => {},
	}));
	const form = useFormik({});

	const redColor = '#f94449';
	const yellowColor = '#FBB124';
	const greenColor = '#1FB25F';

	const bgRedColor = tinycolor(redColor).lighten(30).toHexString();
	const bgYellowColor = tinycolor(yellowColor).lighten(30).toHexString();
	const bgGreenColor = tinycolor(greenColor).lighten(30).toHexString();

	const { user, company } = useMst();

	const [isModal, setIsModal] = useState(false); // 템플릿 모달
	const typeOfModal = props?.isOverTimeApplyModal || '';

	const [weekData, setWeekData] = useState({});

	const [workPlaceBtn, setWorkPlaceBtn] = useState({
		value: 0,
		label: '',
	});

	const [startTime, setStartTime] = useState([]);
	const [endTime, setEndTime] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [isOpenAddress, setIsOpenAddress] = useState(false);
	const [selectedArea, setSelectedArea] = useState({});
	const [openMap, setOpenMap] = useState(false);
	const [tempSelectionRange, setTempSelectionRange] = useState([
		{
			startDate: new Date(), // addDays(new Date(), -7)
			endDate: new Date(),
			key: 'selection',
		},
	]);
	const [selectDateArry, setSelectDateArry] = useState([]);
	// const [approvalList, setApprovalList] = useState([]);
	const [workType, setWorkType] = useState({});
	const [referenceModalOpen, setReferenceModalOpen] = useState(false);
	const [openSuccessRequestModal, setOpenSuccessRequestModal] = useState(false);
	const [holidayList, setHolidayList] = useState([]);
	const [chagneDateRangeYear, setChagneDateRangeYear] = useState(moment(new Date()).year());

	const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black',
			// width: 250}
		};
	};

	const requestWork = useFormik({
		initialValues: {
			id: 0,
			areaId: props?.groups?.areaId || 0,
			companyId: company.id,
			workType: 0, // 근무유형의 아이디
			type: 'WORK',
			isAllTime: false,
			dateRange: [
				{
					startDate: new Date(moment()),
					endDate: new Date(moment()),
					key: 'selection',
				},
			],
			timeRange: ['', ''],
			overseas: false,
			address: '',
			addressDetail: '',
			workPlace: '',
			region: {
				latitude: 0, // 기본값 임시
				longitude: 0,
				latitudeDelta: 0,
				longitudeDelta: 0,
			},
			memo: '',
			etc: '',
			startAt: '',
			endAt: '',
			approvailLine: [],
			option: {},
			isApproval: {},
			ccUsers: [],
		},
		validate: (values) => {
			const errors = {};

			if (values.dateRange.length < 1) {
				errors.dateRange = '날짜 선택은 필수입니다';
			}
			if (!values.startAt || !values.endAt) {
				errors.startAt = '시간설정은 필수입니다';
			}
			if (!values.etc) {
				errors.etc = '사유 입력은 필수입니다';
			}
			// if (moment(values.endAt, 'HH:mm') < moment(values.startAt, 'HH:mm')) {
			// 	errors.startAt = '종료시간은 시작시간보다 빠를 수 없습니다';
			// }
			if (values.approvailLine.length < 1) {
				errors.approvailLine = '결재라인 선택은 필수입니다';
			}
			return errors;
		},
		onSubmit: async (values) => {
			// console.log('values <<<', values);
			if ((moment(values.endAt, 'HH:mm') < moment(values.startAt, 'HH:mm'))
			
			// && !(moment(values.endAt, 'HH:mm').isSame(moment('00:00', 'HH:mm')))
			) {
				// 'HH:mm' 형식으로 파싱된 값끼리 비교
				values.dateRange[0].endDate = moment(values.dateRange[0].endDate, 'YYYY-MM-DD').add(1, 'days');
				// console.log('values...', values);
			}

			props?.setPreventDoubleRequest(true);
			await CompanyService.workdayRequest(values).then((result) => {
				console.log('result >> ', result);
				if (result?.data) {
					setOpenSuccessRequestModal(true);
					// props?.reFresh();
				} else if (result?.message) {
					showNotification(t('근무신청'), t(result?.message) + '.', 'danger');
					props?.setPreventDoubleRequest(false);
					if((moment(values.endAt, 'HH:mm') < moment(values.startAt, 'HH:mm')) && moment(values.dateRange[0].endDate, 'YYYY-MM-DD').diff(moment(values.dateRange[0].startDate, 'YYYY-MM-DD'), 'day') === 1) {
						values.dateRange[0].endDate = moment(values.dateRange[0].endDate, 'YYYY-MM-DD').subtract(1, 'days');
					}
				} else {
					showNotification(t('근무신청'), t('근무신청에 문제가 발생 했습니다') + '.', 'danger');
					props?.setPreventDoubleRequest(false);
				}
			});
		},
	});

	
	const sumSelectedDates = (startDate, endDate) => {
		return moment(startDate).diff(moment(endDate), 'days');
	};

	const approvailLineUser = requestWork?.values?.approvailLine[0]?.line[0]?.user;
	const countApprovalUsers = requestWork?.values?.approvailLine
		?.map((i) => i.line)
		.reduce((accumulator, currentArray) => {
			return accumulator + currentArray.length;
		}, 0);
	const sumOfDates = sumSelectedDates(requestWork?.values?.dateRange[0]?.endDate, requestWork?.values?.dateRange[0]?.startDate) + 1;

	const getUsersWorkInfo = async () => {
		await CompanyService.attendWeekInfo({ companyId: company.get.id }).then((res) => {
			// console.log('res?.data >>>', res?.data)
			setWeekData(res?.data || {});
		});
	};

	// const getApprovalLine = useCallback(async () => {
	// 	await UserService.getApproval(company.get.id, { userId: user.id, departId: company.get?.departInfos[0]?.departId || '', option: 'up' }).then((res) => {
	// 		setApprovalList(res?.data || []);
	// 	});
	// }, [user.id, company]);

	const getRestSelect = async () => {
		const res = await CompanyService.getUserRestType({ companyId: company.get.id, userId: user.id, typeArray: ['WORK'] });

		if (res?.data) {
			setWorkType(res?.data?.find((i) => typeOfModal.includes(i.name)));
		}
	};

	const resetModal = () => {
		requestWork.resetForm();
		setWorkPlaceBtn({
			value: 0,
			label: '국내',
		});
		setTempSelectionRange([
			{
				startDate: new Date(), // addDays(new Date(), -7)
				endDate: new Date(),
				key: 'selection',
			},
		]);
		requestWork.setFieldValue('dateRange', []);
		setOpenMap(false);
	};

	useEffect(() => {
		getRestSelect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const convertMinsToHrsMins = useCallback(
		(mins) => {
			let h = Math.floor(mins / 3600);
			let m = Math.floor((mins % 3600) / 60);
			let s = Math.floor(mins % 60);
			//let h = Math.floor(mins / (60 * 60));
			//let m = mins % (60);
			h = h < 10 && h > 0 ? '0' + h : h;
			m = m < 10 ? '0' + m : m;
			return t(`{{hour}}시간 ${m > 0 ? m + '분' : ''}`, { hour: h, minute: m });
		},
		[t]
	);

	const timeSetting = () => {
    let times = [];

    for (let i = 0; i < 144; i++) { 
			times.push({
					value: moment('0000', 'HH:mm').clone().add(i * 10, 'minutes').format('HHmm'),
					label: moment('00:00', 'HH:mm').clone().add(i * 10, 'minutes').format('HH:mm')
			});
	}
    setStartTime([...times]);
		setEndTime([...times])
};


useEffect(() => {
    timeSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [requestWork.values.startAt]);

	const _convertDateNm = (_date) => {
		const _yymm = moment(_date).format('MM/DD');
		const _day = moment(_date).day();
		let _dayNm = '(일)';

		if (_day === 0) {
			_dayNm = '(일)';
		} else if (_day === 1) {
			_dayNm = '(월)';
		} else if (_day === 2) {
			_dayNm = '(화)';
		} else if (_day === 3) {
			_dayNm = '(수)';
		} else if (_day === 4) {
			_dayNm = '(목)';
		} else if (_day === 5) {
			_dayNm = '(금)';
		} else if (_day === 6) {
			_dayNm = '(토)';
		}

		const _dateNm = _yymm + _dayNm;

		return _dateNm;
	};

	const getHolidayInfo = async (date) => {
		await CompanyService.holidayList(company.id, date).then((res) => {
			setHolidayList(res?.data);
		});
	};

	useEffect(() => {
		getHolidayInfo(chagneDateRangeYear);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chagneDateRangeYear]);

	const disableHoliday = (date) => {
		const dayOfWeek = date.getDay();
		if (!props?.groups?.workDay?.includes(dayOfWeek)) {
			return true;
		}
		const formattedDate = date.toISOString();
		const isDateDisabled = holidayList.some((disabledDate) => {
			return formattedDate === disabledDate.date;
		});

		return isDateDisabled;
	};

	useEffect(() => {
		getUsersWorkInfo();
		getHolidayInfo(moment(new Date()).year());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAreas = useCallback(async () => {
		let areas = [];

		await CompanyService.getWorkingArea(company.get.id, user.id).then((response) => {
			const tmp = response.data.filter((data) => data.address != 'outer');
			areas.push({
				label: t('------신규 추가(선택)------'),
				name: `area-new`,
				value: '------신규 추가(선택)------',
				row: {},
			});
			for (let i in tmp) {
				areas.push({
					label: t(tmp[i].name),
					name: `area-${tmp[i].id}`,
					value: tmp[i].id,
					row: tmp[i],
				});
			}
			setAreaList(areas);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getAreas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const workTypeModal = () => {
		return (
			<>
				<div style={{ width: '100%', height: '58px', border: !mobileDesign && '1px solid #D9D9D9' }}>
					<div className={`d-flex ${mobileDesign ? 'justify-content-start' : 'work-title justify-content-center align-items-center flex-wrap'}`}>
						<div className='work-image-wrapper'>
							<img className='work-image' alt='avatar1' src={creature_3} style={{ width: 45, height: 45 }} />
						</div>
						<div className={`${mobileDesign && 'mt-3'}`}>
							{user.me?.name} {user?.me?.userRank || ''}
						</div>
					</div>
					<div className='d-flex w-50 align-items-center justify-content-between me-4' style={{ height: '100%' }}>
						<div className={`text-nowrap ${mobileDesign ? 'pb-3' : 'px-4'}`}>
							<span className='tot-vacation-title'>{t('이번주 근무시간')}</span>&nbsp;&nbsp;
							<span className='work-tot-vacation-count'>
								{convertMinsToHrsMins(
									weekData.weekWorkTime > 0 || weekData.weekWeeklyWorkTime > 0 || weekData.weekNightTime > 0 || weekData.weekWeeklyNightTime > 0
										? (weekData?.weekWorkTime || 0) +
												(weekData?.weekWorkOverTime || 0) +
												(weekData?.weekNightTime || 0) +
												(weekData?.weekNightOverTime || 0) +
												(weekData?.weekWeeklyWorkTime || 0) +
												(weekData?.weekWeeklyWorkOverTime || 0) +
												(weekData?.weekWeeklyNightTime || 0) +
												(weekData?.weekWeeklyNightOverTime || 0) -
												((weekData?.weekRestTime || 0) + (weekData?.weekRestOverTime || 0) + (weekData?.weekWeeklyRestTime || 0) + (weekData?.weekWeeklyRestOverTime || 0))
										: 0
								)}
							</span>
						</div>
					</div>
				</div>
				<div className='d-flex flex-wrap row'>
					<div className={`${!mobileDesign && 'work-content-left-wrapper'} mt-5 col-md-6`}>
            <div
              className={`p-4 mb-4 ${!mobileDesign && 'mx-4'}`}
							style={{ marginTop: requestWork?.errors?.dateRange ? 10 : 25, border: '1px solid #D9D9D9', borderRadius: '8px', backgroundColor: 'rgba(217, 217, 217, 0.08)' }}
            >
							<FormGroup>
								<div className={`d-flex align-items-start col-3`}>
									<Label className={'fw-bold'} style={{ color: 'black' }}>
										{t('근무지')}
									</Label>
									<span className='text-danger fs-5'>&nbsp;*</span>
								</div>
								<div
									className='d-flex justify-content-around mt-3'
									style={{
										padding: '4px',
										borderRadius: 8,
										backgroundColor: '#F1F1F1',
									}}>
									{['국내', '해외'].map((btn, index) => {
										const isButtonSelected = workPlaceBtn.value === index;
										return (
											<div key={`timeBtn-${index}`} className='w-100'>
												<Button
													className='w-100'
													style={{
														padding: `7px ${mobileDesign ? '25px' : '39px'} 7px ${mobileDesign ? '25px' : '39px'}`,
														borderRadius: 8,
														backgroundColor: isButtonSelected ? 'white' : '#F1F1F1',
													}}
													onClick={() => {
														setWorkPlaceBtn({
															...workPlaceBtn,
															value: index,
															label: btn,
														});
														setOpenMap(false);
														if (index === 0) {
															// 국내
															requestWork.setFieldValue('areaId', props?.groups?.areaId);
															requestWork.setFieldValue('overseas', false);
															requestWork.setFieldValue('startAt', '');
															requestWork.setFieldValue('endAt', '');
														} else {
															requestWork.setFieldValue('areaId', 0);
															requestWork.setFieldValue('overseas', true);

															if(props?.groups?.workTime?.length > 0) {
																requestWork.setFieldValue('startAt', props?.groups?.workTime[0][0] || '09:00');
																requestWork.setFieldValue('endAt', props?.groups?.workTime[0][1] || '09:00');
															} else {
																requestWork.setFieldValue('startAt', '09:00');
																requestWork.setFieldValue('endAt', '18:00');
															}															
														}
													}}>
													<span
														className=''
														style={{
															color: isButtonSelected ? 'black' : '#A4A6A9'
														}}>
														{btn}
													</span>
												</Button>
											</div>
										);
									})}
								</div>
							</FormGroup>
						</div>
						<div className={`text-truncate p-4 mb-3 ${!mobileDesign && 'mx-4'}`} style={{ border: '1px solid #D9D9D9', borderRadius: '8px', backgroundColor: 'rgba(217, 217, 217, 0.08)' }}>
							<div className='work-req-vac-txt-g'>
								<FormGroup>
									<Label className={'fw-bold'} style={{ color: 'black' }}>
										{t('날짜 선택')}
									</Label>
									<span className='text-danger fs-5'>&nbsp;*</span>
									<div className='d-flex justify-content-around mt-2 text-nowrap'>
										<Popovers
											id='selectDate'
											placement={mobileDesign ? 'bottom' : 'right'}
											ref={popRef}
											desc={
												<div className='row' /* ref={popRef} */>
													<div className='col-xl-10'>
														<Card className='shadow-none w-100 mb-0'>
															<CardBody className='px-0 pb-0 row'>
																<div className='col-sm-9 px-0'>
																	<DateRange
																		locale={locales['ko']}
																		editableDateInputs={false}
																		// dragSelectionEnabled={isDisabled}
																		dateDisplayFormat='yyyy-MM-dd'
																		ranges={tempSelectionRange}
																		maxDate={moment(tempSelectionRange[0].startDate).add(364, 'days').toDate()}
																		minDate={moment(tempSelectionRange[0].startDate).subtract(364, 'days').toDate()}
																		// showSelectionPreview={true}
																		moveRangeOnFirstSelection={false}
																		// retainEndDateOnFirstSelection={false}
																		className='p-3'
																		// onRangeFocusChange={(e)=>{
																		// 	console.log(e)
																		// 	// setIsCalendar(e[1] === 1)
																		// }}
																		onChange={(item) => {
																			setTempSelectionRange([item.selection]);
																		}}
																		onShownDateChange={(e) => {
																			setChagneDateRangeYear(moment(e).year());
																		}}
																		months={1}
																		direction='horizontal'
																		//scroll={{enabled: true}}
																		//disabledDay={(date) => (props?.groups?.isHolidayWork == false && disableHoliday(date))}
																		//임시 주석처리 아래
																		//disabledDay={(date) => disableHoliday(date)}
																	/>
																</div>
															</CardBody>
															<CardFooter className='py-0 my-0'>
																<Button
																	type='button'
																	color='success'
																	onClick={() => {
																		requestWork.setFieldValue('dateRange', tempSelectionRange);
																		if (popRef.current) popRef.current.onOpen();

																		let _dateArry = [];
																		let _date = tempSelectionRange[0].startDate;
																		// if (props?.groups?.isHolidayWork == false) {
																		while (_date <= tempSelectionRange[0].endDate) {
																			const _yymm = moment(_date).format('YYYY-MM-DD');
																			//const dayOfWeek = moment(_yymm).day();

																			// if (props?.groups?.workDay?.includes(dayOfWeek)) {
																			// 	const formattedDate = moment(_date).format('YYYY-MM-DD');
																			// 	const isDateDisabled = props?.holidayList.some((disabledDate) => moment(disabledDate.date).format('YYYY-MM-DD') === formattedDate);

																				//if (!isDateDisabled) {
																					_dateArry.push(_convertDateNm(_date));
																				//}
																			//}

																			_date = moment(_date).add(1, 'days');
																		}
																		// } else if (props?.groups?.isHolidayWork == true) {
																		// 	while (_date <= tempSelectionRange[0].endDate) {
																		// 		_dateArry.push(_convertDateNm(_date));
																		// 		_date = moment(_date).add(1, 'days');

																		//   }
																		//	}
																		setSelectDateArry(_dateArry);
																	}}>
																	{t('선택')}
																</Button>
															</CardFooter>
														</Card>
													</div>
												</div>
											}
											// placement='bottom-end'
											className='mw-100'
											trigger='click'>
											<div className='d-flex justify-space-around flex-wrap row' style={{ rowGap: 10 }}>
												<div className='col-md-6'>
													<div className='work-gray-text'>{t('시작일')}</div>
													<div
														className='work-content-cal d-flex align-items-center work-gray-text px-3'
														onClick={() => {
															popRef.current.onOpen();
														}}>
														<Icon icon='CalendarToday' size='lg' style={{ marginRight: 8 }} />
														{requestWork?.values?.dateRange && requestWork?.values?.dateRange.length > 0 ? (
															<span className='fw-bold' style={{ color: 'black' }}>
																{moment(requestWork?.values?.dateRange[0]?.startDate).format('YYYY.MM.DD')}
															</span>
														) : (
															<span>{t('시작일 선택')}</span>
														)}
													</div>
												</div>
												<div className='col-md-6'>
													<div className='work-gray-text'>{t('종료일')}</div>
													<div className='work-content-cal d-flex align-items-center work-gray-text px-3'>
														<Icon icon='CalendarToday' size='lg' style={{ marginRight: 8 }} />
														{requestWork?.values?.dateRange && requestWork?.values?.dateRange.length > 0 ? (
															<span className='fw-bold' style={{ color: 'black' }}>
																{moment(requestWork?.values?.dateRange[0]?.endDate).format('YYYY.MM.DD')}
															</span>
														) : (
															<span>{t('종료일 선택')}</span>
														)}
													</div>
												</div>
											</div>
										</Popovers>
									</div>
								</FormGroup>
							</div>

							<hr className='text-nowrap text-truncate' style={{ marginTop: '20px' }} />
							<div className='work-cal-sel-wrapper'>
								<div className='d-flex justify-content-between'>
									<div className='work-cal-sel-txt work-req-vac-txt-g fw-bold mb-3'>{t('선택된 날짜')}</div>
									<div>
										{requestWork?.values?.dateRange && requestWork?.values?.dateRange.length > 0 ? (
											<div>
												<span className='fw-bold' style={{ color: '#556EE6' }}>
													{/* {`${sumOfDates}일`} */}
													{`${selectDateArry?.length || 1}일`}
												</span>
												&nbsp;&nbsp;
												<span className='text-muted'>{t('선택됨')}</span>
											</div>
										) : (
											<div className='work-cal-sel-cont'>{t('날짜를 선택해주세요')}</div>
										)}
									</div>
								</div>
								{/* {requestWork?.values?.dateRange && requestWork?.values?.dateRange.length > 0 && (
									<div className='d-flex flex-wrap' style={{ marginTop: '10px' }}>
										{selectDateArry.map((_date, index) => (
											<Badge className={'m-1'} style={{ padding: '3px 4px 3px 4px', borderRadius: 4, backgroundColor: '#E8ECFF', color: '#556EE6' }} color={'#E8ECFF'} key={index}>
												<span className='fw-normal' style={{ fontSize: 12 }}>
													{_date}
												</span>
											</Badge>
										))}
									</div>
								)} */}
							</div>
						</div>
						{requestWork?.errors?.dateRange ? (
							<div className='text-danger px-5'>
								<Icon icon='InfoOutline' className='mx-2' />
								{requestWork?.errors?.dateRange + '.'}
							</div>
						) : null}

						{requestWork.values.overseas == false && (
							<>
								<div
									className={`p-4 mb-3 ${!mobileDesign && 'mx-4'}`}
									style={{ marginTop: requestWork?.errors?.dateRange ? 10 : 25, border: '1px solid #D9D9D9', borderRadius: '8px', backgroundColor: 'rgba(217, 217, 217, 0.08)' }}>
									<div className=''>
										<FormGroup>
											<div className='d-flex align-items-center'>
												<Label className={'fw-bold mb-0'} style={{ color: 'black' }}>
													{t('시간설정')}
												</Label>
												<div className='mx-2 px-2' style={{border: '1px solid #D9D9D9', borderRadius: 5}}>
													<span className='fw-bold'>{t('01시간')}</span>
												</div>
												<span className='text-danger fs-5'>&nbsp;*</span>
											</div>
											<div className='d-flex justify-content-around mt-4 flex-wrap row' style={{ rowGap: 10 }}>
												<div className='col-md-6'>
													<span className='text-muted fw-normal'>{t('시작시간')}</span>
														<Select2
															ariaLabel='Select StartTime'
															options={startTime}
															placeholder={t('시간 선택')}
															// value={restRequest.values.startAt}
															onChange={(e) => {
																let formattedTime = moment(e.value, 'HHmm').format('HH:mm');
																requestWork.setFieldValue('startAt', formattedTime);
															}}
															style={
																{
																	padding: '1rem 8rem 1rem 1rem',
																}
															}
															theme={(theme) => ({
																...theme,
																borderRadius: 8,
																borderColor: '#D9D9D9',
															})}
														/>
												</div>
												<div className='col-md-6'>
													<span className='text-muted fw-normal'>{t('종료시간')}</span>
													<Select2
														ariaLabel='Select EndTime'
														options={endTime}
														placeholder={t('시간 선택')}
														// value={selectedDepartment}
														onChange={(e) => {
															let formattedTime = moment(e.value, 'HHmm').format('HH:mm');
															requestWork.setFieldValue('endAt', formattedTime);

															// if (moment(e.value, 'HHmm') < moment(requestWork.values.startAt, 'HH:mm')){
															// 	let plusEndDate = moment(requestWork.values.dateRange[0].endDate).add(1, 'days').format('YYYY-MM-DD');

															// 	requestWork.setFieldValue('dateRange', [{startDate: moment(requestWork.values.dateRange[0].startDate).format('YYYY-MM-DD'), endDate: plusEndDate}])
															// }

														}}
														style={
															{
																padding: '1rem 5rem 1rem 1rem',
															}
														}
														theme={(theme) => ({
															...theme,
															borderRadius: 8,
															borderColor: '#D9D9D9',
														})}
													/>
												</div>
											</div>
										</FormGroup>
									</div>
								</div>

								{requestWork?.errors?.startAt ? (
									<div className={`text-danger ${mobileDesign ? 'px-2' : 'px-5'} mt-2`}>
										<Icon icon='InfoOutline' className='mx-2' />
										{t(requestWork?.errors?.startAt) + '.'}
									</div>
								) : requestWork?.errors?.endAt ? (
									<div className={`text-danger ${mobileDesign ? 'px-2' : 'px-5'} mt-2`}>
										<Icon icon='InfoOutline' className='mx-2' />
										{t(requestWork?.errors?.endAt) + '.'}
									</div>
								) : null}
							</>
						)}
					</div>

					<div className={`mt-5 col-md-6`}>
						<div className={`text-truncate ${!mobileDesign ? 'p-3 mx-4' : 'p-2'}`}>
							<div className='d-flex flex-wrap justify-content-between mb-3' style={{ rowGap: 10 }}>
								<div className={`d-flex align-items-start mt-3`}>
									<span className='work-req-vac-txt-g fw-bold'>{t('결재라인')}&nbsp;</span>
									<span style={{ color: 'red' }}>*</span>
								</div>
								<div className='mx-3 flex-grow-1'>
									<Select
										name='approvailLine'
										onBlur={requestWork.handleBlur}
										style={newStyle(requestWork?.values?.approvailLine?.length === 0)}
                    // 에러때문에 임시 주석...
										// list={[...props?.approvalList]?.map((v) => {
										// 	return { value: v.id, label: v.name };
										// })}
										ref={props?.selectRef}
										placeholder={t('결재라인을 선택해주세요')}
										onChange={(e) => {
											const approval = props?.approvalList.find((v) => parseInt(v.id) === parseInt(e.target.value));
											requestWork.setFieldValue('approvailLine', approval?.lines || approval?.line || []);
											requestWork.setFieldValue('option', approval?.option || {});
											requestWork.setFieldValue('ccUsers', approval?.ccUsers || []);
										}}
									/>
									{requestWork?.touched?.approvailLine && requestWork?.values?.approvailLine?.length === 0 ? (
										<div className='text-danger mt-2'>
											<Icon icon='InfoOutline' className='mx-2' />
											<span>{t('결재라인 선택은 필수입니다') + '.'}</span>
										</div>
									) : null}
									<div className='work-detail-content-wrapper' style={{ marginTop: '7px' }}></div>

									<div className='work-detail-content-title'></div>
									<div className='work-detail-content'>
										{requestWork?.values?.approvailLine && requestWork?.values?.approvailLine.length > 0 && (
											<Button
												// disabled
												style={{
													borderRadius: 8,
													borderWidth: 1,
													borderColor: '#D9D9D9',
													backgroundColor: '#F8F9FA',
													padding: '10px 10px 10px 10px',
													marginRight: 20,
													width: '100%',
												}}
												onClick={() => {
													requestWork.setFieldValue('approvailLine', []);
													if (props.selectRef.current) props.selectRef.current.value = [];
												}}>
												<div className='d-flex justify-content-around align-items-center'>
													<span className=' text-muted'>{`${approvailLineUser?.name} ${approvailLineUser?.rank} ${countApprovalUsers > 1 ? '외 ' + (countApprovalUsers - 1) + ' 인' : ''}`}</span>
													<Icon icon='Cancel' size='lg' />
												</div>
											</Button>
										)}
									</div>
								</div>
							</div>

							<div className='d-flex flex-wrap justify-content-between mb-4' style={{ marginTop: requestWork?.errors?.approvailLine ? '8px' : '16px', rowGap: 10 }}>
								<div className='d-flex align-items-start mt-3'>
									<span className='work-req-vac-txt-g fw-bold'>{t('사유')}&nbsp;</span>
									<span className='text-danger fs-5'>&nbsp;*</span>
								</div>
								<div className={`flex-grow-1 ${mobileDesign ? 'ms-3' : 'ms-5'} me-3`}>
									<div>
										<textarea
											id='etc'
											className='form-control'
											rows='3'
											style={{
												borderRadius: 8,
												borderColor: '#D9D9D9',
												padding: '10px',
												resize: 'none',
											}}
											placeholder={t('사유입력')}
											value={requestWork?.values?.etc}
											onChange={requestWork.handleChange}
										/>
										{requestWork?.errors?.etc ? (
											<div className='text-danger mt-2'>
												<Icon icon='InfoOutline' className='mx-2' />
												{t(requestWork?.errors?.etc) + '.'}
											</div>
										) : null}
									</div>
								</div>
							</div>

							<div className='d-flex mb-4' style={{ marginTop: '16px', rowGap: 10 }}>
								<div className='d-flex align-items-start mt-2 text-nowrap'>
									<span className='work-req-vac-txt-g fw-bold'>{t('참조')}&nbsp;</span>
								</div>
								<div className={mobileDesign ? 'ms-3' : 'ms-5'}>
									<Button
										type={'button'}
										style={{
											borderRadius: 8,
										}}
										isOutline={true}
										color={'info'}
										onClick={() => {
											setReferenceModalOpen(true);
										}}>
										<div className='d-flex align-items-center'>
											<Icon icon='Add' size='lg' color='info' />
											<span className=''>{t('추가하기')}</span>
										</div>
									</Button>
									<div className='' style={{ marginTop: '6px' }}>
										<div className='d-flex flex-wrap'>
											{requestWork?.values?.ccUsers?.map((v, i) => {
												return (
													<Button
														style={{
															borderRadius: 6,
															borderWidth: 1,
															borderColor: '#D9D9D9',
															backgroundColor: '#F8F9FA',
														}}
														key={`ccUsers-${i}`}
														className='py-1 px-2 me-2 my-1 text-nowrap'
														onClick={() => {
															let newCcUsers = [...requestWork?.values?.ccUsers];
															newCcUsers.splice(requestWork?.values?.ccUsers[i], 1);

															requestWork.setFieldValue('ccUsers', newCcUsers);
														}}>
														<Icon icon='Visibility' size='md' style={{ color: 'grey' }} />
														<span className='mx-3'>
															{v.name} {requestWork.values.ccUsers.length - 1 > i}
														</span>
														<Icon icon='Close' size='md' />
													</Button>
												);
											})}
										</div>
									</div>
								</div>
							</div>

							<div className='d-flex flex-wrap justify-content-between mb-4' style={{ marginTop: '16px', rowGap: 10 }}>
								<div className='d-flex align-items-start mt-3'>
									<span className='work-req-vac-txt-g fw-bold'>{t('메모')}&nbsp;</span>
								</div>
								<div className={`flex-grow-1 ${mobileDesign ? 'ms-3' : 'ms-5'} me-3`}>
									<textarea
										id='memo'
										style={{
											borderRadius: 8,
											borderColor: '#D9D9D9',
											padding: '10px',
											resize: 'none',
										}}
										className='form-control'
										rows='3'
										placeholder={t('메모입력 (선택)')}
										value={requestWork?.values?.memo}
										onChange={requestWork.handleChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	const disableRequestBtn = !requestWork.isValid || requestWork?.values?.approvailLine.length === 0;
	// || !openMap;

	return (
		<>
			<Modal size='lg' isStaticBackdrop={true} isOpen={props?.isOpen} setIsOpen={props?.setIsOpen} isScrollable isCentered>
				<ModalHeader setIsOpen={props?.setIsOpen} className='work-modal-header'>
					<ModalTitle id='tour-title'>
						{typeOfModal} {t('신청')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className=''>{workTypeModal()}</ModalBody>
				<ModalFooter>
					<div className='m-3'>
						{/* 닫기버튼 */}
						<Button
							color='info'
							isOutline
							className='me-3'
							style={{
								borderRadius: 6,
								borderWidth: 1,
								padding: '8px 40px 8px 40px',
							}}
							onClick={() => {
								props.setIsOpen(false)
							}}>
							{t('닫기')}
						</Button>
						<Button
							style={{
								borderRadius: 6,
								borderWidth: 1,
								borderColor: '#D9D9D9',
								padding: '8px 40px 8px 40px',
								backgroundColor: 
								// disableRequestBtn
								props?.preventDoubleRequest
								? '#D9D9D9' : '#556EE6',
								color: 'white',
							}}
							disabled={
								// disableRequestBtn || 
								props?.preventDoubleRequest}
							onClick={() => {
								requestWork.setFieldValue('timeRange', [requestWork.values.startAt, requestWork.values.endAt]);
								requestWork.setFieldValue('workType', workType?.id);
								requestWork.handleSubmit();
							}}>
							{t('승인요청')}
						</Button>
					</div>
				</ModalFooter>

				<SortableTreeModal
					title={t('참조자 선택')}
					isOpen={referenceModalOpen}
					setIsOpen={setReferenceModalOpen}
					disabledList={requestWork?.values?.approvailLine}
					list={requestWork.values.ccUsers}
					setList={(list) => {
						requestWork.setFieldValue('ccUsers', list);
					}}
				/>

				{/* <Modal isOpen={isOpenAddress}>
					<ModalBody>
						<DaumPostcode onComplete={addAddress} style={{ height: '82vh' }} />
					</ModalBody>
					<ModalFooter>
						<Button color='secondary' onClick={() => setIsOpenAddress(!isOpenAddress)}>
							{t('Cancel')}
						</Button>
					</ModalFooter>
				</Modal> */}
			</Modal>
			{/* 근무 신청 성공 모달 */}
			<Modal isOpen={openSuccessRequestModal} setIsOpen={setOpenSuccessRequestModal} isCentered size='sm'>
				<ModalBody>
					<div className='px-4'>
						<div className='d-flex justify-content-center'>
							<img src={SuccessIcon} alt='success Image' width={130} />
						</div>
						<div className='d-flex justify-content-center my-4 fw-bold fs-3'>
							<span>{t('근무 신청')}</span>&nbsp;<span style={{ color: '#34C38F' }}>{t('완료')}</span>
						</div>
						<div className='d-flex justify-content-center mb-4'>
							<span className='text-muted fs-5'>{t('신청이 완료되었습니다') + '.'}</span>
						</div>
						<div className='d-flex justify-content-center'>
							<Button
								className='w-100'
								size='lg'
								color='success'
								rounded={1}
								onClick={() => {
									setOpenSuccessRequestModal(false);
									props?.setIsOpen(false);
									resetModal();
								}}>
								{t('확인')}
							</Button>
						</div>
					</div>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</Modal>
			<WorkCalenderOvertimeWarningModal isOpen={isModal} setIsOpen={setIsModal} ref={ref} />
		</>
	);
});

export default WorkCalendarBusinessTripOffsiteApplyModal;
