import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Badge from '../../../../../components/bootstrap/Badge';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Input from '../../../../../components/bootstrap/forms/Input';
import InputGroup from '../../../../../components/bootstrap/forms/InputGroup';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../../components/bootstrap/Modal';
import Popovers from '../../../../../components/bootstrap/Popovers';
import Icon from '../../../../../components/icon/Icon';
import Label from '../../../../../components/bootstrap/forms/Label';
import { useFormik } from 'formik';
import CompanyService from '../../../../../services/CompanyService';
import { useMst } from '../../../../../models';
import showNotification from '../../../../../components/extras/showNotification';
import Timeline, { TimelineItem } from '../../../../../components/extras/Timeline';
import { useTranslation } from 'react-i18next';
import GroupService from '../../../../../services/GroupService';
import Alert from '../../../../../components/bootstrap/Alert';
import DaumPostcode from 'react-daum-postcode';
import { serachAddress, getCoordToAddress } from '../../../../../lib/map';
import { checkMoment } from '../../../../../lib/Util';
import WorkRecodeChangeModal from '../../../../AuthMenu/Work/components/modal/WorkRecodeChangeModal';
import deadlineService from "../../../../../services/DeadlineService";

const WorkReportAsideDeatail = ({ item, reFresh, setIsMapModal, calendarDate }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [isCcCodeOpen, setIsCcCodeOpen] =useState(false);

	return (
		<>
			<form className='user-select-none'>
				<div className='text-end'>
					<Popovers desc={t(`근무기록을 삭제합니다.`)} trigger='hover'>
						<Button
							type='button'
							color='secondary'
							isLight
						>
							{t('삭제')}
						</Button>
					</Popovers>
					<Popovers desc={t(`기록 변경시 변경 로그가 결재항목에 추가됩니다.`)} trigger='hover'>
						<Button
							type='button'
							icon='SaveAlt'
							color='info'
							className='ms-2'
						>
							{t('기록 변경')}
						</Button>
					</Popovers>
				</div>
				<table className='table align-middle'>
					<thead>
						<tr>
							<th colSpan={2} className='lead fw-bold'>
								2024-02-03 (토)
								<div className="mt-3 d-flex justify-content-between">
									<span className={'fs-5'}>구/인 3교대 3조</span>
									<span className={'fs-5 text-black-50'}>06:00 ~ 14:00</span>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{t('테이블출근')}</td>
							<td className='lead'>
								<InputGroup id='stime'>
									<Input
										type='date'
										name='sdate'
									/>
									<Input type='time' name='stime' id='stime' />
								</InputGroup>
							</td>
						</tr>
						<tr>
							<td>{t('테이블퇴근')}</td>
							<td className='lead'>
								<InputGroup id='etime'>
									<Input
										type='date'
										name='edate'
										id='edate'
									/>
									<Input type='time' name='etime' id={'etime'} />
								</InputGroup>
							</td>
						</tr>

						{/* cc코드 */}
						<tr>
							<td colSpan={2} className='border-top-0 border-end-0 border-start-0 border-2 border-dark'>
								<div className='d-flex justify-content-between align-items-center'>
									<p className='fs-5 fw-bold mb-0'>CC코드별 근무시간</p>
									<Icon icon={isCcCodeOpen ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} size='2x' className='cursor-pointer' onClick={() => {setIsCcCodeOpen(!isCcCodeOpen)}} />
								</div>
							</td>
						</tr>
						
						{/* cc코드 열렸을때 */}
						{
							isCcCodeOpen &&
							<tr>
								<td colSpan={2}>
									<div>
										<div className='d-flex justify-content-between align-items-center py-2'>
											<Label className='mb-0 text-dark me-2'>1</Label>
											<Input name='code' placeHolder='CC코드' className='border-1 me-2' />
											<Label className='mb-0 col-1'>출근</Label>
											<Input type='time' className='me-2' />
											<Label className='mb-0 col-1'>퇴근</Label>
											<Input type='time' />
										</div>
										<div className='d-flex justify-content-between align-items-center py-2'>
											<Label className='mb-0 text-dark me-2'>2</Label>
											<Input name='code' placeHolder='CC코드' className='border-1 me-2' />
											<Label className='mb-0 col-1'>출근</Label> 
											<Input type='time' className='me-2' />
											<Label className='mb-0 col-1'>퇴근</Label>
											<Input type='time' />
										</div>
										<div className='d-flex justify-content-between align-items-center py-2'>
											<Label className='mb-0 text-dark me-2'>3</Label>
											<Input name='code' placeHolder='CC코드' className='border-1 me-2' />
											<Label className='mb-0 col-1'>출근</Label> 
											<Input type='time' className='me-2' />
											<Label className='mb-0 col-1'>퇴근</Label>
											<Input type='time' />
										</div>
									</div>
									{/* 입력안했을시 에러메시지 */}
									<div className='mt-2'>
										<p className='mb-0 text-danger'>* 해당 CC코드의 출근/퇴근 시간을 설정해주세요.</p>
										<p className='mb-0 text-danger'>* CC코드를 입력해주세요.</p>
									</div>
								</td>
							</tr>
						}

						{/* cc코드 여백용 */}
						<tr><td></td></tr>

						{/* 근무기록 없다면 */}
						<tr>
							<th colSpan={2}>
								<div className='text-center py-5 text-danger'>{t('근무기록값이 없습니다')}</div>
							</th>
						</tr>

						<tr>
							<td colSpan={2} className={'bg-light fw-bold'}>근무시간</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('휴식시간 미포함')}>
									{t('주간근무 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>08:00</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('오후 10시 ~ 오전 6시')}>
									{t('야간근무 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>-</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('휴식시간 미포함')}>
									{t('연장근무 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>02:56</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('휴식시간 미포함')}>
									{t('야간 연장근무 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>-</td>
						</tr>

						<tr>
							<td colSpan={2} className={'bg-light fw-bold'}>휴게시간</td>
						</tr>

						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('근무 + 야간근무 휴식시간')}>
									{t('주간 휴식 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>01:00</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('연장근무 + 야간연장근무 휴식시간')}>
									{t('연장 휴식 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>-</td>
						</tr>
						<tr>
							<td>
								<Popovers trigger={'hover'} desc={t('근무 + 연장 휴식시간')}>
									{t('총 휴식 시간')}
								</Popovers>
							</td>
							<td className='lead text-end'>01:00</td>
						</tr>

						<tr>
							<td colSpan={2} className={'bg-light fw-bold'}>합계</td>
						</tr>

						<tr className='table-dark text-white'>
							<td>
								<Popovers trigger={'hover'} desc={t('근무 - 휴식')}>
									{t('총근무 시간')}
								</Popovers>
							</td>
							<td className='lead text-end fw-bold'>09:56</td>
						</tr>	
					</tbody>
				</table>
			</form>
				<table className='table align-middle user-select-none'>
					<thead>
						<tr>
							<th className='lead fw-bold'>{t('근무 히스토리')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{/* <Timeline className='w-auto'>
									{item.history?.map((h, i) => {
										//console.log(h)
										return (
											<TimelineItem key={`history-${i}`} label={moment(h.realTime).format('HH:mm')} color={h.state === 'S' ? 'success' : 'dark'}>
												{h.state === 'S' && h.restday.name === '근무' && `${t('기록출근')} (${moment(h.time).format('HH:mm')})`}
												{h.state === 'E' && h.restday.name === '근무' && t('기록퇴근')}
												{h.state === 'S' && h.restday.name === '휴식' && t('휴식시작')}
												{h.state === 'E' && h.restday.name === '휴식' && t('휴식종료')}
											</TimelineItem>
										);
									})}
								</Timeline> */}
							</td>
						</tr>
					</tbody>
				</table>
			{/* {typeof item.request === 'object' && item?.request?.find(x => x.state=='SUCCESS' && x.type == 'RECORD') && (
				<table className='table align-middle user-select-none'>
					<thead>
						<tr>
							<th className='lead fw-bold'>{t('근무 기록 변경 내역')}</th>
						</tr>
					</thead>
					<tbody>
								<tr className='bg-l25-info'>
									<td className='fw-bold'>{t('현재')}</td>
									<td>{t('기록출근')}</td>
									<td>{item.stime ? moment(item.stime).format('HH:mm') : '-'}</td>
									<td>{t('기록퇴근')}</td>
									<td>{item.etime ? moment(item.etime).format('HH:mm') : '-'}</td>
								</tr>
								<tr>
									<td colSpan={5} className={'fw-bold text-start'}>{t('기록변경')}</td>
								</tr>
						{item?.request?.filter(x => x.state=='SUCCESS' && x.type == 'RECORD').map((req, i) => {
							// console.log("<<<", req)							
							const before_s = req?.cc !== null ? req?.cc?.dates?.history?.find(v=>v.state === 'S') || {} : '';
							const before_e = req?.cc?.dates?.history?.findLast(v=>v.state === 'E') ? req?.cc?.dates?.history?.findLast(v=>v.state === 'E') : '';

							return (
								<tr key={`i-${i}`}>
									<td className='lh-1'>
										{moment(req.createdAt).format('YYYY.MM.DD HH:mm')}
										<br />
										<span className='small'>{t(req?.requestMemo) || ''}</span>
									</td>
									<td colSpan={4}>
										<div className='d-flex flex-row'>
											<div className='me-5'>{t('기록출근')}</div>
											<div className='d-flex justify-content-between'>
												<div>{before_s ? moment(before_s.realTime).format('HH:mm') : '--:--'}</div>
												<div><Icon icon='ChevronRight' /></div>
												<div>{req.startTime ? req.startTime : '--:--'}</div>
											</div>
										</div>
										<div className='d-flex flex-row'>
											<div className='me-5'>{t('기록퇴근')}</div>
											<div className='d-flex justify-content-between'>
												<div>{before_e ? moment(before_e.realTime).format('HH:mm') :'--:--'}</div>

												<div><Icon icon='ChevronRight' /></div>
												
												<div>{req.endTime ? req.endTime : '--:--'}</div>
											</div>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)} */}
		</>
	);
};

export default React.memo(WorkReportAsideDeatail);
