import React, { useContext, useEffect, useRef } from 'react';
import Header, { HeaderLeft } from '../../layout/Header/Header';
import CommonHeaderChat from '../../../common/Headers/CommonHeaderChat';
import Search from '../../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { useMst } from '../../../../models';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import firebaseInit from '../../../../firebaseInit';
import firebase from 'firebase/app';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../../components/bootstrap/Toasts';
import { demoPages } from '../../../../menu';
import { observer } from 'mobx-react';
import AuthService from '../../../../services/AuthService';
import { browserName, browserVersion, osVersion, osName } from "react-device-detect";
import Alert from '../../../../components/bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import CompanyService from '../../../../services/CompanyService';
import ThemeContext from '../../../../contexts/themeContext';
import { Base64 } from 'js-base64';

const DashboardHeader = observer(() => {
	
	const navigate = useNavigate();
	const location = useLocation();
	const { user, company, notifiction, navtabmenus } = useMst();
	const { setAsdieSubMenuStatus, setAsideMenu, setEApproval, eApproval } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);
 
	let messaging = null;
	if (firebase.messaging.isSupported()) {
		messaging = firebaseInit.messaging();

		messaging.onMessage(function (payload) {
			//console.log("onMessage", payload);	
			// alarm 에 보이도록 !!!
			// refresh Notification
			reFreshMsg();
			addToast(
				<Toasts
					icon={'InfoOutline'}
					iconColor={'info'}
					title={t((payload.data?.title ? payload.data?.title : payload.notification.title), payload.data)}
					/* isDismiss */
					>
					<>
						<p>{t((payload.data?.message ? payload.data?.message : payload.notification.body), payload.data)}</p>
						{payload?.data?.detailId && <Button type='button' size='xs' onClick={()=>{
							const cryptoUrl = Base64.encode(`${company.get.id}_${payload?.data?.detailId}`);
							window.location.href = `/e-approval/document/${cryptoUrl}`;
						}} ><small>링크</small></Button>}
					</>
				</Toasts>,
				{
					autoDismiss: false,
				}
			)
			/* showNotification(
				payload.notification.title,
				payload.notification.body,
				'info'
			) */ 
			// notification refresh 
		});		


			// messaging.setBackgroundMessageHandler(function(payload) {
			// 	console.log('[firebase-messaging-sw.js] Received background message', payload);
			// 	return new Promise(function(resolve, reject) {
			// 		resolve();
			// 		setTimeout(function(){
			// 			self.registration.getNotifications().then(notifications => {  
			// 				notifications.forEach((notification) => { 
			// 					notification.close();
			// 				})
			// 			})
			// 		},10);
			// 	});
			// });

			  
		// messaging.setBackgroundMessageHandler(function (payload) {
		// 	console.log("onBackgroundMessage", payload);
		// 	const notificationTitle = t(payload.notification.title, payload.data);
		// 	const notificationOptions = {
		// 		body: t(payload.notification.body, payload.data)
		// 	}
		// 	return self.registration.showNotification(notificationTitle, notificationOptions);
		// });

	}

	const { addToast } = useToasts();
	
	const reFreshMsg = async () => {
		notifiction.getRefresh(company.get.id);
		// await CompanyService.getEApproval(company.get.id, company.get.isDoc ? 0 : user.me.id).then( res => {
		// 	if(res?.data) {
		// 		let r = {};
		// 		Object.keys(res.data)?.map( v => {
		// 			r[v] = res.data[v]?.length || 0		
		// 		})
		// 		setEApproval(r);
		// 	}			
		// })
	}

	function useInterval(callback, delay) {
		const savedCallback = useRef();
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);
	
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	const checkSession = async () => {					
		// console.log('check Session');		
		await AuthService.check().then( async res => {
			await notifiction.getRefresh(company.get.id)
			if(res.data === undefined) {
				navigate(`/${demoPages.login.path}`)
			}
		})
	};		

	useInterval(() => {
		checkSession();
	}, 1000 * 60 * 5)
	

	// useEffect(()=>{		
	// 	//console.log("@@@", user.me)
	// 	if(!user.me.id) {
	// 		navigate(`/${demoPages.login.path}`);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user])


	// useEffect(()=>{
	// 	console.log('navtabmenus.get.list', navtabmenus.get.list)
	// }, [navtabmenus.get.list])

	useEffect(()=>{		
		reFreshMsg();
		//checkSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Header>
				<HeaderLeft className={'text-truncate'}>
					{navtabmenus?.get?.list.length > 0 ? (
						<div className='col-md overflow-auto me-5'>
							{navtabmenus?.get?.list?.map((item) => (
								<ButtonGroup key={item.id}>
									<Button
										type='button'
										size='sm'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={() => {
											let lists = navtabmenus?.get?.list?.map( v => {
												return {...v, isActive: item.path === v.path ? true : false}
											})
											navtabmenus.setMenus(lists);
											navigate(item.path[0] === '/' ? item.path : '/' + item.path);
										}}
										className='text-nowrap'>
										{item.name}
									</Button>
									<Button
										type='button'
										size='sm'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={async () => {
											let lists = navtabmenus.get.list?.filter((v) => v.id !== item.id);
											navtabmenus.setMenus(lists);
										}}>
										<Icon icon='close' />
									</Button>
								</ButtonGroup>
							))}
						</div>
					) : (
						<></>
					)}
				</HeaderLeft>
				<CommonHeaderRight /* afterChildren={<CommonHeaderChat />} */ />
			</Header>
			{browserName.includes('Safari') && (
				<Alert icon='infoOutline' className={'mx-5'} rounded={0}>
					Safari 브라우저에서는 푸시알림이 지원되지 않습니다. Chrome, Edge 브라우저를 이용해주세요
				</Alert>
			)}
		</>
	);
});

export default DashboardHeader;

