import React, {forwardRef} from 'react';
import {t} from "i18next";
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/bootstrap/Modal_v2";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import Icon from '../../../../components/icon/Icon';
import Input from '../../../../components/bootstrap/forms/Input';
import Badge from '../../../../components/bootstrap/Badge';


const WorkFlexAddModal = ((props, ref) => {
  return (
    <>
      <Modal
        id=""
        titleId=""
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        isScrollable
        isBottomed
      >
        <ModalBody>
          <FormGroup>
            <div>
							<Label className={'fw-bold mb-0 text-dark'}>
								{t('시간설정')}
							</Label>
							<Badge
								color={'#D9D9D980'}
								style={{
									marginLeft : 2,
									backgroundColor: '#D9D9D980',
									borderRadius: 3,
									color: '#74788D',
								}}>
								<span>0</span>{t('시간')}
							</Badge>
							<span className='text-danger'>&nbsp;*</span>
            </div>
            <div className='d-flex justify-content-between mt-2'>
              <Button
                className='text-start'
                style={{
                  width: 'calc(50% - 5px)',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  padding: '10px',
                  color: '#808080'
                }}
              >
                {t(' 00:00')}
              </Button>
              <Button
                className='text-start'
                style={{
                  width: 'calc(50% - 5px)',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  padding: '10px',
                  color: '#808080'
                }}
              >
                {t(' 00:00')}
              </Button> 
            </div>
          </FormGroup>
          <FormGroup className='mt-3'>
            <div>
							<Label className={'fw-bold mb-0 text-dark'}>
								{t('날짜선택')}
							</Label>
							<span className='text-danger'>&nbsp;*</span>
            </div>
            <div className='d-flex justify-content-between mt-2'>
              <Button
                className='text-start'
                style={{
                  width: 'calc(50% - 5px)',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  padding: '10px',
                  color: '#808080'
                }}
              >
                {t('2024.00.00 (월)')}
              </Button>
              <Button
                className='text-start'
                style={{
                  width: 'calc(50% - 5px)',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  padding: '10px',
                  color: '#808080'
                }}
              >
                {t('2024.00.00 (월)')}
              </Button> 
            </div>
          </FormGroup>
        </ModalBody>
        {/* 기본상태 */}
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="info"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('닫기')}</span>
          </Button>
          <Button
            color="info"
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100'
          >
            <span>{t('저장')}</span>
          </Button>
        </ModalFooter>
        {/* 수정상태 */}
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="light"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('삭제')}</span>
          </Button>
          <Button
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100 bg-l25-dark'
          >
            <span className='text-white'>{t('수정')}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
})

export default WorkFlexAddModal;