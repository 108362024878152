import React, { forwardRef, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter } from '../../../../components/bootstrap/Modal_v2';
// import Modal, {ModalBody, ModalFooter} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Picker from 'react-mobile-picker-scroll';
import { t } from 'i18next';
import moment from 'moment';

// react-mobile-picker-scroll
// https://github.com/dsmalicsi/react-mobile-picker-scroll?tab=readme-ov-file
const UserReqRecordEditTimeSettingModal = forwardRef((props, ref) => {
	// console.log('props', props)
	const initValueGroups = {
		hh: '00',
		mm: '00',
	};

	const [optionGroups, setOptionGroup] = useState({
		hh: [],
		mm: [],
	});

	const [valueGroups, setValueGroups] = useState({ initValueGroups });

	const handleChange = (name, value) => {
		setValueGroups((prevValueGroups) => ({
			...prevValueGroups,
			[name]: value,
		}));
	};

	const timeSetting = () => {
		let hrs = [];
		let mins = [];

		for (let i = 0; i < 25; i++) {
			hrs.push(moment('00', 'HH').clone().add(i, 'hour').format('HH'));
		}

		for (let i = 0; i < 59; i++) {
			mins.push(
				moment('00', 'mm')
					.clone()
					.add(i * 1, 'minutes')
					.format('mm')
			);
		}

		setOptionGroup({
			hh: hrs,
			mm: mins,
		});
	};

	const handleSubmit = (type) => {
		let hr = moment(valueGroups?.hh, 'HH').format('HH');
		let min = moment(valueGroups?.mm, 'mm').format('mm');

		let formmatedTime = moment(`${hr}${min}`, 'HHmm').format('HH:mm');

		if (type === 'START') {
			props.data.setFieldValue('startTime', formmatedTime);
		} else {
			props.data.setFieldValue('endTime', formmatedTime);
		}
		props?.setIsOpen(false);
	};

	useEffect(() => {
		timeSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!props?.isOpen) {
			setValueGroups({
				hh: '00',
				mm: '00',
			});
		}
	}, [props.isOpen]);

	return (
		<>
			<Modal id='' titleId='' isOpen={props?.isOpen} setIsOpen={props?.setIsOpen} isScrollable isBottomed>
				<ModalBody>
					<div>
						<Picker optionGroups={optionGroups} valueGroups={valueGroups} onChange={handleChange} itemHeight={40} height={160} />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className='w-100 py-3'
						color='info'
						isDisable={!valueGroups?.hh && !valueGroups?.mm}
						onClick={() => {
							handleSubmit(props?.checkStartOrEnd);
						}}>
						<span>{t('선택완료')}</span>
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
});

export default UserReqRecordEditTimeSettingModal;
