import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../../layout/SubHeader/SubHeader';
import Button from '../../../../components/bootstrap/Button';
import Page from '../../../../layout/Page/Page';
import { useTranslation } from 'react-i18next';
import MultiPersonSelectionModal from '../../../dashboard/component/MultiPersonSelectionModal';
import SendMailModal from '../../../dashboard/component/SendMailModal';
import { getItem } from '../../../../lib/localstorage';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';
import { observer } from 'mobx-react';
import Breadcrumb, { BreadcrumbItem } from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import NoticeBoardNew from '../../../dashboard/component/NoticeBoardNew';
import OvertimeWorkersNew from '../../../dashboard/component/OvertimeWorkersNew';
import ApprovalGraphNew from '../../../dashboard/component/ApprovalGraphNew';
import AttendOffCanvas from '../../../dashboard/component/AttendOffCanvas';
import { useEffectOnce } from 'react-use';

const AdminDashboard = observer(() => {
    const [isAttendOffCanvas, setIsAttendOffCanvas] = useState(false);
    const [attendData, setAttendData] = useState(0);
    const { t } = useTranslation(['translation', 'menu']);
    const [isOpenFindUser, setIsOpenFindUser] = useState(false); // 사용자 관리 모달
    const [isOpenSendMail, setIsOpenSendMail] = useState(false); // 메일발송 모달
    const [selectedUser, setSelectedUser] = useState(
        getItem('mailSelectedUser') && JSON.parse(getItem('mailSelectedUser')).length > 0
            ? JSON.parse(getItem('mailSelectedUser'))
            : []
    );
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffectOnce(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    });

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Breadcrumb list={null} tag={'nav'}>
                        <BreadcrumbItem tag='nav' to={demoPages.sales.subMenu.dashboard.path}>
                            {t(demoPages.sales.subMenu.dashboard.text)}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <InputGroup>
                        <InputGroupText>
                            {/* {moment().format(`YYYY${t('년')} MM${t('월')} DD${t('일')} (dd)`)} */}
                            {moment().format('YYYY') + t('대시보드년')}
                            {moment().format('MM') + t('대시보드월')}
                            {moment().format('DD') + t('대시보드일')}
                        </InputGroupText>
                        <Button
                            color={'info'}
                            onClick={() => {
                                setIsOpenSendMail(true);
                            }}>
                            {t('근태보고 메일 발송')}
                        </Button>
                    </InputGroup>
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid' style={{backgroundColor: '#FAFBFF'}}>
                <div className='row'>

                    {/* <div className={`col-xl-6 col-xxl-4`} style={{ order: windowSize?.width < 1400 ? 1 : 1 }}>
						<AttendSituationCardNew setIsAttendOffCanvas={setIsAttendOffCanvas} setAttendData={setAttendData} />
					</div> */}

                    <div className={`col-xl-6 col-xxl-6`} style={{ order: windowSize?.width < 1400 ? 1 : 1 }}>
                        <ApprovalGraphNew /> {/* 결재대기목록 */}
                    </div>
                    <div className={`col-xl-3 col-xxl-3`} style={{ order: windowSize?.width < 1400 ? 2 : 2 }}>
                        {/*<OvertimeWorkersNew workTime={42} />*/}
                        <OvertimeWorkersNew workTime={40} workOverTime={12} />
                    </div>
                    <div className={`col-xl-3 col-xxl-3`} style={{ order: windowSize?.width < 1400 ? 3 : 3 }}>
                        <NoticeBoardNew />
                    </div>


                    {/* <div className={`col-xl-8 col-xxl-8`} style={{ order: windowSize?.width < 1400 ? 4 : 4 }}>
						<AttendHistoryGraphNew />
					</div>

					<div className={`col-xl-4 col-xxl-4`} style={{ order: windowSize?.width < 1400 ? 5 : 5 }}>
						<AttendHistoryNew />
					</div> */}

                    {/* <div className={`col-xl-4 col-xxl-4`} style={{ order: windowSize?.width < 1400 ? 7 : 7 }}>
						<OvertimeWorkersNew workTime={52} />
					</div> */}

                    <SendMailModal
                        selectedUser={selectedUser}
                        isOpenSendMail={isOpenSendMail}
                        setIsOpenSendMail={setIsOpenSendMail}
                        attendData={attendData}
                        setIsOpenFindUser={setIsOpenFindUser}
                    />
                    <MultiPersonSelectionModal
                        setIsOpenSendMail={setIsOpenSendMail}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        isOpenFindUser={isOpenFindUser}
                        setIsOpenFindUser={setIsOpenFindUser}
                        title={t(`메일 송신 직원 선택`)}
                    />
                    <AttendOffCanvas
                        attendData={attendData}
                        isAttendOffCanvas={isAttendOffCanvas}
                        setIsAttendOffCanvas={setIsAttendOffCanvas}
                    />
                </div>
            </Page>
        </>
    );
});

export default AdminDashboard;
