import React, {forwardRef} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/bootstrap/Modal";
import Icon from "../../../../components/icon/Icon";
import iconDocument from "../../../../assets/img/icon/icon_document.png";
import Button from "../../../../components/bootstrap/Button";
import iconImg from "../../../../assets/img/icon/img_notice_lg.svg";
import {useTranslation} from "react-i18next";


const WorkRecordDeleteModal = forwardRef((props) => {
  const { t } = useTranslation(['translation', 'menu'])
  return (
    <>
      <Modal
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        isStaticBackdrop
        isScrollable
        isCentered
        size='sm'
      >
        <ModalHeader />
        <ModalBody>
          <div className='text-center'>
            <img src={iconImg} alt="리스트 아이콘" width={60}/>
            <h5 className='fw-bold mt-4 lh-base'>근무기록 삭제</h5>
            <p className='text-muted mb-0'>
              기록을 삭제하시겠습니까?
              <br/>이전 내용을 되돌릴 수 없습니다.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
              className='w-50'
              style={{
                borderRadius: 7,
              }}
              color='light'
              isOutline
              onClick={() => props?.setIsOpen(false)}
          >
            <span className='px-3'>{t('취소')}</span>
          </Button>
          <Button
              className='w-50'
              style={{
                borderRadius: 7,
              }}
              color='secondary'
              onClick={() =>props?.setIsOpen(false)}
          >
            <span className='px-3'>{t('삭제')}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
})

export default WorkRecordDeleteModal;
