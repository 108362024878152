import React from 'react'

import PubIndex from './PubIndex'
import PubModalList from './pages/ModalList'
import PubEmptyPage from './pages/EmptyPage'
import PubLayout from './pages/PubLayout'
import PubEtcPage from './pages/EtcPage'
import PubWorkCalender from './AuthMenu/User/WorkCalender'
import PubAdminDashboard from './dashboard/page/AdminDashboardPage'
import PubUserDashboard from './dashboard/page/UserDashboardPage'
import WorkingScheduleManagement from './AuthMenu/Work/workingScheduleManagement'

const idrPubList = [
    {
        path: '/Pub',
        element: <PubIndex />,
    },
    {
        path: '/Pub/EmptyPage',
        element: <PubEmptyPage />,
        exact: true,
    },
    {
        path: '/Pub/ModalList',
        element: <PubModalList />,
        exact: true,
    },
    {
        path: '/Pub/Layout',
        element: <PubLayout />,
        exact: true,
    },
    {
        path: '/Pub/work/Calender',
        element: <PubWorkCalender />,
        exact: true,
    },
    {
        path: '/Pub/work/schedule',
        element: <WorkingScheduleManagement />,
        exact: true,
    },
    {
        path: '/Pub/AdminDashboard',
        element: <PubAdminDashboard />,
        exact: true,
    },
    {
        path: '/Pub/UserDashboard',
        element: <PubUserDashboard />,
        exact: true,
    },
    {
        path: '/Pub/Etc',
        element: <PubEtcPage />,
        exact: true,
    },
]

export default idrPubList;