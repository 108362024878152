import React, {forwardRef, useState} from 'react';
import Modal, {ModalBody, ModalFooter} from "../../../../components/bootstrap/Modal_v2";
import Button from "../../../../components/bootstrap/Button";
import Picker from 'react-mobile-picker-scroll';
import {t} from "i18next";

// react-mobile-picker-scroll
// https://github.com/dsmalicsi/react-mobile-picker-scroll?tab=readme-ov-file
const TimePickerModal = forwardRef((props, ref) => {
    const initValueGroups = {
        hh: '00',
        mm: '00',
    }

    const optionGroups = {
        hh: ['00','01','02','03','04','05','06','07','08','09'],
        mm: ['00','01','02','03','04','05','06','07','08','09'],
    }

    const [valueGroups, setValueGroups] = useState({initValueGroups})

    const handleChange = ( name, value) => {
        setValueGroups((prevValueGroups) => ({
            ...prevValueGroups,
            [name]: value
        }))
    }

    return (
        <>
            <Modal
                id=""
                titleId=""
                isOpen={props?.isOpen}
                setIsOpen={props?.setIsOpen}
                isScrollable
                isBottomed
            >
                <ModalBody>
                    <div>
                        <Picker
                            optionGroups={optionGroups}
                            valueGroups={valueGroups}
                            onChange={handleChange}
                            itemHeight={40}
                            height={160}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='w-100 py-3'
                        color="info"
                        isDisable
                        onClick={()=>{props?.setIsOpen(false)}}
                    >
                        <span>{t('선택완료')}</span>
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
})

export default TimePickerModal;
