import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import BoardService from '../../../services/BoardService';
import { useMst } from '../../../models';
import showNotification from '../../../components/extras/showNotification';
import moment from 'moment';
export function convertFileName(orgnStr) {
  return moment().format('YYYYMMDD')+orgnStr.replace(/[\{\}\[\]\/?,;: |\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi, "_");
}

const Files = (props) => {
	// api 통신 연결 후 송수신에 따른 추가 제거 삭제..
	const { index, file, deleteHandler, shortName, downloadHandler } = props;
	const [info, setInfo] = useState({});
	const { user, company } = useMst();

	const dirName = "./data/images/user"+user.me.id;
	// const dirName = "/app/backend/public/data/images/user"+_.userId;
	const saveFile = dirName + "/" + convertFileName(file.name);

	useEffect(() => {
		setInfo(file);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	// const downloadHandler = async (index) => {
	// 	function s2ab(s) {
	// 		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	// 		var view = new Uint8Array(buf); //create uint8array as viewer
	// 		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
	// 		return buf;
	// 	}

	// 	await BoardService.download(index).then(async (res) => {
	// 		console.log('CHECK >> ', res);
	// 		if (res.data && res.status === 200) {
	// 			console.log('down', res.headers['content-type']);
	// 			const blobFile = new Blob([s2ab(atob(res.data))], { type: res.headers['content-type'] });
	// 			const downloadUrl = window.URL.createObjectURL(blobFile);
	// 			// const downloadUrl = window.URL.createObjectURL(
	// 			//     // new Blob([s2ab(atob(res.data))], { //s2ab(atob(
	// 			//     new Blob([res.data], { //s2ab(atob(
	// 			//         // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
	// 			//         type: res.headers['content-type'],
	// 			//     })
	// 			// );
	// 			const link = document.createElement('a');
	// 			link.href = downloadUrl;
	// 			link.setAttribute('download', info.name); //any other extension
	// 			document.body.appendChild(link);
	// 			link.click();
	// 			link.remove();
	// 		}
	// 	});
	// };

	const returnFileSize = useCallback((number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number > 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number > 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	}, []);

	const returnIcon = (type) => {
		switch (type) {
			case 'image/png':
			case 'image/jpg':
			case 'image/jpeg':
			case 'image/bmp':
				return (
					<span className='me-2'>
						<Icon icon='FileImage' size='lg' />
					</span>
				);
			case 'application/pdf':
				return (
					<span className='me-2'>
						<Icon icon='FilePdf' size='lg' />
					</span>
				);
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return (
					<span className='me-2'>
						<Icon icon='FileExcel' size='lg' />
					</span>
				);
			case 'application/x-msdownload':
				return (
					<span className='me-2'>
						<Icon icon='FileX' size='lg' />
					</span>
				);
			case 'text/html':
				return (
					<span className='me-2'>
						<Icon icon='FileCode' size='lg' />
					</span>
				);
			case 'application/json':
				return (
					<span className='me-2'>
						<Icon icon='FileCode' size='lg' />
					</span>
				);
			case 'video/mp4':
				return (
					<span className='me-2'>
						<Icon icon='FilePlay' size='lg' />
					</span>
				);
			case 'application/msword':
			case 'application/haansofthwp':
				return (
					<span className='me-2'>
						<Icon icon='FileWord' size='lg' />
					</span>
				);
			case 'application/x-zip-compressed':
				return (
					<span className='me-2'>
						<Icon icon='FileZip' size='lg' />
					</span>
				);
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return (
					<span className='me-2'>
						<Icon icon='FilePpt' size='lg' />
					</span>
				);
			default:
				return (
					<span className='me-2'>
						<Icon icon='InsertDriveFile' size='lg' />
					</span>
				);
		}
	};

	return (
		<>
			<div className='d-flex justify-content-between align-items-center mx-3 mb-1 board-file-list'>
				<div
					className='flex-grow-1 cursor-pointer'
					onClick={async () => {
							downloadHandler(index);
					}}>
					{returnIcon(info.type)} {shortName ? info?.name?.substring(0, 25) + '...' : info.name} <small>{`[${info?.download || 0}]`}</small>
				</div>
				<div>{returnFileSize(info.size)}</div>
				{deleteHandler && (
					<div>
						<Button
							type='button'
							onClick={() => {
								deleteHandler(index);
							}}
							icon='Close'
						/>
					</div>
				)}
			</div>
		</>
	);
};
export default Files;
