import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
// import {useReactToPrint} from 'react-to-print';
import { useTranslation } from 'react-i18next'; //번역관련
import Card, { CardBody, CardHeader, CardTitle } from '../../../../components/bootstrap/Card';
import { useMst } from '../../../../models';
import Button from '../../../../components/bootstrap/Button';
import Popovers from '../../../../components/bootstrap/Popovers';
import ApprovalContentsRequestModal from './ApprovalContentsRequestModal';
import GetRestInfo from './GetRestInfo';
import CompanyService from '../../../../services/CompanyService';
import { isExists } from 'date-fns';
import { oneOf } from 'prop-types';
import showNotification from '../../../../components/extras/showNotification';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Files from '../../../Board/component/Files';
import Icon from '../../../../components/icon/Icon';
function getTitle(request) {
	if (request?.title !== '' && request?.title !== null) {
			return request?.title;
	} else {
			if (request.restday === null) {
				switch (request.type) {
					case 'RECORD':
							return '근무기록 수정';
					case 'USER_REST':
					case 'REST':
							return `${request?.data?.title} 신청`;
					case 'WORK':
							return `${request?.data?.title} 신청`;
					case 'WORK_CANCEL':
					case 'USER_REST_CANCEL':
					case 'REST_CUSTOM_CANCEL':
					case 'REST_CANCEL':
							return `${request?.data?.title} 신청 취소`;
					default:
							return '-';
			}
			}
			switch (request.type) {
					case 'RECORD':
							return '근무기록 수정';
					case 'USER_REST':
					case 'REST_TRANSFER':
					case 'REST':
							return `${request?.restday?.name} 신청`;
					case 'WORK':
							return `${request?.restday?.name} 신청`;
					case 'WORK_CANCEL':
					case 'USER_REST_CANCEL':
					case 'REST_TRANSFER_CANCEL':
					case 'REST_CUSTOM_CANCEL':
					case 'REST_CANCEL':
							return `${request?.restday?.name} 신청 취소`;
					default:
							return '-';
			}
	}
}

const ApprovalContentsToPrint = ({ printRef, userData, setSelectedUser,isEditFlag, setSelectItem, setApprovalList, selectItem, approvalList, isPrint, refreshOrigin, setIsModalOpen}) => {

	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const [newApprovalList, setNewApprovalList] = useState([]);
	const [newAgreeList, setNewAgreeList] = useState([]);
	const [openRequest, setOpenRequest] = useState(false);
	const [modalTypeFlag, setModalTypeFlag] = useState('');
	const { user, company } = useMst();
	const [approvalCheckValue, setApprovalCheckValue] = useState('APPROVAL');
	const [agreeCheckValue, setAgreeCheckValue] = useState('APPROVAL');
	const [approvalList1, setApprovalList1] = useState([]);
	const [agreeList, setAgreeList] = useState([]);
	const [requestData, setRequestData] = useState(userData);

	// const [doucumentType, setDocumentType] = useState(requestData ? requestData?.restday?.document : '');
	const [files, setFiles] = useState(userData?.files || []);
	const inputRef = useRef(null);

	const [deleteFile, setDeleteFile] = useState({
		idx: 0,
		id: 0
	});

	const [selectionRange, setSelectionRange] = useState(
		requestData?.userId
			? ([
					{
						startDate: new Date(moment(requestData.startDate)),
						endDate: new Date(moment(requestData.endDate)),
						key: 'selection',
					},
			  ])
			: 
		(requestData?.start 
			?
				([
						{
							startDate: new Date(moment(requestData.start)),
							endDate: new Date(moment(requestData.end)),
							key: 'selection',
						},
				])
			:
				([
					{
						startDate: new Date(moment()),
						endDate: new Date(moment()),
						key: 'selection',
					},
				])
				
		)
	)

	const [userInformation, setUserInformation] = useState({
		state: {},
		users: [{
			x: 0,
			y: 0,
			userId: 0
		}],
	});
	const [proxy, setProxy] = useState(false);

	const [openModal, setOpenModal] = useState({
		modalTypeFlag: '',
		selectUserY:0,
		selectUserX:0,
	})
	const [selectUser, setSelectUser] = useState({
		x: 0,
		y: 0
	})
	const cc = requestData?.ccUsers;

	const whosNext = async () => {
		try {
			// const res = await CompanyService.who(company.get.id, requestData?.id);
			const res = await CompanyService.requestDetail(requestData?.id);
			
				setUserInformation(res?.data?.nextWho); // 해당 결재 인원 정보만 띄워줌
				forceUpdate()
				
			let isAbsentee = res?.data?.nextWho?.users[0].isAbsentee;
			if (isAbsentee) {
				setProxy(true);
			} else {
				setProxy(false);
			}
		} catch (error) {
			// 오류 처리
		}
	};

	const refresh = async () => {

		const originalList = [];

		requestData?.approvals?.forEach((i, index) => {
			i?.line?.forEach((j, jndex)=> {
				originalList.push({...j, 'y':index, 'x':jndex});
			})
		});

		const agrees = originalList?.filter((i) => i.type == 'AGREEMENT');
		const approvals = originalList?.filter((i) => i.type == 'APPROVAL');

		setApprovalList1(approvals);
		setAgreeList(agrees);

		whosNext();
	};

	// 전결 배열 생성
	const findApprovalIndex = approvalList1?.indexOf(approvalList1?.find((i) => i.state == 'APPROVAL_ALL'));
	const findApprovalRequestIndex = approvalList1?.indexOf(approvalList1?.find((i) => i.state == 'REQUEST'));
	const findAgreeRequestIndex = agreeList?.indexOf(agreeList?.find((i) => i.state == 'REQUEST'));
	const allApprovalList = approvalList1?.slice(0, findApprovalIndex + 1);
	const allApprovalRequestList = approvalList1?.slice(0, findApprovalRequestIndex + 1);
	const allAgreeRequestList = agreeList?.slice(0, findAgreeRequestIndex + 1);

	const filterAgreeList = (requestIdx, requestArr, agreeList) => {
		if (requestIdx > -1) {
			let newList = [...requestArr];
			for (let i = 0; i < agreeList.length - requestArr.length; i++) {
				newList.push({});
			}
			setNewAgreeList(newList);
		} 
		// else {
		// 	setNewAgreeList(agreeList);
		// }
	};

	//전결시...
	const filterApprovList = (allApprovIdx, requestIdx, allApprovArr, reqeustArr, approvList) => {
		const filterNewList = (copyList) => {
			let newList = [...copyList];
			for (let i = 0; i < approvList?.length - copyList.length; i++) {
				newList.push({});
			}
			setNewApprovalList(newList);
		};

		if (allApprovIdx > -1) {
			//ALL_APPROVAL 있을때
			if (requestIdx > -1) {
				//REQUEST 가 있을때
				if (allApprovIdx < requestIdx) {
					//Request가 뒤에 있을때
					filterNewList(allApprovArr);
				} else if (allApprovIdx > requestIdx) {
					// REQUEST 앞에 있음
					filterNewList(reqeustArr);
				}
			} else {
				//REQUEST 가 없을때
				filterNewList(allApprovArr);
			}
		} else {
			// ALL_APPROVAL 없을때
			if (requestIdx > -1) {
				//REQUEST 있는 경우
				filterNewList(reqeustArr);
			} 
			// else {
			// 	//REQUEST 없는 경우
			// 	// let newList = [...approvalList1];
			// 	setNewApprovalList(approvalList1);
			// }
		}
	};
	const returnIcon = (type) => {
		switch(type) {
			case 'image/png':
			case 'image/jpg':
			case 'image/jpeg':
			case 'image/bmp':
				return <span className='me-2'><Icon icon='FileImage' size='lg' /></span>
			case 'application/pdf':
				return <span className='me-2'><Icon icon='FilePdf' size='lg' /></span>
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return <span className='me-2'><Icon icon='FileExcel' size='lg' /></span>
			case 'application/x-msdownload':
				return <span className='me-2'><Icon icon='FileX' size='lg' /></span>
			case 'text/html':
				return <span className='me-2'><Icon icon='FileCode' size='lg' /></span>
			case 'application/json':
				return <span className='me-2'><Icon icon='FileCode' size='lg' /></span>
			case 'video/mp4':
				return <span className='me-2'><Icon icon='FilePlay' size='lg' /></span>
			case 'application/msword':
			case 'application/haansofthwp':
				return <span className='me-2'><Icon icon='FileWord' size='lg' /></span>
			case 'application/x-zip-compressed':
				return <span className='me-2'><Icon icon='FileZip' size='lg' /></span>
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return <span className='me-2'><Icon icon='FilePpt' size='lg' /></span>
			default:
				return <span className='me-2'><Icon icon='InsertDriveFile' size='lg' /></span>
		}
	}

	const returnFileSize = useCallback((number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number > 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number > 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	}, []);

	const stateCellColor = (type, approval, Request) => {
		let color = '';
		if(type == 'APPROVAL'){
		if (approval > -1) {
			if (Request > -1) {
				if (approval < Request) {
					color = 'seashell';
				} else {
					color = '';
				}
			} else {
				color = 'seashell';
			}
		} else {
			if (Request > -1) {
				color = '';
			}
		}
		return color;
	} else {
		return color = '';
	}
	};

	const stateBtnColor = (state) => {
		switch (state) {
			case 'REJECT':
				return 'danger';
			case 'AGREEMENT_REQ':
				return 'info';
			case 'APPROVAL':
			case 'APPROVAL_ALL':
			case 'APPROVAL_PROXY':
				return 'success';
			case 'APPROVAL_LATER':
				return '';
			case 'REQUEST':
				return 'success';
		}
	};

	const stateBtnName = useCallback((type, state) => {
		// console.log('item', item)
		switch (state) {
			case 'REJECT':
				return type === 'APPROVAL' ? 'REJECT' : t('반대');
			case 'AGREEMENT_REQ':
				return 'AGREEMENT_REQ';
			case 'APPROVAL_ALL': 
				return 'APPROVAL_ALL';
			case 'APPROVAL':
			case 'APPROVAL_PROXY':
				return type === 'APPROVAL' ? 'APPROVAL' : t('합의');
			case 'APPROVAL_LATER':
				return '';
			case 'REQUEST':
				// console.log('isPrint', isPrint)
				if(isPrint !== true){
					return t('결재');
					// if (stateWho) {
					// 	stateWho.users?.findIndex(i => {
					// 		if (i?.user?.userId === item.user.userId) 
					// 			return type === 'APPROVAL' ? t('결재 대기중') : t('합의 대기중')
					// 	});
					// }
				}
				else
					return '';
		}
	}, [isPrint, t]);

	const requestHandleOnlick = (state, type, y ,x) => {
		if (state === 'REQUEST') {
			setOpenModal({
				modalTypeFlag: type,
				selectUserY: y,
				selectUserX: x,
			})
			setOpenRequest(true);
		}
	};

	const downloadHandler = async (index, fileInput) => {
		function s2ab(s) {
			var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf); //create uint8array as viewer
			for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
			return buf;
		}

			await CompanyService.approvalDownloadFiles(company.get.id, userData?.id, fileInput?.id).then(async (res) => {
				console.log('CHECK >> ', res);
				if (res.data && res.status === 200) {
					console.log('down', res.headers['content-type']);
					const blobFile = new Blob([s2ab(atob(res.data))], { type: res.headers['content-type'] });
					const downloadUrl = window.URL.createObjectURL(blobFile);
					const link = document.createElement('a');
					link.href = downloadUrl;
					link.setAttribute('download', fileInput.name); //any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				}
			});
	};
	const deleteHandler = async (index) => {
		// 임시. 작업받은 백엔드 추후 연결
		let tmp = [...files];
		console.log('delete', index, tmp[index]);
		
		setDeleteFile({
			...deleteFile,
			idx: index,
			id: tmp[index]?.id
		});

		tmp.splice(index, 1);
	
		setFiles([...tmp]);
	};

	const uploadApprovalDocument = async () => {
		const data = {
			requestId: requestData?.id,
			companyId: company.get.id,
		}
		const form = new FormData();

		if(files.length > 0) {
			files?.map(file => {
			 form.append('files', file);
			}) }

	 	form.append('data', JSON.stringify(data));

		await CompanyService.approvalUploadFile(form).then(res => {
			console.log('res', res);
			if(res?.data){
				let selectedItemIdx = approvalList.findIndex(i => i?.id === requestData?.id);
				let updatedApprovalList = [...approvalList];
				updatedApprovalList[selectedItemIdx].files = res?.data?.files;
				setApprovalList(updatedApprovalList);
				showNotification(t('증명자료'), t('증명자료가 제출되었습니다'), 'info');
			} else {
				showNotification(t('증명자료'), t('다시 시도해주세요'), 'danger');
			}
		})
	}

	const showDocumentBtn = () => {
		return (
			<div className='col-sm-12'>
				<FormGroup>
					<div className='d-flex justify-content-center'>
						{files?.length > 0 &&
						<div className='d-block'>
							<ol>
								{files.map((fileInput, fileIndex) => {
									return(
										<li key={`file-${fileIndex}`} >
											<div
												className='flex-grow-1 cursor-pointer'
												onClick={async () => {
													downloadHandler(fileIndex, fileInput);
												}}>
												{returnIcon(fileInput.type)} {fileInput.name} {returnFileSize(fileInput.size)}
											</div>
									{/* <div>
										<Button
											type='button'
											onClick={() => {
												deleteHandler(fileIndex);
											}}
											icon='Close'
											/>
									</div> */}
										</li>
										)
									})}
							</ol>
							</div>
						}
						{/* { requestData?.userId === user.me.id && 
							doucumentType === 'AFTER' &&
						<>
							<Input
								className='d-none'
								ref={inputRef}
								type='file'
								multiple
								accept='.xlsx,.xlsb,.xlsm,.xlt,.xltm,.xltx,.xlw,.xls,.pdf,.jpg,.jpeg,.png,.gif,.ppt,.pot,.potm,.potx,.ppa,.ppam,.pps,.ppsm,.ppsx,.pptm,.pptx,.doc,.docm,.docx,.dot,.dotx,.rtf,.csv,.txt,.hwp'
								onChange={async (e) => {
									var file = e.target.files;
									// console.log("==============", file);
									const maxSize = 100 * 1024 * 1024;
									let tmp = [];
									Object.keys(file).map((key) => {
										console.log(maxSize, file[key].size);
										if (file[key].size < maxSize) {
											tmp.push(file[key]);
										} else {
											showNotification(t('파일 업로드'), `${file[key].name} 파일이 100mb 보다 커서 제외되었습니다.`, 'danger');
										}
									});
									setFiles([...files, ...tmp]);

									uploadApprovalDocument();
									
									e.target.value = null;
								}}
							/>
							<div className='mx-4'>
								<Button
									color={'light'}
									type='button'
									isOutline
									style={{width: files && files.length > 0 ? 110 : 400, height: 100, border: '1px dashed #bfbdbd'}}
									// disabled={!requestFileEdit}
									onClick={async () => {
										console.log('inputRef', inputRef)
										if (inputRef.current) inputRef.current.click();
									}}>
										<div className=' my-2'>
											<Icon icon='UploadFile' style={{color: '#dcdcdc'}} className='' size='3x'/>
										</div>
										<span style={{fontSize: 12}}>{t('파일 업로드')}</span>
								</Button>
							</div>
						</>
						} */}
					</div>
				</FormGroup>
			</div>
			)
		}

	


	useEffect(()=> {
		if(findApprovalIndex == -1 && findApprovalRequestIndex == -1){setNewApprovalList(approvalList1)
		}
	},[findApprovalIndex, findApprovalRequestIndex,approvalList1])

	useEffect(()=> {
		if(findAgreeRequestIndex == -1) {
			setNewAgreeList(agreeList)
		}
	},[agreeList, findAgreeRequestIndex])

	useEffect(() => {
		filterApprovList(findApprovalIndex, findApprovalRequestIndex, allApprovalList, allApprovalRequestList, approvalList1);
		filterAgreeList(findAgreeRequestIndex, allAgreeRequestList, agreeList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findApprovalIndex, findApprovalRequestIndex, findAgreeRequestIndex]);

	useEffect(() => {
		if (!openRequest) {
			setApprovalCheckValue('');
			setAgreeCheckValue('');
		}
	}, [openRequest]);
	
	useEffect(() => {
		// if(requestData?.state === 'REQUEST')
		// 	whosNext(); 
		// else
			refresh();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestData]);

	// useEffect(() => {
	// 	refresh();
	// 	// console.log('requestData', requestData);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [requestData]);


	// useEffect(() => {
	// 	console.log('isPrint', isPrint)
	// 	forceUpdate();
	// }, [isPrint, forceUpdate])

	return (
		<div className='row report-template' ref={printRef}>
			<div className='col-12'>
				<>
					<Card shadow='none mb-0 mb-sm-3'>
						<CardHeader className='my-2 justify-content-center'>
							<CardTitle className='fs-2'>{t(getTitle(requestData)) || '-'}</CardTitle>
						</CardHeader>
						<CardBody className='px-0 px-sm-3 pb-0 py-sm-3'>
							<div className='d-flex flex-wrap flex-sm-nowrap flex-sm-row justify-content-between row'>
								<div className='col-12 col-md-4'>
									<table className='table border border-1 border-dark user-select-none'>
										<tbody>
											<tr>
												<th className='bg-light' style={{ width: 100 }}>
													{t('기안 부서')}
												</th>
												<td>
													<span>{requestData?.deaprtmentName || '-'}</span>
												</td>
											</tr>
											<tr>
												<th className='bg-light'>{t('기안자')}</th>
												<td>
													<span>{requestData?.user?.name || requestData?.user || ''}</span>
													<small className='ms-1'>{t(requestData?.rankName) || ''}</small>
												</td>
											</tr>
											<tr>
												<th className='bg-light'>{t('기안일자')}</th>
												<td>
													<span>{requestData?.createdAt ? moment(requestData?.createdAt).format('YYYY-MM-DD (dd)') : ''}</span>
												</td>
											</tr>
											<tr>
												<th className='bg-light'>{t('참조')}</th>
												<td>
													{cc?.map((i, index) => {
														return (
															<span className='pe-1' key={`cc-${index}`} >
																{i?.name}
															</span>
														);
													})}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className='col-12 col-md-6 d-flex flex-column align-items-end justify-content-end'>
									{approvalList1 && approvalList1.length > 0 &&  (
										<table className='table table-bordered align-middle border border-1 border-dark text-cetner w-auto'>
											<tbody>
												<tr>
													<td rowSpan={4} className='bg-light text-center fw-bold'>
														{t('결재서')}
													</td>
												</tr>
												<tr className=''>
													{approvalList1?.map((i, index) => {
														// console.log('i>>>',i);
														return (
															<td key={`approval-${index}`} style={{ maxWidth: 100, width: 100 }}>
																{/* &nbsp; */}
																<div className='text-center'>
																	{approvalList1[index]?.user?.rank ? <small className='ms-1'>{t(approvalList1[index]?.user?.rank || '')}</small> : ''}
																	</div>
																{/* {i?.user?.name ? i?.user?.name : ''} {i?.user?.rank ? t(i?.user?.rank) : ''} */}
															</td>
														);
													})}
												</tr>
												<tr className='text-center'>
													{approvalList1?.map((i, index) => {
														// console.log('i>>', i)
														const test = approvalList1?.findIndex( (tv, tindex) => {
															return tindex < index && tv.state == 'APPROVAL_ALL'
														})
														// console.log('check', test)
														if(test === -1) {
															if (Object.keys(i).length > 0) {
																return (
																	<td
																		key={`newApproval-${index}`}
																		// height={120}
																		style={{
																			backgroundImage:
																				i.state == 'APPROVAL_ALL'
																					? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="gray" /></svg>')`
																					: null,
																			backgroundColor: i.state == 'APPROVAL_ALL' ? 'seaShell' : i.state !== 'REQUEST' && i.state !== 'APPROVAL_ALL' ? '#F5F5F5' : '',
																			maxHeight: 100,
																			height: 100,
																		}}
																		colSpan={i.state == 'APPROVAL_ALL' ? approvalList1.length - index + 1 : 1}
																		className='text-center'>
																		<div className='d-flex flex-column justify-content-end h-100'>
																			<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																				<div className=''>
																					{i?.state !== 'REQUEST' ?
																					<div>
																						{/* 결재가 끝난 상태 출력 */}
																						{i?.otherUser && Object.keys(i?.otherUser)?.length > 0 ?
																								<Popovers
																									title={<div className='text-center'>{t('대결')}</div>}
																									desc={
																										<div>
																										<span>
																											<span className='fw-bold'>{'대결자 | '}</span> {i?.otherUser?.name || ''} {i?.otherUser?.rank || ''}
																										</span>
																										{i?.memo && <div><span className='fw-bold'>{'사유 | '}</span> {i?.memo}</div>}
																										</div>
																									}
																									placement='top'
																									flip='top'
																									trigger='hover'>
																									<div>
																										<div className='fw-bold'>{i.state == 'REJECT' ? t('반려') : t('대결')}</div>
																									</div>
																								</Popovers> :
																								<div className='fw-bold'>
																									{i?.memo ?
																										<Popovers
																											title={<div className='text-center fw-bold'>{t('의견')}</div>}
																											desc={i?.memo}
																											placement='top'
																											flip='top'
																											trigger='hover'>
																											<div>
																												<div className='fw-bold'>{t(stateBtnName('APPROVAL', i.state))}</div>
																											</div>
																										</Popovers>:<div>
																											{t(stateBtnName('APPROVAL', i.state))}
																										</div>
																									}
																									</div>
																									}
																					</div>
																					:
																					<div>
																						{userInformation?.users?.findIndex(item => parseInt(item.x) == i.x && parseInt(item.y) == i.y) > -1 &&
																						<>
																							{userInformation?.users?.findIndex(x => x?.user?.userId == user.me.id) > -1 ?
																							<Button
																							key={`apprval-${index}`}
																							size='sm'
																							color={stateBtnColor(i.state)}
																							isOutline
																							disabled={userInformation?.users?.findIndex(finduser => finduser?.user?.userId == user.me.id) == -1}
																							onClick={() => {
																								const approvaly=i.y;
																								const approvalX=i.x;
																								requestHandleOnlick(i.state, 'APPROVAL', approvaly, approvalX);
																								setApprovalCheckValue('APPROVAL');
																							}}>
																							{t(stateBtnName('APPROVAL', i.state))}
																							</Button> :
																							<div>
																								<span className='text-muted' style={{fontSize
																								: 12}}>{t('결재 대기중')}</span>
																							</div>
																						}
																						</>

																						}
																					</div>
																					}
																				</div>
																			</div>
																			<div className='small'>
																				{/* {approvalList1[index]?.user?.name ? approvalList1[index]?.user?.name : ''} */}
																				{i?.sussDate ? moment(i?.sussDate).format('YYYY-MM-DD (dd)') : ''}
																				{/* {approvalList1[index]?.user?.rank ? <small className='ms-1'>{t(approvalList1[index]?.user?.rank || '')}</small> : ''} */}
																			</div>
																		</div>
																	</td>
																);
															} else {
																return (
																	<td
																		key={`coloredCell-${index}`}
																		style={{ backgroundColor: stateCellColor('APPROVAL',findApprovalIndex, findApprovalRequestIndex), maxHeight: 100, height: 100 }}
																		className='text-center '>
																		<div className='d-flex flex-column justify-content-end h-100'>
																			<div className='small'>
																				{/* {approvalList1[index]?.user?.name ? approvalList1[index]?.user?.name : ''} */}
																				{i?.sussDate ? moment(i?.sussDate).format('YYYY-MM-DD (dd)') : ''}
																				{/* {approvalList1[index]?.user?.rank ? <small className='ms-1'>{t(approvalList1[index]?.user?.rank || '')}</small> : ''} */}
																			</div>
																		</div>
																	</td>
																);
															}
														} else {
															return <></>
														}
													})}
												</tr>
												<tr className=''>
												{newApprovalList.map((i, index) => 
												userInformation?.users?.findIndex(item => parseInt(item.x) == i.x && parseInt(item.y) == i.y) > -1 ?
												userInformation?.users?.map((u, udex) => {
													return(
														<td key={`approvDate-${index}`} className='text-center'>
															<div className='d-flex justify-content-center align-items-center'>
																<span className='text-muted small text-nowrap mr-2'>{approvalList1[index]?.user?.name ? approvalList1[index]?.user?.name : ''}</span>
																{
																	(i?.user?.userId === userInformation?.users[udex]?.userId) && (userInformation?.users[udex]?.userId !== userInformation?.users[udex]?.user?.userId) && 
																	<Popovers
																		title={t('결재권 위임')}
																		desc={
																			<div>
																				<div>
																					<span className='fw-bold'>{'위임자 | '}</span> 
																					<span>{userInformation?.users[udex]?.name || ''}</span>
																				</div>
																				<div>
																					<span className='fw-bold'>{'대리인 | '}</span> 
																					<span>{userInformation?.users[udex]?.user?.name || ''}</span>
																				</div>
																			</div>
																		}
																		placement='top'
																		flip='top'
																		trigger='hover'
																	>
																		<div className='d-flex align-items-center'>
																			<Icon icon='ArrowForward' className='mr-1'/>
																			<span className='text-muted small text-nowrap'>{userInformation?.users[udex]?.user?.name ? userInformation?.users[udex]?.user?.name : ''}</span>
																		</div>
																	</Popovers>
																}
															</div>
														</td>)
													})
													:
													<td key={`approvDate-${index}`} className='text-center'>
														<span className='text-muted small text-nowrap'>{approvalList1[index]?.user?.name ? approvalList1[index]?.user?.name : ''}</span>
													</td>
													)
												}
												</tr>
											</tbody>
										</table>
									)}

									{agreeList && agreeList?.length > 0 && (
										<table className='table table-bordered align-middle border border-1 border-dark text-cetner w-auto'>
											<tbody>
												<tr>
													<td rowSpan={4} className='bg-light text-center fw-bold'>
														{t('합의서')}
													</td>
												</tr>
												<>
													<tr className=''>
														{agreeList?.map((i, index) => {
														// console.log('newAgreeList', i)
															return (
																<td key={`agree-${index}`} style={{ maxWidth: 100, width: 100 }}>
																	{/* &nbsp; */}
																	<div className='text-center'>
																	{agreeList[index]?.user?.rank ? <small className='ms-1'>{t(agreeList[index]?.user?.rank || '')}</small> : ''}
																	</div>
																	{/* {i?.user?.name ? i?.user?.name : ''} {i?.user?.rank ? t(i?.user?.rank) : ''} */}
																</td>
															);
														})}
													</tr>
													<tr className='text-center'>
														{agreeList?.map((i, index) => {
															// console.log('newAgreeList-i', i)
															if (Object.keys(i).length > 0) {
																return (
																	<td
																		key={`agree-${index}`}
																		// height={120}
																		style={{ backgroundColor: (i.state !== 'REQUEST' && i.state !== 'APPROVAL_ALL') ? '#F5F5F5' : '', maxHeight: 100, height: 100 }}
																		className='text-center '>
																		<div className='d-flex flex-column justify-content-end h-100'>
																			<div className='flex-grow-1  d-flex justify-content-center align-items-center'>
																				{/* 원래 버튼 위치 */}
																				{i?.state !== 'REQUEST' ?
																					<div>
																						{/* 결재가 끝난 상태 출력 */}
																						{i?.otherUser && Object.keys(i?.otherUser)?.length > 0 ?
																								<Popovers
																									title={<div className='text-center'>{t('대결')}</div>}
																									desc={
																										<div>
																										<span>
																											<span className='fw-bold'>{'대결자 | '}</span> {i?.otherUser?.name || ''} {i?.otherUser?.rank || ''}
																										</span>
																										{i?.memo && <div><span className='fw-bold'>{'사유 | '}</span> {i?.memo}</div>}
																										</div>
																									}
																									placement='top'
																									flip='top'
																									trigger='hover'>
																									<div>
																										<div className='fw-bold'>{i.state == 'REJECT' ? t('반대') : t('대결')}</div>
																									</div>
																								</Popovers> :
																								<div className='fw-bold'>
																									{i?.memo ?
																										<Popovers
																											title={<div className='text-center fw-bold'>{t('의견')}</div>}
																											desc={i?.memo}
																											placement='top'
																											flip='top'
																											trigger='hover'>
																											<div>
																												<div className='fw-bold'>{t(stateBtnName('AGREE', i.state))}</div>
																											</div>
																										</Popovers>:<div>
																											{t(stateBtnName('AGREE', i.state))}
																										</div>
																									}
																									</div>
																								}
																					</div>
																					:
																					<div>
																						{userInformation?.users?.findIndex(item => parseInt(item.x) == i.x && parseInt(item.y) == i.y) > -1 &&
																						<>
																							{userInformation?.users?.find(item => parseInt(item.x) == i.x && parseInt(item.y) == i.y)?.user?.userId == user.me.id ?
																							<Button
																							key={`agree-${index}`}
																							size='sm'
																							color={stateBtnColor(i.state)}
																							isOutline
																							// disabled={i?.user?.userId !== user.me.id}
																							onClick={() => {
																								const agreey=i.y;
																								const agreeX=i.x;
																								requestHandleOnlick(i.state, 'AGREE', agreey, agreeX);
																								setAgreeCheckValue('APPROVAL');
																							}}>
																							{t(stateBtnName('AGREE', i.state))}
																							</Button> :
																							<div>
																								<span className='text-muted' style={{fontSize
																								: 12}}>{isPrint != true && t('합의 대기중')}</span>
																							</div>
																						}
																						</>

																						}
																					</div>
																					}
																				{/* {i?.state == 'REQUEST' ?
																					(
																						<>

																						{userInformation?.users?.map((item)=> {
																							if (item.x === i.x && item.y === i.y) {
																								return(
																									<Button
																									key={`agreeBtn-${index}`}
																									size='sm'
																									// disabled={user.me.id !== i?.user?.userId}
																									// disabled={userInformation?.userId !== user?.me?.id}
																									color={stateBtnColor(i.state)}
																									isOutline
																									onClick={() => {
																										// if (userInformation?.users?.find((item)=> item?.user?.userId == i?.user?.userId)) {
																											const agreeY=i.y;
																											const agreeX=i.x;
																											requestHandleOnlick(i.state, 'AGREE', agreeY, agreeX);
																											setAgreeCheckValue('APPROVAL');
																										// }
																									}}>
																									{t(stateBtnName('AGREE', i.state))}

																									</Button>	)
																							}
																						}) }
																						</>
																						) : (
																						//success나 reject일 경우..
																						<div className='fw-bold'>

																							{t(stateBtnName('AGREE', i.state))}
																						</div>
																					)} */}
																			</div>
																			<div className='small'>
																				{/* {newAgreeList[index]?.user?.name ? newAgreeList[index]?.user?.name : ''} */}
																				{i?.sussDate ? moment(i?.sussDate).format('YYYY-MM-DD (dd)') : ''}
																				{/* {newAgreeList[index]?.user?.rank ? <small className='ms-1'>{t(newAgreeList[index]?.user?.rank || '')}</small> : ''} */}
																			</div>
																		</div>
																	</td>
																);
															} else {
																return (
																	<td
																		key={`agree-${index}`}
																		style={{ backgroundColor: stateCellColor('AGREE',findApprovalIndex, findApprovalRequestIndex), maxHeight: 100, height: 100 }}
																		className='text-center '>
																		<div className='d-flex flex-column justify-content-end h-100'>
																			<div className='small'>
																				{/* {agreeList[index]?.user?.name ? agreeList[index]?.user?.name : ''} */}
																				{i?.sussDate ? moment(i?.sussDate).format('YYYY-MM-DD (dd)') : ''}
																				{/* {agreeList[index]?.user?.rank ? <small className='ms-1'>{t(agreeList[index]?.user?.rank || '')}</small> : ''} */}
																			</div>
																		</div>
																	</td>
																);
															}
														})}
													</tr>
													<tr className=''>
														{agreeList.map((i, index) => {
															// console.log('agree i',i)
															return (
																<td key={`emptyAgree-${index}`} className='text-center'>
																	<span className='text-muted small text-nowrap'>{agreeList[index]?.user?.name ? agreeList[index]?.user?.name : ''}{/* {i?.sussDate ? moment(i?.sussDate).format('YYYY-MM-DD (dd)') : ''} */}</span>
																</td>
															);
														})}
													</tr>
												</>
											</tbody>
										</table>
									)}
								</div>
							</div>

							<div className='border-top border-dark mt-5 d-flex align-items-center'>
								<table className='table table-modern mx-0 mx-sm-5 my-sm-5 mt-3 mb-0 w-100'>
									<tr className=''>
										{/* {(doucumentType === 'BEFORE' || doucumentType === 'AFTER') &&
											<td>
												<div className='d-flex align-items-start'>
													<div>
														<Icon icon='AttachFile' className='me-1'/>
														<span className='fw-bold'>{t('첨부파일')}</span>
													</div>
													<div className='ms-2'>
														{showDocumentBtn()}
													</div>
												</div>
											</td>
										} */}
										<td>
											<div className='d-flex align-items-start'>
												<div className='align-items-start'>
													<table className='table table-modern'>
														<GetRestInfo userData={requestData}
																	 setSelectedUser={setSelectedUser}
																	 isEditFlag={isEditFlag}/>
													</table>
												</div>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</CardBody>
					</Card>
					
					{openRequest && (
						<ApprovalContentsRequestModal
							setApprovalList={setApprovalList}
							setIsModalOpen={setIsModalOpen}
							userData={requestData}
							setUserData={setRequestData}
							agreeList={agreeList}
							approvalList={approvalList}
							approvalList1={approvalList1}
							openRequest={openRequest}
							setOpenRequest={setOpenRequest}
							modalTypeFlag={modalTypeFlag}
							setApprovalCheckValue={setApprovalCheckValue}
							setAgreeCheckValue={setAgreeCheckValue}
							approvalCheckValue={approvalCheckValue}
							agreeCheckValue={agreeCheckValue}
							setSelectItem={setSelectItem}
							selectItem={selectItem}
							proxy={proxy}
							setUserInformation={setUserInformation}
							userInformation={userInformation}
							setApprovalList1={setApprovalList1}			
							selectUser={selectUser}			
							openModal={openModal}
							setOpenModal={setOpenModal}
							refreshOrigin={refreshOrigin}
							/>
					)}
				</>
			</div>
		</div>
	);
};
export default ApprovalContentsToPrint;
