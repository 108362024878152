import moment from 'moment';
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid, WindowScroller } from 'react-virtualized';
import Alert from '../../../../../components/bootstrap/Alert';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Card, { CardBody, CardTitle } from '../../../../../components/bootstrap/Card';
import Input from '../../../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../../../components/bootstrap/forms/InputGroup';
import Label from '../../../../../components/bootstrap/forms/Label';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../../components/bootstrap/Modal';
import showNotification from '../../../../../components/extras/showNotification';
import Icon from '../../../../../components/icon/Icon';
import { useMst } from '../../../../../models';
import * as xlsx from 'xlsx';
import GroupService from '../../../../../services/GroupService';
import { useEffectOnce } from 'react-use';

const WorkScheduleExcelModal = forwardRef((props) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { company, user } = useMst();
	const listRef = useRef(null);
	const fileInputRef = useRef(null);
	const [validExcel, setValidExcel] = useState([]);
	const [validOpen, setValidOpen] = useState(false);
	const [files, setFiles] = useState('');
	const [startAt, setStartAt] = useState(moment().format('YYYY-MM-DD'));
	const [endAt, setEndAt] = useState(moment().format('YYYY-MM-DD'));
	const [users, setusers] = useState([]);

	const cellCache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 50,
		minWidth: 146,
		fixedHeight: false,
	});

	const customRestStyle = {
		borderRadius: 8,
		backgroundColor: 'white',
		border: '1px solid #e6e7e7',
		resize: 'none',
	};

	const [selectionRange, setSelectionRange] = useState({
		startDate: moment().startOf('isoWeek').valueOf(),
		endDate: moment().endOf('isoWeek').valueOf(),
		key: 'selection',
	});

	useEffect(() => {
		// listRef.current.forceUpdateGrids();
		// listRef.current.recomputeGridSize();
	}, [validExcel]);

	useEffect(() => {
		if (!props?.isModalOpen) {
			setFiles([]);
			setValidOpen(false);
			setValidExcel([]);
		}
	}, [props?.isModalOpen]);

	useEffect(() => {
		setusers(props.usersOfExcel)
	}, [props.usersOfExcel]);

	// [2024.02.05] 엑셀 유저 목록을 props.usersOfExcel에서 처리하는 걸로 수정해서, refresh() 미사용으로 해당 부분 주석
	// useEffectOnce(() => {
	// 	refresh();
	// }, []);

	// const refresh = async () => {
	// 	const HolyRange = getRange(moment(selectionRange.startDate), moment(selectionRange.endDate), 'd');
	// 	// console.log('HolyRange', HolyRange)
	//
	// 	const response = await GroupService.search({ companyId: company.get.id, isUser: true });
	// 	let newGroups = [];
	// 	let items = [];
	// 	let openGroups = {};
	//
	// 	// 스케줄근로제
	// 	response?.data
	// 		?.filter((g) => g.workSystem.name.indexOf('스케줄') > -1)
	// 		?.map((group) => {
	// 			// console.log('group', group)
	// 			// console.log('paidRestDay', group.paidRestDay)
	// 			const thisUserHoly = [...HolyRange]?.filter((v) => {
	// 				// console.log('EEEEE', v.format('E'));
	// 				return group.paidRestDay.indexOf(parseInt(v.format('E'))) > -1;
	// 			});
	// 			// console.log('thisUserHoly', thisUserHoly)
	// 			newGroups.push(
	// 				Object.assign({}, group, {
	// 					title: group?.name || '',
	// 					// height: 'auto',
	// 					root: true,
	// 					parent: null,
	// 				})
	// 			);
	// 			openGroups[parseInt(group.id)] = true;
	// 			if (group.users) {
	// 				group?.users?.map((user) => {
	// 					newGroups.push({
	// 						...user,
	// 						isRoot: false,
	// 						parent: group.id,
	// 						title: user?.user?.name || '',
	// 						id: user?.userId,
	//
	// 						// height: 'auto',
	// 					});
	// 					// 주휴일 확인 아이템 추가
	// 					thisUserHoly?.map((holy) => {
	// 						// console.log('holy', holy.startOf('d').format('YYYY.MM.DD HH:mm:ss'));
	// 						const isCheckTime = [...items]?.find(
	// 							(v) =>
	// 								v.group === user?.userId &&
	// 								((moment(v['start_time']) >= moment(moment(holy.startOf('d').format('YYYY-MM-DD HH:mm:ss'))) &&
	// 									moment(v['start_time']) <= moment(moment(holy.startOf('d').format('YYYY-MM-DD HH:mm:ss')))) ||
	// 									(moment(v['end_time']) >= moment(moment(holy.endOf('d').format('YYYY-MM-DD HH:mm:ss'))) && moment(v['end_time']) <= moment(moment(holy.endOf('d').format('YYYY-MM-DD HH:mm:ss')))))
	// 						);
	// 						// console.log('>>>', user?.userId, isCheckTime);
	// 					});
	// 				});
	// 			}
	// 		});
	//
	// 	setusers(newGroups.slice(1));
	// };

	function getRange(startDate, endDate, type) {
		let fromDate = moment(startDate);
		let toDate = moment(endDate);
		let diff = toDate.diff(fromDate, type);
		let range = [];
		for (let i = 0; i <= diff; i++) {
			range.push(moment(startDate).add(i, type));
		}
		return range;
	}

	const checkValid = (str) => {
		return str.charAt(0) == '#' && str.charAt(str.length - 1) == '#' ? str.substring(1, str.length - 1) : str;
	};

	const getExcelRead = (data) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = xlsx.readFile(reader.result);
				const sheetName = workbook.SheetNames[0];
				const sheetCellData = workbook.Sheets[workbook.SheetNames[0]];

				const range = xlsx.utils.decode_range(sheetCellData['!ref']);
				// console.log('range', range);
				const startRow = 2; // 2번째 행부터 시작
				const endRow = range.e.r + 1; // 마지막 행
				const startCol = 0; // A열부터
				const endCol = range.e.c; // 마지막 열

				const dynamicRange = `${xlsx.utils.encode_col(startCol)}${startRow}:${xlsx.utils.encode_col(endCol)}${endRow}`;

				const sheetData = xlsx.utils.sheet_to_json(sheetCellData, {
					range: dynamicRange,
					header: 0,
					defval: '',
				});

				const cleanedSheet = sheetData.map(item => {
					for (const key in item) {
							if (item.hasOwnProperty(key) && key?.includes('EMPTY')) {
									delete item[key];
							}
					}
					return item;
				});

				const updatedData = cleanedSheet.map((entry) => {
					const updatedEntry = { ...entry };
			
			
					Object.keys(updatedEntry).forEach((key) => {
						
							if (key !== '이름' && key !== '사번' && key) {
									let formattedDate = null;
									let orignalData = updatedEntry[key];

									// 숫자 값
									if (!isNaN(key)) {
											let excelDate = key;
											if (Number.isInteger(excelDate)) {
													const jsDate = new Date((excelDate - 25569) * 86400 * 1000); // 변환
													formattedDate = moment(jsDate).format('YYYY-MM-DD');
											}
									} else {
											const dateFormats = ['MM/DD/YY', 'MM/DD/YYYY', 'YY/MM/DD', 'YYYY/MM/DD', 'M/D/YY','YYYY-MM-DD'];
											for (let i = 0; i < dateFormats.length; i++) {
													const parsedDate = moment(key.trim(), dateFormats[i], true);
													if (parsedDate.isValid()) {
														formattedDate = parsedDate.format('YYYY-MM-DD');
														break;
													}
											}
									}
			
									if (formattedDate) {										
										 updatedEntry[formattedDate] = orignalData
										 if(formattedDate !== key){
											delete(updatedEntry[key]);
										 }
									}
									 else {
											reject(t('엑셀의 날짜 형식이 맞지 않습니다.') + key);
									}
							}
					});
			
					return updatedEntry;
			});

				// const hasInvalidDateFormat = updatedData.some((entry) => {
				// 	const keysWithoutNameAndId = Object.keys(entry).filter((key) => key !== '이름' && key !== '사번');
				// 	console.log('keysWithoutNameAndId', keysWithoutNameAndId)
				// 	return keysWithoutNameAndId.some((key) => moment(key, 'YYYY-MM-DD', true).isValid());
				// });

				if (!sheetCellData?.A2?.v || sheetCellData.A2.v !== '이름' || !sheetCellData?.B2?.v || sheetCellData.B2.v !== '사번') {
					reject(t('엑셀의 형식이 맞지 않아 파일을 불러올 수 없습니다.'));
				} else if (data.length <= 0) {
					reject(t('파일을 등록해 주시기 바랍니다.'));
				// } else if (hasInvalidDateFormat == false) {
					// reject(t('엑셀의 날짜 형식이 맞지 않습니다.'));
				} else {
					

					resolve(updatedData);
				}
			};

			reader.readAsArrayBuffer(data);
		});
	};

	const excelFormDownload = () => {
		const currentDate = new Date();

		const monday = new Date(currentDate);
		monday.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

		let dyDay = [];
		let cellTxt = ['이름', '사번'];

		for (let i = 0; i < 7; i++) {
			const day = new Date(monday);
			day.setDate(monday.getDate() + i);
			let monthText = day.getMonth() + 1 >= 10 ? day.getMonth() + 1 : `0${day.getMonth() + 1}`
			let dayText =  day.getDate() >= 10 ? day.getDate(): `0${day.getDate()}`
			const formattedDate = `${day.getFullYear()}-${monthText}-${dayText}`;
			dyDay.push(formattedDate);
		}

		cellTxt.push(...dyDay);

		const data = [['사원정보', '', '근무코드 입력', '', '', '', '', '', ''], cellTxt];

		users.forEach((employee, index) => {
			const employeeRow = [employee.title, employee.companyNumber];
			data.push(employeeRow);
		});

		const wb = xlsx.utils.book_new();

		const ws = xlsx.utils.aoa_to_sheet(data);

		// 셀 병합 설정
		ws['!merges'] = [
			{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // A1, A2 병합 (사원정보)
			//{ s: { r: 0, c: 2 }, e: { r: 0, c: 8 } }, // C1 ~ I1 병합 (근무코드 입력)
			// { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } }, // 사번
			// { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },

			// { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } }, //날짜
			// { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
			// { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
			// { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
			// { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
			// { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },
			// { s: { r: 1, c: 8 }, e: { r: 2, c: 8 } },
		];

		// 스타일 정보 생성
		const grayFill = { pattern: 'solid', fgColor: { rgb: '808080' } }; // 회색 배경
		const purpleFill = { pattern: 'solid', fgColor: { rgb: '800080' } }; // 보라색 배경

		// 스타일 정보를 셀에 적용
		ws['A1'].s = grayFill; // A1 셀에 회색 배경 적용
		ws['C1'].s = purpleFill; // C1 셀에 보라색 배경 적용

		xlsx.utils.book_append_sheet(wb, ws, '사원정보');

		const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });

		const blob = new Blob([new Uint8Array(excelBuffer)], { type: 'application/octet-stream' });

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = '근무계획 sample.xlsx';

		a.click();

		window.URL.revokeObjectURL(url);
	};

	const TDCell = ({ cellData, columnData, columnIndex, dataKey, isScrolling, parent, rowData, rowIndex }) => {
		let data = null;
		let classs = '';

		switch (dataKey) {
			case '이름':
				data = (
					<div>
						<Input
							size='sm'
							className={rowData?.이름?.length <= 0 ? 'border-danger' : ''}
							value={rowData?.이름}
							onChange={(e) => {
								let edit = [...validExcel];
								edit[rowIndex - 1]['이름'] = e.target.value;
								//props.setData(edit);
							}}
						/>
					</div>
				);
				break;
			case '사번':
				data = (
					<div>
						<Input
							size='sm'
							className={rowData?.사번?.length <= 0 ? 'border-danger' : ''}
							value={rowData?.사번}
							// onChange={(e) => {
							// 	let edit = [...validExcel];
							// 	edit[rowIndex - 1]['사번'] = e.target.value;
							// 	//props.setData(edit);
							// }}
						/>
					</div>
				);
				break;
			default:
				data = (
					<div>
						<Input
							size='sm'
							//className={rowData[dataKey].length <= 0 ? 'border-danger' : ''}
							value={rowData[dataKey]}
							onChange={(e) => {
								const edit = [...validExcel];
								edit[rowIndex - 1][dataKey] = e.target.value;
								// props.setData(edit);
							}}
						/>
					</div>
				);
				break;
		}
		return data;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const cellRenderer = ({ columnIndex, key, rowIndex, style, parent }) => {
		let styles = { ...style };
		let colData = Object.keys(validExcel[0]);

		return (
			<>
				{rowIndex === 0 ? (
					<CellMeasurer key={`row-${rowIndex}-${columnIndex}`} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
						<div style={styles} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{colData[columnIndex]}
						</div>
					</CellMeasurer>
				) : (
					<CellMeasurer key={`row-${rowIndex}-${columnIndex}`} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
						<div
							style={styles}
							className={`p-2 text-nowrap text-center border-bottom border-end`}>
							{TDCell({
								cellData: validExcel[rowIndex - 1][colData[columnIndex]],
								columnData: colData[rowIndex - 1],
								columnIndex,
								dataKey: colData[columnIndex],
								parent,
								rowData: validExcel[rowIndex - 1],
								rowIndex,
							})}
						</div>
					</CellMeasurer>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	return (
		<Modal size='xl' isStaticBackdrop={true} isOpen={props?.isModalOpen} setIsOpen={props?.setIsModalOpen} isScrollable isCentered>
			<ModalHeader setIsOpen={props?.setIsModalOpen}>
				<ModalTitle>{t('근무계획 업로드')}</ModalTitle>
			</ModalHeader>
			<ModalBody className='h-100'>
				<ButtonGroup>
					<Button
						type={'button'}
						//isOutline
						isActive={false}
						isDisable={true}>
						{`${t('예시파일')}`}
					</Button>

					<Button
						type={'button'}
						size={'sm'}
						onClick={async () => {
							excelFormDownload();
						}}>
						<span className='fs-5 text-info fw-bold text-decoration-underline'>{`xlsx ${t('다운로드')}`}</span>
						<Icon icon='FileDownload' size='lg' color='info' className='ms-3' />
					</Button>
				</ButtonGroup>

				<Alert color='light'>
					<div className='d-flex align-items-center'>
						<Icon icon='InfoOutline' className='ms-1' />
						<div className='row m-2'>{'업로드 유의사항'}</div>
					</div>
					<div>
						<div className='row m-2'>
							<li>{'xls, xlsx 파일만 등록 가능합니다.'}</li>
						</div>
						{/* <div className='row m-2'>
							<li>{'근태 CC코드는 셀별 하나씩만 입력이 가능합니다.'}</li>
						</div>
						<div className='row m-2'>
							<li>{'파견 근태코드 (CC2, CC3) 입력 시 셀을 추가하여 입력해주세요.'}</li>
						</div>
						<div className='row m-2'>
							<li>{'휴가 시간은 숫자만 입력 가능합니다.'}</li>
						</div> */}
					</div>
				</Alert>

				<Card shadow={'none'} className='mb-0'>
					<CardBody>
						{/* <div className='mt-2'>
							<div className='row my-4'>
								<Label className='form-label col-form-label col-sm-2 ps-4 fw-bold text-dark'>{t('기준 날짜 선택')}</Label>
								<div className='col-sm-5'>
									<InputGroup>
										<Input
											id='startAt'
											type='date'
											style={customRestStyle}
											value={startAt}
											onChange={(e) => {
												setStartAt(e.target.value);
											}}
										/>
										<InputGroupText>{t('-')}</InputGroupText>
										<Input
											id='endAt'
											type='date'
											style={customRestStyle}
											value={endAt}
											onChange={(e) => {
												setEndAt(e.target.value);
											}}
										/>
									</InputGroup>
								</div>
							</div>
						</div> */}

						<div className='mt-2'>
							<InputGroup>
								<Input
									className='form-control '
									id='formFileSm'
									type={'file'}
									accept={'.xlsx,.xls'}
									onChange={(e) => {
										setFiles(Array.from(e.target.files));
										var files = e.target.files; //input file 객체를 가져온다.
										var i, f;
										for (i = 0; i != files.length; ++i) {
											f = files[i];
											var reader = new FileReader(); //FileReader를 생성한다.
											//성공적으로 읽기 동작이 완료된 경우 실행되는 이벤트 핸들러를 설정한다.
											reader.onload = function (e) {
												getExcelRead(f)
													.then((extractedData) => {
														setValidExcel(extractedData);
														setValidOpen(true);
													})
													.catch((error) => {
														console.error(error);
														showNotification(t('근무계획'), error, 'danger');
														setValidOpen(false);
														setValidExcel([]);
													});
											};
											//파일객체를 읽는다. 완료되면 원시 이진 데이터가 문자열로 포함됨.

											reader.readAsBinaryString(f);
										} //end. for
									}}
								/>
								<Button
									type={'button'}
									color={'success'}
									//icon='FileUpload'
									isDisable={true}>
									{t('파일 검사')}
									<Icon icon='FileDownload' size='lg' color='light' className='ms-3' />
								</Button>
							</InputGroup>
						</div>
					</CardBody>
				</Card>

				{validOpen === true && validExcel.length > 0 ? (
					<div className='mt-5 w-100 h-100' style={{ minHeight: 200 }}>
						<WindowScroller>
							{({ scrollTop, isScrolling, onChildScroll }) => {
								return (
									<AutoSizer
										onResize={() => {
											listRef.current.recomputeGridSize();
										}}>
										{({ width, height }) => (
											<div className='h-auto'>
												<ColumnSizer columnCount={Object.keys(validExcel[0]).length} width={width}>
													{({ adjustedWidth, getColumnWidth, registerChild }) => (
														<MultiGrid
															ref={listRef}
															cellRenderer={cellRenderer}
															classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
															classNameTopRightGrid='border-light border-bottom fw-bold'
															classNameBottomLeftGrid='border-light border-end'
															fixedColumnCount={1}
															fixedRowCount={1}
															height={height}
															width={adjustedWidth}
															overscanRowCount={5}
															overscanColumnCount={5}
															columnCount={Object.keys(validExcel[0]).length}
															columnWidth={cellCache.columnWidth}
															rowCount={validExcel.length + 1}
															rowHeight={cellCache.rowHeight}
														/>
													)}
												</ColumnSizer>
											</div>
										)}
									</AutoSizer>
								);
							}}
						</WindowScroller>
					</div>
				) : (
					<></>
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					type={'button'}
					icon='FileUpload'
					color={'success'}
					onClick={async () => {
						props?.setExcelltems(validExcel);
						// console.log('validExcel', validExcel);
						props?.setIsModalOpen(false);
					}}>
					{t('업로드')}
				</Button>
			</ModalFooter>
		</Modal>
	);
});

export default WorkScheduleExcelModal;
