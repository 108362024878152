import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import UserDashboard from './../component/UserDashboard';
import { observer } from 'mobx-react';

const AdminDashboardPage = observer(() => {
    return (
        <PageWrapper>
            <UserDashboard />
        </PageWrapper>
    );
});

export default AdminDashboardPage;
