import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Page from '../../../../layout/Page/Page';
import { observer } from 'mobx-react';
import MySimpleProfileNew from '../../../dashboard/component/MySimpleProfileNew';
import UserWeeklyAttendHistoryCard from './UserWeeklyAttendHistoryCard';
import NoticeBoardNew from '../../../dashboard/component/NoticeBoardNew';
import UserApprovalNeedCard from './UserApprovalNeedCard';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { useTranslation } from 'react-i18next';
import UserRestListCard from './UserRestListCard';
import UserWorkListCard from './UserWorkListCard';
import { useMst } from '../../../../models';
import deadlineService from '../../../../services/DeadlineService';
import DeadlineAlertModal from '../../../dashboard/component/DeadlineAlertModal';

//모달때문에 추가된 부분 // 지우기
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import ThemeContext from '../../../../contexts/themeContext';

// 20240123 퍼블 추가
import UserWorkSummaryCard from './UserWorkSummaryCard';
import UserJobApplyCard from './UserJobApplyCard';

const UserDashboard = observer(() => {

    const { t } = useTranslation(['translation', 'menu']);
    const { mobileDesign } = useContext(ThemeContext);
    const { user, company } = useMst();
    const [deadlineAlertModalOpen, setDeadlineAlertModalOpen] = useState(false);
    const [deadlines, setDeadlines] = useState([]);
    const [AlternativeHolidayAlarm, setAlternativeHolidayAlarm] = useState(false);

    useEffect(() => {
        deadlineService
            .listForNotice({
                userId: user.me.id,
                companyId: company.get.id,
            })
            .then((res) => {
                console.log('res::', res);
                if (res?.data?.length > 0) {
                    setDeadlines(res.data);
                    setDeadlineAlertModalOpen(true);
                }
            })
            .catch((err) => {
                console.log('err::', err);
            });
    }, [company.get.id, user.me.id]);

    const deadline = useMemo(() => {
        if (deadlines?.length === 0) return {};
        else if (deadlines?.length === 1) return deadlines[0];
        else {
            // 마감기한이 가장 임박한 deadline 으로 보여준다
            return deadlines.reduce((urgentDeadline, current) => (current.completeDate < urgentDeadline.completeDate ? current : urgentDeadline), [deadlines[0]])[0];
        }
    }, [deadlines]);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Breadcrumb list={null} tag={'nav'}>
                        <BreadcrumbItem tag='nav' to={demoPages.sales.subMenu.dashboard.path}>
                            {t(demoPages.sales.subMenu.dashboard.text)}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </SubHeaderLeft>
            </SubHeader>

            <Page container='fluid' style={{backgroundColor: '#FAFBFF'}}>
                <div className='row'>

                    {!mobileDesign &&
                        <>
                            <div className='col-xl-3 col-xxl-3'>
                                <UserJobApplyCard />
                            </div>
                            
                            <div className='col-xl-6 col-xxl-7'>
                                <UserWeeklyAttendHistoryCard />
                            </div>

                            <div className='col-xl-3 col-xxl-2'>
                                <MySimpleProfileNew />
                            </div>

                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserRestListCard type='REST' />
                            </div>

                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserApprovalNeedCard />
                            </div>

                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <NoticeBoardNew />
                            </div>
                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserWorkListCard type='WORK' />
                            </div>

                        </>
                    }
                    {/* 권한 작업 중이므로 잠시 제외 */}
                    {/* <div className='col-xl-3'>
						<AttendMyTeam />
					</div> */}


                    {mobileDesign &&
                        <>
                            <div className='col-12 col-xl-6 col-xxl-4 pt-3'>
                                <UserWorkSummaryCard />
                            </div>
                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserJobApplyCard />
                            </div>
                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserRestListCard type='REST' />
                            </div>
                            <div className='col-12 col-xl-6 col-xxl-4'>
                                <UserApprovalNeedCard />
                            </div>
                            <div className='col-xl-12 col-xxl-6'>
                                <UserWeeklyAttendHistoryCard />
                            </div>

                            <div className='col-xl-6 col-xxl-3'>
                                <MySimpleProfileNew />
                            </div>
                            <div className='col-xl-6 col-xxl-3'>
                                <NoticeBoardNew />
                            </div>
                        </>
                    }

                </div>

                <Modal size='sm' isStaticBackdrop={true} isOpen={AlternativeHolidayAlarm} setIsOpen={setAlternativeHolidayAlarm} isScrollable isCentered>
                    <ModalBody className='user-modal-body'>
                        <div className='cancel-confirm-modal-margin'>
                            <div className='justify-content-center d-flex'>
                                <Icon icon='SyncAlt' className='mb-5' size={'3x'} style={{ color: '#A2A1EB' }} />
                            </div>
                            <div className='fs-5 mb-3 d-flex justify-content-center '>
                                <span className='cancel-confirm-modal-text1'>{t('일정에 변동이 있어요')}</span>
                            </div>
                            <div className='d-flex justify-content-center '>
                                <div className='cancel-confirm-modal-outer'>
                                    <span className='cancel-confirm-modal-text2'>{t('휴일이 아래와 같이 변경되었어요.')}</span>
                                </div>
                            </div>

                            <div className='fs-5 mb-3 d-flex justify-content-center ' style={{ display: 'flex', justifyContent: 'space-between', border: '2px solid #D9D9D9', borderRadius: '6px 6px 0px 0px', padding: '10px' }}>
                                <div >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1, textAlign: 'center', justifyContent: 'center' }}>before</div>
                                        <div style={{ flex: 1, textAlign: 'center', justifyContent: 'center' }}>After</div>
                                    </div>
                                </div>
                            </div>

                            <div className='cancel-confirm-modal-outer'>
								<span className='cancel-confirm-modal-button-margin'>
									<Button
                                        className='mt-3'
                                        style={{
                                            width: 150,
                                        }}
                                        type={'button'}
                                        rounded={1}
                                        color={'info'}
                                        onClick={() => {
                                            setAlternativeHolidayAlarm(false);
                                        }}>
										<span className='text-white'>{t('근무 확인')}</span>
									</Button>
								</span>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* 근태마감 모달 */}
                <DeadlineAlertModal isOpen={deadlineAlertModalOpen} setIsOpen={setDeadlineAlertModalOpen} deadline={deadline} />
            </Page>
        </>
    );
});

export default UserDashboard;
