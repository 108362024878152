import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import Card, { CardLabel, CardBody, CardTitle, CardHeader, CardActions } from '../../../../components/bootstrap/Card';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../../models';
import CompanyService from '../../../../services/CompanyService';
import moment from 'moment';
import useRequest from '../../../../hooks/useRequest';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import { useNavigate } from 'react-router-dom';
import { usersMenu, eapproval } from '../../../../menu';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Badge from '../../../../components/bootstrap/Badge';
import showNotification from '../../../../components/extras/showNotification';
import ThemeContext from '../../../../contexts/themeContext';

const UserRestListCard = ({ type = 'REST' }) => {
	const typeName = type === 'REST' ? '휴가' : '근무';
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef();
	const navigate = useNavigate();
	const { company, user } = useMst();
	const { responseData: originData, loading, request } = useRequest([]);
	const [selectedDate, setSelectedDate] = useState({
		startDate: moment().startOf('year').format('YYYY-MM-DD'),
		endDate: moment().endOf('year').format('YYYY-MM-DD'),
	});
	const [restTypeInfo, setRestTypeInfo] = useState({});
	const [userInfo, setUserInfo] = useState([]);

	const { mobileDesign } = useContext(ThemeContext);

	const remainingRests = useCallback((a) => {		
		let originalRest = parseFloat(a || 0);
		// console.log('originalRest', originalRest);

		let result = 0;
		let day = 0;
		let hrs = 0;

		result = originalRest < 0 ? Math.abs(originalRest) : originalRest;
		day = Math.floor(result);

		if (result < 1) {
			hrs = result / 0.125;
		} else {
			hrs = (result % Math.floor(result)) / 0.125;
		}
		return { day: day, hrs: hrs };
	}, []);

	const totalUsedRest = remainingRests((restTypeInfo?.susMonthRest || 0) + (restTypeInfo?.susRest || 0) + (restTypeInfo?.susLeftRest || 0) + (restTypeInfo?.susLoanRest || 0)); //총 success
	const totalRequestsRest = remainingRests((restTypeInfo?.reqMonthRest || 0) + (restTypeInfo?.reqRest || 0) + (restTypeInfo?.reqLeftRest || 0) + (restTypeInfo?.reqLoanRest || 0)); // 총 request
	const totalUseReqRest = remainingRests(restTypeInfo?.userest || 0) // 총 sus + req
	const totalRemainRest = remainingRests(restTypeInfo?.rest || 0) // 총 잔여
	const totalCountsRest = remainingRests(restTypeInfo?.totalrest || 0); // 총 연차 


	const restColumnList = useMemo(
		() => [{ name: `${typeName} 유형` }, { name: '시작일' }, { name: '종료일' }, { name: '결재 상태' }],
		[typeName]
	);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	const setRestInfo = async () => {
		await CompanyService.restdayInfo({ companyId: company.id, userId: user.id, clear: true }).then(async (res) => {
			// console.log('restInfo', res?.data)
			setRestTypeInfo(res?.data);
			if(res?.data)
				await company.setRestInfo(res.data);
			// setRestUse(res?.data?.restHistory);
		});

		// await CompanyService.userListOne(company.get.id, user.me.id, moment().format('YYYY')).then((user) => {
		// 	setUserInfo(user?.data?.rows[0] || []);
		// 	// console.log('userInfo', user?.data?.rows[0]);
		// });
	}

	useEffect(()=> {
		setRestInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		request(
			CompanyService.getRestOrWorkStatus,
			[
				{
					companyId: company.id,
					userId: user.id,
					type: type,
					startDate: selectedDate.startDate,
					endDate: selectedDate.endDate,
				},
			],
			null,
			(error) => {
				showNotification(t('휴가 현황'), t('휴가 현황 현황을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
			},
			{
				key: 'startDate',
				type: 'date',
				direction: 'descending',
			}
		);
	}, [user, company, selectedDate, typeName, type, request, t]);

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const dataOfRowIndex = originData[rowIndex - 1];

			switch (columnIndex) {
				case 0:
					data = dataOfRowIndex?.restday?.name
						? dataOfRowIndex?.restday?.name === '근무' && dataOfRowIndex?.type === 'RECORD'
							? '근무 기록'
							: dataOfRowIndex?.restday?.name
						: '';
					break;
				case 1:
					data = dataOfRowIndex?.startDate ? moment(dataOfRowIndex?.startDate).format('YYYY-MM-DD') : '-';
					break;
				case 2:
					data = dataOfRowIndex?.endDate ? moment(dataOfRowIndex?.endDate).format('YYYY-MM-DD') : '-';
					break;
				case 3:
					const reqState = dataOfRowIndex?.state;
					data =
						reqState === 'REQUEST' ? (
							<div className='text-primary'>{t('진행중')}</div>
						) : reqState === 'SUCCESS' ? (
							<div className='text-success'>{t('완료')}</div>
						) : reqState === 'REJECT' ? (
							<div className='text-danger'>{t('반려')}</div>
						) : (
							'-'
						);
					break;
			}

			return data;
		},
		[originData, t]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(restColumnList[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer justify-content-center ${
							rowIndex % 2 === 0 ? 'bg-l50-light' : ''
						}`}
						onClick={() => {
							// navigate(usersMenu.approvalDocument.path + '/' + originData[rowIndex - 1]?.id)
							navigate(eapproval.approvalReportAll.path, { state: { id: originData[rowIndex - 1]?.id } });
						}}
						>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, originData, restColumnList, navigate, renderText, t]
	);



	return (
		<Card className='custom-box-shadow rounded-2' stretch>
			<CardHeader className={`rounded-2 ${mobileDesign && 'd-flex flex-row pb-0'}`}>
				<CardLabel>
					<CardTitle className='cursor-pointer' onClick={() => navigate(usersMenu.VacationManagement.path)}>
						{t('휴가 현황')}
					</CardTitle>
				</CardLabel>
				<CardActions>
					{
						mobileDesign === false && <span className='text-wrap text-dark small'>{`${moment(selectedDate.startDate).format('YYYY-MM-DD(ddd)')}~${moment(
							selectedDate.endDate
						).format('YYYY-MM-DD(ddd)')}`}</span>
					}
					<Badge color='light' className='text-wrap'>
						<span className='text-dark'>{t('총 상신 수')} : </span>
						<span className='fw-bold text-info fs-6'>{originData?.length || 0}</span>
					</Badge>
				</CardActions>
			</CardHeader>
			<CardBody
				ref={ref}
				className={`dashboard-height ${mobileDesign && 'pt-0'}`}
				style={mobileDesign ? {height: '310px'} : {}}
				onMouseOver={(e) => {
					if (ref?.current?.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					if(ref?.current) {
						ref.current.scroll = true;
						document.body.style.overflow = 'auto';
						document.body.style.removeProperty('padding-right');
					}
				}}>
				{loading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) : originData.length <= 0 ? (
					<div className='pb-5 lead h-100 d-flex flex-column justify-content-center align-itmes-center text-center'>
						<span>{t(`휴가 내역이 비어있습니다.`)}</span>
					</div>
				) : (
					<>
						<div>
							<div className='py-4 bg-l10-info rounded-1 d-flex flex-row justify-content-around'>
								<div className='d-flex flex-column justify-content-around flex-grow-1'>
									<div className='d-flex justify-content-center'>
										<span>{t('잔여')}</span>
									</div>
									<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
										<span className='text-info me-1'>
											{totalRemainRest?.day}{'일'}&nbsp;
											{totalRemainRest?.hrs ? totalRemainRest?.hrs + 'H' : ''} 	
										</span>
									</div>
								</div>
								<div className='d-flex flex-column justify-content-around flex-grow-1'
									style={{
										borderLeft: '1px solid #D9D9D9',
										borderRight: '1px solid #D9D9D9',
									}}>
									<div className='d-flex justify-content-center'>
										<span>{t('사용')}</span>
									</div>
									<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
										<span className='text-dark me-1'>
											{totalUsedRest?.day}{'일'}&nbsp;
											{totalUsedRest?.hrs ? totalUsedRest?.hrs + 'H' : ''}
										</span>
									</div>
								</div>
								<div className='d-flex flex-column justify-content-around flex-grow-1'>
									<div className='d-flex justify-content-center'>
										<span>{t('계획')}</span>
									</div>
									<div className='d-flex justify-content-center mt-3 fw-bold fs-5 text-muted'>
										<span className='text-dark me-1'>
											{totalRequestsRest?.day}{'일'}&nbsp;
											{totalRequestsRest?.hrs ? totalRequestsRest?.hrs + 'H' : ''}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							className='mt-2 overflow-scroll'
							style={mobileDesign ? {height: '190px'} : {height: 'calc(40vh - 135px)'}}
						>
							<table className='w-100'>
								{
									originData.map((v, i) => {
										return (
											<tr 
												key={i}
												className={`border-bottom border-light`}
												style={{cursor:'pointer'}}
												onClick={()=> {
													navigate(usersMenu.approvalDocument.path, {
														state: {
															id: originData[i]?.id,
														},
													});
												}}
											>
												<td className={`p-3 ${v?.state === 'CANCEL' && 'text-decoration-line-through text-muted'}`}>
													<p className='mb-0 fw-bold'>
														<span className='me-1'>{v?.restday?.name}</span>
													</p>
												</td>
												<td className={`p-3 ${v?.state === 'CANCEL' && 'text-decoration-line-through text-muted'}`}>
												{
													mobileDesign ? 
													<p className='mb-0 text-muted'>
														<span className='ms-1'>{moment(v?.startDate).format('MM.DD')} ~ {moment(v?.endDate).format('MM.DD')}</span>
													</p> : 
													<p className='mb-0 text-muted'>
														<Icon icon='CalendarToday' className='me-2' />
														{/* <span>{v.startDate}</span> */}
														{/* <span className='mx-1'>~</span> */}
														{/* <span>{v.endDate}</span> */}
														<span className='ms-1'>{moment(v?.startDate).format('YY.MM.DD')} ~ {moment(v?.endDate).format('YY.MM.DD')} 
														{v?.duration.length > 1 || v?.deduction === 1 || v?.deduction === 0
														? ` ${v?.duration.length}일`
														: ` ${v?.deduction / 0.125}시간`}
														</span>
													</p>
												}
												</td>
												<td className='p-3'>
													{
														v.state === 'REQUEST' ? 
														<span className='text-muted fs-6'>{t('승인 대기')}</span> : 
														<span className={`fs-6 ${v?.state === 'CANCEL' ? 'text-muted' : 'text-info'}`}>{t(v?.state === 'CANCEL' ? '취소' :'완료')}</span>	
													}
												</td>
											</tr>
										)
									})
								}
							</table>
						</div>
					</>
				)}
			</CardBody>
		</Card>
	);
};
export default React.memo(UserRestListCard);
