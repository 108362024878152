import React, { forwardRef, useContext } from 'react';
import {t} from "i18next";
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../../components/bootstrap/Modal";
import Button from "../../../../../../components/bootstrap/Button";
import Card, { CardBody } from '../../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../../../components/bootstrap/forms/Label';
import Select from '../../../../../../components/bootstrap/forms/Select';
import Icon from '../../../../../../components/icon/Icon';
import Input from '../../../../../../components/bootstrap/forms/Input';
import ThemeContext from '../../../../../../contexts/themeContext';

const WorkCalenderFlextimeApplyModal = forwardRef((props, ref) => {
  const { mobileDesign } = useContext(ThemeContext);
  const newStyle = (color) => {
		return {
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			borderWidth: 1,
			borderColor: '#D9D9D9',
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginRight: 20,
			resize: 'none',
			color: color ? '#808080' : 'black'
		}
	};

  return (
    <>
      <Modal
        isCentered={true}
        size='lg'
        isOpen={props?.isOpen}
        setIsOpen={props?.setIsOpen}
        titleId="example-title"
        isScrollable
        isStaticBackdrop
        fullScreen={'sm'}
      >
        <ModalHeader className={!mobileDesign && 'user-modal-header'}>
          <ModalTitle className='fw-bold'>
            {t('시차출퇴근 신청')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody className='pt-0'>
          <Card className='custom-box-shadow rounded-2' style={{marginBottom : '24px'}}>
            <CardBody>
              <p className='mb-0'>{t('이번주 근무시간')}</p>
              <p className='text-black fw-bold mb-0 mt-2'>
                <span>10</span>{t('시간')}
              </p>
            </CardBody>
          </Card>
          <div>
            {/* 날짜선택 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('날짜선택')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Input
                  className='w-100'
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    fontSize: 11,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
                  value={t('2024.01.16(화) - 2024.01.16(화))')}
                />
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('날짜 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 시간설정 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('시간설정')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <div>
                  <div className='d-flex'>
                    <p className='col-2 mt-1 mb-0'>{t('오전')}</p>
                    <div className='col-10 d-flex flex-wrap gap-2'>
                      {
                        ['7:00','7:30','8:00','8:30','9:00','9:30','10:00','10:30','11:00']
                        .map((v,i) => {
                          return (
                            <Button 
                              className='p-1'
                              style={{
                                width: 'calc(100% / 3 - 5px)',
                                borderRadius: 8,
                                color: (i === 0) ? 'white' : '#A4A6A9',
																backgroundColor: (i === 0) ? '#556EE6' : '#E8E8E8',
                              }} 
                              key={i}
                            >
                              {v}
                            </Button>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <p className='col-2 mt-1 mb-0'>{t('오후')}</p>
                    <div className='col-10 d-flex flex-wrap gap-2'>
                      {
                        ['13:00','16:00','17:00','18:00','19:00']
                        .map((v,i) => {
                          return (
                            <Button 
                              className='p-1'
                              style={{
                                width: 'calc(100% / 3 - 5px)',
                                borderRadius: 8,
                                color: (i === 0) ? 'white' : '#A4A6A9',
																backgroundColor: (i === 0) ? '#556EE6' : '#E8E8E8',
                              }} 
                              key={i}
                            >
                              {v}
                            </Button>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-3'>
                  <Button
                    style={{
                      width: 'calc(50% - 5px)',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      padding: '10px',
                      color: '#808080'
                    }}
                  >
                    {t('오전 00:00')}
                  </Button>
                  <Button
                    style={{
                      width: 'calc(50% - 5px)',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: '#D9D9D9',
                      padding: '10px',
                      color: '#808080'
                    }}
                  >
                    {t('오후 00:00')}
                  </Button> 
                </div>
                <div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('시간 선택은 필수입니다')}</span>
								</div>
							</div>
            </FormGroup>
            {/* 결제라인 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('결재라인')}</Label>
                <span className='text-danger fs-3'>&nbsp;*</span>
              </div>
              <div className='col-9 text-nowrap text-truncate'>
                <Select
									placeholder={t('결재라인을 선택해주세요')}
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#D9D9D9',
                    padding: '10px',
                    color: '#808080'
                  }}
								/>
								<div className='text-danger mt-1'>
									<Icon icon='InfoOutline' className='me-2' />
									<span>{t('결재라인 선택은 필수입니다')}</span>
								</div>
              </div>
            </FormGroup>
            {/* 참조 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('참조')}</Label>
              </div>
              <div>
								<Button
									type={'button'}
									style={{
										borderRadius: 8,
									}}
									isOutline
									color={'info'}
                >
									<div className='d-flex align-items-center'>
										<Icon icon='Add' size='lg' color='info' />
										<span className='custom-write-button-margin'>{t('추가하기')}</span>
									</div>
								</Button>
							</div>
            </FormGroup>
            {/* 사유 */}
            <FormGroup className='d-flex mt-3'>
              <div className='col-3'>
                <Label className='mt-2 fw-bold text-black'>{t('사유')}</Label>
              </div>
              <div className='col-9'>
								<textarea id='etc' className='form-control' rows='3' style={newStyle()} placeholder={t('사유를 입력해주세요')} />
							</div>
            </FormGroup>
          </div>
        </ModalBody> 
        <ModalFooter className='justify-content-center flex-nowrap'>
          <Button
            color="info"
            isOutline
            className='py-3 w-100'
            onClick={()=>{props?.setIsOpen(false)}}
          >
            <span>{t('닫기')}</span>
          </Button>
          <Button
            color="info"
            onClick={()=>{props?.setIsOpen(false)}}
            className='py-3 w-100'
          >
            <span>{t('신청')}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default WorkCalenderFlextimeApplyModal;
