import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import {startOfISOWeek} from 'date-fns';
import {t} from 'i18next';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import {DateRange} from 'react-date-range';
import tinycolor from 'tinycolor2';
import Button from '../../../../components/bootstrap/Button';
import Card, {CardBody, CardFooter} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../../../../components/bootstrap/Modal';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import {truncateString} from '../../../../lib/Util';
import groupImg from '../../../AuthMenu/Work/User/svg/groupImg.svg';
import Badge from '../../../../components/bootstrap/Badge';
import Select2 from 'react-select';
import * as locales from 'react-date-range/dist/locale';

import UserReqRecordEditModal from '../../../AuthMenu/Work/User/UserReqRecordEditModal';
import CompanyService from '../../../../services/CompanyService';
import showNotification from '../../../../components/extras/showNotification';
import BtnTrashbin from '../../../../assets/img/btns/btn_trashbin.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import {Base64} from 'js-base64';
import {useMst} from '../../../../models';
import ThemeContext from '../../../../contexts/themeContext';
import WorkRecodeCloseModal from '../../../AuthMenu/Company/components/WorkRecodeCloseModal';
import deadlineService from "../../../../services/DeadlineService";

const localizer = momentLocalizer(moment);

// const DragAndDropCalendar = withDragAndDrop(Calendar);
const defaultDate = new Date();

function isInThisWeek(date) {
    // 현재 날짜를 가져옵니다.
    const today = moment();

    // 이번 주의 시작일과 끝일을 계산합니다.
    const startOfWeek = moment().startOf('isoWeek');
    const endOfWeek = moment().endOf('isoWeek');

    // 주어진 날짜가 이번 주에 속하는지 확인합니다.
    return moment(date).isBetween(startOfWeek, endOfWeek, null, '[]');
}

const WorkCalendars = ({
                           events,
                           onDropFromOutside,
                           customOnDragOver,
                           moveEvent,
                           newEvent,
                           isWorkRegModal,
                           setIsWorkRegModal,
                           setSeletedWork,
                           todate,
                           holydays,
                           step,
                           flexibeSelectDate,
                           groups,
                           flexDeatilTime,
                           setFlexDeatilTime,
                           eventItems,
                           setEventItems,
                           flexDeatilArr,
                           setFlexDeatilArr,
                           setMyEvents,
                           reFresh,
                       }) => {

    const { mobileDesign } = useContext(ThemeContext);
    const mainColor = '#5A6CFA';
    const bgColor = tinycolor(mainColor).lighten(30).toHexString();

    const navigate = useNavigate();
    const location = useLocation();
    const popRef = useRef({});
    const clickRef = useRef({});
    const { user, company } = useMst();

    const [flexDetailOpen, setFlexDetailOpen] = useState(false);
    const [closedAttendModalOpen, setClosedAttendModalOpen] = useState(false)

    const [flexDeatilTimeNDate, setFlexDeatilTimeNDate] = useState(flexDeatilTime);
    const [flexError, setFlexError] = useState({ bool: false, text: '' });
    const [flexTimeSetting, setTimeSetting] = useState(0);

    const [flexStartTime, setFlexStartTime] = useState([]);
    const [flexEndTime, setFlexEndTime] = useState([]);
    const [endTimePlus, setEndTimePlus] = useState([]);

    const timeStartSetting = () => {
        let time = [];

        for (let i = 0; i < 48; i++) {
            time.push({value: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HHmm'), label: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm')});
        }
        setFlexStartTime([...time]);
    };

    const timeEndSetting = () => {

        let time = [];

        for (let i = 0; i < 48; i++) {
            time.push({value: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HHmm'), label: moment('00:00', 'HH:mm').clone().add(i * 30, 'minutes').format('HH:mm')});
        }
        setFlexEndTime([...time]);
    };

    useEffect(() => {
        timeStartSetting();
        timeEndSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flexDeatilTimeNDate.startTime]);


    const startDateExists = flexibeSelectDate && flexibeSelectDate.startDate;
    const defaultStartDate = startDateExists ? startOfISOWeek(new Date(flexibeSelectDate.startDate)) : new Date();
    const defaultEndDate = startDateExists ? startOfISOWeek(new Date(flexibeSelectDate.startDate)) : new Date();

    const [tempSelectionRange, setTempSelectionRange] = useState([
        {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            key: 'selection',
        },
    ]);

    const [workRecordModal, setWorkRecordModal] = useState(false);
    const [workRecordInfo, setWorkRecordInfo] = useState([]);
    const [requestRecordEdit, setRequestRecordEdit] = useState(false);

    const convertMinsToHrsMins2 = useCallback((mins) => {
        let h = Math.floor(mins / 3600);
        let m = Math.floor((mins % 3600) / 60);
        let s = Math.floor(mins % 60);
        //let h = Math.floor(mins / (60 * 60));
        //let m = mins % (60);
        // h = h < 10 && h > 0 ? '0' + h : h;
        // m = m < 10 ? '0' + m : m;
        return t(`{{hour}}시간 ${m > 0 ? m + '분' : ''}`, { hour: h, minute: m });
    }, []);

    const disableHoliday = (date) => {
        const dayOfWeek = date.getDay();
        if (!groups?.workDay?.includes(dayOfWeek)) {
            return true;
        }
        const formattedDate = date.toISOString();
        const isDateDisabled = holydays.some((disabledDate) => {
            return formattedDate === disabledDate.date;
        });

        return isDateDisabled;
    };

    useEffect(() => {
        if (flexDetailOpen == false) {
            setFlexError({ bool: false, text: '' });
            setTimeSetting(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flexDetailOpen]);

    useEffect(() => {
        if (flexDeatilTimeNDate?.startTime !== '' && flexDeatilTimeNDate.endTime !== '') {

            const startDate = moment(tempSelectionRange[0].startDate);
            const endDate = moment(tempSelectionRange[0].endDate);
            const startTime = flexDeatilTimeNDate?.startTime;
            const endTime = flexDeatilTimeNDate?.endTime;
            const formattedStartTime = moment(startTime, 'HH:mm');
            const formattedEndTime = moment(endTime, 'HH:mm');

            let endDateChange = startDate.clone();
            if (formattedEndTime.isBefore(formattedStartTime)) {
                endDateChange.add(1, 'days');
            }

            let startInData = moment(`${startDate.format('YYYY-MM-DD')} ${formattedStartTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
            let endInData = moment(`${endDateChange.format('YYYY-MM-DD')} ${formattedEndTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');

            let durationInSeconds = moment.duration(endInData.diff(startInData)).asSeconds();

            let restRealTimeMinutes = durationInSeconds;
            let restTimeMinutes = 0;
            if(!groups.restAuto) {
                if(groups.restTime[moment(startDate).format('E')-1][0][0] === "") {
                    restTimeMinutes =
                        Math.floor(
                            (parseInt(restRealTimeMinutes)) /
                            (270 * 60)
                        ) *
                        (30 * 60);
                } else {
                    // 시간 범위 체크
                    const s2 = moment(formattedStartTime, 'HH:mm');
                    const e2 = moment(formattedEndTime, 'HH:mm');
                    groups.restTime[moment(startDate).format('E')-1].map( times => {
                        console.log(">>> ", times)
                        if(times[0] !== '') {

                            if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[1], 'HH:mm') <= moment(e2)) {
                                console.log('this ALL', moment(times[0], 'HH:mm').format('HH:mm'), moment(times[1], 'HH:mm').format('HH:mm'))
                                restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
                                // console.log("= ", moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second'))
                            } else if(moment(times[0], 'HH:mm') >= moment(s2) && moment(times[0], 'HH:mm') <= moment(e2)) {
                                console.log('this S', moment(times[0], 'HH:mm').format('HH:mm'));
                                restTimeMinutes += moment(e2).diff(moment(times[0], 'HH:mm'), 'second');
                                // console.log("= ", moment(e).diff(moment(times[0], 'HH:mm'), 'second'))
                            } else if(moment(times[1], 'HH:mm') <= moment(e2) && moment(times[1], 'HH:mm') >= moment(s2)) {
                                console.log('this E', moment(times[1], 'HH:mm').format('HH:mm'))
                                restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(s2), 'second');
                                // console.log("= ", moment(times[1], 'HH:mm').diff(moment(s), 'second'))
                            } else {
                                console.log('...')
                                if (
                                    (moment(times[0], 'HH:mm') >= moment(s2) && moment(times[1], 'HH:mm') <= moment(e2)) ||
                                    (moment(times[0], 'HH:mm') >= moment(s2) && moment(times[0], 'HH:mm') <= moment(e2)) ||
                                    (moment(times[1], 'HH:mm') <= moment(e2) && moment(times[1], 'HH:mm') >= moment(s2))
                                ) {
                                    restTimeMinutes += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'second');
                                }else{
                                    restTimeMinutes = 0
                                }
                            }

                        }
                    })
                }
                restTimeMinutes = restTimeMinutes > 0 ? restTimeMinutes : 0;
            }

            let workDefaultTime = 28800;
            if(company.get.employType.includes('촉탁')) {
                // 소정시간 구하기
                if(groups?.workTime[0][1])
                    workDefaultTime = moment.duration(moment(groups?.workTime[0][1], 'HH:mm').diff(moment(groups?.workTime[0][0], 'HH:mm'))).asSeconds();
            }

            const duration = parseFloat((restRealTimeMinutes - restTimeMinutes) / workDefaultTime);

            let minutesExistence  = Math.floor(((restRealTimeMinutes - restTimeMinutes)  % 3600) / 60);

            let workCompare =(restRealTimeMinutes - restTimeMinutes) / 3600;
            setTimeSetting(restRealTimeMinutes - restTimeMinutes)


            if (workCompare > 12) {
                setFlexError({ bool: true, text: 'maxTime' });
            } else if (workCompare < 4) {
                setFlexError({ bool: true, text: 'minTime' });
            } else {
                setFlexError({ bool: false, text: '' });
            }



            if (groups?.coreTime[0][0] !== null && moment(groups?.coreTime[0][0]) < startTime) {
                setFlexError({ bool: true, text: 'startTime' });
            } else if (groups?.coreTime[0][0] !== null && moment(groups?.coreTime[0][1]) < endTime) {
                setFlexError({ bool: true, text: 'endTime' });
            }




            // if (minutesExistence > 0){
            // 	setFlexError({ bool: true, text: 'minute' });
            // }else{
            // 	setFlexError({ bool: false, text: '' });
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flexDeatilTimeNDate.startTime, flexDeatilTimeNDate.endTime]);

    useEffect(() => {
        if (location.state && Object.keys(location.state).length > 0) {
            const { type, id } = location.state;

            let theEvent = null;

            if (type === 'WORK') {
                theEvent = events?.find((i) => i?.row?.id === id);
            } else {
                theEvent = events?.find((i) => i?.id === id);
            }

            if (theEvent) {
                setWorkRecordInfo([theEvent]);
                setWorkRecordModal(true);

                // Clear location state after using it
                history.replaceState({}, document.title, window.location.pathname);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, events]);


    const recordModalOpen = (e) => {
        setWorkRecordModal(true);
        let eventsOnSameDate = [];

        if (e.type == 'WORK' || e.type == 'WORK_SCHDULE') {
            const targetDate = moment(e.start);
            events.forEach((evt) => {
                if (evt.type === 'WORK' || evt.type === 'WORK_SCHDULE') {
                    const currentEventDate = moment(evt.start);
                    if (currentEventDate.isSame(targetDate, 'day')) {
                        eventsOnSameDate.push(evt);
                    }
                }
            });
        }

        if (eventsOnSameDate?.length > 0) {
            setWorkRecordInfo(eventsOnSameDate);
        } else {
            setWorkRecordInfo([e]);
        }

        console.log('eventsOnSameDate : ', eventsOnSameDate);
        console.log('e : ', e);
    };

    const MonthCellHeaderContent = (props) => {
        const { date } = props;
        const dayOfWeek = parseInt(moment(date).format('E'));
        const event = [...events]?.find((v) => moment(v.start).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) || {};
        const holyday = holydays?.find((v) => moment(v.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
        const className = dayOfWeek === 6 || dayOfWeek === 7 || holyday?.name ? 'text-danger' : '';
        // console.log(date, 'event', event);

        return (
            <>
            {
                mobileDesign ? 
                <div className='py-2'>
                    <div className={`d-flex ${mobileDesign ? 'flex-column' : 'flex-row'} justify-content-between pt-2 ${className}`}>
                        <div className={`text-center ${moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'fw-bold text-info' : 'fw-light'} fs-5 text-nowrap flex-grow-1`}>
                            <span className={`${holyday?.name === "휴일대체" && 'text-danger'}`}>
								{props.label}
							</span>
                            
                        </div>
                        <div className={`text-center ${moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'fw-bold text-info' : 'fw-light'} text-nowrap flex-grow-1`}>
                            <span className='ms-1 small text-danger text-truncate fw-light'>{holyday?.name || '휴일대체'}</span>
                        </div>

                        <div className={`d-flex justify-content-center flex-wrap small`}>
                            {event?.title && event?.title !== '탄력근무' && !event?.Reqbool && (event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-danger text-center px-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) - (event?.row?.restOverTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title !== '탄력근무' && !event?.Reqbool && (event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-dark text-center px-1 ms-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) - (event?.row?.restTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title == '탄력근무' && event?.Reqbool == true && (event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-dark text-center px-1 ms-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) - (event?.row?.restTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title == '탄력근무' && event?.Reqbool == true && (event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-danger text-center px-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) - (event?.row?.restOverTime || 0))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                :
                <div className='p-2'>
                    <div className={`d-flex ${mobileDesign ? 'flex-column' : 'flex-row'} justify-content-between px-2 pt-2 ${className}`}>
                        <div className={`text-start ${moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'fw-bold text-info' : 'fw-light'} fs-5 text-nowrap flex-grow-1`}>
							<span className={`${holyday?.name === "휴일대체" && 'text-danger'}`}>
								{props.label}
							</span>
                            <span className='ms-1 small text-danger text-truncate fw-light'>{holyday?.name || ''}</span>
                        </div>

                        <div className={`d-flex flex-column-reverse justify-content-end align-items-end small`}>
                            {event?.title && event?.title !== '탄력근무' && !event?.Reqbool && (event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-danger text-center px-2 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) - (event?.row?.restOverTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title !== '탄력근무' && !event?.Reqbool && (event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-dark text-center px-2 ms-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) - (event?.row?.restTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title == '탄력근무' && event?.Reqbool == true && (event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-dark text-center px-2 ms-1 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingTime || 0) + (event?.row?.nightTime || 0) - (event?.row?.restTime || 0))}
                                </div>
                            )}
                            {event?.title && event?.title == '탄력근무' && event?.Reqbool == true && (event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) > 0 && (
                                <div className={`${event?.title ? 'border bg-white w-auto border-1 border-light rounded-1 text-danger text-center px-2 text-nowrap' : ''}`}>
                                    {convertMinsToHrsMins((event?.row?.workingOverTime || 0) + (event?.row?.nightOverTime || 0) - (event?.row?.restOverTime || 0))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            </>
        );
    };

    const MyDateCellWrapper = ({ children, value }) => {
        const event = [...events].find((v) => moment(v.start).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'));
        const holyday = [...holydays].find((v) => v === moment(value).format('YYYYMMDD'));
        const isAfterDay = moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') ? true : false;
        const isWeek = isInThisWeek(value);
        //console.log('>>>', moment(value).format('YYYY-MM-DD'), isWeek);

        const startDate = moment(flexibeSelectDate?.startDate);
        const range = flexibeSelectDate?.range;

        let endD;

        if (range === '2주') {
            endD = startDate.clone().add(1.9, 'weeks').endOf('isoWeek');
        } else if (range === '3개월') {
            endD = startDate.clone().add(3, 'months').endOf('isoWeek');
        }

        // const isInRange = step === 2 && moment(value).isBetween(startDate, endD, null, '[]');
        const isInRange = step === 2 && true;

        let current = moment(value).format('YYYY-MM-DD');
        let eventStart = moment(event?.start).format('YYYY-MM-DD');
        let eventEnd = moment(event?.end).format('YYYY-MM-DD');

        const flexBool = moment(current).isBetween(eventStart, eventEnd, null, '[]');

        const dayOfWeek = moment(value).day();
        const isWorkDay = groups?.workDay?.includes(dayOfWeek);
        const isDateDisabled = holydays.some((disabledDate) => moment(value).format('YYYY-MM-DD') === moment(disabledDate.date).format('YYYY-MM-DD'));

        let dynamicStyle = {};

        if (step === 2) {
            if (groups?.isHolidayWork) {
                if (isInRange && !flexBool) {
                    dynamicStyle = {
                        backgroundSize: `${mobileDesign ? '20px' : '22%'}`,
                        backgroundImage: `url(${groupImg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: `${mobileDesign ? '50% 60%' : '50%'}`,
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 500,
                        borderStyle: 'dashed',
                        backgroundColor: '#E8ECFF4D',
                    };
                } else {
                    dynamicStyle = {
                        borderStyle: 'dashed',
                        filter: 'blur(5px)',
                    };
                }
            } else {
                if (isInRange && isWorkDay && !isDateDisabled && !flexBool) {
                    dynamicStyle = {
                        backgroundSize: `${mobileDesign ? '20px' : '22%'}`,
                        backgroundImage: `url(${groupImg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: `${mobileDesign ? '50% 60%' : '50%'}`,
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 500,
                        borderStyle: 'dashed',
                        backgroundColor: '#E8ECFF4D',
                    };
                } else {
                    dynamicStyle = {
                        borderStyle: 'dashed',
                        filter: 'blur(5px)',
                    };
                }
            }
        } else {
            dynamicStyle = isAfterDay ? { borderStyle: 'dashed' } : {};
        }

        return (
            <div
                ref={clickRef}
                id={moment(value).format('YYYYMMDD').toString()}
                className={`${isAfterDay ? 'border-1 border-light' : 'bg-l25-light border-0'} w-100 h-auto rounded m-1 ${isWeek ? 'bg-l75-light' : ''}`}
                style={dynamicStyle}
                // onMouseOver={(e) => {
                // 	// e.target.style.border = 'dotted #556EE6';
                // 	// e.target.style.borderRadius = '8px';
                // }}
                onClick={() => {
                    if (isInRange && event === undefined) {
                        if (groups?.isHolidayWork) {
                            setFlexDetailOpen(true);
                        } else {
                            const dayOfWeek = moment(value).day();
                            const isWorkDay = groups?.workDay?.includes(dayOfWeek);
                            const isDateDisabled = holydays.some((disabledDate) => moment(value).format('YYYY-MM-DD') === moment(disabledDate.date).format('YYYY-MM-DD'));
                            if (isWorkDay && !isDateDisabled) {
                                setFlexDetailOpen(true);
                            }
                        }

                        let lastWorkDay = moment(value).endOf('isoWeek');

                        if (!groups?.workDay?.includes(moment(value).day())) {
                            lastWorkDay = moment(value);
                        }

                        while (!groups?.workDay?.includes(lastWorkDay.day()) && groups?.workDay?.includes(moment(value).day())) {
                            lastWorkDay = lastWorkDay.subtract(1, 'day').endOf('day');
                        }
                        setFlexDeatilTimeNDate({ startTime: '', endTime: '', startDate: value, endDate: lastWorkDay.toDate(), btnName: '저장' });
                        setTempSelectionRange([
                            {
                                startDate: new Date(value),
                                endDate: lastWorkDay.toDate(),
                                key: 'selection',
                            },
                        ]);
                    }
                }}
                // onMouseOut={(e) => {
                // 	// if (event) {
                // 	// 	e.target.style.border = '';
                // 	// } else {
                // 	// 	e.target.style.border = 'dotted #D9D9D9';
                // 	// 	e.target.style.borderRadius = '8px';
                // 	// }
                // }}
            >
                {children}
            </div>
        );
    };

    const workReg = () => {
        setIsWorkRegModal(true);
    };

    const agendaDateContent = ({ evnet, day, label }) => {
        // console.log('agendaDateContent event', event, 'day', day, 'label', label);
        // return moment(day).format('YYYY-MM-DD');
        return (
            <>
                <p className='mb-0 py-2'>
                    {moment(day).format('MM.DD(dd)')}
                    {/* 완료 상태 text-info */}
                    <span className='px-2 fw-bold text-info'>| 완료</span>
                    {/* 승인대기 상태 text-black-50 */}
                    {/* <span className='px-2 fw-bold text-black-50'>| 승인대기</span> */}
                    {/* 건수 더 있다면 보여짐*/}
                    <sppn className='fw-bold text-black-50'>외 1건</sppn>
                </p>
            </>
        );
    };

    const agendaTimeContent = ({ event, day, label }) => {
        // console.log('agendaTimeContent event', event, 'day', day, 'label', label);
        // if (event.type == 'WORK' || event.type == 'WORK_SCHDULE') return moment(event?.stime).format('HH:mm') + ' - ' + moment(event?.etime).format('HH:mm');
        // else return '';
        return '';
    };

    const dateContent = (props) => {
        const { date, event, title } = props;

        //console.log('event >>>', event);
        let startDate = '';
        let range = '';
        let endD;
        let endDate = '';
        let isInRange;

        if (step == 2) {
            startDate = moment(flexibeSelectDate?.startDate);
            range = flexibeSelectDate?.range;

            endD;

            if (range === '2주') {
                endD = startDate.clone().add(1.9, 'weeks').endOf('isoWeek');
            }
            endDate = endD;
            isInRange = step === 2 && moment(event?.start).isBetween(startDate, endDate, null, '[]');
        }

        let schdules = event?.type === 'WORK_SCHDULE';
        let rest = event?.type.includes('REST');
        let business = false;
        if(event?.type === 'WORK'){
            if(event?.row?.dates?.history[0]?.attendCategory?.name === '출장') {
                business = true;
            } else {
                business = false;
            }
        }

        return (
            <>
                {
                    mobileDesign && step === 2 ? 
                    <div className='text-muted text-center' style={{fontSize: '11px'}}>
                        <p className='mb-0'>08:00</p>
                        <p className='mb-0'>17:00</p>
                    </div> :
                    <>
                    <div
                    className='d-flex flex-row justify-content-between align-items-center'
                    onClick={() => {
                        setSeletedWork(event);
                        // workReg();
                        if (isInRange && event.title == '탄력근무') {
                            let lastWorkDay = moment(event?.start).endOf('isoWeek');

                            if (!groups?.workDay?.includes(moment(event?.start).day())) {
                                lastWorkDay = moment(event?.start);
                            }

                            while (!groups?.workDay?.includes(lastWorkDay.day()) && groups?.workDay?.includes(moment(event?.start).day())) {
                                lastWorkDay = lastWorkDay.subtract(1, 'day').endOf('day');
                            }

                            setFlexDeatilTimeNDate({
                                startTime: moment(event?.stime).format('HH:mm'),
                                endTime: moment(event?.etime).format('HH:mm'),
                                startDate: event?.start,
                                endDate: lastWorkDay.toDate(),
                                btnName: '수정',
                            });
                            setTempSelectionRange([
                                {
                                    startDate: new Date(event?.start),
                                    endDate: lastWorkDay.toDate(),
                                    key: 'selection',
                                },
                            ]);
                            setFlexDetailOpen(true);
                        }
                        if (step !== 2 && (event.type == 'WORK' || schdules)) {
                            recordModalOpen(event);
                        }
                        if (step !== 2 && (event.type == 'REST' || event.type == 'USER_REST')) {
                            recordModalOpen(event);
                        }
                    }}>
                    <div
                        className={`border-1 ${rest ? 'border-success' : (business ? 'border-warning' : 'border-info')} px-1 bg-white`}
                        style={{ borderRadius: 5, borderStyle: (schdules || (rest && event?.state === 'REQUEST')) ? 'dashed' : 'solid' }}>
                        <Icon
                            icon={rest ? 'FlightTakeoff' : (business ? 'LocalTaxi' : 'DateRange')}
                            color={null}
                            style={{ color: !schdules ? (rest ? event?.state === 'SUCCESS' ? '#006D75' : tinycolor('#006D75') : (business ? tinycolor('#F1B44C') : tinycolor(mainColor).darken(30).toHexString())) : '#556EE680' }}
                        />
                    </div>

                    <div
                        className={`flex-grow-1 ms-2 border-1 ${rest ? 'border-success' : 'border-info'} ${schdules && 'bg-white'}`}
                        style={{ borderRadius: (schdules || (rest && event?.state === 'REQUEST')) && 5, borderStyle: (schdules || (rest && event?.state === 'REQUEST')) ? 'dashed' : null }}>
                        {rest ?
                            (
                                <div className='text-truncate text-center py-1' style={{ backgroundColor: (event?.state === 'SUCCESS') && '#7CB305', borderRadius: 5 }}>
                                    <span className={`${event?.state === 'SUCCESS' ? 'fw-bold text-white' : 'text-muted'}`}>{truncateString(title || '', 5)}</span>
                                </div>
                            )
                            : (
                                <div
                                    className={`d-flex flex-row justify-content-between align-items-center px-2 fs-5 ${!schdules && 'fw-bold'}`}
                                    style={{ backgroundColor: (!schdules && !business) ? '#10239E' : (!schdules && business) ? '#FFC53D' : '', borderRadius: 5, color: schdules && '#A4A6A9' }}>
                                    {event?.stime && <span className={`small ${!schdules && 'text-white'}`}>{moment(event?.stime).format('HH:mm')}</span>}
                                    <span className='text-white fw-bold'>{'-'}</span>
                                    {event?.etime && <span className={`small ${!schdules && 'text-white'}`}>{moment(event?.etime).format('HH:mm')}</span>}
                                </div>
                            )}
                        {}
                    </div>
                </div>
                 <div className={'text-black-50 text-end mt-1'} style={{fontSize: '12px'}}>종일</div>
                </>

                }
            </>
        );
    };

    const convertMinsToHrsMins = useCallback(
        (mins) => {
            let h = Math.floor(mins / 3600);
            let m = Math.floor((mins % 3600) / 60);
            let s = Math.floor(mins % 60);
            //let h = Math.floor(mins / (60 * 60));
            //let m = mins % (60);
            h = h < 10 && h > 0 ? '0' + h : h;
            m = m < 10 ? '0' + m : m;
            return t(`{{hour}} ${mobileDesign ? 'h' : '시간'} ${m > 0 ? m + (mobileDesign ? 'm' : '분') : ''}`, { hour: h, minute: m });
        }, [mobileDesign]);

    const DragAndDropCalendarRef = useRef(null);

    useEffect(() => {
        if (DragAndDropCalendarRef.current) {
            // console.log('DragAndDropCalendarRef.current', DragAndDropCalendarRef.current);
            // DragAndDropCalendarRef.current.
        }
    }, [mobileDesign]);

    const checkDeadlineUpdatable = async (e) => {
        let clickDate = e.start.format('YYYY-MM-DD'); // 근무 수정을 위해 클릭한 날짜

        const {data: approvable} = await deadlineService.attendUpdatable({
            userId: user.me.id,
            companyId: company.get.id,
            eventStartDate: clickDate,
            eventEndDate: clickDate
        })

        //approvable 수정
        return approvable
    }

    return (
        <>
            <Calendar
                ref={DragAndDropCalendarRef}
                toolbar={false}
                date={mobileDesign ? moment(todate).startOf('month').toDate() : todate}
                defaultDate={defaultDate}
                // defaultView={Views.MONTH}
                // view={'month'}
                defaultView={mobileDesign && step != 2 ? Views.AGENDA : Views.MONTH}
                view={mobileDesign && step != 2 ? 'agenda' : 'month'}
                views={{
                    month: true,
                    agenda: true,
                }}
                events={events}
                localizer={localizer}
                onShowMore={(events, date) => {
                    console.log('onShowMore events', events, 'date', date);
                }}
                // timeslots={1}
                // onDropFromOutside={onDropFromOutside}
                // onDragOver={customOnDragOver}
                // onEventDrop={moveEvent}
                resizable
                onSelectSlot={(event) => {
                    // console.log('event', event) //해당 날짜는
                    if (step !== 2) {
                        recordModalOpen(event);
                    }
                }}
                // selectable
                popup
                // dayPropGetter={(date) => {
                // 	console.log('dayPropGetter date', date);
                // }}
                messages={{ date: '일자', event: '근무일정', time: '시간', noEventsInRange: <div className='w-100 text-center mt-5'>{'일정이 없습니다'}</div> }}
                showAllEvents
                // onView={(view) => {
                // 	console.log('onView view', view);
                // }}
                // onRangeChange={(range, view) => {
                // 	console.log('onRangeChange range', range, 'view', view);

                // }}
                // onNavigate={(date, view, action) => {
                // 	console.log('onNavigate date', date, 'view', view, 'action', action);
                // 	// let start, end;

                // 	// if (view === 'month') {
                // 	// 	start = moment(date).startOf('month');
                // 	// 	end = moment(date).endOf('month');
                // 	// }
                // 	// if (view === 'agenda') {
                // 	// 	start = moment(date).startOf('month');
                // 	// 	end = moment(date).endOf('month');
                // 	// }

                // 	// return { start, end };
                // }}
                components={{
                    agenda: {
                        date: agendaDateContent,
                        time: agendaTimeContent, // css로 숨김처리
                        event: dateContent,
                    },
                    month: {
                        dateHeader: MonthCellHeaderContent,
                        event: dateContent,
                    },
                    dateCellWrapper: MyDateCellWrapper,
                }}
            />

            {/** 탄력근무 날짜시간 설정 모달 */}
            <Modal size='md' isStaticBackdrop={true} isOpen={flexDetailOpen} setIsOpen={setFlexDetailOpen} isScrollable isCentered>
                <ModalHeader className='fs-4 fw-bold' setIsOpen={setFlexDetailOpen}>
                    {t('근무')}
                </ModalHeader>
                <ModalBody className='user-modal-body'>
                    <div className='work-content-left work-req-vac-txt-g'>
                        <FormGroup>
                            <Label className={'fw-bold'} style={{ color: 'black' }}>
                                {t('시간설정')}
                            </Label>
                            <Badge
                                color={'#D9D9D980'}
                                style={{
                                    marginLeft : 2,
                                    backgroundColor: '#D9D9D980',
                                    borderRadius: 3,
                                    color: '#74788D',
                                }}>
                                {t(convertMinsToHrsMins2(flexTimeSetting))}
                            </Badge>
                            <span className='text-danger'>&nbsp;*</span>
                            {/*<span>{flexTimeSetting}</span>*/}

                            <div className='d-flex justify-content-around mt-4'>
                                <div className='w-100 pe-3'>
                                    <span className='text-muted fw-normal'>{t('시작시간')}</span>
                                    <Select2
                                        ariaLabel='Select StartTime'
                                        //className='d-flex align-items-center my-3 px-3'
                                        className= 'mt-3'
                                        options={flexStartTime}
                                        placeholder={t('시간 선택')}
                                        onChange={(e) => {
                                            setFlexDeatilTimeNDate((prevState) => ({
                                                ...prevState,
                                                startTime: moment(e.value, 'HHmm').format('HH:mm')
                                            }));
                                            setFlexDeatilTimeNDate((prevState) => ({
                                                ...prevState,
                                                endTime: moment(e.value, 'HHmm').add(9, 'hour').format('HH:mm'),
                                            }));
                                            setEndTimePlus({
                                                ...endTimePlus,
                                                value: moment(e.value, 'HHmm').add(9, 'hour').format('HH:mm'),
                                                label: moment(e.value, 'HHmm').add(9, 'hour').format('HH:mm'),
                                            })
                                        }}
                                        style={
                                            {
                                                padding: '1rem 8rem 1rem 1rem',
                                            }
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            borderColor: '#D9D9D9',
                                        })}
                                    />
                                </div>
                                <div className='w-100 pl-3'>
                                    <span className='text-muted fw-normal'>{t('종료시간')}</span>
                                    <Select2
                                        ariaLabel='Select endTime'
                                        className='mt-3'
                                        options={flexEndTime}
                                        placeholder={t('시간 선택')}
                                        value={endTimePlus}
                                        onChange={(e) => {
                                            setFlexDeatilTimeNDate((prevState) => ({
                                                ...prevState,
                                                endTime: moment(e.value, 'HHmm').format('HH:mm')
                                            }));

                                            setEndTimePlus({
                                                ...endTimePlus,
                                                value: moment(e.value, 'HHmm').format('HH:mm'),
                                                label: moment(e.value, 'HH:mm').format('HH:mm'),
                                            })
                                        }}
                                        style={
                                            {
                                                padding: '1rem 8rem 1rem 1rem',
                                                width: '-webkit-fill-available'
                                            }
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            borderColor: '#D9D9D9',
                                        })}
                                    />

                                </div>
                            </div>
                            {flexError?.bool == true && (
                                <div className='cancel-confirm-modal-margin text-danger'>
                                    <span className='text-danger'>&nbsp;*&nbsp;</span>
                                    {flexError?.text == 'startTime' && '시작시간이 의무시간과 다릅니다'}
                                    {flexError?.text == 'endTime' && '종료시간이 의무시간과 다릅니다'}
                                    {flexError?.text == 'minTime' && '최소 4시간 미만 근무가 불가합니다'}
                                    {flexError?.text == 'maxTime' && '최대 12시간 이하로 근무 등록이 가능합니다'}
                                </div>
                            )}
                        </FormGroup>
                    </div>

                    <div className='work-content-left text-truncate' style={{ marginBottom: 10, marginTop: 10 }}>
                        <div className='work-req-vac-txt-g'>
                            <FormGroup>
                                <Label className={'fw-bold'} style={{ color: 'black' }}>
                                    {t('날짜 선택')}
                                </Label>
                                <span className='text-danger'>&nbsp;*</span>
                                <div className='d-flex justify-space-around w-100'>
                                    <Popovers
                                        id='selectDate'
                                        placement={mobileDesign ? 'auto-bottom':'right'}
                                        ref={popRef}
                                        desc={
                                            <div className='row w-100'>
                                                <div className='col-xl-12'>
                                                    <Card className='shadow-none w-100 mb-0'>
                                                        <CardBody className='px-0 pb-0 row'>
                                                            <div className='col-sm-9 px-0'>
                                                                <DateRange
                                                                    locale={locales['ko']}
                                                                    editableDateInputs={false}
                                                                    // dragSelectionEnabled={isDisabled}
                                                                    dateDisplayFormat='yyyy-MM-dd'
                                                                    ranges={tempSelectionRange}
                                                                    maxDate={new Date(flexibeSelectDate.endDate)}
                                                                    minDate={new Date(flexibeSelectDate.startDate)}
                                                                    // showSelectionPreview={true}
                                                                    moveRangeOnFirstSelection={false}
                                                                    // retainEndDateOnFirstSelection={false}
                                                                    className='p-3'
                                                                    // onRangeFocusChange={(e)=>{
                                                                    // 	console.log(e)
                                                                    // 	// setIsCalendar(e[1] === 1)
                                                                    // }}
                                                                    onChange={(item) => {
                                                                        setTempSelectionRange([item.selection]);
                                                                    }}
                                                                    disabledDay={(date) => groups?.isHolidayWork == false && disableHoliday(date)}
                                                                    months={1}
                                                                    direction='horizontal'
                                                                    //scroll={{enabled: true}}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                        <CardFooter className='py-0 my-0'>
                                                            <Button
                                                                type='button'
                                                                color='success'
                                                                onClick={() => {
                                                                    if (popRef.current) popRef.current.onOpen();
                                                                }}>
                                                                {t('닫기')}
                                                            </Button>
                                                        </CardFooter>
                                                    </Card>
                                                </div>
                                            </div>
                                        }
                                        // placement='bottom-end'
                                        className='mw-100'
                                        trigger='click'>

                                        <div className={ mobileDesign ?  'd-flex justify-space-around flex-wrap row' : 'd-flex justify-space-around w-100'}>
                                            <div className={ mobileDesign ? 'col-md-6' : 'w-100 pe-3'}>
                                                <div className='user-gray-text'>{t('시작일')}</div>
                                                <div
                                                    className='user-content-cal d-flex align-items-center work-gray-text w-100'
                                                    onClick={() => {
                                                        popRef.current.onOpen();
                                                    }}>
                                                    <Icon icon='CalendarToday' size='lg' style={{ marginLeft: '14px', marginRight: '14px' }} />
                                                    {tempSelectionRange !== '' ? (
                                                        <span className='fw-bold' style={{ color: 'black' }}>
															{`${moment(tempSelectionRange[0].startDate).format('YYYY-MM-DD')}`}
														</span>
                                                    ) : (
                                                        <span>{t('시작일 선택')}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={ mobileDesign ? 'col-md-6' : ' w-100 pl-3'}>
                                                <div className='user-gray-text'>{t('종료일')}</div>
                                                <div className='user-content-cal d-flex align-items-center work-gray-text w-100'>
                                                    <Icon icon='CalendarToday' size='lg' style={{ marginLeft: '14px', marginRight: '14px' }} />
                                                    {tempSelectionRange !== '' ? (
                                                        <span className='fw-bold' style={{ color: 'black' }}>
															{`${moment(tempSelectionRange[0].endDate).format('YYYY-MM-DD')}`}
														</span>
                                                    ) : (
                                                        <span>{t('종료일 선택')}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Popovers>
                                </div>
                            </FormGroup>
                        </div>
                    </div>

                    <div className='row d-flex work-req-vac-txt-g'>
                        <div className='row cancel-confirm-modal-outer d-flex justify-content-end'>
                            {' '}
                            {/* <span className='cancel-confirm-modal-button-margin'> */}
                            {flexDeatilTimeNDate?.btnName == '수정' ? (
                                <Button
                                    className='mt-3 me-2 btn-only-icon'
                                    isOutline
                                    type={'button'}
                                    color={'light'}
                                    style={{ borderStyle: 'dashed', width: 60 }}
                                    rounded={1}
                                    onClick={() => {
                                        let deleteFlex = events.filter((item) => {
                                            return !(item.title == '탄력근무' && moment(item?.start).format('YYYY-MM-DD') == moment(flexDeatilTimeNDate?.startDate).format('YYYY-MM-DD'));
                                        });

                                        let deleteFlexSend = eventItems.filter((item) => {
                                            return !(item.title == '탄력근무' && moment(item?.start).valueOf() == moment(flexDeatilTimeNDate?.startDate).valueOf());
                                        });

                                        setEventItems(deleteFlexSend);

                                        setMyEvents(deleteFlex);
                                    }}>
                                    <img src={BtnTrashbin} alt='' width='20px' />
                                </Button>
                            ) : null}
                            {/* </span> */}
                            {/* <span className='cancel-confirm-modal-button-margin'> */}
                            <Button
                                className='mt-3 me-2'
                                type={'button'}
                                rounded={1}
                                color={'info'}
                                style={{ width: 140 }}
                                isDisable={
                                    flexError?.bool == true || tempSelectionRange[0].startDate == '' || tempSelectionRange[0].endDate == '' || flexDeatilTimeNDate?.startTime == '' || flexDeatilTimeNDate?.endTime == ''
                                }
                                onClick={() => {
                                    const startDate = moment(tempSelectionRange[0].startDate);
                                    const endDate = moment(tempSelectionRange[0].endDate);
                                    const startTime = flexDeatilTimeNDate?.startTime;
                                    const endTime = flexDeatilTimeNDate?.endTime;

                                    console.log('flexDeatilTimeNDate ? startTime', flexDeatilTimeNDate?.startTime);

                                    let filteredEvents = [...events];

                                    //날짜 중복

                                    const eventsNew = [];

                                    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
                                    const formattedStartTime = moment(startTime, 'HH:mm');
                                    const combinedStartDateTime = moment(`${formattedStartDate} ${startTime}`, 'YYYY-MM-DD HH:mm');

                                    let formattedEndDate = moment(endDate).format('YYYY-MM-DD');

                                    const formattedEndTime = moment(endTime, 'HH:mm');

                                    // if(formattedStartTime < formattedEndTime ){
                                    // 	formattedEndDate.add(1, 'days')
                                    // }

                                    const combinedEndDateTime = moment(`${formattedEndDate} ${endTime}`, 'YYYY-MM-DD HH:mm');

                                    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
                                        const dateFormat = moment(date).format('YYYY-MM-DD');
                                        const dayIndex = moment(date).day();
                                        let endDateChange = moment(date);

                                        if (formattedEndTime.isBefore(formattedStartTime)) {
                                            endDateChange.add(1, 'days');
                                        }

                                        let startInData = moment(`${dateFormat} ${startTime}`, 'YYYY-MM-DD HH:mm');
                                        let endInData = moment(`${endDateChange.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm');

                                        let durationInSeconds = moment.duration(endInData.diff(startInData)).asSeconds();

                                        //휴게시간
                                        let restTimes = 0;
                                        if (groups?.restAuto == false) {
                                            if (groups?.restTime[0][0][0] !== '') {
                                                let diffRestTime = 0;
                                                groups?.restTime[dayIndex].map((_, i) => {
                                                    let startRestTime = moment(groups?.restTime[dayIndex][i][0], 'HH:mm');
                                                    let endRestTime = moment(groups?.restTime[dayIndex][i][1], 'HH:mm');
                                                    let restTimesDiff = moment.duration(endRestTime.diff(startRestTime)).asSeconds();
                                                    restTimes = diffRestTime += restTimesDiff;
                                                });
                                            } else if (groups?.restTime[0][0][0] === '') {
                                                restTimes = Math.floor(parseInt(durationInSeconds) / (240 * 60)) * (30 * 60);
                                            }
                                        } else {
                                            restTimes = 0;
                                        }

                                        restTimes = restTimes > 0 ? restTimes : 0;

                                        eventsNew.push({
                                            id: `attendFlexible-$${moment(date).valueOf()}`,
                                            title: '탄력근무',
                                            type: 'WORK',
                                            start: date.clone(),
                                            end: date.clone(),
                                            stime: moment(`${dateFormat} ${startTime}`, 'YYYY-MM-DD HH:mm'),
                                            etime: moment(`${endDateChange.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm'),
                                            color: '#556EE6',
                                            row: { workingTime: durationInSeconds, restTime: restTimes },
                                            Reqbool: true,
                                        });
                                    }

                                    let filterFlexEvents = eventsNew.filter((item) => {
                                        const hasOverlap = events.some(
                                            (event) =>
                                                (event.title !== '탄력근무' && moment(event.start).isSame(moment(item.etime), 'day') && moment(event.end).isSame(moment(item.stime), 'day')) ||
                                                (event.title !== '탄력근무' && event?.duration?.length > 0 && moment(event?.duration[0], 'YYYY-MM-DD').isSame(moment(item?.start).format('YYYY-MM-DD')))
                                        );

                                        if (groups?.isHolidayWork) {
                                            return !hasOverlap;
                                        } else {
                                            const itemStart = moment(item?.start);
                                            const itemEnd = moment(item?.end);

                                            const isWorkDayStart = groups?.workDay?.includes(itemStart.day());
                                            const isWorkDayEnd = groups?.workDay?.includes(itemEnd.day());
                                            const isNotHolidayStart = !holydays.some((disabledDate) => itemStart.isSame(moment(disabledDate.date), 'day'));
                                            const isNotHolidayEnd = !holydays.some((disabledDate) => itemEnd.isSame(moment(disabledDate.date), 'day'));

                                            return !hasOverlap && isWorkDayStart && isWorkDayEnd && isNotHolidayStart && isNotHolidayEnd;
                                        }
                                    });

                                    const allnoFlier = [...filteredEvents, ...filterFlexEvents];

                                    let flexOverCheck = allnoFlier.filter((item) => {
                                        const isOverlap = filterFlexEvents.some((event) => {
                                            let s = moment(event.start);
                                            let e = moment(event.end);
                                            let sitem = moment(item.start);
                                            let eitem = moment(item.end);

                                            return event.title === '탄력근무' && s.isSame(sitem, 'day') && e.isSame(eitem, 'day');
                                        });

                                        return !isOverlap;
                                    });

                                    const flexWorkData = [...flexOverCheck, ...filterFlexEvents].filter((data) => data.title === '탄력근무');

                                    const mappedFlexWorkData = flexWorkData.map((item) => {
                                        return {
                                            id: `attendFlexible-$${moment(item.start).valueOf()}${Math.floor(100000 + Math.random() * 900000)}`,
                                            title: '탄력근무',
                                            type: 'WORK',
                                            start: moment(item?.stime).valueOf(),
                                            end: moment(item?.etime).valueOf(),
                                            stime: moment(item?.stime).valueOf(),
                                            etime: moment(item?.etime).valueOf(),
                                            color: '#556EE6',
                                        };
                                    });

                                    setEventItems(mappedFlexWorkData);

                                    setMyEvents([...flexOverCheck, ...filterFlexEvents]);

                                    setFlexDetailOpen(false);
                                }}>
                                <span className='text-white'>{flexDeatilTimeNDate?.btnName}</span>
                            </Button>
                            {/* </span> */}
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/** 근무기록 수정 모달 */}
            <Modal size='md' isStaticBackdrop={true} isOpen={workRecordModal} setIsOpen={setWorkRecordModal} isScrollable isCentered>
                <ModalHeader setIsOpen={setWorkRecordModal} style={{ borderBottom: '1px solid #D9D9D9' }}>
                    <div style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}></div>
                    <div className='mb-3'>
                        <div className='mb-1'>
							<span className='fs-5 fw-bold text-dark'>
								{workRecordInfo[0]?.type === 'WORK'
                                    ? t(workRecordInfo[0]?.row?.dates?.history[0]?.attendCategory?.name === '출장' ? '출장' : '근무')
                                    : moment(workRecordInfo[0]?.start).format('dd') === '토' || moment(workRecordInfo[0]?.start).format('dd') === '일'
                                        ? '휴일'
                                        : workRecordInfo[0]?.type?.includes('REST')
                                            ? t(workRecordInfo[0]?.title)
                                            : '근무'}
							</span>
                        </div>
                        {workRecordInfo[0]?.type?.includes('REST') ? (
                            <div>
                                <span className=''>{moment(workRecordInfo[0]?.start).format('MM월 DD일 (dd)')}</span>

                                {workRecordInfo[0]?.duration.length > 1 ? moment(workRecordInfo[0]?.duration[workRecordInfo[0].duration.length - 1]).format(' - MM월 DD일 (dd)') : ''}
                            </div>
                        ) : (
                            <span className=''>{moment(workRecordInfo[0]?.start).format('MM월 DD일 (dd)')}</span>
                        )}
                    </div>
                </ModalHeader>
                <ModalBody className='user-modal-body mb-5 mx-4'>
                    {workRecordInfo[0]?.type?.includes('REST') ? (
                        <div className='cancel-confirm-modal-margin'>
                            <div style={{ marginTop: 25 }}>
                                <div className='pb-2' style={{ borderBottom: '1px solid #D9D9D9' }}>
                                    <span className='fw-bold'>{t('휴가 사용 시간')}</span>
                                    <div className='d-flex align-items-center my-3 mx-2'>
                                        <Icon icon='Clock' />
                                        <div className='mx-3'>
											<span className='fw-bold text-dark fs-5'>
												{workRecordInfo[0]?.duration.length > 1 || workRecordInfo[0]?.deduction === 1 || workRecordInfo[0]?.deduction === 0
                                                    ? `${workRecordInfo[0]?.duration.length}일`
                                                    : `${workRecordInfo[0]?.deduction / 0.125}시간`}
											</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {workRecordInfo[0]?.deduction !== 0 && workRecordInfo[0]?.deduction !== 1 && (
                                <div className='d-flex justify-content-start mt-4'>
                                    <div style={{width: 'calc(50% - 10px)'}}>
                                        <span className='text-dark fw-bold'>{t('시작시간')}</span>
                                        <div className='my-3 d-flex align-items-center' style={{ border: '1px solid #D9D9D9', borderRadius: 7, padding: '12px 0px 12px 12px' }}>
                                            <Icon className='me-3' icon='Clock' />
                                            <span className='fw-bold'>{workRecordInfo[0]?.stime ? moment(workRecordInfo[0]?.stime).format('A hh:mm') : '-- -- : --'}</span>
                                        </div>
                                    </div>
                                    <div style={{width: 'calc(50% - 10px)'}}>
                                        <span className='text-dark fw-bold'>{t('종료시간')}</span>
                                        <div className='my-3 d-flex align-items-center' style={{ border: '1px solid #D9D9D9', borderRadius: 7, padding: '12px 0px 12px 12px' }}>
                                            <Icon className='me-3' icon='Clock' />
                                            <span className='fw-bold'>{workRecordInfo[0]?.etime ? moment(workRecordInfo[0]?.etime).format('A hh:mm') : '-- -- : --'}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='d-flex'>
                                <div className='w-50' style={{ marginTop: 25 }}>
                                    <div className='pb-2'>
                                        <span className='fw-bold'>{t('사유')}</span>
                                        <div className='d-flex align-items-center my-3 mx-2'>
                                            <span className='text-muted'>{workRecordInfo[0]?.etc ? workRecordInfo[0]?.etc : t('사유가 없습니다') + '.'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-50' style={{ marginTop: 25 }}>
                                    <div className='pb-2'>
                                        <span className='fw-bold'>{t('메모')}</span>
                                        <div className='d-flex align-items-center my-3 mx-2'>
                                            <span className='text-muted'>{workRecordInfo[0]?.memo ? workRecordInfo[0]?.memo : t('메모가 없습니다') + '.'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : workRecordInfo?.length > 1 || workRecordInfo[0]?.type === 'WORK' ? (
                        <div className='cancel-confirm-modal-margin'>
                            <div style={{ marginTop: 25 }}>
                                <div className='pb-2' style={{ borderBottom: '1px solid #D9D9D9' }}>
                                    <span className='fw-bold'>{t('총 근무시간')}</span>
                                    <div className='d-flex align-items-center my-3 mx-2'>
                                        <Icon icon='Clock' />
                                        <div className='mx-3'>
											<span className='fw-bold text-dark fs-5'>
												{convertMinsToHrsMins2((workRecordInfo[0]?.row?.workingTime || 0) + (workRecordInfo[0]?.row?.nightTime || 0) - (workRecordInfo[0]?.row?.restTime || 0))}
											</span>
                                        </div>
                                        <Badge
                                            color={workRecordInfo[0]?.row?.workingOverTime || workRecordInfo[0]?.row?.nightOverTime ? '#FF3B3B1A' : '#D9D9D980'}
                                            style={{
                                                backgroundColor: workRecordInfo[0]?.row?.workingOverTime || workRecordInfo[0]?.row?.nightOverTime ? '#FF3B3B1A' : '#D9D9D980',
                                                borderRadius: 3,
                                                color: workRecordInfo[0]?.row?.workingOverTime || workRecordInfo[0]?.row?.nightOverTime ? '#E92C2C' : '#74788D',
                                            }}>
                                            {t(
                                                workRecordInfo[0]?.row?.workingOverTime || workRecordInfo[0]?.row?.nightOverTime
                                                    ? convertMinsToHrsMins2((workRecordInfo[0]?.row?.workingOverTime || 0) + (workRecordInfo[0]?.row?.nightOverTime || 0) - (workRecordInfo[0]?.row?.restOverTime || 0))
                                                    : '연장 없음'
                                            )}
                                        </Badge>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between my-4'>
                                <div style={{width: 'calc(50% - 10px)'}}>
                                    <span className='text-dark fw-bold'>{t('시작시간')}</span>
                                    <div className='my-3 d-flex align-items-center' style={{ border: '1px solid #D9D9D9', borderRadius: 7, padding: '12px 0px 12px 12px' }}>
                                        <Icon className='me-3' icon='Clock' />
                                        <span className='fw-bold'>{workRecordInfo[0]?.stime ? moment(workRecordInfo[0]?.stime).format('A hh:mm') : '-- -- : --'}</span>
                                    </div>
                                </div>
                                <div style={{width: 'calc(50% - 10px)'}}>
                                    <span className='text-dark fw-bold'>{t('종료시간')}</span>
                                    <div className='my-3 d-flex align-items-center' style={{ border: '1px solid #D9D9D9', borderRadius: 7, padding: '12px 0px 12px 12px' }}>
                                        <Icon className='me-3' icon='Clock' />
                                        <span className='fw-bold'>{workRecordInfo[0]?.etime ? moment(workRecordInfo[0]?.etime).format('A hh:mm') : '-- -- : --'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='align-items-center d-flex'>
                                <Icon icon='Calendar' />
                                <div className='mx-3'>
                                    {workRecordInfo.findIndex((i) => i.type === 'WORK_SCHDULE') > -1 ? (
                                        <div>
											<span className='fw-bold'>
												{moment(workRecordInfo.find((i) => i.type === 'WORK_SCHDULE').stime).format('A hh:mm') +
                                                    ' - ' +
                                                    moment(workRecordInfo.find((i) => i.type === 'WORK_SCHDULE').etime).format('A hh:mm')}
											</span>
                                            &nbsp;&nbsp;&nbsp;
                                            {/* <span className='fw-bold'>
												{`(${moment(workRecordInfo.find(i => i.type === 'WORK_SCHDULE').etime).diff(moment(workRecordInfo.find(i => i.type === 'WORK_SCHDULE').stime), 'hours') - 1}시간)`}
											</span>&nbsp;&nbsp;&nbsp; */}
                                            <span className='text-muted'>{t('예정됨')}</span>
                                        </div>
                                    ) : (
                                        <span>{t('예정된 근무 일정이 없습니다') + '.'}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : workRecordInfo?.length === 1 ? (
                        workRecordInfo[0]?.type === 'WORK_SCHDULE' ? ( // 계획근무만 존재
                            <div className='cancel-confirm-modal-margin'>
                                <div style={{ marginTop: 25 }}>
                                    <div className='pb-2' style={{ borderBottom: '1px solid #D9D9D9' }}>
                                        <span className='fw-bold'>{t('총 근무시간')}</span>
                                        <div className='text-muted d-flex fs-5 justify-content-center align-items-center my-3 mx-2 py-5'>
                                            <Icon icon='Clock' size='xl' />
                                            <div className='mx-2'>
                                                <span>{t('근무한 시간이 없습니다') + '.'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='align-items-center d-flex mt-4'>
                                    <Icon icon='Calendar' />
                                    <div className='mx-3'>
                                        <div>
											<span className='fw-bold'>
												{moment(workRecordInfo.find((i) => i.type === 'WORK_SCHDULE').stime).format('A hh:mm') +
                                                    ' - ' +
                                                    moment(workRecordInfo.find((i) => i.type === 'WORK_SCHDULE').etime).format('A hh:mm')}
											</span>
                                            &nbsp;&nbsp;&nbsp;
                                            <span className='text-muted'>{t('예정됨')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : !workRecordInfo[0]?.id ? (
                            <div className='text-muted d-flex justify-content-center align-items-center py-5 fs-5' style={{ marginTop: 45 }}>
                                <div className='me-3'>
                                    <Icon icon='Calendar' size={'3x'} />
                                </div>
                                <div>
                                    <span>{t('근무 일정이 없습니다') + '.'}</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                </ModalBody>
                <ModalFooter className='justify-content-end'>
                    {/* 해당 연차 전일까지만 회수 및 취소요청 가능 */}
                    <Button
                        // disabled={
                        // 	// workRecordInfo[0]?.type?.includes('REST')
                        // 	// &&
                        // 	// !(moment(workRecordInfo[0]?.duration[0], 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')) && moment(workRecordInfo[0]?.duration[0]).diff(moment().format('YYYY-MM-DD'), 'day') > 0)
                        // }
                        style={{
                            border:
                            // !(
                            // 	workRecordInfo[0]?.type?.includes('REST')
                            // &&
                            // !(
                            // 	moment(workRecordInfo[0]?.duration[0], 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')) && moment(workRecordInfo[0]?.duration[0]).diff(moment().format('YYYY-MM-DD'), 'day') > 0
                            // )
                            // ) &&
                                '1px solid #FE637C',
                            borderRadius: 7,
                            color: '#FE637C',
                            padding: '10px 15px 10px 15px',
                        }}
                        onClick={async () => {
                            if (workRecordInfo[0]?.type?.includes('REST')) {
                                if (workRecordInfo[0]?.approvals[0]?.line[0]?.state === 'REQUEST') {
                                    const returnRest = await CompanyService.cancellationRequest(workRecordInfo[0]?.id);
                                    console.log('returnRest >>', returnRest);
                                    showNotification('휴가 회수', '휴가 회수가 완료되었습니다', 'info');
                                    setWorkRecordModal(false);
                                    reFresh();
                                } else {
                                    const cryptoUrl = Base64.encode(`${company.get.id}_${workRecordInfo[0]?.id}`);
                                    navigate(`/e-approval/document/${cryptoUrl}`);
                                    console.log('승인받은 기안 취소 기능은 준비중입니다.');
                                }
                            } else {
                                const updatable = await checkDeadlineUpdatable(workRecordInfo[0]);
                                if (!updatable) {
                                    setClosedAttendModalOpen(true);
                                } else {
                                    //근무 취소
                                    setRequestRecordEdit(true);
                                    setWorkRecordModal(false);
                                }
                                // reFresh();
                            }
                        }}>
                        {workRecordInfo[0]?.type?.includes('REST') ? (
                            workRecordInfo[0]?.type?.includes('REST') &&
                            // moment(workRecordInfo[0]?.duration[0], 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')) &&
                            // moment(workRecordInfo[0]?.duration[0]).diff(moment().format('YYYY-MM-DD'), 'day') > 0 &&
                            (
                                <span className='fw-bold'>{t(workRecordInfo[0]?.approvals[0]?.line[0]?.state === 'REQUEST' ? '회수' : '취소요청')}</span>
                            )
                        ) : (
                            <span className='fw-bold'>{t('근무기록 수정요청')}</span>
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
            {requestRecordEdit && <UserReqRecordEditModal isOpen={requestRecordEdit} setIsOpen={setRequestRecordEdit} data={workRecordInfo} />}

            <WorkRecodeCloseModal isOpen={closedAttendModalOpen} setIsOpen={setClosedAttendModalOpen} />
        </>
    );
};

export default WorkCalendars;
