import React, {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import { componentsMenu, dashboardMenu, demoPages, layoutMenu, commonsMenu, adminsMenu, footsMenu, usersMenu, noticeListMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import showNotification from '../../components/extras/showNotification';
import LANG, { getLangWithKey } from '../../lang';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Checks from '../../components/bootstrap/forms/Checks';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import Popovers from '../../components/bootstrap/Popovers';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useMst } from '../../models';
import { observer } from 'mobx-react';
import Badge from '../../components/bootstrap/Badge';
import CompanyService from '../../services/CompanyService';
import { setItem, getItem } from '../../lib/localstorage';
import {getRoleFilteredMenu, isPermittedPage} from "../../utils/role.utils";
import moment from 'moment';

const Aside = observer(() => {
	const { asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus, asideSubMenuStatus, asideMenu, mobileDesign, lang, setLang, noticeCount } = useContext(ThemeContext);
	const { asideStyle, asideSubStyle, touchStatus, hasTouchButton, asideWidthWithSpace, asideSubWidthWithSpace, x, x2 } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus,setDarkModeStatus } = useDarkMode();
	const { user, company, navtabmenus } = useMst();
	const navigation = useNavigate();
	const location = useLocation()
	const { i18n } = useTranslation();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const cleaerRestInfo = async () => {
		await company.setRestInfo({})
	}
	const setRestInfo = async () => {
		await CompanyService.restdayInfo({ companyId: company.id, userId: user.id }).then( async res => {
			console.log('res >> ',res)
			if(res?.data)
				await company.setRestInfo(res.data);
		});
	};

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng).then(()=>{
			moment.locale(lng);
			// moment.updateLocale(lng);
			// moment.defineLocale(lng);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`${getLangWithKey(lng)?.text}`}</span>
				</span>,
				t('You updated the language of the site.'),
			);
		}).then(()=>{
			setLang(lng);
		})
	};

	useEffect(() => {

		// console.log("----------------", JSON.stringify([]));
		const navTabls = JSON.parse(getItem(`navTabMenu_${company.get.id}_${company.get.isDoc ? 'admin':'user'}`) || JSON.stringify([]));
		// console.log('load tabs', navTabls);
		if(navtabmenus?.setMenus)
			navtabmenus?.setMenus(navTabls);

		if (company.get.isDoc) {
			// console.log('admin');
			cleaerRestInfo();
		} else {
			// console.log('user');
			setRestInfo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.get.isDoc, navtabmenus]);

	useEffect(() => {
		if (!isPermittedPage(user.me.permissions?.menuRole, location.pathname)) {
			alert('접근 권한이 없는 페이지 입니다.')
			navigation(-1)
		} else if (location.pathname === '/e-approval/setting/outOfOffice' && !(user.me.permissions?.detailRole?.approval?.absentee)) {
			// 부재설정 기능은 권한관리의 detailRole에 속해 있어서, 전체적인 page Layout Aside.js 에서 판단하는것이 아니라 이곳에다가 따로 path를 부여하는식으로 체크 했습니다.
			alert('접근 권한이 없는 페이지 입니다.')
			navigation(-1)
		}
	}, [location.pathname, navigation, user.me.permissions?.menuRole, user.me.permissions?.detailRole?.approval?.absentee])

	const [test, setTest] = useState(false)

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					}
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} asideSubStatus={asideSubStatus} setAsideSubStatus={setAsideSubStatus} />
				</div>
				<div className='aside-body'>
					{company.get.isAdmin &&
						<div className='navigation-item'>
							<div
								className='navigation-link cursor-pointer'
								onClick={() => {
									company.setIsAdmin(!company.get.isDoc);
									// 상단 탭 불러오기
									navigation('/');
								}}
							>
								<span className='navigation-link-info'>
								<Icon icon={company.get.isDoc ? 'ToggleOn' : 'ToggleOff'} color={company.get.isDoc ? 'success' : 'info'} size='2x' className='navigation-icon'/>
								<span className='navigation-text fw-bold'>{company.get.isDoc ? t('관리자 모드') : t('사용자 모드')}</span>
								</span>
							</div>
						</div>
					}
					<NavigationLine />

					<Navigation menu={commonsMenu} id='aside-dashboard' />
					{/*  사용자 전용 메뉴 */}
					{!company.get.isDoc && (
						<>
							<Navigation menu={(company.get.isAdmin || (user.me.workGubun != '기능직' && user.me.workGubun != '현장직')) ? usersMenu : Object.values(usersMenu).filter(menu => menu.id != 'approvalDocument')} id='aside-menu-two' />
						</>
					)}
					{/*  관리자 전용 메뉴 */}
					{company.get.isDoc && (
						<>
							<Navigation menu={getRoleFilteredMenu(user.me.permissions.menuRole, 'admin')} id='aside-menu-two' />
						</>
					)}
				</div>
				<div className='aside-foot'>
					{
						mobileDesign &&
						<ul className='navigation'>
							<li>
								<Link to={noticeListMenu.notice.path} className='navigation-link'>
									<Button
										{...styledBtn}
										icon='Campaign'
									/>
								</Link>
							</li>
							{/* 언어설정 */}
							<li>
								<Dropdown className='navigation-link'>
									<DropdownToggle hasIcon={false}>
										<Button
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											icon={getLangWithKey(i18n.language)?.icon}
											aria-label='Change language'
											data-tour='lang-selector'
										/>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
										{Object.keys(LANG).map((i) => (
											<DropdownItem key={LANG[i].lng}>
												<Button isDisable={lang === LANG[i].lng} icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
													{LANG[i].text}
												</Button>
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							</li>
							{/* 다크모드 */}
							<li>
								<div className='navigation-link'>
									<Popovers trigger='hover' desc={t('다크 / 화이트 모드')}>
										<Button
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											onClick={() => setDarkModeStatus(!darkModeStatus)}
											aria-label='Toggle fullscreen'
											data-tour='dark-mode'
										/>
									</Popovers>
								</div>
							</li>
						</ul>
					}
					{/* 사용자 버튼 */}
					<User />
				</div>
				{/* Aside 닫기 버튼(모바일) */}
				{
					(asideStatus && mobileDesign) &&
					<div
						className='position-absolute'
						style={{left: '15rem', background: '#F6F6F6', width: '40px', height: '80px', borderRadius: '0 8px 8px 0'}}
					>
						<Button
							type='button'
							aria-label='Toggle Aside'
							className='w-100 h-100 p-0'
							onClick={() => {
								setAsideStatus(!asideStatus);
								setAsideSubStatus(!asideSubStatus);
							}}>
							<Icon icon='FirstPage' size='3x' />
						</Button>
					</div>
				}
			</motion.aside>

			{/* SUB */}
			{asideSubMenuStatus && (
				<motion.asides
					style={asideSubStyle}
					className={classNames(
						'aside-sub',
						//{ 'd-none': !asideSubStatus && hasTouchButton && isModernDesign },
						{ open: asideSubStatus },
						{
							'aside-touch-bar': hasTouchButton && isModernDesign,
							'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
							'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
						}
					)}>
					<div className='aside-sub-head'></div>
					<div className='aside-sub-body'>
						<Navigation menu={asideMenu} id='aside-sub-dashboard' />
					</div>
					<div className='aside-sub-foot'></div>
				</motion.asides>
			)}

			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title={t('Toggle Aside')} flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => {
								// console.log('x.get()', x.get())
								// console.log('x2.get()', x2.get())
								// console.log('>>', x2.get() === 0 ? asideSubWidthWithSpace : 0)
								x.set(x.get() === 0 ? asideWidthWithSpace : 0);
								x2.set(x2.get() === 0 ? asideSubWidthWithSpace : 0);
							}}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
});

export default Aside;
