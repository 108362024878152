import React from 'react'
import Modal, {ModalBody, ModalFooter} from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import {useTranslation} from "react-i18next";
import imgNotice from "../../../../assets/img/deadline/img_notice.png";

const WorkRecodeCloseModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation(['translation', 'menu'])

  return (
    <Modal
      isCentered
      isStaticBackdrop
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      size={'sm'}
    >
      <div className='modal-wraped mx-3'>
        <ModalBody>
          <div className='text-center'>
            <img src={imgNotice} alt="경고" />
            <h5 className='fw-bold mt-4 lh-base'>
              {t('근무기록 마감')}
            </h5>
            <p className='text-muted mb-0'>
              {t('해당 근무기록은 마감되어')}
              <br />{t('수정하실 수 없습니다.')}
            </p>
          </div>   
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button
            style={{
              borderRadius: 7,
            }}
            color='info'
            onClick={() => setIsOpen(false)}
          >
            <span className='px-4'>{t('확인')}</span>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default React.memo(WorkRecodeCloseModal)