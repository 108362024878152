import { APIClient } from '../lib/apiHelper';

const { get, post, put, remove } = new APIClient();

export default {
	/**
	 * 전체 공지 가져오기
	 */
	get: (companyId) => get('/notice?companyId=' + companyId),
	/**
	 * 글 수정 or 삭제
	 */
	write: (notice) => post('/notice', notice),
	/**
	 * 글 삭제
	 */
	delete: (id) => remove('/notice?id=' + id),

	getById: (id) => get('/notice?noticeId=' + id),
};
